import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  Add,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import fonts from "../../libs/fonts";
import Flex from "../../components/flex/Flex";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function SubCategoryRows({
  subcategoryid,
  rows,
  i,
  setcategoryreser,
  categoryselectfunc,
  before,
  after,
  setsubcategoryid,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();

  var modeType = 0;
  if (!rows.detailGroup) {
    modeType = 1;
  }
  const [modCker, setmodCker] = useState(modeType);
  const [categoryinput, setcategoryinput] = useState(rows.detailGroup);

  const modChangeFunc = () => {
    if (modCker === 0) {
      setmodCker(1);
    } else {
      setmodCker(0);
    }
  };

  const modActiveFunc = (id) => {
    if (!categoryinput) {
      dispatch(
        actionOpen("카테고리 분류명을 입력해주세요", null, null, null, true)
      );
      return;
    }
    rows.kind = categoryinput;
    var params = {};
    var fn = null;

    if (id) {
      params = {
        detailGroup: categoryinput,
        sort: rows.sort,
        id: id,
      };

      fn = APIS.postGoodsTypeModify;
    } else {
      params = {
        detailGroup: categoryinput,
        sort: i + 1,
      };
      fn = APIS.postGoodsTypeCreate;
    }
    //   /api/goodswithdrawal/goodsCategory/create

    dispatch(loadingStart);

    fn(subcategoryid, params)
      .then(() => setcategoryreser(1))
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };
  const updateSort = (type) => {
    var params = {};
    var params2 = {};
    var url = "";

    if (type === "up") {
      if (!before) return;
      params = {
        detailGroup: before.detailGroup,
        sort: rows.sort,
        id: before.id,
      };

      APIS.postGoodsTypeModify(subcategoryid, params)
        .then(() => setcategoryreser(1))
        .catch((err) => dispatch(actionError(err)));

      params2 = {
        detailGroup: rows.detailGroup,
        sort: before.sort,
        id: rows.id,
      };

      APIS.postGoodsTypeModify(subcategoryid, params2)
        .then(() => setcategoryreser(1))
        .catch((err) => dispatch(actionError(err)));
    } else {
      if (!after) return;
      params = {
        detailGroup: after.detailGroup,
        sort: rows.sort,
        id: after.id,
      };

      APIS.postGoodsTypeModify(subcategoryid, params)
        .then(() => setcategoryreser(1))
        .catch((err) => dispatch(actionError(err)));

      params2 = {
        detailGroup: rows.detailGroup,
        sort: after.sort,
        id: rows.id,
      };
      APIS.postGoodsTypeModify(subcategoryid, params2)
        .then(() => setcategoryreser(1))
        .catch((err) => dispatch(actionError(err)));
    }
  };
  return (
    <>
      <tr key={i.toString() + rows.checked}>
        <td>
          {modeType === 0 && (
            <>
              <ArrowDropUpOutlined
                onClick={() => {
                  updateSort("up");
                }}
              />
              <ArrowDropDownOutlined
                onClick={() => {
                  updateSort("down");
                }}
              />
            </>
          )}
        </td>

        <td>{i + 1}</td>
        <td>
          {modCker === 0 ? (
            rows.detailGroup
          ) : (
            <Input
              value={categoryinput}
              onChange={(v) => {
                setcategoryinput(v);
              }}
              placeholder={""}
              className={classes.input}
            />
          )}
        </td>
        {modCker === 0 ? (
          <>
            <td
              style={{ cursor: "pointer", color: "red" }}
              onClick={() => modChangeFunc()}
            >
              수정{rows.id}
            </td>
          </>
        ) : (
          <>
            <td
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => modActiveFunc(rows.id ? rows.id : "")}
            >
              저장
            </td>
          </>
        )}
        <td>
          <Checkbox
            checked={rows.checked}
            onChange={(e) => categoryselectfunc(rows.id, e)}
          />
        </td>
      </tr>
    </>
  );
}

const useStyle = makeStyles({
  img2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 300,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  img: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px 20px",
    alignItems: "center",
    boxSizing: "border-box",
    width: "auto !important",
  },

  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },

  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "5px 10px",
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
  },
  radiogroup: {
    marginTop: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "80%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
});
