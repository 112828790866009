import {
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import SimpleTable from "../../components/simple-table/SimpleTable";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import PhysicalOrderDetailDialog from "./PhysicalOrderDetailDialog";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { objToQueryStr, numFormat } from "../../services/utils";
import { numberWithCommas, unComma } from "../../libs/utils";
import * as APIS from "../../libs/apis";
import consts from "../../libs/consts";
import StatusRadioGroup from "./components/StatusRadioGroup";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";

export default function PhysicalOrderDetail({}) {
  const dispatch = useDispatch();
  let clickCheck = false;
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};

  const classes = useStyle();
  const [dialog, setDialog] = useState(false);
  const [rows, setrows] = useState([]);
  const [product, setproduct] = useState({
    imageIds: [
      {
        path: "",
      },
    ],
  });

  const [status, setStatus] = useState("");
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [statussave, setstatussave] = useState("");
  const [shipmentStatusSave, setShipmentStatusSave] = useState("");
  const [estimateDate, setestimateDate] = useState("");
  const [memo, setmemo] = useState("");
  const [standardPay, setstandardPay] = useState("");
  const [additionalPay, setadditionalPay] = useState(0);
  const [priceSum, setpriceSumData] = useState(0);
  const [transOrderid, settransOrderid] = useState(0);
  const [imgspopOpen, setimgspopOpen] = useState(0);
  const [ordermemo, setordermemo] = useState("");
  const [historyset, sethistoryset] = useState({
    id: 0,
    memo: "",
  });
  const [histories, setHistories] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const { isKorda } = useSelector((s) => s.auth);

  const onSaveAdmin = async (adminPassword) => {
    if (!clickCheck) {
      clickCheck = true;

      var paramQuery = "";
      var data = {};

      if (shipmentStatus === "MAKE" || shipmentStatus === "SHIPMENT") {
        paramQuery = objToQueryStr({
          estimateDate: estimateDate,
          estimateOutSourcingDate: estimateDate,
          historyId: historyset.id,
          memo: memo,
          standardPay: standardPay,
          status: status,
          shipmentStatus: shipmentStatus,
          additionalPay: additionalPay,
          transId: state.id,
        });
        data = {
          estimateDate: estimateDate,
          estimateOutSourcingDate: estimateDate,
          historyId: historyset.id,
          memo: memo,
          standardPay: standardPay,
          status: status,
          shipmentStatus: shipmentStatus,
          additionalPay: additionalPay,
          transId: state.id,
          managerPassword: adminPassword,
        };
      } else {
        paramQuery = objToQueryStr({
          estimateDate: estimateDate,
          estimateOutSourcingDate: estimateDate,
          standardPay: standardPay,
          status: status,
          shipmentStatus: shipmentStatus,
          additionalPay: additionalPay,
          transId: state.id,
        });
        data = {
          estimateDate: estimateDate,
          estimateOutSourcingDate: estimateDate,
          standardPay: standardPay,
          status: status,
          shipmentStatus: shipmentStatus,
          additionalPay: additionalPay,
          transId: state.id,
          managerPassword: adminPassword,
        };
      }
      APIS.postProductOrderMod(paramQuery, data)
        .then(({ data }) => {
          if (!data.success) {
            dispatch(actionOpen(data.message, null, null, null, true));
          } else {
            dispatch(
              actionOpen(
                "제작 주문 정보가 수정되었습니다.",
                null,
                null,
                null,
                true
              )
            );

            if (status === "CANCEL") {
              history.go(-1);
            }

            onLoad();
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });

      // dispatch(loadingStart);
      // const data = {
      //   ids: state?.selected || [],
      //   status: selectState,
      //   managerPassword: adminPassword,
      // };
      // APIS.putGoodsWithdrawalStatus(data)
      //   .then(({ data: { data, message, success } }) => {
      //     if (success) {
      //       dispatch(
      //         actionOpen(
      //           "진행상태가 변경되었습니다.",
      //           () => {
      //             // handleValueChange("selected", []);
      //             history.replace({
      //               pathname: location.pathname,
      //               state: null,
      //             });
      //             // fetchList();
      //           },
      //           null,
      //           null,
      //           true
      //         )
      //       );
      //     } else {
      //       dispatch(actionError(message));
      //     }
      //   })
      //   .catch((error) => dispatch(actionError(error)))
      //   .finally(() => dispatch(loadingEnd));
      // }
      return () => {};
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    APIS.getOrderDetail(state.id).then(({ data }) => {
      setrows(data);
      setStatus(data.status);
      setShipmentStatus(data.shipmentStatus);
      setstatussave(data.status);
      setShipmentStatusSave(data.shipmentStatus);
      let reformData = [];
      data.goodsTransHistory.forEach((element) => {
        reformData.push({
          ...element,
          status: element.status
            .replace("APPROVAL", "결제")
            .replace("MAKE", "제작중")
            .replace("SHIPMENT", "출고중")
            .replace("DELIVERY", "출고완료")
            .replace("TAKEOUT", "인출완료")
            .replace("CONFIRM", "인출확정")
            .replace("CANCEL", "결제취소"),
        });
      });
      setHistoryList(reformData);
      APIS.getProductDetail(data.goodsId).then(({ data }) => {
        setproduct(data.data);

        // 기본 공임
        setstandardPay(data.data.pay);
      });

      APIS.postProductOrderHistory(data.transId).then(({ data }) => {
        setestimateDate(data.data.estimateOutSourcingDate);

        // setstandardPay(data.data.standardPay);

        // 추가 공임
        setadditionalPay(data.data.additionalPay);

        setmemo(data.data.memo);

        var priceSumData =
          data.data.standardPay * 1 + data.data.additionalPay * 1;

        settransOrderid(data.data.transId);
        var historyData = data.data.historys;
        for (let i in historyData) {
          if (
            historyData[i].status === "MAKE" ||
            historyData[i].status === "SHIPMENT"
          ) {
            var historyset = {
              id: historyData[i].id,
              memo: historyData[i].memo,
            };
            setmemo(historyData[i].memo);
            sethistoryset(historyset);
          }
        }
        setHistories(historyData);
      });
    });
  };

  const setpriceSum = (v) => {
    setstandardPay(v);
    setpriceSumData(additionalPay * 1 + v * 1);
  };
  const setpriceSum2 = (v) => {
    setadditionalPay(v);
    setpriceSumData(standardPay * 1 + v * 1);
  };

  const orderDatilSaver = () => {
    var paramQuery = "";
    var data = {};
    if (status === "MAKE") {
      paramQuery = objToQueryStr({
        estimateDate: estimateDate,
        estimateOutSourcingDate: estimateDate,
        historyId: historyset.id,
        memo: memo,
        standardPay: standardPay,
        status: status,
        additionalPay: additionalPay,
        transId: state.id,
      });
      data = {
        estimateDate: estimateDate,
        estimateOutSourcingDate: estimateDate,
        historyId: historyset.id,
        memo: memo,
        standardPay: standardPay,
        status: status,
        additionalPay: additionalPay,
        transId: state.id,
      };
    } else {
      paramQuery = objToQueryStr({
        estimateDate: estimateDate,
        estimateOutSourcingDate: estimateDate,
        standardPay: standardPay,
        status: status,
        additionalPay: additionalPay,
        transId: state.id,
      });
      data = {
        estimateDate: estimateDate,
        estimateOutSourcingDate: estimateDate,
        standardPay: standardPay,
        status: status,
        additionalPay: additionalPay,
        transId: state.id,
      };
    }

    if (status === "MAKE") {
      APIS.postProductOrderModPut(paramQuery, data)
        .then(({ data }) => {
          if (!data.success) {
            dispatch(actionOpen(data.message, null, null, null, true));
          } else {
            dispatch(
              actionOpen(
                "제작 주문 정보가 수정되었습니다.",
                null,
                null,
                null,
                true
              )
            );

            if (status === "CANCEL") {
              history.go(-1);
            }

            onLoad();
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    } else {
      APIS.postProductOrderMod(paramQuery, data)
        .then(({ data }) => {
          if (!data.success) {
            dispatch(actionOpen(data.message, null, null, null, true));
          } else {
            dispatch(
              actionOpen(
                "제작 주문 정보가 수정되었습니다.",
                null,
                null,
                null,
                true
              )
            );

            if (status === "CANCEL") {
              history.go(-1);
            }

            onLoad();
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };

  const canModify =
    isKorda &&
    (statussave === "APPROVAL" ||
      statussave === "TAKEOUT" ||
      statussave === "CONFIRM") &&
    (shipmentStatusSave === "NOT_SHIPPED" || shipmentStatusSave === "MAKE");

  return (
    <>
      {dialog && (
        <PhysicalOrderDetailDialog
          onClose={() => {
            setDialog(false);
          }}
        />
      )}
      <span className={classes.label}>주문 상세보기</span>
      <SimpleTable
        fullWidth
        columns={[
          {
            label: "주문번호",
            key: "no",
          },
          {
            label: "요청일",
            key: "createdAt",
            type: "datetime",
          },
          {
            label: "고객",
            key: "userName",
          },
          {
            label: "핸드폰",
            key: "phone",
            type: "phone",
          },
          {
            label: "방문예약",
            key: "visitDate",
          },
          {
            label: "매장명",
            key: "shopName",
          },
          {
            label: "인출상태",
            key: "status",
            type: "orderstatus",
          },
          {
            label: "인출확정일",
            key: "confirmDate",
            type: "datetime",
          },
          {
            label: "출고상태",
            key: "shipmentStatus",
            type: "orderstatus",
          },
          {
            label: "출고일",
            key: "shipmentDate",
            type: "datetime",
          },
        ]}
        data={[rows]}
      />
      <Flex row style={{ justifyContent: "right" }}>
        <StatusRadioGroup
          status={status}
          shipmentStatus={shipmentStatus}
          setShipmentStatus={setShipmentStatus}
          shipmentStatusSave={shipmentStatusSave}
          statussave={statussave}
          setStatus={setStatus}
          histories={histories}
        />
        <Flex className={classes.buttons} row>
          <Button
            label="취소"
            className={classes.bt1}
            onClick={() => {
              history.replace({
                pathname: location.pathname,
                state: { ...location.state },
              });
              onLoad();
            }}
          />
          <AdminConfirmButton
            popupLabel="상태 변경"
            popupMessage="변경사항을 저장하시겠습니까?"
            label="저장"
            callback={onSaveAdmin}
            rootClassName={classes.confirmRoot}
            btnClassName={classes.confirmButton}
          />
        </Flex>
      </Flex>
      <Flex row className={classes.main}>
        <Flex className={classes.mainC1}>
          <Flex row className={classes.header}>
            회원 주문 정보
          </Flex>
          <Flex className={classes.mainC1Content}>
            <Grid container spacing={3} className={classes.mainC2ContentGrid}>
              <Grid item xs={3}>
                주문번호
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.no}
              </Grid>
              <Grid item xs={3}>
                제품번호
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.code}
              </Grid>
              <Grid item xs={3}>
                제품분류
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.categoryName}
              </Grid>
              <Grid item xs={3}>
                상품명
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.name}
              </Grid>
              {/* <Grid item xs={3}>
                매장명
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.shopName}
              </Grid> */}
              <Grid item xs={3}>
                이미지
              </Grid>
              <Grid item xs={3} style={{ display: "flex" }}>
                <div className={classes.img}>
                  {product.imageIds.length > 0 && (
                    <img
                      src={
                        product.imageIds[0].path
                          ? consts.s3BaseUrl + "/" + product.imageIds[0].path
                          : ""
                      }
                      width="100"
                      alt="상품이미지"
                      style={{ cursor: "pointer" }}
                      onClick={() => setimgspopOpen(1)}
                    />
                  )}
                </div>
                {imgspopOpen === 1 && (
                  <div
                    className={classes.imgspop}
                    onClick={() => setimgspopOpen(0)}
                  >
                    <img
                      src={
                        product.imageIds[0].path
                          ? consts.s3BaseUrl + "/" + product.imageIds[0].path
                          : ""
                      }
                      width="60%"
                      alt="상품이미지"
                    />
                  </div>
                )}
              </Grid>

              <Grid item xs={3}>
                순도
              </Grid>
              <Grid item xs={3} style={{ display: "flex" }}>
                24k(999)
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={3}>
                중량
              </Grid>
              <Grid item xs={3} style={{ display: "flex" }}>
                {rows.totalGram}g
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={3}>
                수량
              </Grid>
              <Grid item xs={3} style={{ display: "flex" }}>
                {rows.qty}개
              </Grid>

              <Grid item xs={3}>
                요청사항
              </Grid>
              <Grid item xs={9} style={{ display: "flex" }}>
                {rows.optionValue}
              </Grid>
            </Grid>
          </Flex>
        </Flex>
        <Flex className={classes.mainC2}>
          <Flex row className={classes.header}>
            제작 주문 정보
          </Flex>
          <Flex className={classes.mainC1Content}>
            <Grid container spacing={3} className={classes.mainC2ContentGrid}>
              <Grid item xs={3}>
                외주제조사
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                {rows.outsourcing}
              </Grid>

              <Grid item xs={3}>
                출고 예정일
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                <input
                  type="date"
                  value={estimateDate}
                  onChange={(e) => setestimateDate(e.target.value)}
                  readOnly={!canModify}
                  disabled={!canModify}
                  style={{ width: "auto" }}
                />
              </Grid>
              {/*
              <Grid item xs={3}>
                본사 출고 예정
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                <input type="date" />
              </Grid>
              */}
              <Grid item xs={3}>
                주문 메모
              </Grid>
              <Grid item xs={9} style={{ color: "black !important" }}>
                <InputBase
                  className={classes.inputWrap}
                  multiline
                  value={memo}
                  readOnly={!canModify}
                  disabled={!canModify}
                  onChange={(e) => {
                    setmemo(e.target.value);
                  }}
                />
              </Grid>

              {isKorda && (
                <>
                  <Grid item xs={3}>
                    기본공임
                  </Grid>
                  <Grid item xs={9} style={{ color: "black !important" }}>
                    {numberWithCommas(standardPay)} 원
                  </Grid>

                  <Grid item xs={3}>
                    추가공임
                  </Grid>
                  <Grid item xs={9} style={{ color: "black !important" }}>
                    {canModify ? (
                      <InputBase
                        style={{
                          border: "1px solid #ddd",
                          marginRight: 10,
                        }}
                        value={numberWithCommas(additionalPay)}
                        onChange={(e) => {
                          setpriceSum2(unComma(e.target.value));
                        }}
                      />
                    ) : (
                      numberWithCommas(additionalPay)
                    )}
                    원
                  </Grid>
                  <Grid item xs={3}>
                    공임합계
                  </Grid>
                  <Grid item xs={9} style={{ color: "black !important" }}>
                    {numberWithCommas(
                      parseInt(unComma(+standardPay)) +
                        parseInt(unComma(+additionalPay))
                    )}{" "}
                    원
                  </Grid>
                </>
              )}
            </Grid>
            {/*
            <Button
              label="수정"
              style={{ alignSelf: "center", margin: "20px 0px" }}
            ></Button>
            */}
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <Flex className={classes.subTitle}>실물인출</Flex>
        <Flex>
          <SimpleTable
            fullWidth
            columns={[
              {
                label: "일시",
                key: "createdAt",
                type: "datetime",
              },
              {
                label: "상태",
                key: "status",
              },
              {
                label: "관리자",
                key: "managerName",
              },
              {
                label: "비고",
                key: "memo",
              },
            ].filter((x) => x)}
            data={historyList}
          />
        </Flex>
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  bt1: {
    backgroundColor: "#fff",
    border: "1px solid black",
    color: "black",
    "& span": { color: "black" },
    marginRight: 10,
  },
  multiInput: {
    border: "1px  solid #f2f2f2",
    minHeight: 200,
    minWidth: "70%",
    marginTop: 20,
  },
  img: {
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    alignItems: "center",
    width: "auto !important",
    "& div": {},
  },
  mainC1Content: {
    padding: "10px 20px",
  },

  main: {
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },
  buttons: {
    marginRight: "3%",
    // marginTop: 30,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  header: {
    backgroundColor: "#f2f2f2",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    alignSelf: "stretch",
    padding: 10,
  },
  mainC1: {
    flex: 4,
    borderRight: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2: { flex: 3 },
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",

    padding: "5px 10px",
  },
  inputWrap: {
    border: "1px solid #ddd",
    minHeight: 200,
    flex: 1,
    width: "100%",
    alignSelf: "stretch",
    alignItems: "center",
  },
  imgspop: {
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.7)",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    zIndex: "1000",
    overflow: "auto",
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
  },
  subTitle: {
    marginTop: "26px",
    marginLeft: "49px",
    fontWeight: "bold",
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 46,
    width: 40,
    marginLeft: 0,
  },
});
