import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import fonts from "../../libs/fonts";
import Button from "../button/Button";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function LabelTabs({ data, optionComponent }) {
  const classes = useStyle();
  const { replace, location } = useHistory();
  const state = location.state || {};
  return (
    <Flex row className={classes.root}>
      <Flex row className="row-center">
        {data.map((x, i) => {
          return (
            <ButtonBase
              onClick={() => {
                replace({
                  pathname: location.pathname,
                  state: { ...state, tab: x.value },
                });
              }}
              className={`${classes.button} ${
                state.tab === x.value && classes.buttonSel
              }`}
              key={i.toString()}
              disabled={x.disabled === "true" ? true : false}
            >
              {x.label} 
              {x.totalCount &&  ("(" + x.totalCount + ")")}
            </ButtonBase>
          );
        })}
      </Flex>
      <Flex row className="row-center">
        {optionComponent}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    margin: "0px 50px",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
    borderBottom: "1px solid black",
  },
  button: {
    fontSize: 18,
    color: "gray",
    fontFamily: fonts.notoSansKRBold,
    padding: "20px",
    minWidth: 150,
  },
  buttonSel: { color: "black !important", fontSize: 20 },
});
