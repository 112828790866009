import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import { RestoreFromTrash } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import ButtonRadios from "../../components/button-radios/ButtonRadios";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import SimpleTable from "../../components/simple-table/SimpleTable";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import * as APIS from "../../libs/apis";
import DaumPostcode from "react-daum-postcode";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../redux/modal/ModalReducer";
import FindAddress from "../../components/find-address/FindAddress";
import { actionOpen } from "../../redux/action/ActionActions";
import { validation } from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import InputFile from "../../components/input/InputFile";

const MAP_COLUMN_TO_LABEL = {
  shopIsFranchisee: "가맹점/직영점",
  shopName: "매장명",
  shopCode: "가맹점코드",
  managerAccount: "아이디",
  managerPassword: "비밀번호",
  managerName: "이름",
  managerPhone: "연락처",
  shopBusinessNumber: "사업자등록번호",
  shopPhone: "전화번호",
  shopFax: "팩스번호",
  shopPostCode: "우편번호",
  shopAddress: "주소",
  shopAddressDetail: "상세주소",
  shopDistrict: "찾아오는길",
  shopLatitude: "위도",
  shopLongitude: "경도",
};

export default function MemberShopAdd(props) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();

  const inputRef = useRef(null);
  const [banks, setBanks] = useState([]);
  const [shop, setShop] = useState({ shopType: "FRANCHISEE" });
  const [images, setImages] = useState([]);

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getBankList()
      .then(
        ({
          data: {
            success,
            data: { content },
            message,
          },
        }) => {
          success && setBanks(content);
          setShop((shop) => ({ ...shop, shopBankCode: content[0]["code"] }));
        }
      )
      .finally(() => {
        dispatch(loadingEnd);
      });
  }, []);

  const handleChangeValue = (key, value) => {
    setShop((shop) => ({ ...shop, [key]: value }));
  };

  const handleClickFindAddress = () => {
    dispatch(
      openModal({
        children: (
          <FindAddress
            callback={({ postCode, address }) => {
              dispatch(closeModal());
              setShop((shop) => ({
                ...shop,
                shopPostCode: postCode,
                shopAddress: address,
                shopAddressDetail: "",
              }));
            }}
          />
        ),
      })
    );
  };

  const handleChangeFile = (file) => {
    setImages([...images, file]);
  };

  const handleClickRegister = (password) => {
    const errorMessage = validationData();
    if (errorMessage) {
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }

    const json = JSON.stringify({ ...shop, password });
    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("shop", blob);

    for (let i in images) {
      formData.append(`image`, images[i]);
    }

    dispatch(loadingStart);
    APIS.postCreateShop(formData)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(actionOpen(message, null, null, null, true));
        } else {
          dispatch(
            actionOpen(
              "매장 추가가 완료되었습니다.",
              () => {
                history.goBack();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const validationData = () => {
    const {
      shopName,
      managerAccount,
      managerPassword,
      managerName,
      managerPhone,
      shopBusinessNumber,
      shopPhone,
      shopFax,
      shopLatitude,
      shopLongitude,
    } = shop;

    if (!shopName) {
      return "매장명을 입력해주세요.";
    }

    if (!managerAccount) {
      return "관리자 아이디를 입력해주세요.";
    }

    if (!managerPassword) {
      return "기본정보 - 비밀번호를 입력해주세요.";
    }

    if (!managerName) {
      return "관리자 이름을 입력해주세요.";
    }

    if (!managerPhone) {
      return "관리자 연락처를 확인해주세요.";
    }

    if (!validation("businessNumber", shopBusinessNumber)) {
      return "사업자등록번호를 확인해주세요.";
    }

    if (!validation("tel", shopPhone)) {
      return "매장 전화번호를 확인해주세요.";
    }

    // if (!validation("tel", shopFax)) {
    //   return "매장 팩스번호를 확인해주세요.";
    // }

    if (!shopLatitude) {
      return "위도를 확인해주세요.";
    }

    if (!shopLongitude) {
      return "경도를 확인해주세요.";
    }
    return "";
  };

  const getInputUI = (key, option = {}) => {
    const label = MAP_COLUMN_TO_LABEL[key];
    return {
      label,
      value: shop[key],
      type: "input",
      onChange: (e) => handleChangeValue(key, e),
      ...option,
    };
  };

  const {
    shopType = "FRANCHISEE",
    shopBankAccountNumber = "",
    shopPostCode = "",
    shopAddress = "",
    shopAddressDetail = "",
    shopBankCode = "",
  } = shop;

  return (
    <>
      <h1 style={{ marginLeft: 50 }}>매장 추가하기</h1>

      <AdminConfirmButton
        popupMessage="등록하시겠습니까?"
        label="등록"
        popupLabel="매장 등록"
        callback={handleClickRegister}
      />
      <GridRow
        label="기본정보"
        data={[
          { label: "등록일", value: "" },
          {
            label: "",
            type: "radio",
            data: [
              { label: "가맹점", value: "FRANCHISEE" },
              { label: "직영점", value: "BRANCH" },
            ],
            value: shopType,
            onChange: (e) => handleChangeValue("shopType", e),
          },
          getInputUI("shopName"),
          getInputUI("shopCode", { validation: "number" }),
          getInputUI("managerAccount", { maxLength: 20 }),
          getInputUI("managerPassword", { type: "input-password" }),
          getInputUI("managerName"),
          getInputUI("managerPhone", { validation: "tel" }),
          getInputUI("shopBusinessNumber", { validation: "businessNumber" }),
          {
            label: "정산계좌",
            value: "",
            type: "render",
            render: (
              <Flex row className={classes.addressContainer}>
                <Flex row>
                  <select
                    style={{
                      minWidth: 100,
                      fontSize: 16,
                    }}
                    value={shopBankCode}
                    onChange={(e) => {
                      setShop((shop) => ({
                        ...shop,
                        shopBankCode: e.target.value,
                      }));
                    }}
                  >
                    {banks.map(({ code, name }, i) => {
                      return (
                        <option key={i.toString()} value={code}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                </Flex>
                <InputBase
                  className={classes.addressDetailWrap}
                  value={shopBankAccountNumber}
                  onChange={({ target: { value } }) => {
                    setShop((shop) => ({
                      ...shop,
                      shopBankAccountNumber: value,
                    }));
                  }}
                />
              </Flex>
            ),
          },
        ]}
      />

      <GridRow
        label="매장정보"
        data={[
          getInputUI("shopPhone", { validation: "tel" }),
          getInputUI("shopFax", { validation: "tel" }),
          {
            label: "지역",
            value: "",
            type: "render",
            fullWidth: true,
            render: (
              <Flex row className={classes.addressContainer}>
                <Button label="주소찾기" onClick={handleClickFindAddress}>
                  주소찾기
                </Button>

                <span
                  className={classes.addressWrap}
                  onClick={handleClickFindAddress}
                >
                  {shopPostCode && `(${shopPostCode}) `}
                  {shopAddress}
                </span>
                <InputBase
                  className={classes.addressDetailWrap}
                  value={shopAddressDetail}
                  onChange={({ target: { value } }) => {
                    setShop((shop) => ({
                      ...shop,
                      shopAddressDetail: value,
                    }));
                  }}
                />
              </Flex>
            ),
          },
          getInputUI("shopDistrict", { fullWidth: true, inputFullWidth: true }),
          getInputUI("shopLatitude", { validation: "number" }),
          getInputUI("shopLongitude", { validation: "number" }),
        ]}
      />

      {/* <Flex row className="row-center padding">
        <InputFile
          accept="image/*"
          label="첨부서류"
          callback={handleChangeFile}
        />

        <span style={{ marginLeft: 10 }}>
          ex. 사업자 등록증, 본인신분증 사본, 가맹계약서, 통장사본 등 (첨부파일
          용량 100mb이하)
        </span>
      </Flex> */}

      {/* <Flex
        className="padding"
        style={{ marginTop: 30, alignSelf: "flex-start" }}
      >
        {images.map(({ name }, i) => {
          return (
            <Flex row className="row-center" key={i.toString()}>
              {name}
              <RestoreFromTrash
                className={classes.icon}
                onClick={() => {
                  const copy = [...images];
                  copy.splice(i, 1);
                  setImages(copy);
                }}
              />
            </Flex>
          );
        })}
      </Flex> */}
    </>
  );
}

const useStyle = makeStyles({
  addressWrap: {
    minWidth: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    padding: "0 10px",
    minHeight: 20,
    cursor: "pointer",
  },
  addressDetailWrap: {
    flex: 1,
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    padding: 5,
    minHeight: 20,
  },
  icon: { cursor: "pointer" },
  addressContainer: { flex: 1, alignSelf: "stretch" },
  history1Toolbar: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 50px",
    marginTop: 50,
  },
  history1Select: {
    border: "1px solid rgba(0,0,0,0.23)",
  },
  input: {
    width: 200,
    padding: "0px 10px",
    border: "1px solid rgba(0,0,0,0.23)",
  },
  button: {
    backgroundColor: "#000",
    width: 100,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  history1buttonRadios: {
    margin: "0px 50px",
    marginTop: 20,
  },
  td: {
    borderRight: "none !important",
    borderBottom: "none !important",
    margin: "20px 50px 30px 50px",
    "& th": {
      padding: "6px",
    },
    "& td": {
      textAlign: "center",
      padding: "10px",
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  td2: {
    borderRight: "none !important",
    borderBottom: "none !important",
    margin: "20px 50px 30px 50px",
    "& th": {
      borderRight: "1px solid rgba(0,0,0,0.23) !important",
      borderBottom: "1px solid rgba(0,0,0,0.23) !important",
      padding: "6px",
    },
    "& td": {
      textAlign: "center",
      padding: "10px",
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  history2ButtonText: {
    color: "black",
    borderBottom: "1px solid black",
  },
});
