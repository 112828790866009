import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import {
  Add,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import fonts from "../../libs/fonts";
import Flex from "../../components/flex/Flex";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import SubClassificationManagementRows from "./SubClassificationManagementRows";

export default function SubClassificationManagementList({
  subcategoryid,
  subCategoryName,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [brandList, setBrandList] = useState([]);
  const [categoryreser, setcategoryreser] = useState("");
  const [categoryid, setcategoryid] = useState([]);

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getDirectBrandList(subcategoryid)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setBrandList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  }, [categoryreser]);

  const categoryAdd = () => {
    setBrandList((categorydata) => [...categorydata, {}]);
  };
  
  const categoryselectfunc = (id, e) => {
    if (e.target.checked === true) {
      setcategoryid((categoryid) => [...categoryid, { id }]);
    } else {
      setcategoryid(categoryid.filter((categoryid) => categoryid.id !== id));
    }
  };

  return (
    <>
      <Flex style={{ flex: 1 }}>
        <Flex row className={classes.header}>
          <Flex>
            <span className={classes.label}>브랜드 관리</span>
            <Flex style={{ fontWeight: "bold" }}>
              카테고리 : {subCategoryName}
            </Flex>
          </Flex>
          <Grid item xs={6} className={classes.gridHeaderRow}>
            <Button
              onClick={() => categoryAdd()}
              label="추가"
              className={classes.bt1}
              style={{ width: "70px", "margin-right": "10px" }}
            />
          </Grid>
        </Flex>
        <Flex className={classes.list}>
          <table className={classes.table} cellPadding="0" cellSpacing="0">
            <tr>
              <th>순서</th>
              <th>No</th>
              <th>브랜드</th>
              <th>사용여부</th>
              <th>수정</th>
            </tr>
            {brandList.map((x, i) => {
              return (
                <>
                  <SubClassificationManagementRows
                    subcategoryid={subcategoryid}
                    rows={x}
                    i={i}
                    setcategoryreser={setcategoryreser}
                    categoryselectfunc={categoryselectfunc}
                    before={brandList[i - 1]}
                    after={brandList[i + 1]}
                    list={brandList}
                    setList={setBrandList}
                  />
                </>
              );
            })}
          </table>
        </Flex>
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  img2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 300,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  img: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px 20px",
    alignItems: "center",
    boxSizing: "border-box",
    width: "auto !important",
  },

  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },

  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "5px 10px",
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
    height: 40,
  },
  radiogroup: {
    marginTop: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "90%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    // marginBottom: 20,
    // marginTop: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
});
