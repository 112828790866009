import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TablePagination,
  Button,
  ButtonBase,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
//import Button from "../../components/button/Button";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import PageLabel from "../../components/page-label/PageLabel";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import consts, { getDefaultPageSize } from "../../libs/consts";
import DataTable from "../../components/table/Table";

import { useDispatch } from "react-redux";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { post } from "axios";
import { red } from "@material-ui/core/colors";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";
import InputFile from "../../components/input/InputFile";
import { objToQueryStr } from "../../services/utils";

export default function SettlementSalesFee({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state || {};
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (!isNaN(state.page)) {
      fetchList();
    }
  }, [state.page, state.size]);

  const fetchList = () => {
    const param = { ...state };

    for (let key in param) {
      const value = param[key];
      if (key.includes("Date")) {
        param[key] = value.replace(/-/g, "");
      }

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }

    dispatch(loadingStart);
    APIS.searchVirtualList(objToQueryStr(param))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setList(data.content);
          setTotalCount(data.totalElements);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const uploadVirtualAccount = (file) => {
    const form = new FormData();

    form.append("virtual_account", file);

    dispatch(loadingStart);
    APIS.postVirtualAccount(form)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const failureCount = data.length;
          //const successCount = data.filter(({ status }) => status).length;
          dispatch(
            actionOpen(
              `${failureCount}개의 가상계좌번호가 실패했습니다. \n` +
                data.join('\n'),
              fetchList,
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionOpen(message, null, null, null, true));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const handleClickUnAssign = (param) => {
    dispatch(
      actionOpen("가상계좌를 할당 해제하시겠습니까?", () => {
        dispatch(loadingStart);
        APIS.unAssignVirtualAccount(param)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              dispatch(
                actionOpen("할당 해제되었습니다.", fetchList, null, null, true)
              );
            } else {
              dispatch(actionOpen(message, null, null, null, true));
            }
          })
          .finally(() => dispatch(loadingEnd));
      })
    );
  };

  return (
    <>
      <PageLabel>가상계좌관리</PageLabel>
      <Filters
        defaultState={{ ...getDefaultPageSize() }}
        onSearch={fetchList}
        data={[
          {
            label: "가상계좌업로드일",
            type: "date-range",
            key: "registeredStartDate,registeredEndDate",
          },
          {
            label: "가상계좌할당일",
            type: "date-range",
            key: "openStartDate,openEndDate",
          },
          {
            label: "회원검색",
            type: "input",
            wrap: true,
            placeholder: "고객명,연락처,가상계좌번호",
            key: "keyword",
          },
          {
            label: "처리상태",
            type: "radio",
            key: "status",
            wrap: true,
            data: [
              { label: "전체", value: "" },
              { label: "할당", value: "ASSIGN" },
              { label: "미할당", value: "UN_ASSIGN" },
              { label: "할당 해지", value: "CLOSE_ASSIGN" },
            ],
          },
        ]}
      />
      <Flex row className={classes.tableHeader}>
        <Flex row className={"row-center"}></Flex>
        <Flex
          row
          className={classes.marginrno}
          style={{ alignItems: "center" }}
        >
          <Text font={fonts.notoSansKRBold} className={classes.marginr10}>
            ※ 가상계좌 업로드시 기존 파일을 덮어쓰지 않고, 신규로 추가됩니다.
          </Text>
          <InputFile
            label="가상계좌업로드"
            accept="text/*"
            callback={uploadVirtualAccount}
          />

          {/* <form id="formVirtual">
            <input
              accept="text/*"
              type="file"
              id="rasied-button-file"
              style={{ display: "none" }}
              name="file"
              onChange={virtualUpload}
            />
            <label htmlFor="rasied-button-file">
              <Button
                className={classes.buttonblack}
                variant="outlined"
                component="span"
                title="가상계좌업로드"
              >
                {" "}
                가상계좌업로드{" "}
              </Button>
            </label>
          </form> */}
        </Flex>
      </Flex>

      <DataTable
        data={list}
        totalCount={totalCount}
        columns={[
          { label: "업로드일", key: "registeredAt", type: "date" },
          { label: "가상계좌번호", key: "virtualAccountNumber" },
          { label: "할당된 고객명", key: "userName" },
          { label: "가상계좌 할당일", key: "openedAt", type: "date" },
          {
            label: "할당 해제",
            render: ({ userId, bankCode, virtualAccountNumber }) => {
              if (userId && bankCode && virtualAccountNumber) {
                return (
                  <Button
                    className={classes.btnDel}
                    onClick={() =>
                      handleClickUnAssign({
                        userId,
                        bankCode,
                        virtualAccountNumber,
                      })
                    }
                  >
                    할당 해제
                  </Button>
                );
              } else {
                return "-";
              }
            },
          },
        ]}
        onClick={() => {}}
      />
    </>
  );
}
/*
  <Button variant="outlined" title="삭제" onClick={() => virtualDelete()}>
      삭제
  </Button>
*/
const useStyle = makeStyles({
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    marginRight: 50,
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  marginrno: {
    marginRight: 0,
  },
  marginr10: {
    marginRight: 10,
  },
  label: {
    marginLeft: 50,
    "& span": { fontSize: 14, color: "#444" },
  },
  tableHeader: {
    alignSelf: "stretch",
    marginTop: 50,
    justifyContent: "space-between",
    margin: "10px 50px",
  },
  table3: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  btnDel: {
    border: "1px solid rgba(0,0,0,0.23)",
    width: "100%",
  },
});
