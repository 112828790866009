import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router";
import SimpleTable from "../../components/simple-table/SimpleTable";
import MainLayout from "../../layouts/main/MainLayout";
import consts from "../../libs/consts";
import routes from "../../libs/routes";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import moment from "moment-timezone";
import Flex from "../../components/flex/Flex";
import {
  formatPhone,
  getPurityLabel,
  numFormat,
  objToQueryStr,
} from "../../services/utils";
import Step1 from "./Step1";
import StatusUpdater1 from "./StatusUpdater1";
import StatusUpdater2 from "./StatusUpdater2";
import { getNoProcessLabel } from "../../libs/utils";
import { openImageModal } from "../../redux/image-modal/ImageModalReducer";
import * as APIS from "../../libs/apis";

export default function ApprasialStatusDetail({}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state || {};
  const classes = useStyle();
  const [data, setData] = useState();

  //
  const [remark, setRemark] = useState("");
  const [status, setStatus] = useState("반려");

  //
  const [weightRates, setWeightRates] = useState([]);
  const [silverGramPrice, setSilverGramPrice] = useState(0);
  const [goldGramPrice, setGoldGramPrice] = useState(0);
  //
  const authReducer = useSelector((s) => s.auth);
  let isShop = !authReducer.isKorda;

  // console.log("관리자 최종 승인 상태 : ", data?.approvedAppraise);
  // console.log("관리자 최종 승인 거절 여부 : ", data?.shopReject);
  // console.log("관리자 최종 승인 거절 이유 : ", data?.shopRejectReason);
  // console.log(data);
  useEffect(() => {
    dispatch(loadingStart);

    APIS.getcurrentmarketprice("GOLD").then((res) =>
      setGoldGramPrice(res.data.data)
    );

    APIS.getcurrentmarketprice("SILVER").then((res) =>
      setSilverGramPrice(res.data.data)
    );

    APIS.getAppraisalWeightRates()
      .then(({ data: { data } }) => setWeightRates(data))
      .catch((err) => dispatch(actionError(err)));

    APIS.getAppraisalRequestDetail(state.id)
      .then((res) => {
        setData({ ...res.data.data });
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  }, [location.key]);

  if (!state.id) {
    return (
      <Redirect
        to={{
          pathname: routes.appraisalStatus,
        }}
      />
    );
  }
  if (!data) {
    return null;
  }
  let tg =
    data.status === "교환완료"
      ? data.appraisalWeightGram
      : weightRates && weightRates.length
      ? data.appraisalSimples.reduce((a, b) => {
          return (
            b.weightGram *
              (weightRates.filter(
                (y) =>
                  y.type === b.appraisalProduct.type && y.purity === b.purity
              )[0]?.weightRatePct /
                100) *
              (b.appraisalProduct.weightRatePct / 100) *
              b.quantity +
            a
          );
        }, 0.0)
      : 0;
  return (
    <>
      <span className={classes.label}>감정평가 상세보기</span>

      <SimpleTable
        fullWidth
        columns={[
          {
            label: "접수번호",
            key: "a",
          },
          {
            label: "요청일",

            key: "b",
          },
          {
            label: "수량",
            key: "edb",
          },
          {
            label: "고객",
            key: "c",
          },
          {
            label: "접수 방법",
            key: "d",
          },
          ["현장감정", "내방"].includes(data.method) && {
            label: "접수 매장",
            key: "d2",
          },

          {
            label: "진행상태",

            key: "e",
          },
          {
            label: "예약일시",

            key: "f",
          },
          {
            label: "최종 처리일시",
            key: "h",
          },
          { label: "미처리", key: "i" },
          //     { label: "재고상태", key: "j" },
        ].filter((x) => x)}
        data={[
          {
            d2: data?.shop?.name,
            a: data.number,
            edb:
              data.method === "현장감정" ? "-" : data.appraisalSimples.length,
            b: moment(
              data.appraisalRequestStatus.find((x) => x.status === "접수")
                .createdAt
            ).format("YYYY.MM.DD HH:mm"),
            c: data.user.name + " (" + formatPhone(data.user.phone) + ")",
            d: data.method,
            e: data.status,
            f:
              data.method === "현장감정"
                ? "-"
                : data.method === "택배"
                ? data.collectDate + " " + data.collectTime
                : data.visitDate + " " + data.visitTime,
            h: moment(
              data.appraisalRequestStatus[
                data.appraisalRequestStatus.length - 1
              ].updatedAt
            ).format("YYYY.MM.DD HH:mm"),
            i: (() => {
              return getNoProcessLabel({
                status: data.appraisalRequestStatus,
                method: data.method,
                approvedAppraise: data.approvedAppraise,
              });
            })(),
          },
        ]}
      />

      {data.method !== "현장감정" && (
        <>
          <Flex row style={{justifyContent: "space-between"}} >
          <span className={classes.label}>제품정보</span>
          {data.appraisalRequestStatus.length &&
          data.appraisalRequestStatus.filter((x) => x.status === "감정완료")
            .length > 0 ? (
            <div className={classes.calc}>
              감정 결과 :{" "}
              {
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "감정완료"
                )[0].appraiseType
              }{" "}
              {parseFloat(
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "감정완료"
                )[0].appraiseGram
              ).toFixed(2) +
                "g = " +
                numFormat(
                  data.appraisalRequestStatus.filter(
                    (x) => x.status === "감정완료"
                  )[0].appraiseGram *
                    (data.appraisalRequestStatus.filter(
                      (x) => x.status === "감정완료"
                    )[0].appraiseType === "GOLD"
                      ? goldGramPrice
                      : silverGramPrice)
                ) +
                "원"}
            </div>
          ) : (
            <div className={classes.calc}>
              예상 결과 : {data.appraisalSimples[0].appraisalProduct.type}{" "}
              {parseFloat(tg).toFixed(2) +
                "g = " +
                numFormat(
                  tg *
                    (data.appraisalSimples[0].appraisalProduct.type === "GOLD"
                      ? goldGramPrice
                      : silverGramPrice)
                ) +
                "원"}
            </div>
          )}
          </Flex>
          <SimpleTable
            fullWidth
            columns={[
              {
                label: "제품분류",
                render: (x) =>
                  x.appraisalProduct.type + ">" + x.appraisalProduct.name,
              },
              {
                label: "순도",
                render: (x) =>
                  getPurityLabel(x.purity, x.appraisalProduct.type),
              },
              { label: "중량", render: (x) => parseFloat(x.weightGram) + "g" },
              { label: "수량", key: "quantity" },
              {
                label: "이미지",
                render: (x) => {
                  let images = [
                    x.topImageUrl,
                    x.frontImageUrl,
                    x.leftSideImageUrl,
                    x.rightSideImageUrl,
                  ].filter((y) => y);
                  return (
                    <div className={classes.images}>
                      {images.map((y, z) => {
                        return (
                          <img
                            onClick={() => {
                              dispatch(
                                openImageModal({
                                  src: y,
                                })
                              );
                            }}
                            style={{ cursor: "pointer" }}
                            key={z.toString()}
                            alt="gurantter"
                            src={y}
                            className={classes.image}
                          />
                        );
                      })}
                    </div>
                  );
                },
              },
              {
                label: "보증서",
                render: (x) =>
                  x.guaranteeImageUrl ? (
                    <img
                      alt="gurantter"
                      src={x.guaranteeImageUrl}
                      className={classes.image}
                    />
                  ) : (
                    ""
                  ),
              },
            ]}
            data={data.appraisalSimples}
          />
        </>
      )}

      <span className={classes.label}>감정평가</span>

      {data.method === "현장감정" || data.method === "내방" ? (
        <StatusUpdater1 key={JSON.stringify(data)} {...data} />
      ) : (
        <></>
        // <StatusUpdater2 key={JSON.stringify(data)} {...data} />
      )}
    </>
  );
}

const useStyle = makeStyles({
  gridLabel: {
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    borderRight: "1px solid rgb(224,224,224)",
    borderBottom: "1px solid rgb(224,224,224)",
    borderLeft: "1px solid rgb(224,224,224)",
    padding: 14,
  },
  gridValue: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    borderRight: "1px solid rgb(224,224,224)",
    borderBottom: "1px solid rgb(224,224,224)",
    borderLeft: "1px solid rgb(224,224,224)",
    "& svg": {
      color: "#000",
    },
    "& .MuiFormControlLabel-root": {
      minWidth: 150,
    },
  },
  input: {
    flex: 1,
    marginRight: "50px",
    paddingLeft: "16px",
    marginLeft: "100px",
    border: "1px solid rgb(224,224,224)",
  },
  grid: {
    alignSelf: "stretch",
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 20,
  },
  calc: {
    margin: "30px 50px 0px 50px",
    textAlign: "right",
    // marginBottom: 5,
    // borderBottom: "1px solid rgb(224,224,224)",
  },
  images: { display: "flex", alignItems: "center", width: "100%" },
  image: {
    width: "80px",
    height: "80px",
    marginRight: 16,
    "&:last-child": { marginRight: 0 },
  },
  bt1: {
    backgroundColor: "#fff",
    border: "1px solid black",
    color: "black",
    "& span": { color: "black" },
    marginRight: 10,
  },
  multiInput: {
    border: "1px  solid #ddd",
    minWidth: "70%",
    padding: 10,
    marginTop: 20,
  },
  img: {
    width: 40,
    height: 40,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    alignItems: "center",
    width: "auto !important",
    "& div": {},
  },
  mainC1Content: {
    padding: "10px 20px",
  },
  mainC2Content: {
    alignItems: "center",
    padding: 50,
  },
  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },
  buttons: {
    marginRight: 50,
    "& button": {
      minWidth: 150,
    },
    marginTop: 30,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  header: {
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    alignSelf: "stretch",
    padding: 10,
  },
  mainC1: {
    marginLeft: 50,
    flex: 4,
    borderRight: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2: {
    marginRight: 50,
    flex: 3,
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
});
