import {
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { axiosDispatch } from "../../services/network";
export default function StatusUpdater2({ appraisalRequestStatus, id, type }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyle();

  const [st1, setSt1] = useState("");
  const [sr1, setSr1] = useState("");

  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");
  const [t3, setT3] = useState("");
  const [t4, setT4] = useState("");

  const [r1, setR1] = useState("");
  const [r2, setR2] = useState("");
  const [r3, setR3] = useState("");

  const dStatus = (id) => {
    return new Promise((resolve, rejet) => {
      dispatch(
        axiosDispatch(
          {
            method: "delete",
            url:
              consts.apiBaseUrl +
              "/api/v1/admin/market/appraisal/Requests/" +
              id +
              "/status",
          },
          (err, res) => {
            if (err) {
              rejet(err);
            } else {
              resolve();
            }
          }
        )
      );
    });
  };
  useEffect(() => {
    if (appraisalRequestStatus.filter((x) => x.status === "접수반려")[0]) {
      setSr1("2");
      return setSt1(
        appraisalRequestStatus.filter((x) => x.status === "접수반려")[0]
          ?.requestCancelReason
      );
    } else if (
      appraisalRequestStatus.filter((x) => x.status === "접수승인")[0]
    ) {
      setSr1("1");
    }

    if (appraisalRequestStatus.filter((x) => x.status === "감정반려")[0]) {
      setR1("2");
      return setT1(
        appraisalRequestStatus.filter((x) => x.status === "감정반려")[0]
          ?.appraiseCancelReason
      );
    } else if (
      appraisalRequestStatus.filter((x) => x.status === "감정시작")[0]
    ) {
      setR1("1");
    }

    if (appraisalRequestStatus.filter((x) => x.status === "감정완료")[0]) {
      let d = appraisalRequestStatus.filter((x) => x.status === "감정완료")[0];
      setT4(String(d.appraiseGram));
      setT2(String(d.appraiseMemo));
      setR2(d.appraiseType === "금" ? "1" : "2");
    }
  }, [appraisalRequestStatus]);

  const onSave = async () => {
    let last = appraisalRequestStatus[appraisalRequestStatus.length - 1];
    if (["접수", "접수반려"].includes(last.status)) {
      if (!sr1) {
        return dispatch(
          actionOpen("접수 상태를 선택해 주세요.", null, "", "", true)
        );
      } else if (sr1 === "2" && !st1) {
        return dispatch(
          actionOpen("접수반려 사유를 입력해 주세요.", null, "", "", true)
        );
      }

      let exarr = appraisalRequestStatus.filter(
        (x) => x.status === (r1 === "1" ? "접수승인" : "접수반려")
      );

      if (exarr.length) {
        try {
          for await (let i of exarr) {
            await dStatus(i.id);
          }
        } catch (error) {
          return dispatch(actionOpen("처리할 수 없습니다."));
        }
      }

      return dispatch(
        axiosDispatch(
          {
            method: "put",
            url:
              consts.apiBaseUrl +
              "/api/v1/admin/market/appraisal/Requests/" +
              id +
              "/status",
            data:
              sr1 === "1"
                ? {
                    status: "접수승인",
                    requestCancelReason: "",
                  }
                : {
                    status: "접수반려",
                    requestCancelReason: st1,
                  },
          },
          async (err, res) => {
            if (err) {
              return dispatch(actionError(err));
            }

            let arr = appraisalRequestStatus.filter(
              (x) =>
                x.status === (sr1 === "1" ? "접수반려" : "접수승인") &&
                exarr.filter((z) => z.id === x.id).length === 0
            );

            if (arr.length) {
              try {
                for await (let i of arr) {
                  await dStatus(i.id);
                }
              } catch (error) {
                return dispatch(
                  actionOpen("페이지를 새로고침 해주세요.", null, "", "", true)
                );
              }
            }

            history.replace({
              pathname: history.location.pathname,
              state: history.location.state,
            });

            return dispatch(
              actionOpen(
                r1 === "1"
                  ? "접수 승인 되었습니다. 배송을 시작해 주세요."
                  : "접수 반려 되었습니다.",
                null,
                "",
                "",
                true
              )
            );
          }
        )
      );
    } else if (["접수승인", "감정반려", "감정시작"].includes(last.status)) {
      if (["1", "2"].includes(r2) && r1 !== "2") {
        if (!r2) {
          return dispatch(
            actionOpen("감정결과를 선택해 주세요.", null, "", "", true)
          );
        } else if (t4 === "" || isNaN(t4)) {
          return dispatch(
            actionOpen(
              "올바른 감정결과를 중량을 입력해 주세요.",
              null,
              "",
              "",
              true
            )
          );
        }

        const makeAsync = (a) => {
          return new Promise((resolve, reject) => {
            dispatch(
              axiosDispatch(
                {
                  method: "put",
                  url:
                    consts.apiBaseUrl +
                    `/api/v1/admin/market/appraisal/Requests/${id}/isApprovedApprise`,
                  data: {
                    approvedAppraise: a ? false : true,
                  },
                },
                (err, res) => {
                  if (err) {
                    reject(err);
                  } else {
                    resolve();
                  }
                }
              )
            );
          });
        };

        //관리자 최종승인 미리함
        try {
          await makeAsync();
        } catch (error) {
          return dispatch(
            actionError("오류가 발생했습니다. 다시 시도해 주세요.")
          );
        }

        return dispatch(
          axiosDispatch(
            {
              method: "put",
              url:
                consts.apiBaseUrl +
                "/api/v1/admin/market/appraisal/Requests/" +
                id +
                "/status",
              data: {
                appraiseGram: parseFloat(t4),
                appraiseMemo: t2,
                appraiseType: r2 === "1" ? "금" : "은",
                status: "감정완료",
              },
            },
            (err, res) => {
              if (err) {
                makeAsync(true);
                return dispatch(actionError(err));
              }

              history.replace({
                pathname: history.location.pathname,
                state: history.location.state,
              });

              return dispatch(
                actionOpen("감정 완료 되었습니다.", null, "", "", true)
              );
            }
          )
        );
      }
      if (!r1) {
        return dispatch(
          actionOpen("감정시작 상태를 선택해 주세요.", null, "", "", true)
        );
      } else if (r1 === "2" && !t1) {
        return dispatch(
          actionOpen("감정반려 사유를 입력해 주세요.", null, "", "", true)
        );
      }

      let exarr = appraisalRequestStatus.filter(
        (x) => x.status === (r1 === "1" ? "감정시작" : "감정반려")
      );

      if (exarr.length) {
        try {
          for await (let i of exarr) {
            await dStatus(i.id);
          }
        } catch (error) {
          return dispatch(actionOpen("처리할 수 없습니다."));
        }
      }

      return dispatch(
        axiosDispatch(
          {
            method: "put",
            url:
              consts.apiBaseUrl +
              "/api/v1/admin/market/appraisal/Requests/" +
              id +
              "/status",
            data:
              r1 === "1"
                ? {
                    status: "감정시작",
                    appraiseCancelReason: "",
                  }
                : {
                    status: "감정반려",
                    appraiseCancelReason: t1,
                  },
          },
          async (err, res) => {
            if (err) {
              return dispatch(actionError(err));
            }

            let arr = appraisalRequestStatus.filter(
              (x) => x.status === (r1 === "1" ? "감정반려" : "감정시작")
            );

            if (arr.length) {
              try {
                for await (let i of arr) {
                  await dStatus(i.id);
                }
              } catch (error) {
                return dispatch(
                  actionOpen("페이지를 새로고침 해주세요.", null, "", "", true)
                );
              }
            }

            history.replace({
              pathname: history.location.pathname,
              state: history.location.state,
            });

            return dispatch(
              actionOpen(
                r1 === "1" ? "감정이 시작 되었습니다." : "감정반려 되었습니다.",
                null,
                "",
                "",
                true
              )
            );
          }
        )
      );
    } else if (["감정완료", "반송신청", "반송반려"].includes(last.status)) {
      if (!r3) {
        return dispatch(
          actionOpen("반송 상태를 선택해 주세요.", null, "", "", true)
        );
      } else if (r3 === "2" && !t3) {
        return dispatch(
          actionOpen("반송반려 사유를 입력해 주세요.", null, "", "", true)
        );
      }

      let exarr = appraisalRequestStatus.filter((x) => x.status === "반송반려");

      if (exarr.length) {
        try {
          for await (let i of exarr) {
            await dStatus(i.id);
          }
        } catch (error) {
          return dispatch(actionOpen("처리할 수 없습니다."));
        }
      }

      return dispatch(
        axiosDispatch(
          {
            method: "put",
            url:
              consts.apiBaseUrl +
              "/api/v1/admin/market/appraisal/Requests/" +
              id +
              "/status",
            data:
              r3 === "1"
                ? {
                    status: "반송완료",
                    returnCancelReason: "",
                  }
                : {
                    status: "반송반려",
                    returnCancelReason: t3,
                  },
          },
          async (err, res) => {
            if (err) {
              return dispatch(actionError(err));
            }

            history.replace({
              pathname: history.location.pathname,
              state: history.location.state,
            });

            return dispatch(
              actionOpen(
                r3 === "1"
                  ? "반송 완료 처리 되었습니다."
                  : "반송반려 되었습니다.",
                null,
                "",
                "",
                true
              )
            );
          }
        )
      );
    }
  };

  return (
    <>
      <Grid container className={classes.grid}>
        <Grid
          style={{
            borderTop: "1px solid rgb(224,224,224)",
          }}
          item
          className={classes.gridLabel}
          xs={2}
        >
          접수
        </Grid>
        <Grid
          style={{
            borderTop: "1px solid rgb(224,224,224)",
          }}
          className={classes.gridValue}
          item
          xs={10}
        >
          <RadioGroup
            row
            value={sr1}
            onChange={(e) => {
              setSt1("");
              setSr1(e.target.value);
            }}
          >
            <FormControlLabel
              value={"1"}
              disabled={
                appraisalRequestStatus.filter((x) => x.status === "접수승인")[0]
              }
              label="승인(배송중)"
              control={<Radio />}
            />
            <FormControlLabel
              value={"2"}
              disabled={
                appraisalRequestStatus.filter((x) => x.status === "접수승인")[0]
              }
              label="반려"
              control={<Radio />}
            />
          </RadioGroup>

          <InputBase
            value={st1}
            disabled={sr1 === "1"}
            onChange={(e) => {
              setSt1(e.target.value);
            }}
            placeholder="반려 사유 입력"
            className={classes.input}
          />
        </Grid>

        <Grid item className={classes.gridLabel} xs={2}>
          감정시작
        </Grid>
        <Grid className={classes.gridValue} item xs={10}>
          <RadioGroup
            row
            value={r1}
            onChange={(e) => {
              setT1("");
              setR1(e.target.value);

              setT2("");
              setT4("");
              setR2("");
            }}
          >
            <FormControlLabel
              value={"1"}
              disabled={
                !appraisalRequestStatus.filter(
                  (x) => x.status === "접수승인"
                )[0] ||
                (!appraisalRequestStatus.filter(
                  (x) => x.status === "접수시작"
                )[0] &&
                  appraisalRequestStatus.filter(
                    (x) => x.status === "감정완료"
                  )[0])
              }
              label="감정시작"
              control={<Radio />}
            />
            <FormControlLabel
              value={"2"}
              disabled={
                !appraisalRequestStatus.filter(
                  (x) => x.status === "접수승인"
                )[0] ||
                (!appraisalRequestStatus.filter(
                  (x) => x.status === "접수시작"
                )[0] &&
                  appraisalRequestStatus.filter(
                    (x) => x.status === "감정완료"
                  )[0])
              }
              label="감정반려"
              control={<Radio />}
            />
          </RadioGroup>

          <InputBase
            value={t1}
            disabled={
              !appraisalRequestStatus.filter(
                (x) => x.status === "접수승인"
              )[0] ||
              (r1 === "1" &&
                !appraisalRequestStatus.filter(
                  (x) => x.status === "접수시작"
                )[0])
            }
            onChange={(e) => {
              setT1(e.target.value);
            }}
            placeholder="반려 사유 입력"
            className={classes.input}
          />
        </Grid>
        <Grid item className={classes.gridLabel} xs={2}>
          감정결과
        </Grid>
        <Grid item xs={10} className={classes.gridValue}>
          <RadioGroup
            value={r2}
            onChange={(e) => {
              setT1("");
              setR1("1");
              setR2(e.target.value);
            }}
            row
          >
            <FormControlLabel
              disabled={
                r1 === "2" ||
                appraisalRequestStatus[appraisalRequestStatus.length - 1]
                  .status !== "감정시작"
              }
              value={"1"}
              label="금(GOLD)"
              control={<Radio />}
            />
            <FormControlLabel
              disabled={
                r1 === "2" ||
                appraisalRequestStatus[appraisalRequestStatus.length - 1]
                  .status !== "감정시작"
              }
              value={"2"}
              label="은(SILVER)"
              control={<Radio />}
            />
          </RadioGroup>
          <InputBase
            value={t4}
            onChange={(e) => {
              setT4(e.target.value);
            }}
            type="number"
            placeholder="0.0g"
            disabled={
              r1 === "2" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status !== "감정시작"
            }
            className={classes.input2}
          />

          <InputBase
            value={t2}
            onChange={(e) => {
              setT2(e.target.value);
            }}
            placeholder="감정내용 설명사항 입력(선택)"
            disabled={
              r1 === "2" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status !== "감정시작"
            }
            style={{ marginLeft: 10 }}
            className={classes.input}
          />
        </Grid>
        <Grid item className={classes.gridLabel} xs={2}>
          반송(돌려받기)
        </Grid>

        <Grid item xs={10} className={classes.gridValue}>
          <RadioGroup
            value={r3}
            onChange={(e) => {
              setT3("");
              setR3(e.target.value);
            }}
            row
          >
            <FormControlLabel
              disabled={
                appraisalRequestStatus.filter((x) => x.status === "반송신청")
                  .length === 0 ||
                appraisalRequestStatus.filter((x) => x.status === "반송완료")
                  .length > 0 ||
                appraisalRequestStatus.filter((x) => x.status === "감정완료")
                  .length === 0
              }
              value={"1"}
              label="반송완료"
              control={<Radio />}
            />
            <FormControlLabel
              value={"2"}
              disabled={
                appraisalRequestStatus.filter((x) => x.status === "반송신청")
                  .length === 0 ||
                appraisalRequestStatus.filter((x) => x.status === "반송완료")
                  .length > 0 ||
                appraisalRequestStatus.filter((x) => x.status === "감정완료")
                  .length === 0
              }
              label="반송반려"
              control={<Radio />}
            />
          </RadioGroup>
          <InputBase
            className={classes.input}
            disabled={
              appraisalRequestStatus.filter((x) => x.status === "반송신청")
                .length === 0 ||
              appraisalRequestStatus.filter((x) => x.status === "반송완료")
                .length > 0 ||
              appraisalRequestStatus.filter((x) => x.status === "감정완료")
                .length === 0 ||
              r3 !== "2"
            }
            value={t3}
            onChange={(e) => {
              setT3(e.target.value);
            }}
            placeholder="반송 반려 사유 입력"
          />
        </Grid>
      </Grid>

      <Flex className={classes.buttons} row>
        <Button onClick={history.goBack} label="취소" className={classes.bt1} />
        <Button label="저장" onClick={onSave} />
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  gridLabel: {
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    borderRight: "1px solid rgb(224,224,224)",
    borderBottom: "1px solid rgb(224,224,224)",
    borderLeft: "1px solid rgb(224,224,224)",
    padding: 14,
  },
  gridValue: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    borderRight: "1px solid rgb(224,224,224)",
    borderBottom: "1px solid rgb(224,224,224)",
    borderLeft: "1px solid rgb(224,224,224)",
    "& svg": {
      color: "#000",
    },
    "& .MuiFormControlLabel-root": {
      minWidth: 150,
    },
  },
  input: {
    flex: 1,
    marginRight: "50px",
    paddingLeft: "16px",
    marginLeft: "100px",
    border: "1px solid rgb(224,224,224)",
  },
  input2: {
    paddingLeft: "16px",
    marginLeft: "100px",
    border: "1px solid rgb(224,224,224)",
  },
  grid: {
    alignSelf: "stretch",
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 20,
  },
  calc: {
    margin: "0px 50px",
    textAlign: "right",
    marginBottom: 100,
    borderBottom: "1px solid rgb(224,224,224)",
  },
  images: { display: "flex", alignItems: "center", width: "100%" },
  image: {
    width: "80px",
    height: "80px",
    marginRight: 16,
    "&:last-child": { marginRight: 0 },
  },
  bt1: {
    backgroundColor: "#fff",
    border: "1px solid black",
    color: "black",
    "& span": { color: "black" },
    marginRight: 10,
  },
  multiInput: {
    border: "1px  solid #ddd",
    minWidth: "70%",
    padding: 10,
    marginTop: 20,
  },
  img: {
    width: 40,
    height: 40,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    alignItems: "center",
    width: "auto !important",
    "& div": {},
  },
  mainC1Content: {
    padding: "10px 20px",
  },
  mainC2Content: {
    alignItems: "center",
    padding: 50,
  },
  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },
  buttons: {
    marginRight: 50,
    "& button": {
      minWidth: 150,
    },
    marginTop: 30,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  header: {
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    alignSelf: "stretch",
    padding: 10,
  },
  mainC1: {
    marginLeft: 50,
    flex: 4,
    borderRight: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2: {
    marginRight: 50,
    flex: 3,
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
});
