import { Icon, IconButton, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import fonts from "../../libs/fonts";
import Button from "../button/Button";
import Flex from "../flex/Flex";
import Text from "../text/Text";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";
import Input from "../input/Input";

export default function AdminConfirmButton({
  label,
  popupLabel,
  more,
  popupMessage,
  callback,
  rootClassName,
  btnClassName,
  selectItem,
}) {
  const classes = useStyle();
  const [data, setData] = useState([]);
  const [password, setPassword] = useState("");

  useEffect(() => {
    setData([
      ...[
        ...(more || []),
        {
          label,
          popupLabel,
          popupMessage,
          callback,
          selectItem,
        },
      ],
    ]);
  }, [more, label, popupLabel, popupMessage, callback, selectItem]);

  const handleOpen = (e, i) => {
    setPassword("");
    const copy = [...data];
    copy[i].anchor = e.currentTarget;
    setData(copy);
  };

  const handleClose = (i) => {
    const copy = [...data];
    copy[i].anchor = null;
    setData(copy);
  };

  const handleConfirm = (i) => {
    if (!password) {
      alert("관리자 비밀번호를 확인하세요.");
    } else {
      data[i].callback && data[i].callback(password, selectItem);
      handleClose(i);
    }
  };

  return (
    <Flex row className={`${classes.root} ${rootClassName}`}>
      {data.map((x, i) => {
        return (
          // <React.Fragment key={Math.random()}>
          <div
            key={i}
            onKeyPress={(e) => {
              if (e?.nativeEvent?.key === "Enter") {
                handleConfirm(i);
              }
            }}
          >
            <Button
              onClick={(e) => handleOpen(e, i)}
              label={x.label}
              className={
                `${classes.button} ${btnClassName}` +
                " " +
                (x.white && `${classes.butonWhite} `)
              }
            />

            <Popover
              open={Boolean(data[i].anchor)}
              anchorEl={data[i].anchor}
              onClose={() => handleClose(i)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Flex row className={classes.popopHeader}>
                <Text font={fonts.notoSansKRBold}>{x.popupLabel}</Text>
                <IconButton
                  style={{ position: "absolute", right: 20 }}
                  onClick={() => handleClose(i)}
                >
                  <Close />
                </IconButton>
              </Flex>

              <Flex className={classes.main}>
                <span>{x.popupMessage}</span>
                <Flex row className={classes.mainInput}>
                  <span>관리자 비밀번호</span>
                  <Input
                    autoFocus
                    password
                    onChange={setPassword}
                    value={password}
                    autoComplete="new-password"
                  />
                </Flex>
                <Button
                  className={classes.b}
                  label="확인"
                  onClick={() => handleConfirm(i)}
                />
              </Flex>
            </Popover>
            {/* </React.Fragment> */}
          </div>
        );
      })}
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: "26px",
    color: "#131313",
  },
  root: {
    margin: "0px 50px",
    marginTop: 20,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  button: {
    height: 40,
    minWidth: 150,
    marginLeft: 10,
  },
  butonWhite: {
    height: 38,
    border: "1px solid black !important",
    backgroundColor: "rgb(240,240,240) !important",
    "& span": {
      color: "#000 !important",
    },
  },
  popopHeader: {
    position: "relative",
    minWidth: "400px",
    padding: "15px",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      color: "#fff",
      fontSize: 16,
    },
    backgroundColor: "rgb(180,180,180)",
  },
  main: {
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,0.23)",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    minHeight: 200,
  },
  mainInput: {
    marginTop: 30,
    alignItems: "center",
    "& span": {
      marginRight: 10,
    },
    "& input": {
      padding: "0px",
    },
  },
  b: { padding: 10, minWidth: "30%", marginTop: 30 },
});
