import * as React from "react";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import Flex from "../flex/Flex";

import {
  Checkbox,
  ButtonBase,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Menu,
  MenuItem,
  TableFooter,
  Tab,
} from "@material-ui/core";
import Text from "../text/Text";
import fonts from "../../libs/fonts";
import colors from "../../libs/colors";
import Button from "../button/Button";
import { useHistory, useLocation } from "react-router";
import { formatTime, numFormat } from "../../libs/utils";
import consts, { STORAGE_KEY } from "../../libs/consts";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useRef } from "react";
import { formatPhone } from "../../services/utils";

export default function DataTable({
  rows = [[]],
  totalCount = 0,
  list = [],
  onClick,
  name = "",
  contextMenuVisible = true,
}) {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation({});
  const state = location.state || {};
  // const [selected, setSelected] = React.useState([]);
  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const handleChangeCheckbox = ({ target: { checked, value } }) => {
    let newselected = [...state.selected];
    if (checked) {
      newselected.push(+value);
    } else {
      const deleteIndex = state.selected.indexOf(+value);
      newselected.splice(deleteIndex, 1);
    }
    handleValueChange("selected", newselected);
    // setSelected(newselected);
  };

  // Excel Control --------------------------------------------------
  const [contextMenu, setContextMenu] = useState(null);
  const excelRef = useRef(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleClickDownloadExcel = () => {
    handleCloseContextMenu();
    excelRef.current.link.click();
  };

  const getHeaders = () => {
    const headers = [];
    rows.forEach((row) => {
      row.forEach(({ key, label }) => {
        if (key && label) {
          headers.push({ key, label });
        }
      });
    });

    return headers;
  };
  // -------------------------------------------------- Excel Control
  return (
    <Flex className={classes.table}>
      <TableContainer
        className={classes.container}
        onContextMenu={contextMenuVisible && handleContextMenu}
      >
        <Table stickyHeader>
          <TableHead>
            {rows.map((cells, i) => {
              return (
                <TableRow>
                  {cells.map(({ label, rowSpan, type }) => {
                    switch (type) {
                      case "no":
                        return (
                          <TableCell
                            rowSpan={rows.length}
                            align="center"
                            style={{ width: 50 }}
                          >
                            NO
                          </TableCell>
                        );
                      case "check":
                        return (
                          <TableCell rowSpan={rowSpan} align="center">
                            <Checkbox
                              checked={
                                state?.selected?.length === list?.length &&
                                state?.selected?.length > 0
                              }
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  handleValueChange("selected", []);
                                } else {
                                  let selectedArray = [];
                                  list?.forEach((item) => {
                                    const { id } = item;
                                    selectedArray.push(id);
                                  });
                                  handleValueChange("selected", selectedArray);
                                }
                              }}
                            />
                          </TableCell>
                        );

                      default:
                        return (
                          <TableCell rowSpan={rowSpan} align="center">
                            {label}
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody>
            {list.map((data, rowIndex) => {
              return rows.map((cells, cellIndex) => {
                return (
                  <TableRow
                    style={{ cursor: onClick ? "pointer" : "auto" }}
                    onClick={() => {
                      onClick && onClick(data);
                    }}
                  >
                    {cells.map(({ key, rowSpan, type, render }) => {
                      switch (type) {
                        case "no":
                          return totalCount ? (
                            <TableCell align="center">
                              {totalCount -
                                parseInt(state.page) * parseInt(state.size) -
                                rowIndex}
                            </TableCell>
                          ) : null;
                        case "check":
                          return (
                            <TableCell>
                              <Checkbox
                                color="primary"
                                checked={
                                  state?.selected?.includes(data.id) || ""
                                }
                                onChange={handleChangeCheckbox}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                value={data.id}
                              />
                            </TableCell>
                          );
                        case "date":
                          const dateString = formatTime(
                            data[key],
                            "YYYY-MM-DD"
                          );
                          return (
                            <TableCell>
                              {dateString === "Invalid date" || !dateString
                                ? "-"
                                : dateString}
                            </TableCell>
                          );
                        case "number":
                          return <TableCell>{numFormat(data[key])}</TableCell>;
                        case "phone":
                          return (
                            <TableCell>{formatPhone(data[key])}</TableCell>
                          );
                        case "render":
                          return (
                            <TableCell>{render && render(data)}</TableCell>
                          );

                        default:
                          return (
                            <TableCell align="center" rowSpan={rowSpan}>
                              {data[key] || "-"}
                            </TableCell>
                          );
                      }
                    })}
                  </TableRow>
                );
              });
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              {!isNaN(state.page) && (
                <TablePagination
                  page={
                    state.page > 0 && totalCount < state.page ? 0 : state.page
                  }
                  rowsPerPage={parseInt(state.size)}
                  count={totalCount || 0}
                  onChangePage={(e, p) => {
                    history.replace(location.pathname, {
                      ...state,
                      page: p,
                    });
                    //  onChange({ page: p, size });
                  }}
                  onChangeRowsPerPage={(e) => {
                    history.replace(location.pathname, {
                      ...state,
                      page: 0,
                      size: e.target.value,
                    });
                    localStorage.setItem(STORAGE_KEY.tableSize, e.target.value);
                  }}
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        onContextMenu={handleContextMenu}
      >
        <MenuItem onClick={handleClickDownloadExcel}>Download Excel</MenuItem>
      </Menu>
      <CSVLink
        filename={`${name}_${formatTime(new Date(), "YYYYMMDDHHmmSS")}`}
        headers={getHeaders()}
        data={list.map((dataRow) => {
          const returnRow = { ...dataRow };

          for (let key in returnRow) {
            if (returnRow[key] && !isNaN(returnRow[key])) {
              returnRow[key] = `=""${returnRow[key]}""`;
            }
          }

          return returnRow;
        })}
        ref={excelRef}
        style={{ width: 0, height: 0 }}
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    border: "1px solid #ddd",
    margin: "10px 50px",
    padding: "20px 50px",
  },
  header: {
    alignSelf: "stretch",
    marginTop: "30px",
    marginBottom: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    marginLeft: "16px",
    padding: "10px 50px",
    backgroundColor: "#000",
  },
  buttonLabel: {
    color: "white",
  },
  add: {
    minWidth: "40%",
    alignSelf: "center",
    marginTop: "50px",
    backgroundColor: "rgba(0,0,0,0)",
    border: "1px solid black",
  },
  addLabel: { color: "black" },
  row: {
    cursor: "pointer",
  },
  th: {
    backgroundColor: "#f5f5f5",
  },
  tableDashboard: {
    marginBottom: 50,
  },
  active: {
    cursor: "pointer",
  },
  blind: {
    backgroundColor: "#b7adad",
    cursor: "pointer",
  },
  table: {
    margin: "0px 50px",
  },
  container: {
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    "& th, td": {
      padding: 5,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      fontFamily: fonts.notoSansKRBold,
      backgroundColor: "#f5f5f5",
    },
  },
});
