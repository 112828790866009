import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import DataTable from "../../components/table/Table";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { objToQueryStr } from "../../services/utils";
import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";

export default function ProductList({ data, optionComponent }) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const [product, setProduct] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!isNaN(state.page)) {
      fetchList();
    }
  }, [state.page, state.size]);

  useEffect(() => {
    APIS.getGoodsCategoryList()
      .then((res) => {
        var cates = res.data;
        var categorys = [];
        for (let i in cates) {
          categorys.push({ label: cates[i].kind, value: cates[i].id });
        }
        setCategories(categorys);
      })
      .catch((err) => dispatch(actionError(err)));
  }, []);

  const fetchList = () => {
    const paramQuery = objToQueryStr({
      ...state,
      page: state.page,
      size: state.size,
    });

    dispatch(loadingStart);
    APIS.productList(paramQuery)
      .then(({ data: { success, data, message, totalElements } }) => {
        setProduct(
          data.map((product) => {
            return { ...product, imagePath: product.imageIds?.[0]?.path || "" };
          })
        );
        setTotalCount(totalElements);
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  return (
    <>
      <Flex className={classes.root}>
        <Flex row className={classes.header}>
          <Flex row className="row-center"></Flex>
          <Flex row>
            <Button
              onClick={() =>
                history.push(routes.physicalProductDetail, { action: "add" })
              }
              label="+ 상품등록"
              className={classes.button}
              classNameLabel={classes.buttonLabel}
            />
          </Flex>
        </Flex>
      </Flex>
      <Filters
        onSearch={fetchList}
        data={[
          {
            label: "조회기간",
            type: "date-range",
            key: "beginDate,endDate",
          },
          {
            label: "검색",
            placeholder: "상품명",
            type: "input",
            key: "search",
          },
          {
            label: "카테고리",
            data: categories,
            type: "menu",
            key: "category",
          },

          {
            label: "제조사",
            type: "input",
            key: "outsourcing",
          },

          {
            label: "상태",
            wrap: true,
            data: [
              { label: "노출중", value: "VISIBLE" },
              { label: "노출안함", value: "HIDDEN" },
            ],
            type: "menu",
            key: "visible",
          },
        ]}
      />
      <DataTable
        data={product}
        totalCount={totalCount}
        onClick={(item) => {
          history.push(routes.physicalProductDetail, {
            id: item.id,
            action: "detail",
          });
        }}
        columns={[
          { label: "상품등록일", key: "createdAt", type: "datetime" },
          { label: "상품코드", key: "code" },
          { label: "이미지", key: "imagePath", type: "s3img" },
          { label: "카테고리", key: "categoryName" },
          { label: "상품명", key: "name" },
          { label: "중량", key: "gram" },
          { label: "인출수수료", key: "fees", type: "number" },
          { label: "제조사", key: "outsourcing" },
          { label: "기본공임", key: "pay", type: "number" },
          { label: "제작기간", key: "days" },
          { label: "재고", key: "stock" },
          { label: "상태", key: "visible", type: "visible" },
        ]}
      />
    </>
  );
}
const useStyle = makeStyles({
  root: {
    margin: "10px 50px",
  },
  header: {
    alignSelf: "stretch",
    marginTop: "30px",
    marginBottom: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },
});
