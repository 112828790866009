import { ButtonBase, makeStyles, Tooltip } from "@material-ui/core";
import { ChevronRight, ViewList } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";

const KORDA_MENU = [
  {
    parent: routes.manager,
    routes: [
      { path: routes.managerAuthority, label: "권한관리" },
      { path: routes.managerManagement, label: "관리자관리" },
      { path: routes.myInfo, label: "내 정보" },
    ],
  },
  {
    parent: routes.member,
    routes: [
      { path: routes.memberNormal, label: "일반회원" },
      { path: routes.memberShop, label: "매장회원" },
      // { path: routes.memberInactive, label: "사용정지회원" },
      // { path: routes.memberDelete, label: "휴면회원" },
    ],
  },
  {
    parent: routes.trading,
    routes: [{ path: routes.tradingAsset, label: "자산" }],
  },
  {
    parent: routes.appraisal,
    routes: [
      { path: routes.appraisalStatus, label: "감정현황" },
      { path: routes.appraisalProduct, label: "제품설정" },
      { path: routes.appraisalProcess, label: "처리현황" },
      //  { path: routes.appraisalStock, label: "수탁재고관리" },
    ],
  },
  {
    parent: routes.physical,
    routes: [
      { path: routes.physicalOrder, label: "통합주문현황" },
      { path: routes.physicalProduct, label: "제품관리" },
    ],
  },

  {
    parent: routes.direct,
    routes: [
      { path: routes.directStatus, label: "판매 관리" },
      { path: routes.directProduct, label: "인기상품 관리" },
      // { path: routes.directOrder, label: "주문관리" },
    ],
  },

  {
    parent: routes.settlement,
    routes: [
      { path: routes.settlementHistory, label: "정산현황" },
      { path: routes.settlementOutmoney, label: "출금관리" },
      { path: routes.settlementSalesFee, label: "매출수수료" },
      { path: routes.settlementShopFee, label: "가맹점수수료" },
      { path: routes.settlementVirtualAc, label: "가상계좌관리" },
      // { path: routes.settlementOutSourcing, label: "외주가공" },
      // { path: routes.settlementMoreService, label: "외부서비스" },
    ],
  },
  {
    parent: routes.service,
    routes: [
      { path: routes.serviceInquiry, label: "고객문의관리" },
      { path: routes.serviceDirect, label: "상품 문의" },
      { path: routes.serviceNotice, label: "공지사항" },
      // { path: routes.serviceReview, label: "거래후기 관리" },
      { path: routes.serviceFaq, label: "FAQ 관리" },
      { path: routes.serviceFaqCategory, label: "FAQ 분류 관리" },
      // { path: routes.serviceUseGuide, label: "이용가이드 관리" },
      // { path: routes.serviceNotifi, label: "알림서비스" },
    ],
  },

  {
    parent: routes.setting,
    routes: [
      // { path: routes.settingOrderPolicy, label: "거래정책" },
      // { path: routes.settingFee, label: "수수료정책" },
      { path: routes.settingDirect, label: "수수료정책" },
      { path: routes.settingPolicy, label: "이용약관" },
      { path: routes.settingPage, label: "기본설정" },
      // { path: routes.settingAdmin, label: "관리자 정보" },
      // { path: routes.settingLog, label: "접속기록" },
      // { path: routes.settingBasicInfo, label: "기본정보" },
    ],
  },
];

const SHOP_MENU = [
  {
    parent: routes.trading,
    routes: [{ path: routes.tradingAsset, label: "자산" }],
  },
  {
    parent: routes.appraisal,
    routes: [
      // { path: routes.appraisalProcess, label: "처리현황" },
      { path: routes.appraisalStatus, label: "감정현황" },
      // { path: routes.appraisalProduct, label: "제품설정" },
      //  { path: routes.appraisalStock, label: "수탁재고관리" },
    ],
  },
  {
    parent: routes.physical,
    routes: [
      { path: routes.physicalOrder, label: "통합주문현황" },
      // { path: routes.physicalProduct, label: "제품관리" },
    ],
  },

  // {
  //   parent: routes.direct,
  //   routes: [
  //     { path: routes.directStatus, label: "접수/등록 현황" },
  //     { path: routes.directProduct, label: "상품관리" },
  //     { path: routes.directOrder, label: "주문관리" },
  //   ],
  // },
  {
    parent: routes.member,
    routes: [{ path: routes.memberNormal, label: "일반회원" }],
  },
  {
    parent: routes.shop,
    routes: [{ path: routes.shopInfo, label: "기본정보" }],
  },
  {
    parent: routes.settlement,
    routes: [{ path: routes.settlementShopFee, label: "가맹점수수료" }],
  },
];

export default function Drawer({ expanded }) {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();

  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const menu = isKorda ? KORDA_MENU : SHOP_MENU;

  return (
    <Flex className={`${classes.root} ${expanded && classes.expand}`}>
      {menu.map((x, i) => {
        if (location.pathname.startsWith(x.parent)) {
          return x.routes.map((route, index) => {
            const point = location.pathname.startsWith(route.path);
            return (
              <ButtonBase
                onClick={(e) => {
                  e.stopPropagation();
                  if (point) {
                    history.replace({ pathname: route.path });
                  } else {
                    history.push(route.path);
                  }
                }}
                className={`${
                  expanded ? classes.expandedButton : classes.button
                } ${point && classes.buttonPoint}`}
                key={index.toString()}
              >
                {expanded ? (
                  <Tooltip title={route.label} placement="right-start">
                    <span>
                      <ViewList className={`${point && classes.buttonPoint}`} />
                    </span>
                  </Tooltip>
                ) : (
                  !expanded && (
                    <Text
                      className={classes.label}
                      style={{ display: expanded && "none" }}
                    >
                      {route.label}
                    </Text>
                  )
                )}
                {!expanded && point && (
                  <ChevronRight color="inherit" style={{ color: "#fff" }} />
                )}
                {/* {point && (
                  <ChevronRight color="inherit" style={{ color: "#fff" }} />
                )} */}
              </ButtonBase>
            );
          });
        }
      })}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: "#f1f1f1",
    alignSelf: "stretch",
    width: 250,
    transition: "all 0.3s",
  },
  expand: { marginLeft: 0, width: 70, transition: "all 0.3s" },
  button: {
    width: "100%",
    flexDirection: "row",
    display: "flex",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 28px",
    textAlign: "left",
    height: 67,
    whiteSpace: "nowrap",
  },
  expandedButton: {
    width: "100%",
    flexDirection: "row",
    display: "flex",
    boxSizing: "border-box",
    alignItems: "center",
    padding: "20px 28px",
    textAlign: "center",
    height: 67,
  },
  label: {
    fontSize: 18,
    textAlign: "left",
    alignSelf: "flex-start",
  },

  buttonPoint: {
    backgroundColor: colors.primary,
    "& span": {
      fontFamily: fonts.notoSansKRBold + " !important",
      color: "#fff",
    },
  },
});
