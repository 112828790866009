import { InputBase, makeStyles } from "@material-ui/core";
import React from "react";

export default function Input({
  onChange,
  autoFocus,
  password,
  placeholder,
  className,
  white,
  value,
  onEnter,
  autoComplete = "",
  validation,
  maxLength,
  disabled,
}) {
  const classes = useStyle();

  let handleChangeValue = (e) => {};
  if (onChange) {
    switch (validation) {
      case "number":
        handleChangeValue = (e) => {
          const num = e.target.value.replace(/[^\d.-]/g, "");

          if (isNaN(num)) {
            if (num === ".") {
              onChange("0.");
            } else {
              onChange(value);
            }
          } else {
            const split = num.split(".");
            if (split[0] !== "") {
              split[0] = Number(split[0]) + "";
            }

            onChange(split.join("."));
          }
        };
        break;

      case "tel":
        handleChangeValue = (e) => {
          onChange(
            e.target.value
              .replace(/[^\d]/g, "")
              .replace(
                /(^02.{0}|^01.{1}|\d{3})(\d{4}|\d{3})(\d{4}).*/,
                "$1-$2-$3"
              )
          );
        };
        break;

      case "businessNumber":
        handleChangeValue = (e) => {
          onChange(
            e.target.value
              .replace(/[^\d]/g, "")
              .replace(/^(\d{3})(\d{2})(\d{5}).*/, "$1-$2-$3")
          );
        };
        break;

      default:
        handleChangeValue = (e) => {
          onChange(e.target.value);
        };
    }
  }

  return (
    <InputBase
      value={value}
      onChange={handleChangeValue}
      onKeyDown={(e) => {
        if (e.key === "Enter" && onEnter) {
          onEnter();
        }
      }}
      autoFocus={autoFocus ? true : false}
      type={password ? "password" : "text"}
      placeholder={placeholder}
      className={`${classes.input} ${white && classes.inputWhite} ${className}`}
      autoComplete={autoComplete}
      inputProps={maxLength ? { maxLength } : {}}
      disabled={disabled === true ? true : false}
    />
  );
}

const useStyle = makeStyles({
  input: {
    alignSelf: "stretch",
    borderRadius: "3px",
    border: "1px solid rgba(0,0,0,0.23)",
    color: "black",
    padding: "0px 12px",
  },
  inputWhite: {
    color: "black",
    background: "white",
  },
});
