import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import routes from "./libs/routes";
import Login from "./pages/login/Login";
import ManagerAuthority from "./pages/manager-authority/ManagerAuthority";
import MemberNormal from "./pages/member-normal/MemberNormal";
import MemberDormant from "./pages/member-dormant/MemberDormant";
import MemberInActive from "./pages/member-inactive/MemberInActive";
import MemberShop from "./pages/member-shop/MemberShop";
import MemberDetail from "./pages/member-detail/MemberDetail";
import MemberShopDetail from "./pages/member-shop-detail/MemberShopDetail";
import MemberShopAdd from "./pages/member-shop-add/MemberShopAdd";
import Trading from "./pages/trading/Trading";
import ApprasialStatus from "./pages/appraisal-status/ApprasialStatus";
import ApprasialProcess from "./pages/appraisal-process/ApprasialProcess";
import ApprasialStatusDetail from "./pages/appraisal-status-detail/ApprasialStatusDetail";
import ApprasialProduct from "./pages/appraisal-product/ApprasialProduct";
import Action from "./redux-components/action/Action";
import ApprasialStock from "./pages/appraisal-stock/ApprasialStock";
import AppraisalStockDetail from "./pages/appraisal-stock-detail/AppraisalStockDetail";
import PhysicalOrder from "./pages/physical-order/PhysicalOrder";
import PhysicalOrderDetail from "./pages/physical-order-detail/PhysicalOrderDetail";
import PhysicalProduct from "./pages/physical-product/PhysicalProduct";
import PhysicalProductAdd from "./pages/physical-product-add/PhysicalProductAdd";
import DirectStatus from "./pages/direct-status/DirectStatus";
import DirectStatusDetail from "./pages/direct-status-detail/DirectStatusDetail";
import DirectStatusDetailAdd from "./pages/direct-status-detail-add/DirectStatusDetailAdd";
import DirectProduct from "./pages/direct-product/DirectProduct";
import DirectProductDetail from "./pages/direct-product-detail/DirectProductDetail";
import DirectOrder from "./pages/direct-order/DirectOrder";
import DirectOrderDetail from "./pages/direct-order-detail/DirectOrderDetail";
import SettlementHistory from "./pages/settlement-history/SettlementHistory";
import SettlementSalesFee from "./pages/settlement-sales-fee/SettlementSalesFee";
import SettlementShopFee from "./pages/settlement-shop-fee/SettlementShopFee";
import SettlementOutSourcing from "./pages/settlement-outsourcing/SettlementOutSourcing";
import SettlementService from "./pages/settlement-service/SettlementService";
import SettlementOutmoney from "./pages/settlement-outmoney/SettlementOutmoney";
import SettlementVirtualac from "./pages/settlement-virtualac/SettlementVirtualac";

import ServiceNotice from "./pages/service-notice/ServiceNotice";
import ServiceNoticeDetail from "./pages/service-notice-detail/ServiceNoticeDetail";
import ServiceReview from "./pages/service-review/ServiceReview";
import ServiceReviewDetail from "./pages/service-review-detail/ServiceReviewDetail";
import ServiceFaq from "./pages/service-faq/ServiceFaq";
import ServiceFaqDetail from "./pages/service-faq-detail/ServiceFaqDetail";
import ServiceFaqCategory from "./pages/service-faqcategory/ServiceFaqCategory";
import ServiceUseGuide from "./pages/service-useguide/ServiceUseGuide";
import ServiceUseGuideDetail from "./pages/service-useguide-detail/ServiceUseGuideDetail";
import ServiceInquiry from "./pages/service-inquiry/ServiceInquiry";
import ServiceInquiryDetail from "./pages/service-inquiry-detail/ServiceInquiryDetail";
import ServiceNotification from "./pages/service-notification/ServiceNotification";
import SettingPolicy from "./pages/setting-policy/SettingPolicy";
import SettingDirect from "./pages/setting-direct/SettingDirect";
import SettingFee from "./pages/setting-fee/SettingFee";
import SettingTermPolicy from "./pages/setting-termpolicy/SettingTermPolicy";
import SettingTermPolicyDetail from "./pages/setting-termpolicy-detail/SettingTermPolicyDetail";
import { useEffect, useState } from "react";
import { userInit } from "./redux/user/UserActions";
import GlobalLoading from "./redux-components/loading/GlobalLoading";
import Modal from "./redux-components/modal/Modal";
import { STORAGE_KEY } from "./libs/consts";
import { signIn } from "./redux/auth/AuthReducer";
import ImageModal from "./redux-components/image-modal/ImageModal";
import ShopInfo from "./pages/shop-info/ShopInfo";
import MainLayout from "./layouts/main/MainLayout";
import ManagerManagement from "./pages/manager-management/ManagerManagement";
import PasswordChange from "./pages/passwordChange/PasswordChange";
import MyInformation from "./pages/myInfo/MyInformation";
import SettingPage from "./pages/setting-page/SettingPage";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import { withRouter } from "react-router-dom";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { BsPersonLinesFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { IoMdReorder } from "react-icons/io";
import ImageViewer from "./components/image-viewer/ImageViewer";
import ServiceDirect from "./pages/service-direct/ServiceDirect";

function InitComponent() {
  const dispatch = useDispatch();
  const token = localStorage.getItem(STORAGE_KEY.accessToken);

  useEffect(() => {
    if (token) {
      dispatch(signIn(token));
    }
  }, []);

  return null;
}

const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    width: 150,
    textAlign: "center",
  },
}));

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { isSigned: authSigned } = useSelector((s) => s.auth);
  const quickMenu = useSelector((s) => s.quickMenu);

  const token = localStorage.getItem(STORAGE_KEY.accessToken);
  const passwordCheck = localStorage.getItem("passwordVerification");
  const isSigned = token || authSigned;

  const menu = quickMenu?.menu?.filter((item) => item.label !== "") || [];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(userInit);
    const status = JSON.parse(localStorage.getItem("kumbangQuick"));
    if (!status) {
      localStorage.setItem(
        "kumbangQuick",
        JSON.stringify({
          enabled: false,
          menu: [
            { id: 0, label: "", path: "", check: false },
            { id: 1, label: "", path: "", check: false },
            { id: 2, label: "", path: "", check: false },
            { id: 3, label: "", path: "", check: false },
            { id: 4, label: "", path: "", check: false },
          ],
        })
      );
    } else {
      localStorage.setItem(
        "kumbangQuick",
        JSON.stringify({
          ...status,
          menu: status.menu.map((item) => {
            return { ...item, check: false };
          }),
        })
      );
    }
  }, []);

  return (
    <>
      <InitComponent />
      {isSigned ? (
        passwordCheck === "false" ? (
          <Switch>
            <Route
              exact
              path={routes.passwordCheck}
              component={PasswordChange}
            />
            <Redirect to={routes.passwordCheck} />
          </Switch>
        ) : (
          <MainLayout>
            <Switch>
              <Route
                exact
                path={routes.settingPolicyDetail}
                component={SettingTermPolicyDetail}
              />
              <Route
                exact
                path={routes.settingPolicy}
                component={SettingTermPolicy}
              />
              <Route exact path={routes.settingFee} component={SettingFee} />
              <Route
                exact
                path={routes.settingOrderPolicy}
                component={SettingPolicy}
              />
              <Route
                exact
                path={routes.settingDirect}
                component={SettingDirect}
              />
              <Route exact path={routes.settingPage} component={SettingPage} />
              <Route
                exact
                path={routes.serviceNotifi}
                component={ServiceNotification}
              />
              <Route
                exact
                path={routes.serviceInquiryDetail}
                component={ServiceInquiryDetail}
              />
              <Route
                exact
                path={routes.serviceInquiry}
                component={ServiceInquiry}
              />
              <Route
                exact
                path={routes.serviceUseGuideDetail}
                component={ServiceUseGuideDetail}
              />
              <Route
                exact
                path={routes.serviceUseGuide}
                component={ServiceUseGuide}
              />
              <Route
                exact
                path={routes.serviceFaqCategory}
                component={ServiceFaqCategory}
              />
              <Route
                exact
                path={routes.serviceFaqDetail}
                component={ServiceFaqDetail}
              />
              <Route exact path={routes.serviceFaq} component={ServiceFaq} />
              <Route
                exact
                path={routes.serviceReviewDetail}
                component={ServiceReviewDetail}
              />
              <Route
                exact
                path={routes.serviceReview}
                component={ServiceReview}
              />
              <Route
                exact
                path={routes.serviceNoticeDetail}
                component={ServiceNoticeDetail}
              />
              <Route
                exact
                path={routes.serviceNotice}
                component={ServiceNotice}
              />
              <Route
                exact
                path={routes.serviceDirect}
                component={ServiceDirect}
              />
              <Route
                exact
                path={routes.settlementMoreService}
                component={SettlementService}
              />
              <Route
                exact
                path={routes.settlementOutSourcing}
                component={SettlementOutSourcing}
              />
              <Route
                exact
                path={routes.settlementOutmoney}
                component={SettlementOutmoney}
              />
              <Route
                exact
                path={routes.settlementVirtualAc}
                component={SettlementVirtualac}
              />

              <Route
                exact
                path={routes.settlementShopFee}
                component={SettlementShopFee}
              />
              <Route
                exact
                path={routes.settlementSalesFee}
                component={SettlementSalesFee}
              />
              <Route
                exact
                path={routes.settlementHistory}
                component={SettlementHistory}
              />
              <Route
                exact
                path={routes.directOrderDetail}
                component={DirectOrderDetail}
              />
              <Route exact path={routes.directOrder} component={DirectOrder} />
              <Route
                exact
                path={routes.directProductDetail}
                component={DirectProductDetail}
              />
              <Route
                exact
                path={routes.directProduct}
                component={DirectProduct}
              />
              <Route
                exact
                path={routes.directStatusDetail}
                component={DirectStatusDetail}
              />
              <Route
                exact
                path={routes.directStatus}
                component={DirectStatus}
              />
              <Route
                exact
                path={routes.physicalProductDetail}
                component={PhysicalProductAdd}
              />
              <Route
                exact
                path={routes.physicalProduct}
                component={PhysicalProduct}
              />
              <Route
                exact
                path={routes.physicalOrderDetail}
                component={PhysicalOrderDetail}
              />
              <Route
                exact
                path={routes.physicalOrder}
                component={PhysicalOrder}
              />
              <Route
                exact
                path={routes.appraisalStockDetail}
                component={AppraisalStockDetail}
              />
              <Route
                exact
                path={routes.appraisalStock}
                component={ApprasialStock}
              />
              <Route
                exact
                path={routes.appraisalProduct}
                component={ApprasialProduct}
              />
              <Route
                exact
                path={routes.appraisalProcess}
                component={ApprasialProcess}
              />
              <Route
                exact
                path={routes.appraisalStatusDetail}
                component={ApprasialStatusDetail}
              />
              <Route
                exact
                path={routes.appraisalStatus}
                component={ApprasialStatus}
              />
              <Route exact path={routes.tradingAsset} component={Trading} />
              <Route exact path={routes.trading} component={Trading} />
              <Route
                exact
                path={routes.memberShopDetail}
                component={MemberShopDetail}
              />
              <Route
                exact
                path={routes.memberShopAdd}
                component={MemberShopAdd}
              />
              <Route
                exact
                path={routes.memberDetail}
                component={MemberDetail}
              />
              <Route
                exact
                path={routes.memberDetailId}
                component={MemberDetail}
              />
              <Route
                exact
                path={routes.memberInactive}
                component={MemberInActive}
              />
              <Route exact path={routes.memberShop} component={MemberShop} />
              <Route
                exact
                path={routes.memberDelete}
                component={MemberDormant}
              />
              <Route
                exact
                path={routes.memberNormal}
                component={MemberNormal}
              />
              <Route
                exact
                path={routes.managerAuthority}
                component={ManagerAuthority}
              />
              <Route
                exact
                path={routes.managerManagement}
                component={ManagerManagement}
              />
              <Route exact path={routes.myInfo} component={MyInformation} />
              <Route exact path={routes.shopInfo} component={ShopInfo} />
              <Redirect to={"/member/normal"} />
            </Switch>
          </MainLayout>
        )
      ) : (
        <Switch>
          <Route exact path={routes.login} component={Login} />
          <Redirect to={routes.login} />
        </Switch>
      )}
      <ImageModal />
      <ImageViewer />
      <Action />
      <GlobalLoading />
      <Modal />

      {quickMenu?.enabled && isSigned && (
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          sx={{ width: 100, position: "fixed", bottom: 25, right: 25 }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          FabProps={{
            sx: {
              bgcolor: "#fe6e0e",
              "&:hover": {
                bgcolor: "#fe6e0e",
              },
            },
          }}
        >
          {menu.map((action) => {
            return (
              <SpeedDialAction
                key={action.id}
                icon={<IoMdReorder />}
                tooltipTitle={action.label}
                classes={{ staticTooltipLabel: classes.staticTooltipLabel }}
                tooltipOpen
                onClick={() => {
                  history.push(action.path);
                  setOpen(false);
                }}
              />
            );
          })}
        </SpeedDial>
      )}
    </>
  );
}

export default withRouter(App);
