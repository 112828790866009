import queryString from "query-string";
import moment from "moment-timezone";

export const objToQueryStr = (obj) => {
  Object.keys(obj).map((key) => {
    if (obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  });

  return "?" + queryString.stringify(obj || {});
};

export const formatPhone = (v) => {
  if (v) {
    return v.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  } else {
    return "-";
  }
};

export const numFormat = (num) => {
  if (num) {
    return Number(num).toLocaleString();
  } else {
    return "";
  }
};
export const getPurityLabel = (purity, type) => {
  if (type === "gold" || type === "금") {
    switch (String(purity)) {
      case "1":
        return "14K(585)";
      case "2":
        return "18K(750)";

      case "3":
        return "24K(999)";
    }
  } else {
    switch (String(purity)) {
      case "1":
        return "기타";
      case "2":
        return "925";
      case "3":
        return "999이상";
    }
  }
};

export const formatTime = (date, format) => {
  if (date) {
    return moment(date).format(format);
  } else {
    return "";
  }
};