var BaseURL;

if (process.env.NODE_ENV === "development") {
  BaseURL = "http://localhost:8181";
} else {
  BaseURL = "http://52.78.242.118:3000";
}

BaseURL = process.env.REACT_APP_USER_API_URL;

export default {
  token: "gumtoken",
  drawerMinWidth: 250,
  apiBaseUrl: BaseURL,
  s3BaseUrl: process.env.REACT_APP_S3_BASE_URL,
};

const STORAGE_PREPARE = "kumbang.manager.";
const STORAGE_KEYS = {
  accessToken: "accessToken",
  tableSize: "tableSize",
};
export const STORAGE_KEY = {};
for (let key in STORAGE_KEYS) {
  STORAGE_KEY[key] = STORAGE_PREPARE + STORAGE_KEYS[key];
}

export const STATUS_CODE = {
  ACTIVE: "활성",
  DISABLED: "비활성",
  LEAVE: "탈퇴",
  LOCK: "사용정지",
  DORMANT: "휴면",
};

export const getDefaultPageSize = () => {
  return { page: 0, size: localStorage.getItem(STORAGE_KEY.tableSize) || 10 };
};

export const FONT_SIZE = {
  BASIC: 16,
};
