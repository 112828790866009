import { useHistory, useLocation } from "react-router";
import Flex from "../../components/flex/Flex";
import Select from "../../components/select/Select";
import Filters from "../../components/filters/Filters";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";
import {
  FormControlLabel,
  InputBase,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionError } from "../../redux/action/ActionActions";
import { formatPhone, objToQueryStr } from "../../services/utils";
import moment from "moment-timezone";
import consts, { getDefaultPageSize, STATUS_CODE } from "../../libs/consts";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import Input from "../../components/input/Input";
export default function MemberNormal({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state || {};
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;

  const [shops, setShops] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    APIS.getRecommendShops().then(({ data: { success, data } }) => {
      if (success) {
        setShops([
          ...data.map(({ id, name }) => ({ value: id, label: name })),
          { value: -1, label: "없음" },
        ]);
      }
    });
  }, []);
  useEffect(() => {
    if (!isNaN(state.page)) {
      fetchList();
    }
  }, [state.page, state.size]);

  const fetchList = () => {
    const param = { ...state };
    if (param.startDate) {
      param.startDate = `${param.startDate}T00:00:00`;
    }
    if (param.endDate) {
      param.endDate = `${param.endDate}T23:59:59`;
    }

    // 이상, 이하
    const { amountType } = param;
    if (param.more) {
      param[`start${amountType}`] = param.more;
    }

    if (param.less) {
      param[`end${amountType}`] = param.less;
    }

    delete param.more;
    delete param.less;
    delete param.amountType;

    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }

    dispatch(loadingStart);
    APIS.searchAccount(param)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setUsers(
            data.content.map((item) => {
              const { status } = item;
              return { ...item, status: STATUS_CODE[status] || status };
            })
          );
          setTotalCount(data.totalElements);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const memoizedUsers = useMemo(() => {
    return users.map((user) => {
      let virtualBankAccount = "",
        bankAccount = "";

      // 가상계좌
      if (user.virtualBankAccountName && user.virtualBankAccountNumber) {
        virtualBankAccount = `${user.virtualBankAccountName} ${user.virtualBankAccountNumber}`;
      }

      // 출금계좌
      if (user.bankAccountName && user.bankAccountNumber) {
        bankAccount = `${user.bankAccountName} ${user.bankAccountNumber}`;
      }
      return {
        ...user,
        virtualBankAccount,
        bankAccount,
      };
    });
  }, [users]);

  const handleClickRow = useCallback((item) => {
    history.push(`${routes.memberDetail}/${item.id}`);
  }, []);

  return (
    <>
      <PageLabel>일반회원</PageLabel>
      <Filters
        defaultState={{ amountType: "Gold", ...getDefaultPageSize() }}
        onSearch={fetchList}
        data={[
          { label: "가입일", type: "date-range", key: "startDate,endDate" },
          {
            label: "검색",
            type: "input",
            placeholder: "이름,휴대폰",
            key: "keyword",
          },

          {
            label: "보유량",
            type: "render",
            render: <AmountFilter />,
          },
          isKorda
            ? {
                label: "추천매장",
                type: "menu",
                key: "recommendShop",
                data: shops,
              }
            : {},
          {
            label: "상태",
            type: "radio",
            key: "status",
            wrap: true,
            data: [
              { label: "전체", value: "" },
              { label: "활성", value: "ACTIVE" },
              { label: "사용정지", value: "LOCK" },
              { label: "휴먼", value: "DORMANT" },
              { label: "탈퇴", value: "LEAVE" },
            ],
          },
        ]}
      />
      <MemoizedDataTable
        data={memoizedUsers}
        totalCount={totalCount}
        onClick={handleClickRow}
      />
    </>
  );
}

const AmountFilter = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const { amountType, more = "", less = "" } = state;

  const handleChangeValue = (key, value = "") => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value },
    });
  };

  return (
    <Flex row>
      <Select
        options={[
          { value: "Gold", label: "금" },
          { value: "Silver", label: "은" },
          { value: "Krw", label: "KRW" },
        ]}
        value={amountType}
        onChange={(e) => {
          handleChangeValue("amountType", e.target.value);
        }}
        style={{ marginRight: 5 }}
      />

      <Input
        white
        validation="number"
        value={more}
        onChange={(value) => {
          handleChangeValue("more", value);
        }}
      />
      <span
        style={{
          alignSelf: "center",
          margin: "0px 5px",
          width: 30,
        }}
      >
        이상
      </span>
      <Input
        white
        validation="number"
        value={less}
        onChange={(value) => {
          handleChangeValue("less", value);
        }}
      />
      <span
        style={{
          alignSelf: "center",
          margin: "0px 5px",
          width: 30,
        }}
      >
        이하
      </span>
    </Flex>
  );
};

const MemoizedDataTable = React.memo(({ data, totalCount, onClick }) => {
  return (
    <DataTable
      name="일반회원"
      data={data}
      totalCount={totalCount}
      columns={[
        { label: "가입일", type: "datetime", key: "createdAt" },
        { label: "이름", key: "name", length: 5 },
        { label: "휴대폰", key: "phone" },
        {
          label: "추천매장",
          key: "recommendShopName",
        },
        { label: "금(GOLD)", type: "number", key: "gold" },
        { label: "은(SILVER)", type: "number", key: "silver" },
        { label: "보유원화", type: "number", key: "krw" },
        { label: "가상계좌", key: "virtualBankAccount" },
        { label: "출금계좌", key: "bankAccount" },
        { label: "계정상태", key: "status" },
      ]}
      onClick={onClick}
      contextMenuVisible={false}
    />
  );
});
