import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
  Tabs,
  Tab,
} from "@material-ui/core";
import {
  Add,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import RenderInput from "../../components/render-input/RenderInput";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
import { assets, filterKey } from "../../constants/index";

export default function ApprasialProduct({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [selectData, setSelectData] = useState({});
  const [type, setType] = useState("GOLD");
  const [name, setName] = useState("");
  const [rate, setRate] = useState("");
  const [imageFile, setImageFile] = useState();
  const [guideFile, setGuideFile] = useState();
  const imageRef = useRef();
  const guideRef = useRef();
  const [tab, setTab] = useState(0);
  const [product, setProduct] = useState([]);

  useEffect(() => {
    const filterAssetKey = filterKey(assets);
    const type = assets[filterAssetKey[tab]].type;
    fetchProduct(type);
  }, [tab]);

  // useEffect(() => {
  //   const type = filterKey(assets);
  //   const parseType = assets[type[tab]].type;
  //   fetchProduct(parseType);
  // }, [tab]);

  useEffect(() => {
    if (selectData?.id) {
      setType(selectData.type);
      setName(selectData.name);
      setRate(selectData.weightRatePct);
      setImageFile({
        path: selectData.imageUrl,
      });
      setGuideFile({
        path: selectData.guideUrl,
      });
    }
  }, [selectData?.id]);

  const handleDelete = (type) => {
    const ids = product.filter((x) => x.checked).map((x) => x.id);
    if (ids.length) {
      dispatch(loadingStart);

      APIS.deleteAppraisalProducts({ ids: ids.join(",") })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => {
          dispatch(
            actionOpen(
              "선택하신 제품이 삭제 되었습니다.",
              null,
              null,
              null,
              true
            )
          );
          dispatch(loadingEnd);
          fetchProduct(type);
        });
    } else {
      dispatch(
        actionOpen("삭제할 제품을 선택해 주세요.", null, null, null, true)
      );
    }
  };

  const fetchProduct = (type) => {
    APIS.getAppraisalProducts(objToQueryStr({ type: type }))
      .then(({ data: { data } }) => {
        setProduct(data);
      })
      .catch((err) => {
        dispatch(actionError(err));
      });
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleAdd = () => {
    setSelectData({ open: true });
    initData(true);
  };

  const initData = (a) => {
    if (!a) {
      setSelectData({});
    }
    setType("GOLD");
    setName("");
    setRate("");
    setImageFile("");
    setGuideFile("");
  };

  const handleSave = () => {
    let data;
    const filterAssetKey = filterKey(assets);
    const type = assets[filterAssetKey[tab]].type;
    const saved = () => {
      if (selectData?.id) {
        dispatch(
          actionOpen("선택하신 제품이 수정 되었습니다.", null, null, null, true)
        );
        fetchProduct(type);
        setSelectData({ isOpen: false });
      } else {
        dispatch(actionOpen("제품이 추가 되었습니다.", null, null, null, true));
        fetchProduct(type);
        setSelectData({ isOpen: false });
        initData();
      }
    };

    const putGuideImage = (id, file, cb, l) => {
      let formData2 = new FormData();
      formData2.append("guide", file);

      APIS.putAppraisalProductsGuide(id, formData2)
        .catch((err) => dispatch(actionError(err)))
        .finally(() => {
          dispatch(loadingEnd);
          cb();
        });
    };

    const putImage = (id, file, cb, l) => {
      let formData = new FormData();
      formData.append("image", file);

      APIS.putAppraisalProductsImage(id, formData)
        .catch((err) => dispatch(actionError(err)))
        .finally(() => {
          dispatch(loadingEnd);
          cb();
        });
    };

    try {
      if (!name) {
        throw "제품명을 입력해 주세요.";
      } else if (isNaN(rate)) {
        throw "올바른 중량 환산율을 입력해 주세요.";
      } else if (!imageFile) {
        throw "제품 이미지를 등록해 주세요.";
      } else if (!guideFile) {
        throw "제품 순도 확인 가이드를 등록해 주세요.";
      }
    } catch (me) {
      dispatch(actionError(me));
      return;
    }

    const params = {
      name,
      type,
      weightRatePct: rate,
    };

    if (!selectData?.id) {
      params.sortOrder = product.length + 11;
    }
    dispatch(loadingStart);

    const successCallback = (res) => {
      data = res.data.data;
      if (!imageFile.path && !guideFile.path) {
        putImage(data.id, imageFile, () => {
          putGuideImage(data.id, guideFile, saved, true);
        });
      } else if (!imageFile.path) {
        putImage(data.id, imageFile, saved, true);
      } else if (!guideFile.path) {
        putGuideImage(data.id, guideFile, saved, true);
      } else {
        dispatch(loadingEnd);
        saved();
      }
    };

    const errorCallback = (err) => {
      dispatch(loadingEnd);
      dispatch(actionError(err));
    };

    selectData?.id
      ? APIS.putAppraisalProducts(selectData.id, params)
          .then(successCallback)
          .catch(errorCallback)
      : APIS.postAppraisalProducts(params)
          .then(successCallback)
          .catch(errorCallback);
  };

  const updateSort = (index, action, type) => {
    const products = product;

    if (
      (action === "up" && index === 0) ||
      (action === "down" && index === products.length - 1)
    ) {
      return false;
    }

    const targetIndex = action === "up" ? index - 1 : index + 1;
    const currentItem = products[index];
    const targetItem = products[targetIndex];

    dispatch(loadingStart);
    const callApi1 = APIS.putAppraisalProducts(currentItem.id, {
      sortOrder: targetIndex,
    });
    const callApi2 = APIS.putAppraisalProducts(targetItem.id, {
      sortOrder: index,
    });

    Promise.all([callApi1, callApi2])
      .then((e) => {
        console.log("allThen", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
        fetchProduct(type);
      });
  };

  const assetKeys = filterKey(assets);

  const onChangeTab = (_, value) => {
    setTab(value);
  };

  return (
    <>
      <Flex
        row
        style={{
          margin: "30px 0px",
        }}
      >
        <Flex style={{ flex: 1, margin: "0px 50px" }}>
          <Tabs
            value={tab}
            onChange={onChangeTab}
            aria-label="basic tabs example"
          >
            {assetKeys.map((item, index) => {
              const title = `${assets[item].title} 제품`;
              return <Tab key={index} label={title} />;
            })}
          </Tabs>
          <TabPanel
            data={product}
            productObj={assets[assetKeys[tab]]}
            updateSort={updateSort}
            handleDelete={handleDelete}
            setSelectData={setSelectData}
            setProduct={setProduct}
            handleAdd={handleAdd}
          />
        </Flex>
        {selectData.open && (
          <Flex style={{ flex: 1, margin: "0px 50px" }}>
            {selectData.open && (
              <Grid container spacing={2} className={classes.mainC2ContentGrid}>
                <Grid item xs={6}>
                  <Text font={fonts.notoSansKRBold}>제품 상세</Text>
                </Grid>
                <Grid item xs={6} className={classes.gridHeaderRow}>
                  <Button onClick={handleSave} label="저장" />
                  <Button
                    className={classes.bt1}
                    onClick={() => initData()}
                    label="취소"
                  />
                </Grid>
                <Grid item xs={3}>
                  분류
                </Grid>
                <Grid item xs={9}>
                  <FormControl>
                    <RadioGroup
                      onChange={handleTypeChange}
                      row
                      value={type}
                      className={classes.radiogroup}
                    >
                      <FormControlLabel
                        style={{ marginTop: "0px !important" }}
                        label="금"
                        control={
                          <Radio
                            value="GOLD"
                            color="default"
                            style={{ color: "black" }}
                          />
                        }
                      />
                      <FormControlLabel
                        style={{ marginTop: "0px !important" }}
                        label="은"
                        control={
                          <Radio
                            value="SILVER"
                            color="default"
                            style={{ color: "black" }}
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  제품명
                </Grid>
                <Grid item xs={9}>
                  <InputBase
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    className={classes.input}
                  />
                </Grid>
                <Grid item xs={3}>
                  중량 환산율
                </Grid>
                <Grid item xs={9}>
                  <span className={classes.input}>
                    <InputBase
                      type="number"
                      value={rate}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          value &&
                          (isNaN(value) ||
                            parseInt(value) < 1 ||
                            parseInt(value) > 100)
                        ) {
                          return;
                        }
                        setRate(value);
                      }}
                    ></InputBase>
                    %
                  </span>
                </Grid>
                <Grid item xs={3}>
                  이미지
                </Grid>
                <Grid item xs={9} style={{ color: "black !important" }}>
                  <div
                    onClick={() => {
                      imageRef.current?.click();
                    }}
                    className={classes.img}
                  >
                    {imageFile ? (
                      <img
                        src={imageFile.uri || imageFile.path}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <Add />
                    )}
                  </div>
                  <RenderInput
                    onChange={setImageFile}
                    inputRef={imageRef}
                    onlyImage
                  />
                </Grid>
                <Grid item xs={3}>
                  제품순도 확인
                  <br />
                  방법 가이드
                </Grid>
                <Grid item xs={9} style={{ color: "black !important" }}>
                  <div
                    onClick={() => {
                      guideRef.current?.click();
                    }}
                    className={classes.img2}
                  >
                    {guideFile ? (
                      <img
                        src={guideFile.uri || guideFile.path}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <Add />
                    )}
                  </div>
                  <RenderInput
                    onChange={setGuideFile}
                    inputRef={guideRef}
                    onlyImage
                  />
                </Grid>
              </Grid>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
}

const TabPanel = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const {
    productObj,
    data,
    updateSort,
    handleDelete,
    setSelectData,
    setProduct,
    handleAdd,
  } = props;
  const title = productObj.title || "-";
  const type = productObj.type || "-";
  return (
    <Flex style={{ flex: 1 }}>
      <Flex row className={classes.header}>
        <span className={classes.label}>{title}제품</span>
        <Flex className={classes.buttonWrap}>
          <Button onClick={handleAdd} key="13" label="제품 추가" />
          &nbsp;
          <Button
            onClick={() => {
              dispatch(
                actionOpen(
                  "선택하신 제품을 삭제하시겠습니까?",
                  () => {
                    handleDelete(type);
                  },
                  "취소",
                  "삭제"
                )
              );
            }}
            label="삭제"
          />
        </Flex>
      </Flex>
      <Flex className={classes.list}>
        <table className={classes.table} cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>순서</th>
              <th>No</th>

              <th>제품</th>
              <th>중량 환산율</th>
              <th>수정</th>
              <th>
                <Checkbox />
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((x, i) => {
              return (
                <tr key={i.toString() + x.checked}>
                  <td>
                    <ArrowDropUpOutlined
                      onClick={() => {
                        updateSort(i, "up", type);
                      }}
                    />
                    <ArrowDropDownOutlined
                      onClick={() => {
                        updateSort(i, "down", type);
                      }}
                    />
                  </td>

                  <td>{i + 1}</td>
                  <td>{x.name}</td>
                  <td>{x.weightRatePct}%</td>
                  <td
                    onClick={() => {
                      setSelectData({
                        ...x,
                        type: x.type,
                        open: true,
                      });
                    }}
                    style={{ cursor: "pointer", color: "red" }}
                  >
                    수정
                  </td>
                  <td>
                    <Checkbox
                      checked={x.checked}
                      onChange={(e) => {
                        const c = e.target.checked;
                        data[i].checked = c;
                        setProduct([...data]);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Flex>
    </Flex>
  );
};

const useStyle = makeStyles({
  img2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 300,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  img: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px 20px",
    alignItems: "center",
    boxSizing: "border-box",
    width: "auto !important",
  },

  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },

  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  buttonWrap: {
    flexDirection: "row",
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "5px 10px",
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
  },
  radiogroup: {
    marginTop: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "80%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
});
