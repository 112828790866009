import {
  Button,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { actionOpen } from "../../redux/action/ActionActions";

export default function SettingPolicy1({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleSave = () => {
    dispatch(actionOpen("수정하시겠습니까?", () => {}, "취소", "저장"));
  };
  return (
    <Flex>
      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>거래수수료 일괄 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th rowSpan={2} colSpan={2}></th>
          <th colSpan={2}>금(Gold)</th>
          <th colSpan={2}>은(Silver)</th>
        </tr>
        <tr>
          <th>매도</th>
          <th>매수</th>
          <th>매도</th>
          <th>매수</th>
        </tr>
        <tr>
          <td colSpan={2} className={classes.tdb}>
            일반회원
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2} className={classes.tdb}>
            매장회원
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />%
            </div>
          </td>
        </tr>
      </table>

      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>거래 호가 상/하한 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th colSpan={2}>금(Gold)</th>
          <th colSpan={2}>은(Silver)</th>
        </tr>
        <tr>
          <th>상한</th>
          <th>하한</th>
          <th>상한</th>
          <th>하한</th>
        </tr>
        <tr>
          {[{}, {}, {}, {}].map((x, i) => {
            return (
              <td style={{ height: 100 }} key={i.toString()}>
                <Flex
                  row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <RadioGroup>
                    <FormControlLabel
                      label={i % 2 === 0 ? "소매살때 가격" : "소매팔때 가격"}
                      control={
                        <Radio
                          color={"default"}
                          style={{ color: colors.black }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="시초가 대비 "
                      control={
                        <Radio
                          color={"default"}
                          style={{ color: colors.black }}
                        />
                      }
                    />
                  </RadioGroup>
                  <Grid container spacing={1} style={{ alignItems: "center" }}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                      <div className={classes.inputWrap}>
                        <InputBase className={classes.input} />원
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.inputWrap}>
                        <InputBase className={classes.input} />%
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.inputWrap}>
                        <InputBase className={classes.input} />원
                      </div>
                    </Grid>
                  </Grid>
                </Flex>
              </td>
            );
          })}
        </tr>
      </table>

      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>거래 단위 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th colSpan={2}></th>
          <th>금(Gold)</th>
          <th>은(Silver)</th>
        </tr>

        <tr>
          <td colSpan={2} className={classes.tdb}>
            호가 단위
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />원
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />원
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2} className={classes.tdb}>
            최소 주문 단위
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />원
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />원
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2} className={classes.tdb}>
            최소 결제 금액
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />원
            </div>
          </td>
          <td>
            <div className={classes.inputWrap}>
              <InputBase className={classes.input} />원
            </div>
          </td>
        </tr>
      </table>

      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>주문 한도 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th colSpan={2}></th>
          <th>매수</th>
          <th>매도</th>
        </tr>

        <tr>
          <td colSpan={2} className={classes.tdb}>
            지정가 주문
          </td>
          <td>
            <Flex row className={"row-center " + classes.a}>
              보유자산 대비
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />원
              </div>
            </Flex>
          </td>
          <td>
            <Flex row className={"row-center " + classes.a}>
              보유자산 대비
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />원
              </div>
            </Flex>
          </td>
        </tr>
        <tr>
          <td colSpan={2} className={classes.tdb}>
            시장가 주문
          </td>
          <td>
            <Flex row className={"row-center " + classes.a}>
              보유자산 대비
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />원
              </div>
            </Flex>
          </td>
          <td>
            <Flex row className={"row-center " + classes.a}>
              보유자산 대비
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />원
              </div>
            </Flex>
          </td>
        </tr>
      </table>
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  labelWrap: {
    margin: "0px 50px",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    "& button": { width: 100 },
  },

  table2: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  tdb: {
    backgroundColor: "#f5f5f5",
  },

  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
  inputWrap: {
    border: "1px solid #ddd",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    width: "80%",
    marginLeft: "10%",
    paddingRight: 10,
  },
  input: {
    paddingRight: 10,
  },
  a: { alignItems: "center", whiteSpace: "nowrap" },
});
