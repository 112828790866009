import {
  ButtonBase,
  InputBase,
  makeStyles,
  FormControl,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonRadios from "../../components/button-radios/ButtonRadios";
import DateRange from "../../components/date-range/DateRange";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import * as APIS from "../../libs/apis";
import { numFormat } from "../../services/utils";
import { objToQueryStr } from "../../services/utils";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { useMemo } from "react";
import { formatTime } from "../../libs/utils";
import { getDefaultPageSize } from "../../libs/consts";
import { assets, filterKey } from "../../constants/index";

export default function Trading({}) {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state || {};
  const [tradePrice, settradePrice] = useState(0);
  const [tradePriceformat, settradePriceformat] = useState("");
  const [startDate, setstartDate] = useState(formatTime());
  const [endDate, setendDate] = useState(formatTime());
  const [done, setdone] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [tradeList, settradeList] = useState([]);
  const [tradeListtotal, settradeListtotal] = useState({ grams: 0, amount: 0 });
  // const [assetType, setAssetType] = React.useState("GOLD");

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
        type: "GOLD",
        ...getDefaultPageSize(),
      });
    }
    let type = state?.type || "GOLD";
    // if (location.pathname === routes.tradingSilver) {
    //   getgoldOrSilver = "SILVER";
    // }
    APIS.getcurrentmarketprice(type).then(({ data: { data } }) => {
      settradePrice(data);
      settradePriceformat(numFormat(data));
    });

    if (!isNaN(state.page)) {
      handleValueChange("searchName", "");
      fetchList(type, state.searchName);
    }
  }, [
    state.tab,
    state.method,
    state.page,
    state.size,
    state.type,
    location.pathname,
    startDate,
    endDate,
  ]);

  const fetchList = (getgoldOrSilver, searchName = "") => {
    dispatch(loadingStart);
    var tradeType = "";
    if (state.method === "buy") {
      tradeType = "BUY";
    } else if (state.method === "sell") {
      tradeType = "SELL";
    }

    const param = {
      page: state.page,
      size: state.size,
      beginDate: startDate,
      endDate: endDate,
      tradeType,
      userCondition: searchName ? "" : state.searchName,
      assetType: getgoldOrSilver || "GOLD",
    };

    var status = "notsigned";
    if (state.tab === "1") {
      status = "done";
    }

    const paramQuery = objToQueryStr(param);

    const fetchApis = [
      APIS.getTradeList(status, paramQuery).then(
        ({
          data: {
            data: { content, totalElements },
          },
        }) => {
          settradeList(content);
          setTotalCount(totalElements);
        }
      ),
      APIS.getTradeSummaryList(status, paramQuery).then(
        ({
          data: {
            data: { totalAmount, totalKrw },
          },
        }) => {
          settradeListtotal({
            grams: totalAmount > 0 ? numFormat(totalAmount) : 0,
            amount: totalKrw > 0 ? numFormat(totalKrw) : 0,
          });
        }
      ),
    ];

    Promise.all(fetchApis).finally(() => dispatch(loadingEnd));
  };

  const nameSubmit = () => {
    var getgoldOrSilver = state.type;
    fetchList(getgoldOrSilver);
  };

  const TradeDataTable = useMemo(() => {
    return (
      <DataTable
        name={`${location.pathname === routes.tradingSilver ? "은" : "금"} ${
          state.tab === "1" ? "체결" : "미체결"
        } 현황`}
        hideLabel
        data={console.log("tradeList", tradeList) || tradeList}
        totalCount={totalCount}
        columns={
          state.tab === "1"
            ? [
                { label: "거래 시간", key: "completedAt", type: "datetime" },
                { label: "구분", key: "tradeType", type: "tradetype" },
                { label: "체결가격", key: "pricePerGram", type: "number" },
                { label: "거래금액", key: "pureTradeKrw", type: "number" },
                { label: "수수료", key: "userFee", type: "number" },
                { label: "체결량", key: "tradeGram", type: "number" },
                { label: "주문자", key: "userName" },
                { label: "휴대폰", key: "userPhoneNumber", type: "phone" },
                { label: "추천매장", key: "recommendShopName" },
                { label: "감정매장", key: "inOutShopName" },
              ]
            : [
                { label: "등록 시간", key: "created_at", type: "datetime" },
                { label: "구분", key: "tradeType", type: "tradetype" },
                { label: "주문가격", key: "orderPrice", type: "number" },
                { label: "주문수량", key: "requestGram", type: "number" },
                { label: "미체결량", key: "remainGram", type: "number" },
                { label: "주문자", key: "userName" },
                { label: "휴대폰", key: "userPhoneNumber", type: "phone" },
                { label: "추천매장", key: "recommendShopName" },
                { label: "감정매장", key: "inOutShopName" },
              ]
        }
      />
    );
  }, [tradeList, totalCount, state.tab, state.page, state.size]);

  // const assetsKeys = filterKey(assets);

  // const handleChange = (event) => {
  //   setAssetType(event.target.value);
  // };

  return (
    <>
      <LabelTabs
        data={[
          { label: "체결 현황", value: "1" },
          { label: "미체결 현황", value: "2" },
        ]}
      />
      <Text className={classes.label1} font={fonts.notoSansKRBold}>
        <Flex row>
          <FormControl>
            <ButtonRadios
              field="type"
              data={[
                {
                  label: "금(GOLD)",
                  value: "GOLD",
                },
                {
                  label: "은(SILVER)",
                  value: "SILVER",
                },
              ]}
            />
            {/* <Select
              className={classes.label3}
              value={assetType}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {assetsKeys.map((item, index) => {
                const title = assets[item].title || "-";
                const type = assets[item].type || "-";
                return (
                  <MenuItem
                    key={index}
                    className={classes.menuItem}
                    value={type}
                  >
                    {title}
                  </MenuItem>
                );
              })}
            </Select> */}
          </FormControl>
          <Text font={fonts.notoSansKRBold} className={classes.label2}>
            {tradePriceformat}KRW
          </Text>
        </Flex>
      </Text>
      <Flex row className={classes.toolbar}>
        <DateRange startDate={setstartDate} endDate={setendDate} />
        <ButtonRadios
          field="method"
          totalCount={totalCount}
          data={[
            {
              label: "전체",
              value: "",
            },
            {
              label: "매수",
              value: "buy",
            },
            {
              label: "매도",
              value: "sell",
            },
          ]}
        />
      </Flex>
      <Flex className={classes.toolbar2} row>
        <Flex row className={classes.toolbar3}>
          <Text>총 {totalCount}건</Text>
          {state.tab === "1" ? (
            <Text>기간내 체결 중량: {tradeListtotal.grams}g</Text>
          ) : (
            <Text>기간내 미체결 중량: {tradeListtotal.grams}g</Text>
          )}
          <Text>주문대금 : {tradeListtotal.amount} KRW</Text>
        </Flex>

        <Flex row>
          <InputBase
            className={classes.input}
            placeholder="고객명, 휴대폰번호"
            onChange={(e) => handleValueChange("searchName", e.target.value)}
            value={state.searchName}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                nameSubmit();
              }
            }}
          />
          <ButtonBase className={classes.button} onClick={() => nameSubmit()}>
            검색
          </ButtonBase>
        </Flex>
      </Flex>
      {TradeDataTable}
    </>
  );
}

const useStyle = makeStyles({
  label1: {
    margin: "50px",
    color: "black",
    fontSize: 24,
  },
  label3: {
    marginTop: 3,
    fontWeight: "bold",
  },
  label2: {
    color: "red",
    marginLeft: 16,
  },
  menuItem: {
    fontWeight: "bold",
  },
  toolbar: {
    alignSelf: "stretch",
    alignItems: "center",
    margin: "0px 50px",
    justifyContent: "space-between",
  },
  toolbar2: {
    alignSelf: "stretch",
    alignItems: "center",
    margin: "0px 50px",
    marginTop: 40,
    justifyContent: "space-between",
  },
  toolbar3: {
    minWidth: "50%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    width: 200,
    padding: "0px 10px",
    border: "1px solid rgba(0,0,0,0.23)",
  },
  button: {
    backgroundColor: "#000",
    width: 100,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
});
