import React from "react";
import Viewer from "react-viewer";
import { useDispatch, useSelector } from "react-redux";
import { closeImageView } from "../../redux/image-viewer/ImageViewerReducer";

export default function ImageViewer() {
  const dispatch = useDispatch();
  const { open, src } = useSelector((s) => s.imageViewer);
  const handleClose = () => {
    dispatch(closeImageView());
  };
  return (
    <>
      <Viewer
        visible={open}
        onClose={handleClose}
        onMaskClick={handleClose}
        zoomSpeed={0.2}
        images={[{ src: src, downloadUrl: src }]}
        downloadable
      />
    </>
  );
}
