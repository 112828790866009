export default {
  login: "/login",
  passwordCheck: "/password-check",
  main: "/",
  member: "/member",
  memberDetail: "/member/normal/detail",
  memberDetailId: "/member/normal/detail/:id",
  memberNormal: "/member/normal",
  memberShop: "/member/shop",
  memberShopDetail: "/member/shop/detail",
  memberShopAdd: "/member/shop/add",
  memberInactive: "/member/inactive",
  memberDelete: "/member/delete",
  // tradingGold: "/trading/gold",
  tradingAsset: "/trading/assets",
  // tradingSilver: "/trading/silver",
  trading: "/trading",
  appraisal: "/appraisal",
  appraisalProcess: "/appraisal/process",
  appraisalStatus: "/appraisal/status",
  appraisalStatusDetail: "/appraisal/status/detail",
  appraisalProduct: "/appraisal/product",
  appraisalStock: "/appraisal/stock",
  appraisalStockDetail: "/appraisal/stock/detail",

  physical: "/physical",
  physicalOrder: "/physical/order",
  physicalOrderDetail: "/physical/order/detail",
  physicalProduct: "/physical/product",
  physicalProductDetail: "/physical/product/detail",

  direct: "/direct",
  directStatus: "/direct/status",
  directStatusDetail: "/direct/status/detail",
  directStatusDetailAdd: "/direct/status/detail/add",
  directProduct: "/direct/product",
  directProductDetail: "/direct/product/detail",
  directOrder: "/direct/order",
  directOrderDetail: "/direct/order/detail",

  settlement: "/settlement",
  settlementHistory: "/settlement/history",
  settlementSalesFee: "/settlement/salesfee",
  settlementShopFee: "/settlement/shopfee",
  settlementOutSourcing: "/settlement/outsourcing",
  settlementMoreService: "/settlement/moreservice",
  settlementOutmoney: "/settlement/outmoney",
  settlementVirtualAc: "/settlement/virtualac",

  service: "/service",
  serviceNotice: "/service/notice",
  serviceNoticeDetail: "/service/notice/detail",
  serviceReview: "/service/review",
  serviceReviewDetail: "/service/review/detail",

  serviceFaq: "/service/faq",
  serviceFaqDetail: "/service/faq/detail",
  serviceFaqCategory: "/service/categoryfaq",
  serviceUseGuide: "/service/useguide",
  serviceUseGuideDetail: "/service/useguide/detail",
  serviceInquiry: "/service/inquiry",
  serviceInquiryDetail: "/service/inquiry/detail",
  serviceNotifi: "/service/notifi",
  serviceDirect: "/service/direct",

  setting: "/setting",
  settingOrderPolicy: "/setting/orderpolicy",
  settingFee: "/setting/fee",
  settingPolicy: "/setting/policy",
  settingPage: "/setting/page",
  settingDirect: "/setting/direct/status",
  settingPolicyDetail: "/setting/policy/detail",
  settingAdmin: "/setting/admin",
  settingLog: "/setting/log",
  settingBasicInfo: "/setting/basicinfo",

  shop: "/shop",
  shopInfo: "/shop/info",

  manager: "/manager",
  managerAuthority: "/manager/authority-management",
  managerManagement: "/manager/manager-management",
  myInfo: "/manager/info",
};
