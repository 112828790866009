import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import Button from "../../components/button/Button";
import GridRow from "../../components/grid-row/GridRow";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import PageLabel from "../../components/page-label/PageLabel";
import SimpleTable from "../../components/simple-table/SimpleTable";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";
import { useDispatch, useSelector } from "react-redux";
import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import moment from "moment-timezone";
import * as APIS from "../../libs/apis";
import Flex from "../../components/flex/Flex";
import { InputBase, makeStyles, Typography } from "@material-ui/core";
import Input from "../../components/input/Input";
import { numberWithCommas, formatTime } from "../../libs/utils";
import { formatPhone } from "../../services/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import AssetStatus from "./AssetStatus";

const MAP_COLUMN_TO_LABEL = {
  createdAt: "가입일",
  name: "이름",
  sns: "가입방법",
  ageGender: "나이/성별",
  account: "로그인 ID",
  phone: "휴대폰",
  bank: "입출금계좌",
  virtualAccount: "가상계좌",
  email: "E-mail",
  fullAddress: "주소",
  recommendShopName: "추천매장",
  status: "계정상태",
};

export default function MemberDetail({ match }) {
  const matchId = match?.params?.id;
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const { tab } = state;
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const userId = state.id || matchId;
  const [isEdit, setIsEdit] = useState(false);
  const [canReSignupDate, setCanReSignupDate] = useState("");
  const [member, setMember] = useState({});
  const [status, setStatus] = useState({ origin: "", current: "" });
  const [fee, setFee] = useState({
    goldBuy: 0,
    goldSell: 0,
    silverBuy: 0,
    silverSell: 0,
  });
  const [shops, setShops] = useState([]);
  const [banks, setBanks] = useState([]);
  const [modify, setModify] = useState({
    goldBuyFee: 0,
    goldSellFee: 0,
    silverBuyFee: 0,
    silverSellFee: 0,
    userStatus: "",
    shopId: "",
    bankCode: "",
    bankNumber: "",
  });

  const canModify = {
    shop: !member.recommendShopId,
    bank: !member.bankName && !member.bankAccountNumber,
  };

  useEffect(() => {
    history.replace(history.location.pathname, {
      ...history.location.state,
      tab: "detail",
    });
  }, []);

  useEffect(() => {
    switch (tab) {
      case "detail":
        userInit();
        break;
      default:
    }
  }, [tab]);

  const userInit = () => {
    dispatch(loadingStart);
    const getUserDetail = APIS.searchUserDetail(userId).then(
      ({ data: { success, data = {}, message } }) => {
        const {
          age,
          gender,
          loginId,
          bankCode,
          bankAccountNumber,
          virtualAccountName,
          virtualAccountAccountNumber,
          postCode,
          address,
          addressDetail,
          status,
          recommendShopId,
          canReSignupDate,
        } = data;

        setMember({
          ...data,
          ageGender: `${age}/${gender ? "남자" : "여자"}`,
          account: loginId,
          virtualAccount: `${virtualAccountName} ${virtualAccountAccountNumber}`,
          fullAddress: `(${postCode}) ${address} ${addressDetail}`,
        });

        setModify((modify) => ({
          ...modify,
          shopId: recommendShopId,
          userStatus: status,
          bankCode,
          bankNumber: bankAccountNumber,
        }));
        setCanReSignupDate(canReSignupDate);
      }
    );

    const getFee = APIS.getUserTradeFee(userId).then(
      ({ data: { success, data, message } }) => {
        setModify((modify) => ({
          ...modify,
          goldBuyFee: data?.goldBuy || 0,
          goldSellFee: data?.goldSell || 0,
          silverBuyFee: data?.silverBuy || 0,
          silverSellFee: data?.silverSell || 0,
        }));
      }
    );

    APIS.getRecommendShops().then(({ data: { success, data } }) => {
      if (success) {
        setShops(data);
      }
    });

    APIS.getBankList().then(
      ({
        data: {
          success,
          data: { content },
          message,
        },
      }) => {
        success && setBanks(content);
      }
    );

    Promise.all([getUserDetail, getFee]).finally(() => {
      dispatch(loadingEnd);
    });
  };

  const getColumnData = (key, option = {}) => {
    return {
      label: MAP_COLUMN_TO_LABEL[key],
      value: member[key],
      ...option,
    };
  };

  const handleChangeFee = (key) => (value) => {
    setFee((fee) => ({ ...fee, [key]: value }));
  };

  const handleChangeModify = (key) => (value) => {
    setModify((modify) => ({ ...modify, [key]: value }));
  };

  const onClickSave = (password) => {
    let putStatus = { success: true };

    if (status.origin !== status.current) {
      putStatus = APIS.putUserStatus(userId, {
        status: status.current,
        password,
      })
        .then(({ data }) => {
          if (data.success) {
            setStatus({ ...status, origin: status.current });
          }
          return data;
        })
        .catch((e) => {
          return {
            success: false,
            message:
              "상태 변경 중 오류가 발생하였습니다. 관리자에게 문의해주세요.",
          };
        });
    }

    const putTradeFee = APIS.putUserTradeFee(userId, { ...fee, password })
      .then(({ data }) => data)
      .catch((e) => {
        return {
          success: false,
          message:
            "수수료율 변경 중 오류가 발생하였습니다. 관리자에게 문의해주세요.",
        };
      });

    Promise.all([putStatus, putTradeFee]).then((e) => {
      let complete = true;

      for (let i in e) {
        const { success, message } = e[i];
        if (!success) {
          complete = false;
          dispatch(actionOpen(message, null, null, null, true));
        }
      }
      if (complete) {
        dispatch(actionOpen("저장이 완료되었습니다.", null, null, null, true));
      }
    });
  };
  const onClickEditReSignupDate = (adminPassword) => {
    if (!adminPassword) {
      dispatch(actionError("비밀번호를 입력하여 주세요."));
    } else {
      const data = { canReSignupDate: canReSignupDate };
      data["adminPassword"] = adminPassword;
      dispatch(loadingStart);
      APIS.modifyCanReSignupDate(userId, data)
        .then(({ data: { success, message } }) => {
          if (!success) {
            dispatch(
              actionOpen(
                message,
                () => {
                  userInit();
                },
                null,
                null,
                true
              )
            );
          } else {
            dispatch(
              actionOpen(
                "수정이 완료되었습니다.",
                () => {
                  userInit();
                  setIsEdit(false);
                },
                null,
                null,
                true
              )
            );
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const onClickSave2 = (adminPassword) => {
    if (member?.status === "LEAVE") {
      dispatch(
        actionError("계정상태가 탈퇴인 회원의 정보는 수정할 수 없습니다.")
      );
    } else {
      const param = { ...modify };

      // if (!canModify.shop) {
      //   delete param["shopId"];
      // }

      if (!canModify.bank) {
        delete param["bankCode"];
        delete param["bankNumber"];
      }

      param["adminPassword"] = adminPassword;

      dispatch(loadingStart);
      APIS.modifyUserDetail(userId, param)
        .then(({ data: { success, message } }) => {
          if (!success) {
            dispatch(
              actionOpen(
                message,
                () => {
                  userInit();
                },
                null,
                null,
                true
              )
            );
          } else {
            dispatch(
              actionOpen("저장이 완료되었습니다.", userInit, null, null, true)
            );
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const {
    goldBuyFee,
    goldSellFee,
    silverBuyFee,
    silverSellFee,
    userStatus,
    shopId,
    bankCode,
    bankNumber,
  } = modify;

  return (
    <>
      <LabelTabs
        className={classes.labelTabs}
        data={[
          { label: "회원 상세정보", value: "detail" },
          { label: "계정변경 이력", value: "history" },
        ]}
      />
      {state.tab === "history" ? (
        <>
          <SimpleTable
            label="계정변경 이력"
            columns={[{ label: "변경일자" }, { label: "변경내용" }]}
            data={[{}, {}, {}, {}, {}]}
          />
        </>
      ) : (
        <>
          {member.id && (
            <>
              {isKorda &&
                (member.status === "LEAVE" ? (
                  <Button
                    label="저장"
                    onClick={() => {
                      dispatch(
                        actionError(
                          "계정상태가 탈퇴인 회원의 정보는 수정할 수 없습니다."
                        )
                      );
                    }}
                    className={classes.infoLeaveSaveButton}
                  />
                ) : (
                  <AdminConfirmButton
                    popupMessage="변경하시겠습니까?"
                    label="저장"
                    popupLabel="회원정호 변경"
                    callback={onClickSave2}
                    rootClassName={classes.confirmInfoSaveButton}
                  />
                ))}
              <GridRow
                label="기본정보"
                data={[
                  {
                    label: "가입일 / 수정일 / 재가입일",
                    type: "render",
                    renderWidth: "65%",
                    render: (
                      <Flex row>
                        {isEdit ? (
                          <Flex row>
                            <Typography>
                              {formatTime(member.createdAt || "-").replace(
                                "Invalid date",
                                " - "
                              ) +
                                " / " +
                                formatTime(member.updatedAt || "-").replace(
                                  "Invalid date",
                                  " - "
                                )}
                            </Typography>
                            <input
                              value={canReSignupDate || ""}
                              onChange={(e) => {
                                setCanReSignupDate(e.target.value);
                              }}
                              type="date"
                              style={{ marginLeft: 10, height: 30 }}
                            />
                            <AdminConfirmButton
                              popupMessage="변경하시겠습니까?"
                              label="저장"
                              popupLabel="재가입일 수정"
                              callback={onClickEditReSignupDate}
                              rootClassName={classes.confirmRoot}
                              btnClassName={classes.confirmButton}
                            />
                          </Flex>
                        ) : (
                          <Flex row>
                            <Typography>
                              {formatTime(member.createdAt || "-").replace(
                                "Invalid date",
                                " - "
                              ) +
                                " / " +
                                formatTime(member.updatedAt || "-").replace(
                                  "Invalid date",
                                  " - "
                                ) +
                                " / " +
                                formatTime(
                                  member.canReSignupDate || "-"
                                ).replace("Invalid date", " - ")}
                            </Typography>
                            {member.status === "LEAVE" ? (
                              <Button
                                label="수정"
                                className={classes.editButton}
                                onClick={() => {
                                  setIsEdit(true);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </Flex>
                        )}
                      </Flex>
                    ),
                  },
                  // getColumnData("createdAt", { renderWidth: "65%" }),
                  getColumnData("name", { renderWidth: "35%" }),
                  getColumnData("sns", { renderWidth: "65%" }),
                  getColumnData("ageGender", { renderWidth: "35%" }),
                  getColumnData("account", { renderWidth: "65%" }),
                  {
                    label: "휴대폰",
                    type: "render",
                    renderWidth: "35%",
                    render: (
                      <Flex row style={{ flex: 1 }}>
                        <Typography>{formatPhone(member.phone)}</Typography>
                      </Flex>
                    ),
                  },
                  {
                    label: "출금계좌",
                    type: "render",
                    renderWidth: "65%",
                    render: (
                      <Flex row style={{ flex: 1 }}>
                        <select
                          style={{ fontSize: 16 }}
                          value={bankCode}
                          onChange={(e) => {
                            handleChangeModify("bankCode")(e.target.value);
                          }}
                          disabled={!canModify.bank}
                        >
                          <option value={""}>{"없음"}</option>
                          {banks.map((bank, i) => {
                            return (
                              <option key={i.toString()} value={bank.code}>
                                {bank.name}
                              </option>
                            );
                          })}
                        </select>
                        <Input
                          className={classes.bankNumberInput}
                          value={bankNumber}
                          onChange={
                            canModify.bank
                              ? handleChangeModify("bankNumber")
                              : null
                          }
                          disabled
                        />
                      </Flex>
                    ),
                  },
                  getColumnData("virtualAccount", { renderWidth: "35%" }),
                  isKorda
                    ? {
                        label: "계정상태",
                        value: userStatus,
                        renderWidth: "65%",
                        type: "radio",
                        data: [
                          { label: "활성", value: "ACTIVE" },
                          { label: "사용정지", value: "LOCK" },
                          { label: "휴면", value: "DORMANT" },
                          { label: "탈퇴", value: "LEAVE" },
                        ],
                        onChange: handleChangeModify("userStatus"),
                      }
                    : getColumnData("status", { renderWidth: "65%" }),
                  {
                    label: "추천매장",
                    renderWidth: "35%",
                    type: "render",
                    render: (
                      <Flex row>
                        <select
                          style={{ minWidth: 50, fontSize: 16 }}
                          value={shopId}
                          onChange={(e) => {
                            setModify({ ...modify, shopId: e.target.value });
                          }}
                          disabled={!isKorda}
                        >
                          <option value={""}>{"없음"}</option>
                          {shops.map((shop, i) => {
                            return (
                              <option key={i.toString()} value={shop.id}>
                                {shop.name}
                              </option>
                            );
                          })}
                        </select>
                      </Flex>
                    ),
                  },
                  getColumnData("email", { fullWidth: true }),
                  getColumnData("fullAddress", { fullWidth: true }),

                  {
                    label: "수수료율",
                    value: "",
                    fullWidth: true,
                    render: (
                      <Flex row className={classes.feeSection}>
                        <Flex row className={classes.feeLabel}>
                          <span>금매수</span>
                          {isKorda ? (
                            <Input
                              className={classes.feeInputWrap}
                              disabled
                              value={goldBuyFee}
                              onChange={handleChangeModify("goldBuyFee")}
                              autoComplete="new-password"
                              validation="number"
                            />
                          ) : (
                            <span className={classes.feeText}>
                              {goldBuyFee}
                            </span>
                          )}
                          %
                        </Flex>
                        <Flex row className={classes.feeLabel}>
                          <span>금매도</span>
                          {isKorda ? (
                            <Input
                              className={classes.feeInputWrap}
                              disabled
                              value={goldSellFee}
                              onChange={handleChangeModify("goldSellFee")}
                              autoComplete="new-password"
                              validation="number"
                            />
                          ) : (
                            <span className={classes.feeText}>
                              {goldSellFee}
                            </span>
                          )}
                          %
                        </Flex>
                        <Flex row className={classes.feeLabel}>
                          <span>은매수</span>
                          {isKorda ? (
                            <Input
                              className={classes.feeInputWrap}
                              disabled
                              value={silverBuyFee}
                              onChange={handleChangeModify("silverBuyFee")}
                              autoComplete="new-password"
                              validation="number"
                            />
                          ) : (
                            <span className={classes.feeText}>
                              {silverBuyFee}
                            </span>
                          )}
                          %
                        </Flex>
                        <Flex row className={classes.feeLabel}>
                          <span>은매도</span>
                          {isKorda ? (
                            <Input
                              className={classes.feeInputWrap}
                              disabled
                              value={silverSellFee}
                              onChange={handleChangeModify("silverSellFee")}
                              autoComplete="new-password"
                              validation="number"
                            />
                          ) : (
                            <span className={classes.feeText}>
                              {silverSellFee}
                            </span>
                          )}
                          %
                        </Flex>
                      </Flex>
                    ),
                    type: "render",
                    // unit: "%",
                  },
                ]}
              />

              <DataTable
                dashboard={[
                  { label: "보유금액" },
                  { label: "금(GOLD)" },
                  { label: "은(SILVER)" },
                  { label: "매수/매도" },
                  { label: "실물인출" },
                  { label: "안심직거래" },
                ]}
                onExcelDownload={() => {}}
                columns={[
                  { label: "구분" },
                  { label: "거래 시간" },
                  { label: "주문형태" },
                  { label: "구분" },
                  { label: "거래금액" },
                  { label: "수수료" },
                  { label: "체결량" },
                  { label: "수익률" },
                  { label: "주문자" },
                  { label: "매수자" },
                ]}
              />
              <AssetStatus userId={userId} />
            </>
          )}
        </>
      )}
    </>
  );
}

const useStyle = makeStyles({
  feeSection: { flex: 1, justifyContent: "space-between" },
  feeLabel: { alignItems: "center", justifyContent: "center" },
  feeInputWrap: {
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    marginRight: 10,
    padding: 5,
  },
  feeText: {
    marginLeft: 5,
  },
  bankNumberInput: {
    marginLeft: 10,
    flex: 1,
  },
  editButton: {
    marginLeft: 50,
    height: 30,
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmInfoSaveButton: {
    marginLeft: 0,
    margin: 0,
    alignItems: "center",
    marginRight: 50,
  },
  confirmButton: {
    minWidth: 70,
    height: 30,
    width: 40,
    marginLeft: 0,
  },
  infoLeaveSaveButton: {
    alignSelf: "end",
    marginRight: "50px",
    width: 150,
    height: 40,
  },
});
