import { Checkbox, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import {
  Add,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import fonts from "../../libs/fonts";
import Flex from "../../components/flex/Flex";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function SubClassificationManagementRows({
  subcategoryid,
  rows,
  i,
  setcategoryreser,
  categoryselectfunc,
  before,
  after,
  setsubcategoryid,
  list,
  setList,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  let modeType = 0;
  if (!rows.brand) {
    modeType = 1;
  }
  const [modCker, setmodCker] = useState(modeType);
  const [categoryinput, setcategoryinput] = useState(rows.brand);
  const [deleteType, setDeleteType] = useState(
    rows.isDelete === false ? true : false
  );
  // console.log(rows, "rows");
  // console.log(modCker, "modCker");
  const modChangeFunc = () => {
    if (modCker === 0) {
      setmodCker(1);
    } else {
      setmodCker(0);
    }
  };

  const modActiveFunc = (id) => {
    if (!categoryinput) {
      dispatch(actionOpen("브랜드명을 입력해주세요", null, null, null, true));
      return;
    }
    rows.brand = categoryinput;
    let data = {};
    let fn = null;

    if (id) {
      data = {
        brand: categoryinput,
        isDelete: deleteType === true ? false : true,
      };

      fn = APIS.putDirectBrandModify;
    } else {
      data = {
        brand: categoryinput,
      };
      fn = APIS.postDirectBrandCreate;
    }
    dispatch(loadingStart);
    fn(subcategoryid, data, id)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setList(data);
          setmodCker(0);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };
  //브랜드 정렬변경
  const updateSort = (type) => {
    let data = [];

    if (type === "up") {
      if (!before) return;
      data = [
        {
          sort: rows.sort,
          id: before.categoryDetailId,
        },
        {
          sort: before.sort,
          id: rows.categoryDetailId,
        },
      ];
    } else {
      if (!after) return;
      data = [
        {
          sort: rows.sort,
          id: after.categoryDetailId,
        },
        {
          sort: after.sort,
          id: rows.categoryDetailId,
        },
      ];
    }
    dispatch(loadingStart);
    APIS.putDirectBrandSort(subcategoryid, data)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };
  return (
    <>
      <tr key={i.toString() + rows.checked}>
        <td>
          {modeType === 0 && (
            <>
              <ArrowDropUpOutlined
                className={i === 0 ? classes.disable : classes.able}
                onClick={() => {
                  updateSort("up");
                }}
              />
              <ArrowDropDownOutlined
                className={
                  i === list.length - 1 ? classes.disable : classes.able
                }
                onClick={() => {
                  updateSort("down");
                }}
              />
            </>
          )}
        </td>
        <td>{i + 1}</td>
        <td>
          {modCker === 0 ? (
            rows.brand
          ) : (
            <Input
              value={categoryinput}
              onChange={(v) => {
                setcategoryinput(v);
              }}
              placeholder={""}
              className={classes.input}
            />
          )}
        </td>
        <td>
          {modCker === 0 ? (
            <>
              <Checkbox checked={rows.isDelete === true ? false : true} />
            </>
          ) : (
            <>
              <Checkbox
                checked={deleteType}
                disabled={rows.brand ? false : true}
                onChange={(e) => setDeleteType(e.target.checked)}
              />
            </>
          )}
        </td>
        {modCker === 0 ? (
          <>
            <td
              style={{ cursor: "pointer", color: "red" }}
              onClick={() => modChangeFunc()}
            >
              수정
            </td>
          </>
        ) : (
          <>
            <td
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() =>
                modActiveFunc(
                  rows.categoryDetailId ? rows.categoryDetailId : ""
                )
              }
            >
              저장
            </td>
          </>
        )}
      </tr>
    </>
  );
}

const useStyle = makeStyles({
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "5px 10px",
  },
  disable: {
    color: "lightgrey",
  },
  able: {
    color: "black",
  },
});
