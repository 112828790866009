import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import PageLabel from "../../components/page-label/PageLabel";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import { useDispatch } from "react-redux";
import { actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";
import { PanoramaOutlined } from "@material-ui/icons";
import consts from "../../libs/consts";
import moment from "moment";
import {
  ButtonBase,
  makeStyles,
  Grid,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";

export default function ServiceNoticeDetail({}) {
  const history = useHistory();
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation({});
  const { state = {} } = location;
  const [noticeItemData, setNoticeItemData] = useState({});
  const [noticeImage, setNoticeImage] = useState("");
  const [eventThumbnail, setEventThumbnail] = useState("");
  const [eventBanner, setEventBanner] = useState("");
  const [eventPopup, setEventPopup] = useState("");

  // const [imagedel, setimagedel] = useState([]);
  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };
  const {
    category = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("category", noticeItemData?.category)
      : "",
    startDate = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("startDate", noticeItemData?.startDate)
      : "",
    endDate = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("endDate", noticeItemData?.endDate)
      : "",
    subject = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("subject", noticeItemData?.subject)
      : "",
    content = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("content", noticeItemData?.content)
      : "",
    createBy = Object.keys(noticeItemData).length !== 0
      ? handleValueChange("createBy", noticeItemData?.createBy)
      : "",
  } = state;
  const [status, setStatus] = useState({
    category: category ? category : "NOTICE",
    status: "ACTIVE",
    startDate: startDate,
    endDate: endDate,
    image: noticeImage,
    subject: subject,
    content: content,
    createBy: createBy,
  });

  const checkRatio = (name, targetWidth, targetHeight) => {
    const bannerBaseRatio = "1.8";
    const popupBaseRatio = "1.8";
    const thumbnailBaseRatio = "3.2";
    if (name === "eventbanner") {
      return (targetWidth / targetHeight).toFixed(1) === bannerBaseRatio;
    } else if (name === "eventpopup") {
      return (targetWidth / targetHeight).toFixed(1) === popupBaseRatio;
    } else if (name === "eventthumbnail") {
      return (targetWidth / targetHeight).toFixed(1) === thumbnailBaseRatio;
    }
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const onChangeImage = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];
    const _URL = window.URL || window.webkitURL;
    const i = new Image();
    i.src = _URL.createObjectURL(file);
    i.onload = () => {
      if (i.width !== 0 && i.height !== 0) {
        if (file.size >= 1024 * 1024 * 5) {
          alert("파일 용량이 초과하였습니다.(5MB)");
          return false;
        }
        if (name === "eventbanner" && !checkRatio(name, i.width, i.height)) {
          alert("배너 이미지는 9 : 5 비율의 이미지만 가능합니다.");
          return false;
        } else if (
          name === "eventpopup" &&
          !checkRatio(name, i.width, i.height)
        ) {
          alert("팝업 이미지는 9 : 5 비율의 이미지만 가능합니다.");
          return false;
        } else if (
          name === "eventthumbnail" &&
          !checkRatio(name, i.width, i.height)
        ) {
          alert("썸네일 이미지는 16 : 5 비율의 이미지만 가능합니다.");
          return false;
        } else {
          if (file) {
            const f = new FileReader();
            f.onload = () => {
              file.uri = f.result;
              if (name === "eventpopup") {
                setEventPopup(file);
              } else if (name === "eventbanner") {
                setEventBanner(file);
              } else if (name === "eventthumbnail") {
                setEventThumbnail(file);
              } else if (name === "noticeImage") {
                setNoticeImage(file);
              }
              setStatus({ ...status, image: file });
            };
            f.readAsDataURL(file);
            e.target.value = "";
          }
        }
      }
    };
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD" + "T" + "HH:mm:ss");
  };

  const validationData = () => {
    if (!location.state.startDate) {
      return "기간 - 시작 날짜를 선택하여 주세요";
    } else if (!location.state.subject) {
      return "제목을 입력하여 주세요";
    } else if (!location.state.content) {
      return "내용을 입력하여 주세요";
    } else if ("EVENT" === location.state.category) {
      if (
        !(
          location.state.content.startsWith("http://") ||
          location.state.content.startsWith("https://")
        )
      ) {
        return "url형식에 맞게 입력하여 주세요";
      }
    }
    return "";
  };
  const handleClickRegister = () => {
    const errorMessage = validationData();
    if (errorMessage) {
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }

    const json = JSON.stringify({
      ...state,
      category: state.category ? state.category : "NOTICE",
      startDate: formatDate(startDate),
      endDate: endDate ? formatDate(endDate) : null,
      status: "ACTIVE",
    });
    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("notice", blob);
    formData.append(
      "detail",
      Object.keys(noticeImage).length !== 0 ? noticeImage : ""
    );
    formData.append(
      "thumbnail",
      Object.keys(eventThumbnail).length !== 0 ? eventThumbnail : ""
    );
    formData.append(
      "banner",
      Object.keys(eventBanner).length !== 0 ? eventBanner : ""
    );
    formData.append(
      "popup",
      Object.keys(eventPopup).length !== 0 ? eventPopup : ""
    );

    dispatch(loadingStart);
    APIS.postCreateNotice(formData)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen(
              "공지 및 이벤트 등록에 실패하였습니다",
              null,
              null,
              null,
              true
            )
          );
        } else {
          dispatch(
            actionOpen(
              "공지 및 이벤트 추가가 완료되었습니다.",
              () => {
                history.goBack();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const handleClickEdit = () => {
    const errorMessage = validationData();
    if (errorMessage) {
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }

    const json = JSON.stringify({
      ...state,
      category: state.category,
      startDate: formatDate(startDate),
      endDate: endDate ? formatDate(endDate) : null,
    });

    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("notice", blob);
    formData.append("detail", noticeImage ? noticeImage : "");
    formData.append("thumbnail", eventThumbnail ? eventThumbnail : "");
    formData.append("banner", eventBanner ? eventBanner : "");
    formData.append("popup", eventPopup ? eventPopup : "");

    dispatch(loadingStart);
    APIS.postEditNotice(formData, state.id)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen(
              "공지 및 이벤트 수정에 실패하였습니다",
              null,
              null,
              null,
              true
            )
          );
        } else {
          dispatch(
            actionOpen(
              "공지 및 이벤트 수정이 완료되었습니다.",
              () => {
                setNoticeItemData(data);
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const deleteImage = (id, type) => {
    // const { name } = e.target;

    // const formData = new FormData();
    // if(name === "eventThumbnail"){
    //   setEventThumbnail("")
    // }
    // if(name === "eventBanner"){
    //   setEventBanner("")
    // }
    // if(name === "eventPopup" ){
    //   setEventPopup("")
    // }

    // formData.append("thumbnail", eventThumbnail ? eventThumbnail : null);
    // formData.append("banner", eventBanner ? eventBanner : null);
    // formData.append("popup", eventPopup ? eventPopup : null);

    // Object.keys(eventThumbnail).length !== 0 ? (
    //   formData.append("thumbnail", eventThumbnail ? eventThumbnail : null)) : (null);
    // Object.keys(eventBanner).length !== 0 ? (
    // formData.append("banner", eventBanner ? eventBanner : null)) : (null);
    // Object.keys(eventPopup).length !== 0 ? (
    // formData.append("popup", eventPopup ? eventPopup : null)) : (null);

    dispatch(loadingStart);
    APIS.deleteImage(state.id, id)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(
            actionOpen("이미지 삭제에 실패하였습니다", null, null, null, true)
          );
        } else {
          dispatch(
            actionOpen(
              "이미지가 삭제되었습니다.",
              () => {
                if (type === "THUMBNAIL") {
                  setEventThumbnail("");
                } else if (type === "BANNER") {
                  setEventBanner("");
                } else if (type === "POPUP") {
                  setEventPopup("");
                } else if (type === "DETAIL") {
                  setNoticeImage("");
                }
                setNoticeItemData(data);
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  useEffect(() => {
    if (!isNaN(state.id)) {
      fetchItem();
    }
  }, [state.id]);

  const fetchItem = () => {
    dispatch(loadingStart);
    APIS.getNoticeDetail(state.id)
      .then(({ data: { success, data } }) => {
        if (success) {
          data.serviceImages.map((item) => {
            item.imageType === "THUMBNAIL"
              ? setEventThumbnail(item)
              : item.imageType === "BANNER"
              ? setEventBanner(item)
              : item.imageType === "POPUP"
              ? setEventPopup(item)
              : item.imageType === "DETAIL"
              ? setNoticeImage(item)
              : null;
          });
          setNoticeItemData(data); //아이템 디테일 정보 넣어주는 곳.
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };
  return (
    <>
      <PageLabel>공지사항 (등록/수정)</PageLabel>
      {Object.keys(noticeItemData).length !== 0 ? (
        noticeItemData.category === "NOTICE" ? ( //수정 노티스
          <GridRow
            data={[
              {
                label: "구분",
                value: noticeItemData.category || "",
                fullWidth: true,
                key: "category",
                type: "radio",
                data: [
                  { label: "공지사항", value: "NOTICE" },
                  { label: "이벤트", value: "EVENT" },
                ],
                onChange: (value) => {
                  setNoticeItemData((status) => ({
                    ...status,
                    category: value,
                  }));
                },
              },
              {
                label: "기간",
                type: "render",
                key: "startDate,endDate",
                fullWidth: true,
                render: (
                  <Flex row>
                    <input
                      value={
                        Object.keys(noticeItemData).length
                          ? moment(noticeItemData.startDate).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                      onChange={(e) => {
                        handleValueChange("startDate", e.target.value);
                        setNoticeItemData((status) => ({
                          ...status,
                          startDate: e.target.value,
                        }));
                      }}
                      type="date"
                      className={classes.datepicker}
                    />
                    <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                      ~
                    </span>
                    <input
                      value={
                        noticeItemData
                          ? moment(noticeItemData.endDate).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => {
                        handleValueChange("endDate", e.target.value);
                        setNoticeItemData((status) => ({
                          ...status,
                          endDate: e.target.value,
                        }));
                      }}
                      type="date"
                      className={classes.datepicker}
                    />
                  </Flex>
                ),
              },
              {
                label: "제목",
                type: "input",
                key: "subject",
                value: noticeItemData.subject || "",
                fullWidth: true,
                inputFullWidth: true,
                placeholder: "제목을 입력하세요.",
                onChange: (value) => {
                  setNoticeItemData((status) => ({
                    ...status,
                    subject: value,
                  }));
                },
              },
              {
                label: "이미지",
                type: "render",
                key: "detailImage",
                fullWidth: true,
                render: (
                  <Grid
                    item
                    xs={12}
                    className={[classes.df, classes.infoContent].join(" ")}
                    style={{ flexDirection: "column" }}
                  >
                    <Grid item xs={12} className={classes.df}>
                      <Grid
                        item
                        xs={11}
                        className={[classes.dfsc, classes.infocell].join(" ")}
                      >
                        {noticeImage ? (
                          <div
                            className={classes.df}
                            style={{ flexDirection: "column" }}
                          >
                            <div
                              className={classes.dfcc}
                              style={{ width: "150px", marginLeft: "16px" }}
                            >
                              <CardMedia
                                component="img"
                                image={
                                  noticeImage.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      noticeImage.path
                                    : noticeImage.uri
                                }
                                width="100"
                              />
                            </div>
                            {/* 삭제  */}
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  noticeImage.id
                                    ? deleteImage(
                                        noticeImage.id,
                                        noticeImage.imageType
                                      )
                                    : setNoticeImage("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          </div>
                        ) : (
                          <>
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-thumbnail-file"
                              name="noticeImage"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-thumbnail-file">
                              <Button
                                className={classes.imageAddBtn}
                                component="span"
                              >
                                <PanoramaOutlined />
                                이미지 등록
                              </Button>
                            </label>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              },
              {
                label: "내용",
                type: "render",
                key: "content",
                fullWidth: true,
                render: (
                  <div className={classes.inputWrap}>
                    <textarea
                      name="content"
                      rows="50"
                      value={noticeItemData.content}
                      onChange={(e) => {
                        handleValueChange("content", e.target.value);

                        setNoticeItemData((status) => ({
                          ...status,
                          content: e.target.value,
                        }));
                      }}
                    />
                  </div>
                ),
              },
            ]}
          />
        ) : (
          //수정 이벤트
          <GridRow
            data={[
              {
                label: "구분",
                value: noticeItemData.category || "",
                fullWidth: true,
                key: "category",
                type: "radio",
                data: [
                  { label: "공지사항", value: "NOTICE" },
                  { label: "이벤트", value: "EVENT" },
                ],
                onChange: (value) => {
                  setNoticeItemData((status) => ({
                    ...status,
                    category: value,
                  }));
                },
              },
              {
                label: "기간",
                type: "render",
                key: "startDate,endDate",
                fullWidth: true,
                render: (
                  <Flex row>
                    <input
                      value={
                        noticeItemData
                          ? moment(noticeItemData.startDate).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                      onChange={(e) => {
                        handleValueChange("startDate", e.target.value);

                        setNoticeItemData((status) => ({
                          ...status,
                          startDate: e.target.value,
                        }));
                      }}
                      type="date"
                      className={classes.datepicker}
                    />
                    <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                      ~
                    </span>
                    <input
                      value={
                        noticeItemData
                          ? moment(noticeItemData.endDate).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => {
                        handleValueChange("endDate", e.target.value);

                        setNoticeItemData((status) => ({
                          ...status,
                          endDate: e.target.value,
                        }));
                      }}
                      type="date"
                      className={classes.datepicker}
                    />
                  </Flex>
                ),
              },
              {
                label: "썸네일",
                type: "render",
                key: "noticeImage",
                fullWidth: true,
                render: (
                  <Grid
                    item
                    xs={12}
                    className={[classes.df, classes.infoContent].join(" ")}
                    style={{ flexDirection: "column" }}
                  >
                    <Grid item xs={12} className={classes.df}>
                      <Grid
                        item
                        xs={11}
                        className={[classes.dfsc, classes.infocell].join(" ")}
                      >
                        {eventThumbnail ? (
                          <div
                            className={classes.df}
                            style={{ flexDirection: "column" }}
                          >
                            <div
                              className={classes.dfcc}
                              style={{ width: "150px", marginLeft: "16px" }}
                            >
                              <CardMedia
                                component="img"
                                image={
                                  eventThumbnail.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      eventThumbnail.path
                                    : eventThumbnail.uri
                                }
                                width="100"
                              />
                            </div>
                            {/* 삭제  */}
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  eventThumbnail.id
                                    ? deleteImage(
                                        eventThumbnail.id,
                                        eventThumbnail.imageType
                                      )
                                    : setEventThumbnail("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          </div>
                        ) : (
                          <>
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-thumbnail-file"
                              name="eventthumbnail"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-thumbnail-file">
                              <Button
                                className={classes.imageAddBtn}
                                component="span"
                              >
                                <PanoramaOutlined />
                                이미지 등록
                              </Button>
                            </label>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              },
              {
                label: "배너등록",
                type: "render",
                key: "noticeImageBanner",
                fullWidth: true,
                render: (
                  <Grid
                    item
                    xs={12}
                    className={[classes.df, classes.infoContent].join(" ")}
                    style={{ flexDirection: "column" }}
                  >
                    <Grid item xs={12} className={classes.df}>
                      <Grid
                        item
                        xs={11}
                        className={[classes.dfsc, classes.infocell].join(" ")}
                      >
                        {eventBanner ? (
                          <div
                            className={classes.df}
                            style={{ flexDirection: "column" }}
                          >
                            <div
                              className={classes.dfcc}
                              style={{ width: "150px", marginLeft: "16px" }}
                            >
                              <CardMedia
                                component="img"
                                image={
                                  eventBanner.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      eventBanner.path
                                    : eventBanner.uri
                                }
                                width="100"
                              />
                            </div>
                            {/* 삭제  */}
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  eventBanner.id
                                    ? deleteImage(
                                        eventBanner.id,
                                        eventBanner.imageType
                                      )
                                    : setEventBanner("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          </div>
                        ) : (
                          <>
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-banner-file"
                              name="eventbanner"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-banner-file">
                              <Button
                                className={classes.imageAddBtn}
                                component="span"
                              >
                                <PanoramaOutlined />
                                이미지 등록
                              </Button>
                            </label>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              },
              {
                label: "팝업등록",
                type: "render",
                key: "noticeImagePopup",
                fullWidth: true,
                render: (
                  <Grid
                    item
                    xs={12}
                    className={[classes.df, classes.infoContent].join(" ")}
                    style={{ flexDirection: "column" }}
                  >
                    <Grid item xs={12} className={classes.df}>
                      <Grid
                        item
                        xs={11}
                        className={[classes.dfsc, classes.infocell].join(" ")}
                      >
                        {eventPopup ? (
                          <div
                            className={classes.df}
                            style={{ flexDirection: "column" }}
                          >
                            <div
                              className={classes.dfcc}
                              style={{ width: "150px", marginLeft: "16px" }}
                            >
                              <CardMedia
                                component="img"
                                image={
                                  eventPopup.path
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      eventPopup.path
                                    : eventPopup.uri
                                }
                                width="100"
                              />
                            </div>
                            {/* 삭제  */}
                            <Grid item xs={12} className={classes.dfcs}>
                              <Button
                                onClick={() => {
                                  eventPopup.id
                                    ? deleteImage(
                                        eventPopup.id,
                                        eventPopup.imageType
                                      )
                                    : setEventPopup("");
                                }}
                                size="small"
                              >
                                <Typography variant="subtitle2">
                                  삭제
                                </Typography>
                              </Button>
                            </Grid>
                          </div>
                        ) : (
                          <>
                            <input
                              accept="image/*"
                              type="file"
                              id="rasied-popup-file"
                              name="eventpopup"
                              style={{ display: "none" }}
                              onChange={onChangeImage}
                              onClick={onInputClick}
                            />
                            <label htmlFor="rasied-popup-file">
                              <Button
                                className={classes.imageAddBtn}
                                component="span"
                              >
                                <PanoramaOutlined />
                                이미지 등록
                              </Button>
                            </label>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              },
              {
                label: "제목",
                type: "input",
                key: "subject",
                value: noticeItemData.subject || "",
                fullWidth: true,
                inputFullWidth: true,
                placeholder: "제목을 입력하세요.",
                onChange: (value) => {
                  setNoticeItemData((status) => ({
                    ...status,
                    subject: value,
                  }));
                },
              },
              {
                label: "내용",
                type: "render",
                key: "content",
                fullWidth: true,
                render: (
                  <div className={classes.inputWrap}>
                    <textarea
                      name="content"
                      rows="50"
                      value={noticeItemData.content || ""}
                      onChange={(e) => {
                        handleValueChange("content", e.target.value);

                        setNoticeItemData((status) => ({
                          ...status,
                          content: e.target.value,
                        }));
                      }}
                    />
                  </div>
                ),
              },
            ]}
          />
        )
      ) : status.category === "NOTICE" ? ( //등록 노티스
        <GridRow
          data={[
            {
              label: "구분",
              value: status.category || "",
              fullWidth: true,
              key: "category",
              type: "radio",
              data: [
                { label: "공지사항", value: "NOTICE" },
                { label: "이벤트", value: "EVENT" },
              ],
              onChange: (value) => {
                setStatus((status) => ({
                  ...status,
                  category: value,
                }));
              },
            },
            {
              label: "기간",
              type: "date-range",
              key: "startDate,endDate",
              fullWidth: true,
              onChange: (value) => {
                setStatus((status) => ({
                  ...status,
                  startDate: value,
                  endDate: value,
                }));
              },
            },
            {
              label: "제목",
              type: "input",
              key: "subject",
              fullWidth: true,
              inputFullWidth: true,
              placeholder: "제목을 입력하세요.",
              onChange: (value) => {
                setStatus((status) => ({
                  ...status,
                  subject: value,
                }));
              },
            },
            {
              label: "이미지",
              type: "render",
              key: "detailImage",
              fullWidth: true,
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {noticeImage ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px", marginLeft: "16px" }}
                          >
                            <CardMedia
                              component="img"
                              image={
                                noticeImage.path
                                  ? process.env.REACT_APP_S3_BASE_URL +
                                    noticeImage.path
                                  : noticeImage.uri
                              }
                              width="100"
                            />
                          </div>
                          {/* 삭제  */}
                          <Grid item xs={12} className={classes.dfcs}>
                            <Button
                              onClick={() => {
                                setNoticeImage("");
                              }}
                              size="small"
                            >
                              <Typography variant="subtitle2">삭제</Typography>
                            </Button>
                          </Grid>
                        </div>
                      ) : (
                        <>
                          <input
                            accept="image/*"
                            type="file"
                            id="rasied-thumbnail-file"
                            name="noticeImage"
                            style={{ display: "none" }}
                            onChange={onChangeImage}
                            onClick={onInputClick}
                          />
                          <label htmlFor="rasied-thumbnail-file">
                            <Button
                              className={classes.imageAddBtn}
                              component="span"
                            >
                              <PanoramaOutlined />
                              이미지 등록
                            </Button>
                          </label>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "내용",
              type: "render",
              key: "content",
              fullWidth: true,
              render: (
                <div className={classes.inputWrap}>
                  <textarea
                    name="content"
                    rows="50"
                    value={noticeItemData.content}
                    onChange={(e) => {
                      handleValueChange("content", e.target.value);
                    }}
                  />
                </div>
              ),
              onChange: (value) => {
                setStatus((status) => ({
                  ...status,
                  content: value,
                }));
              },
            },
          ]}
        />
      ) : (
        //등록 이벤트
        <GridRow
          data={[
            {
              label: "구분",
              value: status.category || "",
              fullWidth: true,
              key: "category",
              type: "radio",
              data: [
                { label: "공지사항", value: "NOTICE" },
                { label: "이벤트", value: "EVENT" },
              ],
              onChange: (value) => {
                setStatus((status) => ({
                  ...status,
                  category: value,
                }));
              },
            },
            {
              label: "기간",
              type: "date-range",
              key: "startDate,endDate",
              fullWidth: true,
              onChange: (value) => {
                setStatus((status) => ({
                  ...status,
                  startDate: value,
                  endDate: value,
                }));
              },
            },
            {
              label: "썸네일",
              type: "render",
              key: "noticeImage",
              fullWidth: true,
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {eventThumbnail ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px", marginLeft: "16px" }}
                          >
                            <CardMedia
                              component="img"
                              image={
                                eventThumbnail.path
                                  ? process.env.REACT_APP_S3_BASE_URL +
                                    eventThumbnail.path
                                  : eventThumbnail.uri
                              }
                              width="100"
                            />
                          </div>
                          {/* 삭제  */}
                          <Grid item xs={12} className={classes.dfcs}>
                            <Button
                              onClick={() => {
                                setEventThumbnail("");
                              }}
                              size="small"
                            >
                              <Typography variant="subtitle2">삭제</Typography>
                            </Button>
                          </Grid>
                        </div>
                      ) : (
                        <>
                          <input
                            accept="image/*"
                            type="file"
                            id="rasied-thumbnail-file"
                            name="eventthumbnail"
                            style={{ display: "none" }}
                            onChange={onChangeImage}
                            onClick={onInputClick}
                          />
                          <label htmlFor="rasied-thumbnail-file">
                            <Button
                              className={classes.imageAddBtn}
                              component="span"
                            >
                              <PanoramaOutlined />
                              이미지 등록
                            </Button>
                          </label>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "배너등록",
              type: "render",
              key: "noticeImageBanner",
              fullWidth: true,
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {eventBanner ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px", marginLeft: "16px" }}
                          >
                            <CardMedia
                              component="img"
                              image={
                                eventBanner.path
                                  ? consts.s3BaseUrl + "/" + eventBanner.path
                                  : eventBanner.uri
                              }
                              width="100"
                            />
                          </div>
                          {/* 삭제  */}
                          <Grid item xs={12} className={classes.dfcs}>
                            <Button
                              onClick={() => {
                                setEventBanner("");
                              }}
                              size="small"
                            >
                              <Typography variant="subtitle2">삭제</Typography>
                            </Button>
                          </Grid>
                        </div>
                      ) : (
                        <>
                          <input
                            accept="image/*"
                            type="file"
                            id="rasied-banner-file"
                            name="eventbanner"
                            style={{ display: "none" }}
                            onChange={onChangeImage}
                            onClick={onInputClick}
                          />
                          <label htmlFor="rasied-banner-file">
                            <Button
                              className={classes.imageAddBtn}
                              component="span"
                            >
                              <PanoramaOutlined />
                              이미지 등록
                            </Button>
                          </label>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "팝업등록",
              type: "render",
              key: "noticeImagePopup",
              fullWidth: true,
              render: (
                <Grid
                  item
                  xs={12}
                  className={[classes.df, classes.infoContent].join(" ")}
                  style={{ flexDirection: "column" }}
                >
                  <Grid item xs={12} className={classes.df}>
                    <Grid
                      item
                      xs={11}
                      className={[classes.dfsc, classes.infocell].join(" ")}
                    >
                      {eventPopup ? (
                        <div
                          className={classes.df}
                          style={{ flexDirection: "column" }}
                        >
                          <div
                            className={classes.dfcc}
                            style={{ width: "150px", marginLeft: "16px" }}
                          >
                            <CardMedia
                              component="img"
                              image={
                                eventPopup.path
                                  ? consts.s3BaseUrl + "/" + eventPopup.path
                                  : eventPopup.uri
                              }
                              width="100"
                            />
                          </div>
                          {/* 삭제  */}
                          <Grid item xs={12} className={classes.dfcs}>
                            <Button
                              onClick={() => {
                                setEventPopup("");
                              }}
                              size="small"
                            >
                              <Typography variant="subtitle2">삭제</Typography>
                            </Button>
                          </Grid>
                        </div>
                      ) : (
                        <>
                          <input
                            accept="image/*"
                            type="file"
                            id="rasied-popup-file"
                            name="eventpopup"
                            style={{ display: "none" }}
                            onChange={onChangeImage}
                            onClick={onInputClick}
                          />
                          <label htmlFor="rasied-popup-file">
                            <Button
                              className={classes.imageAddBtn}
                              component="span"
                            >
                              <PanoramaOutlined />
                              이미지 등록
                            </Button>
                          </label>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ),
            },
            {
              label: "제목",
              type: "input",
              key: "subject",
              fullWidth: true,
              inputFullWidth: true,
              placeholder: "제목을 입력하세요.",
              onChange: (value) => {
                setStatus((status) => ({
                  ...status,
                  subject: value,
                }));
              },
            },
            {
              label: "내용",
              type: "render",
              key: "content",
              fullWidth: true,
              render: (
                <div className={classes.inputWrap}>
                  <textarea
                    name="content"
                    rows="50"
                    value={noticeItemData.content}
                    onChange={(e) => {
                      handleValueChange("content", e.target.value);
                    }}
                  />
                </div>
              ),
              onChange: (value) => {
                setStatus((status) => ({
                  ...status,
                  content: value,
                }));
              },
            },
          ]}
        />
      )}

      <Flex className={classes.buttons} row>
        <ButtonBase onClick={history.goBack} className={classes.button1}>
          목록
        </ButtonBase>

        {Object.keys(noticeItemData).length !== 0 ? (
          <ButtonBase onClick={handleClickEdit} className={classes.button2}>
            수정
          </ButtonBase>
        ) : (
          <ButtonBase onClick={handleClickRegister} className={classes.button2}>
            저장
          </ButtonBase>
        )}
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  buttons: { alignSelf: "center" },
  button1: {
    border: "1px solid #ddd",
    height: 50,
    width: 150,
    color: "#000",
  },
  button2: {
    height: 50,
    width: 150,
    color: "#fff",
    backgroundColor: "#000",
    marginLeft: 20,
  },
  inputWrap: {
    display: "flex",
    flex: 1,
    marginBottom: 10,
    flexDirection: "column",
    height: "500px !important",
    alignSelf: "stretch",
  },
  img2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 300,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  img: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px 20px",
    alignItems: "center",
    boxSizing: "border-box",
    width: "auto !important",
  },

  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },

  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "5px 10px",
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
  },
  radiogroup: {
    marginTop: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "80%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  imageAddBtn: {
    width: "130px",
    height: "100px",
    marginTop: "10px",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: "5px",
    color: "rgba(0,0,0,0.5)",
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
  },
});
