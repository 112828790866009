import React, { useEffect, useState } from "react";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import * as APIS from "../../libs/apis";
import ContextMenuLayout from "../../components/excel-download/ContextMenuLayout";
import {
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { numberWithCommas } from "../../libs/utils";

const AssetStatus = ({ userId }) => {
  const classes = useStyle();
  const [userAssetData, setUserAssetData] = useState([]);

  const headerRows = [
    [
      { label: "구분", rowSpan: 1, width: 50 },
      { label: "KRW", rowSpan: 1, width: 100 },
      { label: "GOLD", rowSpan: 1, width: 100 },
      { label: "SILVER", rowSpan: 1, width: 100 },
    ],
  ];

  const columns = [
    { key: "type", label: "구분", align: "center" },
    { key: "krw", label: "KRW", align: "center", type: "number" },
    { key: "gold", label: "GOLD", align: "center", type: "number" },
    { key: "silver", label: "SILVER", align: "center", type: "number" },
  ];
  const reformData = (data) => {
    const parseData = [];
    const lockDetailData = data?.lockDetail.map((item) => {
      return {
        type:
          item.tradeType === "GOODS"
            ? "실물인출대기"
            : item.tradeType === "APPRAISAL"
            ? "감정평가대기"
            : item.tradeType === "BUY"
            ? "매수대기"
            : item.tradeType === "SELL"
            ? "매도대기"
            : item.tradeType === "DEPOSIT"
            ? "입금대기"
            : item.tradeType === "WITHDRAW"
            ? "출금대기"
            : item.tradeType === "SAFE_DIRECT"
            ? "안심직거래대기"
            : "-",
        krw: item.krw || 0,
        gold: item.gold || 0,
        silver: item.silver || 0,
      };
    });
    parseData.push(
      data?.asset.krw !== null
        ? {
            type: data?.asset.krw !== null ? "총 보유" : "",
            krw: data?.asset.krw ? data?.asset.krw : "0",
            gold: data?.asset.gold ? data?.asset.gold : "0",
            silver: data?.asset.silver ? data?.asset.silver : "0",
          }
        : {},
      data?.asset.acceptableKrw !== null
        ? {
            type: data?.asset.acceptableKrw !== null ? "사용가능" : "",
            krw: data?.asset.acceptableKrw ? data?.asset.acceptableKrw : 0,
            gold: data?.asset.acceptableGold ? data?.asset.acceptableGold : 0,
            silver: data?.asset.acceptableSilver
              ? data?.asset.acceptableSilver
              : 0,
          }
        : {},
      ...lockDetailData
    );
    return parseData;
  };

  useEffect(() => {
    APIS.getUserAsset(userId).then(({ data: { data } }) => {
      setUserAssetData(reformData(data));
    });
  }, []);
  return (
    <Flex style={{ margin: "0 50px 0 50px" }}>
      <Text style={{ marginBottom: 10 }} font={fonts.notoSansKRBold}>
        자산현황
      </Text>
      <Flex>
        <ContextMenuLayout
          name="권한관리"
          headers={columns}
          data={userAssetData}
        >
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                {headerRows?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {row.map(
                        ({ label, rowSpan, colSpan, width, render }, index) => {
                          return (
                            <TableCell
                              key={index}
                              colSpan={colSpan}
                              width={width}
                            >
                              {render || label}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  );
                })}
              </TableHead>
              <TableBody>
                {userAssetData?.map((row, index) => {
                  return (
                    <TableRow style={{ cursor: "pointer" }}>
                      {columns.map(({ key, align, type }, index) => {
                        const defaultAlign =
                          type === "number" ? "right" : "left";
                        return (
                          <TableCell key={index} align={align || defaultAlign}>
                            {type === "number"
                              ? numberWithCommas(row[key])
                              : row[key]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </ContextMenuLayout>
      </Flex>
    </Flex>
  );
};

const useStyle = makeStyles({
  tableContainer: {
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    "& th, td": {
      padding: 5,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      fontFamily: fonts.notoSansKRBold,
      backgroundColor: "#f5f5f5",
      textAlign: "center",
    },
    "& tr:hover": {
      backgroundColor: "#ededed",
    },
  },
});

export default AssetStatus;
