import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import SettingPolicy1 from "./SettingPolicy1";
import SettingPolicy2 from "./SettingPolicy2";
import SettingPolicy3 from "./SettingPolicy3";
import SettingPolicy4 from "./SettingPolicy4";
import SettingPolicy5 from "./SettingPolicy5";

export default function SettingPolicy({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const state = location.state || {};
  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
        page: "1",
      });
    }
  }, [state.tab]);
  return (
    <>
      <LabelTabs
        data={[
          { label: "매수/매도", value: "1" },
          { label: "감정/인출", value: "2" },
          { label: "안심직거래", value: "3" },
          { label: "입/출금", value: "4" },
          { label: "거래시간", value: "5" },
        ]}
      />
      {(() => {
        switch (state.tab) {
          case "1":
            return <SettingPolicy1 />;
          case "2":
            return <SettingPolicy2 />;
          case "3":
            return <SettingPolicy3 />;
          case "4":
            return <SettingPolicy4 />;
          case "5":
            return <SettingPolicy5 />;
        }
      })()}
    </>
  );
}

const useStyle = makeStyles({});
