import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import MainLayout from "../../layouts/main/MainLayout";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import EventList from "./EventList";
import NoticeList from "./NoticeList";
import { getDefaultPageSize } from "../../libs/consts";

export default function ServiceNotice({}) {
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
        ...getDefaultPageSize(),
      });
    }
  }, [state.tab]);

  return (
    <>
      <LabelTabs
        data={[
          { label: "공지사항", value: "1" },
          { label: "이벤트", value: "2" },
        ]}
      />
      {state.tab === "1" ? <NoticeList /> : <EventList />}
    </>
  );
}
