import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  Grid,
  TablePagination,
  TableRow,
  Button,
  Divider,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import {
  actionOpen,
  actionError,
  actionClose,
} from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import Flex from "../../components/flex/Flex";
import { objToQueryStr, numFormat, formatTime } from "../../services/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { useDispatch } from "react-redux";
import ContextMenuLayout from "../../components/excel-download/ContextMenuLayout";
import { numberWithCommas } from "../../libs/utils";
import { closeModal, openModal } from "../../redux/modal/ModalReducer";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import cloneDeep from "lodash.clonedeep";

export default function FeeSetting({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state || {};

  return (
    <>
      <SalesFeeTable />
    </>
  );
}

const SalesFeeTable = React.memo(() => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [feeSettingData, setFeeSettingData] = useState([]);

  const handleClickFeeEdit = (id) => {
    dispatch(
      openModal({
        visiable: true,
        children: <FeeEdit id={id} setFeeSettingData={setFeeSettingData} />,
      })
    );
  };
  const headerRows = [
    [
      { label: "카테고리", rowSpan: 2, width: 50 },
      { label: "감정비용", rowSpan: 2 },
      { label: "금액대", rowSpan: 2 },
      { label: "판매자 수수료율", rowSpan: 2 },
      { label: "구매자 수수료율", rowSpan: 2 },
      { label: "위약 수수료율", colSpan: 2 },
      { label: "매장 수수료율", colSpan: 4 },
      { label: "수정", rowSpan: 2 },
    ],
    [
      { label: "판매자" },
      { label: "구매자" },
      { label: "판매자 추천매장" },
      { label: "판매접수 추천매장" },
      { label: "구매자 추천매장" },
      { label: "구매출고 매장" },
    ],
  ];

  // const columns = [
  //   { key: "categoryName", label: "카테고리", align: "center" },
  //   { key: "previewFee", label: "감정비용", align: "center" },
  //   { key: "feeRateList", label: "금액대", align: "center" },
  //   {
  //     key: "feeRateList",
  //     label: "판매자 수수료율",
  //     align: "center",
  //     secondKey: "sellFee",
  //   },
  //   {
  //     key: "feeRateList",
  //     label: "구매자 수수료율",
  //     align: "center",
  //     secondKey: "buyFee",
  //   },
  //   { key: "sellerPenalty", label: "판매자", align: "center" },
  //   { key: "buyerPenalty", label: "구매자", align: "center" },
  //   { key: "sellRecommendShopFee", label: "판매자 추천매장", align: "center" },
  //   { key: "sellShopFee", label: "판매접수 추천매장", type: "number" },
  //   { key: "buyRecommendShopFee", label: "구매자 추천매장", type: "number" },
  //   { key: "buyShopFee", label: "구매출고 매장", type: "number" },
  // ];

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getDirectFeeSetting()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          // const newData = [...data]; //feeData
          // let returnData = [];
          // newData.map((data) => {
          //   categoryList.map((category) => {
          //     if (category.categoryId === data.categoryId) {
          //       data["categoryName"] = category.name;
          //     }
          //   });
          //   returnData.push(data);
          // });
          setFeeSettingData(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  }, []);

  return (
    <Flex style={{ margin: "10px 50px 10px 50px", width: "fit-content" }}>
      <Flex row style={{ border: "1px solid", borderBottom: "none" }}>
        <Flex
          className={classes.header}
          style={{
            minWidth: 110,
          }}
        >
          카테고리
        </Flex>
        <Flex
          className={classes.header}
          style={{
            minWidth: 110,
          }}
        >
          감정비용
        </Flex>
        <Flex
          className={classes.header}
          style={{
            minWidth: 300,
          }}
        >
          금액대
        </Flex>
        <Flex
          className={classes.header}
          style={{
            minWidth: 110,
          }}
        >
          판매자 수수료율
        </Flex>
        <Flex
          className={classes.header}
          style={{
            minWidth: 110,
          }}
        >
          구매자 수수료율
        </Flex>
        <Flex className={classes.header}>
          <Flex style={{ borderBottom: "1px solid" }}>위약 수수료율</Flex>
          <Flex row>
            <Flex
              style={{
                minWidth: 100,
                borderRight: "1px solid",
              }}
            >
              판매자
            </Flex>
            <Flex
              style={{
                minWidth: 100,
              }}
            >
              구매자
            </Flex>
          </Flex>
        </Flex>
        <Flex className={classes.header}>
          <Flex style={{ borderBottom: "1px solid" }}>매장 수수료율</Flex>
          <Flex row>
            <Flex
              style={{
                minWidth: 105,
                borderRight: "1px solid",
                borderBottom: "none",
              }}
            >
              판매자 추천매장
            </Flex>
            <Flex
              style={{
                minWidth: 105,
                borderRight: "1px solid",
                borderBottom: "none",
              }}
            >
              판매접수 매장
            </Flex>
            <Flex
              style={{
                minWidth: 105,
                borderRight: "1px solid",
                borderBottom: "none",
              }}
            >
              구매자 추천매장
            </Flex>
            <Flex
              style={{
                minWidth: 105,
                borderBottom: "none",
              }}
            >
              구매출고 매장
            </Flex>
          </Flex>
        </Flex>
        <Flex
          className={classes.header}
          style={{
            minWidth: 90,
            // borderRight: "none"
          }}
        >
          수정
        </Flex>
      </Flex>
      <Flex style={{ border: "1px solid", borderBottom: "none" }}>
        {feeSettingData?.map((row) => {
          return (
            <Flex row>
              <Flex
                style={{
                  minWidth: 110,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                  backgroundColor: "#f5f5f5",
                }}
              >
                {row.categoryName}
              </Flex>
              <Flex
                style={{
                  minWidth: 110,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                {numberWithCommas(row.sellerReturnPenalty)}원
              </Flex>
              <Flex
                style={{
                  minWidth: 300,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                {row.feeRateList.map((item) => {
                  return (
                    <Flex
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid",
                      }}
                    >
                      {numberWithCommas(item.min) +
                        "원 이상 ~ " +
                        numberWithCommas(item.max) +
                        "원 미만"}
                    </Flex>
                  );
                })}
              </Flex>
              <Flex
                style={{
                  minWidth: 110,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                {row.feeRateList.map((item) => {
                  return (
                    <Flex
                      style={{
                        borderBottom: "1px solid",
                        textAlign: "center",
                        justifyContent: "center",
                        height: "-webkit-fill-available",
                      }}
                    >
                      {numberWithCommas(item.sellFee) + "%"}
                    </Flex>
                  );
                })}
              </Flex>
              <Flex
                style={{
                  minWidth: 110,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                {row.feeRateList.map((item) => {
                  return (
                    <Flex
                      style={{
                        borderBottom: "1px solid",
                        textAlign: "center",
                        justifyContent: "center",
                        height: "-webkit-fill-available",
                      }}
                    >
                      {numberWithCommas(item.buyFee) + "%"}
                    </Flex>
                  );
                })}
              </Flex>
              <Flex
                style={{
                  minWidth: 100,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                {row.sellerPenalty}%
              </Flex>
              <Flex
                style={{
                  minWidth: 101,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                {row.buyerPenalty}%
              </Flex>
              <Flex
                style={{
                  minWidth: 105,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                {row.sellRecommendShopFee}%
              </Flex>
              <Flex
                style={{
                  minWidth: 105,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                {row.sellShopFee}%
              </Flex>
              <Flex
                style={{
                  minWidth: 105,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                {row.buyRecommendShopFee}%
              </Flex>
              <Flex
                style={{
                  minWidth: 106,
                  textAlign: "center",
                  justifyContent: "center",
                  borderRight: "1px solid",
                  borderBottom: "1px solid",
                }}
              >
                {row.buyShopFee}%
              </Flex>
              <Flex
                align="center"
                style={{
                  minWidth: 90,
                  textAlign: "center",
                  justifyContent: "center",
                  borderBottom: "1px solid",
                  alignItems: "center",
                }}
              >
                <Button
                  // variant="outlined"
                  style={{ width: 50, color: "blue" }}
                  onClick={() => {
                    handleClickFeeEdit(row.categoryId);
                  }}
                >
                  수정
                </Button>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
});

const FeeEdit = (props) => {
  const { id, setFeeSettingData } = props;
  const classes = useStyle();
  const [feeList, setFeeList] = useState([]);
  const [feeDetail, setFeeDetail] = useState({});
  const [sellerReturnPenalty, setSellerReturnPenalty] = useState("");
  const [sellerMinPenalty, setSellerMinPenalty] = useState(""); //판매자 위약 최소
  const [sellerPenalty, setSellerPenalty] = useState("");
  const [buyerPenalty, setBuyerPenalty] = useState("");
  const [buyerMinPenalty, setBuyerMinPenalty] = useState("");
  const [sellRecommendShopFee, setSellRecommendShopFee] = useState("");
  const [buyRecommendShopFee, setBuyRecommendShopFee] = useState("");
  const [sellShopFee, setSellShopFee] = useState("");
  const [buyShopFee, setBuyShopFee] = useState("");

  const dispatch = useDispatch();

  const addFee = () => {
    setFeeList((feeData) => [...feeData, {}]);
  };

  //관리자 비밀번호 저장
  const onSaveAdmin = async (adminPassword) => {
    if (!adminPassword) {
      dispatch(actionError("비밀번호를 입력하여 주세요."));
    } else {
      const data = {
        managerPassword: adminPassword,
        feeRateList: feeList,
        sellerPenalty: Number(sellerPenalty), // 판매자 위약
        sellerMinPenalty: Number(sellerMinPenalty), // 판매자 위약 최소
        buyerPenalty: Number(buyerPenalty), // 구매자 위약
        buyerMinPenalty: Number(buyerMinPenalty), // 구매자 최소
        sellShopFee: Number(sellShopFee), // 판매자 입고 매장 수수료
        buyShopFee: Number(buyShopFee), // 구매자 출고 매장 수수료
        sellerReturnPenalty: Number(sellerReturnPenalty), // 감정비용
        sellRecommendShopFee: Number(sellRecommendShopFee), // 판매자 추천 매장 수수료
        buyRecommendShopFee: Number(buyRecommendShopFee), // 구매자 추천 매장 수수료
        // previewFee: 10000, // 프리뷰 비용
        // previewActive: false,
      };
      dispatch(loadingStart);
      APIS.putDirectFeeSettingEdit(id, data)
        .then(({ data: { data, message, success } }) => {
          if (success) {
            dispatch(
              actionOpen(
                "수수료 설정이 수정되었습니다.",
                () => {
                  setFeeSettingData(data);
                },
                null,
                null,
                true
              )
            );
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getDirectFeeSettingDetail(id)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setFeeDetail(data);
          setSellerReturnPenalty(data.sellerReturnPenalty);
          setSellerMinPenalty(data.sellerMinPenalty);
          setSellerPenalty(data.sellerPenalty);
          setBuyerPenalty(data.buyerPenalty);
          setBuyerMinPenalty(data.buyerMinPenalty);
          setSellRecommendShopFee(data.sellRecommendShopFee);
          setBuyRecommendShopFee(data.buyRecommendShopFee);
          setSellShopFee(data.sellShopFee);
          setBuyShopFee(data.buyShopFee);
          setFeeList(data?.feeRateList);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  }, []);

  return (
    <div style={{ width: "850px", height: "500px" }}>
      <div className={classes.modal}>{feeDetail?.categoryName}</div>
      <div className={classes.content}>
        <Flex
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <Flex row style={{ justifyContent: "space-between" }}>
            <label className={classes.label}>감정비용 설정</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <input
              value={sellerReturnPenalty}
              onChange={(e) => {
                setSellerReturnPenalty(e.target.value);
              }}
              placeholder="원"
              style={{ width: 250, height: 30 }}
            />
          </Flex>
          <Divider className={classes.divider} />
          {/* <Flex row style={{ justifyContent: "space-between" }}>
            <label>사용자 수수료율 설정</label>&nbsp;&nbsp;&nbsp;&nbsp;
            <Flex>
              <Flex row style={{ marginBottom: 5 }}>
                <label>판매자 최소 수수료</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input placeholder="원" style={{ width: 250, height: 30 }} />
              </Flex>
              <Flex row>
                <label>구매자 최소 수수료</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input placeholder="원" style={{ width: 250, height: 30 }} />
              </Flex>
            </Flex>
          </Flex> */}
          <Flex>
            <Flex style={{ alignItems: "end" }}>
              <Button onClick={addFee} style={{ width: 100 }}>
                <Add />
                추가하기
              </Button>
            </Flex>
            <Flex
              style={{ maxHeight: 200, minHeight: 200, overflowY: "scroll" }}
            >
              <Table stickyHeader>
                <colgroup>
                  <col width="55%" />
                  <col width="15%" />
                  <col width="15%" />
                </colgroup>
                <TableHead
                  style={{
                    textAlignLast: "center",
                    backgroundColor: "rgb(236 236 236)",
                  }}
                >
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      결제금액
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      판매자 수수료율
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      구매자 수수료율
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feeList?.map((item, i) => {
                    return (
                      <TableRow key={i.toString()} style={{ marginBottom: 10 }}>
                        <TableCell style={{ textAlign: "center", padding: 5 }}>
                          <Flex row style={{ justifyContent: "center" }}>
                            <input
                              type="number"
                              value={item.min}
                              onChange={(e) => {
                                let copyData = cloneDeep(feeList);

                                copyData[i] = {
                                  ...copyData[i],
                                  min: e.target.value,
                                };
                                setFeeList(copyData);
                              }}
                              placeholder="원"
                              style={{ width: 100, height: 30 }}
                            />
                            &nbsp; 이상 &nbsp; &nbsp;&nbsp;
                            <input
                              type="number"
                              value={item.max}
                              onChange={(e) => {
                                let copyData = cloneDeep(feeList);

                                copyData[i] = {
                                  ...copyData[i],
                                  max: e.target.value,
                                };
                                setFeeList(copyData);
                              }}
                              placeholder="원"
                              style={{ width: 100, height: 30 }}
                            />
                            &nbsp; 미만
                          </Flex>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", padding: "0px 5px" }}
                        >
                          <input
                            type="number"
                            value={item.sellFee}
                            onChange={(e) => {
                              let copyData = cloneDeep(feeList);

                              copyData[i] = {
                                ...copyData[i],
                                sellFee: e.target.value,
                              };
                              setFeeList(copyData);
                            }}
                            placeholder="%"
                            style={{ width: 100, height: 30 }}
                          />
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", padding: "0px 5px" }}
                        >
                          <input
                            type="number"
                            value={item.buyFee}
                            onChange={(e) => {
                              let copyData = cloneDeep(feeList);

                              copyData[i] = {
                                ...copyData[i],
                                buyFee: e.target.value,
                              };
                              setFeeList(copyData);
                            }}
                            placeholder="%"
                            style={{ width: 100, height: 30 }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Flex>
          </Flex>
          <Divider className={classes.divider} />
          <Flex row style={{ justifyContent: "space-between" }}>
            <label className={classes.label}>위약 수수료율 설정</label>
            &nbsp;&nbsp;
            <Flex row>
              <Flex row>
                <label>판매 최소 수수료</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  value={sellerMinPenalty}
                  onChange={(e) => {
                    setSellerMinPenalty(e.target.value);
                  }}
                  placeholder="원"
                  style={{ width: 180, height: 30 }}
                />
              </Flex>
              &nbsp;&nbsp;
              <Flex row style={{ marginBottom: 5 }}>
                <label>판매자 철회(예상가 기준)</label>
                &nbsp;&nbsp;&nbsp;
                <input
                  value={sellerPenalty}
                  onChange={(e) => {
                    setSellerPenalty(e.target.value);
                  }}
                  placeholder="%"
                  style={{ width: 150, height: 30 }}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex>
            <Flex row style={{ justifyContent: "end" }}>
              <Flex row>
                <label>구매 최소 수수료</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  value={buyerMinPenalty}
                  onChange={(e) => {
                    setBuyerMinPenalty(e.target.value);
                  }}
                  placeholder="원"
                  style={{ width: 180, height: 30 }}
                />
              </Flex>
              &nbsp;&nbsp;
              <Flex row>
                <label>구매취소(거래가격 기준)</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  value={buyerPenalty}
                  onChange={(e) => {
                    setBuyerPenalty(e.target.value);
                  }}
                  placeholder="%"
                  style={{ width: 150, height: 30 }}
                />
              </Flex>
            </Flex>
          </Flex>
          <Divider className={classes.divider} />
          <label className={classes.label}>매장 수수료율 설정</label>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Flex row style={{ justifyContent: "space-between" }}>
            <Flex>
              <Flex row style={{ marginBottom: 5 }}>
                <label>판매자 추천(판매수수료 금액 기준)</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  value={sellRecommendShopFee}
                  onChange={(e) => {
                    setSellRecommendShopFee(e.target.value);
                  }}
                  placeholder="%"
                  style={{ width: 150, height: 30 }}
                />
              </Flex>
              <Flex row>
                <label>구매자 추천(구매수수료 금액 기준)</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  value={buyRecommendShopFee}
                  onChange={(e) => {
                    setBuyRecommendShopFee(e.target.value);
                  }}
                  placeholder="%"
                  style={{ width: 150, height: 30 }}
                />
              </Flex>
            </Flex>
            <Flex>
              <Flex row style={{ marginBottom: 5 }}>
                <label>판매접수(판매수수료 금액 기준)</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  value={sellShopFee}
                  onChange={(e) => {
                    setSellShopFee(e.target.value);
                  }}
                  placeholder="%"
                  style={{ width: 150, height: 30 }}
                />
              </Flex>
              <Flex row>
                <label>구매출고(구매수수료 금액 기준)</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  value={buyShopFee}
                  onChange={(e) => {
                    setBuyShopFee(e.target.value);
                  }}
                  placeholder="%"
                  style={{ width: 150, height: 30 }}
                />
              </Flex>
            </Flex>
          </Flex>
          <Divider className={classes.divider} />
          <Flex
            row
            style={{
              alignSelf: "center",
              marginTop: 5,
              width: "16%",
              justifyContent: "space-between",
            }}
          >
            <AdminConfirmButton
              popupLabel="수수료율 수정"
              popupMessage="변경사항을 저장하시겠습니까?"
              label="저장"
              callback={onSaveAdmin}
              rootClassName={classes.confirmRoot}
              btnClassName={classes.confirmButton}
            />
            <Button
              variant="outlined"
              style={{ fontWeight: "bold" }}
              onClick={() => {
                dispatch(closeModal());
              }}
            >
              취소
            </Button>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  tableContainer: {
    maxHeight: 650,
    overflowY: "scroll",
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    "& th, td": {
      padding: 5,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      fontFamily: fonts.notoSansKRBold,
      backgroundColor: "#f5f5f5",
      textAlign: "center",
    },
    "& tr:hover": {
      backgroundColor: "#ededed",
    },
  },
  modal: {
    textAlign: "left",
    padding: 2,
    width: "100%",
    height: "50px",
    background: " rgb(180,180,180)",
    position: "absolute",
    right: "0px",
    top: "0px",
    borderRadius: "10px 10px 0px 0px",
    lineHeight: "50px",
  },
  content: {
    marginTop: "30px",
    height: "150px",
  },
  sectionContents: {
    overflow: "hidden",
    overflowY: "auto",
    height: "400px",
    width: "857px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  divider: {
    width: "100%",
    margin: "5px 0px",
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 0,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
  label: {
    fontWeight: "bold",
  },
  header: {
    borderRight: "1px solid",
    borderBottom: "none",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  },
});
