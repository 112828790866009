import React, { useEffect, useState } from "react";
import PageLabel from "../../components/page-label/PageLabel";
import Flex from "../../components/flex/Flex";
import {
  makeStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Box,
  styled,
  Paper,
} from "@material-ui/core";
import cloneDeep from "lodash.clonedeep";
import Text from "../../components/text/Text";
import Button from "../../components/button/Button";
import fonts from "../../libs/fonts";
import { useHistory, useLocation } from "react-router";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { useDispatch, useSelector } from "react-redux";
import ContextMenuLayout from "../../components/excel-download/ContextMenuLayout";
import { numberWithCommas } from "../../libs/utils";
import { objToQueryStr, formatTime } from "../../services/utils";
import { closeModal, openModal } from "../../redux/modal/ModalReducer";
import FindAddress from "../../components/find-address/FindAddress";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import { parse } from "query-string";

export default function ManagerAuthority({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [authGroupList, setAuthGroupList] = useState([]);

  const handleClickGroupAdd = () => {
    dispatch(
      openModal({
        visiable: true,
        children: <AdminGroupAdd fetchAuthorityList={authorityList} />,
      })
    );
  };

  const authorityList = () => {
    dispatch(loadingStart);
    APIS.getAuthorityGroupList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          let reformData = data;
          reformData = data.map((item, index) => {
            return { ...item, index: index + 1 };
          });
          setAuthGroupList(reformData);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  useEffect(() => {
    authorityList();
  }, []);
  return (
    <>
      <PageLabel>권한관리</PageLabel>
      <Flex className={classes.root}>
        <Flex row className={classes.header}>
          <Flex row className="row-center"></Flex>
          <Flex row>
            <Button
              label="관리자 그룹추가"
              value="ADD"
              className={classes.button}
              onClick={handleClickGroupAdd}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex row className={classes.tableHeader}>
        <Flex>
          <Flex row className={"row-center"}>
            <Text font={fonts.notoSansKRBold}>
              목록 검색결과 {authGroupList?.length}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex className={classes.groupList}>
        <AuthorityTable
          data={authGroupList}
          fetchAuthorityList={authorityList}
        />
      </Flex>
    </>
  );
}

const AdminGroupAdd = (props) => {
  const { fetchAuthorityList } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const [authList, setAuthList] = useState();
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const history = useHistory();
  const location = useLocation();
  const [groupName, setGroupName] = useState();

  const reformData = (data) => {
    const changeAuthList = [...data] || [];
    const titleList = changeAuthList.map((item) => {
      return { title: item?.group };
    });
    const deduplicationGroup = titleList.filter((titleName, idx, arr) => {
      return arr.findIndex((item) => item.title === titleName.title) === idx;
    });

    let parseData = {};
    let arrayData = [];

    deduplicationGroup.forEach((group) => {
      // group 필터링
      changeAuthList.forEach((item) => {
        if (group.title === item.group) {
          arrayData.push(item);
          parseData[group.title] = [...arrayData];
        }
      });
      arrayData = [];
    });
    return parseData;
  };
  const handleChangeAuthority = ({ target }) => {
    const { value, dataset } = target;
    const { itemIndex, categoryIndex } = dataset;
    const cloneAuthList = cloneDeep(authList);

    cloneAuthList[categoryIndex][itemIndex].status = value;
    setAuthList(cloneAuthList); //authList의 status값을 바꾸는 곳.
  };

  const handleClickSave = (adminPassword) => {
    if (!groupName || groupName === "") {
      dispatch(actionError("그룹명을 입력해주세요."));
    } else {
      let arr = [];
      Object.keys(authList).map((title, cateIndex) => {
        return authList[title]?.map((authItem) => {
          return (arr = arr.concat(authItem));
        });
      });

      const authorityList = arr.map(({ authority, status }) => {
        return {
          authority: authority,
          status: status || "NONE",
        };
      });
      const param = {
        groupId: null,
        groupName: groupName,
        authorityRegisterDtoList: authorityList,
      };
      param["managerPassword"] = adminPassword;

      dispatch(loadingStart);
      APIS.postAuthorityGroup(param)
        .then(({ data: { success, message } }) => {
          if (!success) {
            dispatch(actionError(message, null, null, null, true));
          } else {
            dispatch(
              actionOpen(
                "저장이 완료되었습니다.",
                () => {
                  fetchAuthorityList();
                },
                null,
                null,
                true
              )
            );
            dispatch(closeModal());
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };
  const authorityList = () => {
    dispatch(loadingStart);
    APIS.getAuthorityList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          const reformAuthList = reformData(data);
          setAuthList(reformAuthList);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  useEffect(() => {
    authorityList();
  }, []);
  return (
    <div style={{ width: "850px", height: "480px" }}>
      <div className={classes.modal}>관리자 그룹추가</div>
      <div className={classes.content}>
        <header
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <div>
            <label>관리자 그룹명</label>&nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="text"
              placeholder="그룹명 입력"
              name="groupName"
              style={{ width: "280px", height: "40px" }}
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
              // ref={this.defaultRef}
            />
          </div>
          {isKorda && (
            <div style={{ marginTop: "-20px" }}>
              <AdminConfirmButton
                popupLabel="권한 그룹 추가"
                popupMessage="추가하시겠습니까?"
                label="저장"
                callback={handleClickSave}
                style={{
                  width: "100px",
                  height: "40px",
                  color: "white",
                  marginRight: "45px",
                }}
              />
            </div>
          )}
        </header>

        <section className={classes.sectionContents}>
          {authList
            ? Object.keys(authList).map((title, cateIndex) => {
                const Item = styled(Paper)(({ theme }) => ({
                  ...theme.typography.body2,
                  // padding: theme.spacing(1),
                  textAlign: "center",
                  color: theme.palette.text.secondary,
                }));
                return (
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid
                        item
                        xs={11}
                        style={{
                          width: "398px",
                          direction: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "40px",
                          border: "1px solid #c7c4c4",
                        }}
                      >
                        <Item style={{ boxShadow: "0px" }}>
                          <Table
                            notList
                            align="center"
                            key={cateIndex}
                            style={{ width: "360px" }}
                          >
                            <colgroup>
                              <col width="22%" />
                              <col width="15%" />
                              <col width="15%" />
                              <col width="15%" />
                              <col width="15%" />
                            </colgroup>
                            <thead style={{ background: "#f5f5f5" }}>
                              <tr>
                                <th colSpan="5" style={{ color: "black" }}>
                                  {title}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>구분</th>
                                <th>읽기</th>
                                <th>읽기/쓰기</th>
                                <th>결제</th>
                                <th>액세스거부</th>
                              </tr>
                              {authList[title]?.map((authItem, itemIndex) => {
                                return (
                                  <tr>
                                    <td className="ta-l">
                                      {authItem.description}
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        name={authItem.id}
                                        data-category-index={title}
                                        data-item-index={itemIndex}
                                        value="READ"
                                        onChange={handleChangeAuthority}
                                        checked={authItem.status === "READ"}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        name={authItem.id}
                                        data-category-index={title}
                                        data-item-index={itemIndex}
                                        onChange={handleChangeAuthority}
                                        checked={authItem.status === "WRITE"}
                                        value="WRITE"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        name={authItem.id}
                                        data-category-index={title}
                                        data-item-index={itemIndex}
                                        onChange={handleChangeAuthority}
                                        checked={
                                          // !authItem.status ||
                                          authItem.status === "PAYMENT"
                                        }
                                        value="PAYMENT"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        name={authItem.id}
                                        data-category-index={title}
                                        data-item-index={itemIndex}
                                        onChange={handleChangeAuthority}
                                        checked={
                                          !authItem.status ||
                                          authItem.status === "NONE"
                                        }
                                        value="NONE"
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })
            : ""}
        </section>
      </div>
    </div>
  );
};

const AdminGroupEdit = (props) => {
  const { id, fetchAuthorityList, originData } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const [authList, setAuthList] = useState();
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const reformData = (data) => {
    const changeAuthList = [...data] || [];
    const titleList = changeAuthList.map((item) => {
      return { title: item?.group };
    });
    const deduplicationGroup = titleList.filter((titleName, idx, arr) => {
      return arr.findIndex((item) => item.title === titleName.title) === idx;
    });

    let parseData = {};
    let arrayData = [];

    deduplicationGroup.forEach((group) => {
      // group 필터링
      changeAuthList.forEach((item) => {
        if (group.title === item.group) {
          arrayData.push(item);
          arrayData.push();
          parseData[group.title] = [...arrayData];
        }
      });
      arrayData = [];
    });

    let copyArray = [];
    let combined = [];
    Object.keys(parseData).forEach((data) => {
      Object.keys(originData).forEach((originItem) => {
        if (data === originItem) {
          if (parseData[data].length !== originData[originItem].length) {
            copyArray = [...parseData[data]];
            const copyAuthority = new Set(
              copyArray.map(({ authority }) => authority)
            );
            combined = [
              ...copyArray,
              ...originData[originItem].filter(
                ({ authority }) => !copyAuthority.has(authority)
              ),
            ];
            parseData[data] = [...combined];
          }
        }
      });
    });
    return parseData;
  };

  const handleChangeAuthority = ({ target }) => {
    const { value, dataset } = target;
    const { itemIndex, categoryIndex } = dataset;
    const cloneAuthList = cloneDeep(authList);

    cloneAuthList[categoryIndex][itemIndex].status = value;
    setAuthList(cloneAuthList); //authList 상태값 바꿔주는 함수
  };

  const handleClickEdit = (adminPassword) => {
    if (!state.groupName || state.groupName === "") {
      dispatch(actionError("그룹명을 입력해주세요."));
    } else {
      let arr = [];
      Object.keys(authList).map((title, cateIndex) => {
        return authList[title]?.map((authItem, itemIndex) => {
          return (arr = arr.concat(authItem));
        });
      });

      const authorityList = arr.map(({ authority, status }) => {
        return {
          authority: authority,
          status: status || "NONE",
        };
      });
      const param = {
        groupId: id,
        groupName: state.groupName,
        authorityRegisterDtoList: authorityList,
      };
      param["managerPassword"] = adminPassword;

      dispatch(loadingStart);
      APIS.postAuthorityGroupModify(param)
        .then(({ data: { data, success, message } }) => {
          if (!success) {
            dispatch(actionError(message, null, null, null, true));
          } else {
            dispatch(
              actionOpen(
                "수정이 완료되었습니다.",
                () => {
                  fetchAuthorityList();
                },
                null,
                null,
                true
              )
            );
            dispatch(closeModal());
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const authorityList = () => {
    dispatch(loadingStart);
    APIS.getAuthorityGroupDetail(id)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setAuthList(reformData(data.groupAuthorityResponseDtoList));
          handleValueChange("groupName", data.groupName);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  useEffect(() => {
    handleValueChange("groupName", "");
    authorityList();
  }, []);
  return (
    <div style={{ width: "850px", height: "480px" }}>
      <div className={classes.modal}>관리자 그룹변경</div>
      <div className={classes.content}>
        <header
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <div>
            <label>관리자 그룹명</label>&nbsp;&nbsp;&nbsp;&nbsp;
            <input
              type="text"
              placeholder="그룹명 입력"
              name="groupName"
              style={{ width: "280px", height: "40px" }}
              value={state.groupName}
              onChange={(e) => {
                handleValueChange("groupName", e.target.value);
              }}
            />
          </div>
          {isKorda && (
            <div style={{ marginTop: "-20px" }}>
              <AdminConfirmButton
                popupLabel="권한 그룹 변경"
                popupMessage="변경하시겠습니까?"
                label="저장"
                callback={handleClickEdit}
                style={{
                  width: "100px",
                  height: "40px",
                  color: "white",
                  marginRight: "45px",
                }}
              />
            </div>
          )}
        </header>

        <section className={classes.sectionContents}>
          {authList
            ? Object.keys(authList).map((title, cateIndex) => {
                const Item = styled(Paper)(({ theme }) => ({
                  ...theme.typography.body2,
                  textAlign: "center",
                  color: theme.palette.text.secondary,
                }));
                return (
                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid
                        item
                        xs={11}
                        style={{
                          width: "398px",
                          direction: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "40px",
                          border: "1px solid #c7c4c4",
                        }}
                      >
                        <Item style={{ boxShadow: "0px" }}>
                          <Table
                            notList
                            align="center"
                            key={cateIndex}
                            style={{ width: "360px" }}
                          >
                            <colgroup>
                              <col width="22%" />
                              <col width="15%" />
                              <col width="15%" />
                              <col width="15%" />
                              <col width="15%" />
                            </colgroup>
                            <thead style={{ background: "#f5f5f5" }}>
                              <tr>
                                <th colSpan="5" style={{ color: "black" }}>
                                  {title}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>구분</th>
                                <th>읽기</th>
                                <th>읽기/쓰기</th>
                                <th>결제</th>
                                <th>액세스거부</th>
                              </tr>
                              {authList[title]?.map((authItem, itemIndex) => {
                                return (
                                  <tr>
                                    <td className="ta-l">
                                      {authItem.description}
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        name={authItem.id}
                                        data-category-index={title}
                                        data-item-index={itemIndex}
                                        value="READ"
                                        onChange={handleChangeAuthority}
                                        checked={authItem.status === "READ"}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        name={authItem.id}
                                        data-category-index={title}
                                        data-item-index={itemIndex}
                                        onChange={handleChangeAuthority}
                                        checked={authItem.status === "WRITE"}
                                        value="WRITE"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        name={authItem.id}
                                        data-category-index={title}
                                        data-item-index={itemIndex}
                                        onChange={handleChangeAuthority}
                                        checked={
                                          // !authItem.status ||
                                          authItem.status === "PAYMENT"
                                        }
                                        value="PAYMENT"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="radio"
                                        name={authItem.id}
                                        data-category-index={title}
                                        data-item-index={itemIndex}
                                        onChange={handleChangeAuthority}
                                        checked={
                                          !authItem.status ||
                                          authItem.status === "NONE"
                                        }
                                        value="NONE"
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })
            : ""}
        </section>
      </div>
    </div>
  );
};

const AuthorityTable = (props) => {
  const { data, fetchAuthorityList } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const [originAuthList, setOriginAuthList] = useState();
  const headerRows = [
    [
      { label: "NO", rowSpan: 1, width: 50 },
      { label: "그룹명", rowSpan: 2 },
      { label: "권한", rowSpan: 1, width: 100 },
    ],
  ];
  const columns = [
    { key: "index", label: "NO", align: "center" },
    { key: "groupName", label: "그룹명", align: "center" },
  ];

  const reformData = (data) => {
    const changeAuthList = [...data] || [];
    const titleList = changeAuthList.map((item) => {
      return { title: item?.group };
    });
    const deduplicationGroup = titleList.filter((titleName, idx, arr) => {
      return arr.findIndex((item) => item.title === titleName.title) === idx;
    });

    let parseData = {};
    let arrayData = [];

    deduplicationGroup.forEach((group) => {
      // group 필터링
      changeAuthList.forEach((item) => {
        if (group.title === item.group) {
          arrayData.push(item);
          parseData[group.title] = [...arrayData];
        }
      });
      arrayData = [];
    });
    return parseData;
  };

  const authorityListOrigin = () => {
    dispatch(loadingStart);
    APIS.getAuthorityList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          const reformOriginAuthList = reformData(data);
          setOriginAuthList(reformOriginAuthList);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleClickGroupEdit = (id) => {
    dispatch(
      openModal({
        visiable: true,
        children: (
          <AdminGroupEdit
            id={id}
            fetchAuthorityList={fetchAuthorityList}
            originData={originAuthList}
          />
        ),
      })
    );
  };
  useEffect(() => {
    authorityListOrigin();
  }, []);
  return (
    <Flex style={{ margin: "0px 50px 0px 50px" }}>
      <ContextMenuLayout name="권한관리" headers={columns} data={data}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              {headerRows?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {row.map(
                      ({ label, rowSpan, colSpan, width, render }, index) => {
                        return (
                          <TableCell
                            key={index}
                            colSpan={colSpan}
                            width={width}
                          >
                            {render || label}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody>
              {data?.map((row, index) => {
                return (
                  <TableRow key={index} style={{ cursor: "pointer" }}>
                    {columns.map(({ key, align, type }, index) => {
                      const defaultAlign = type === "number" ? "right" : "left";
                      return (
                        <TableCell key={index} align={align || defaultAlign}>
                          {type === "number"
                            ? numberWithCommas(row[key])
                            : type === "date"
                            ? formatTime(row[key], `YYYY.MM.DD HH:mm:SS`)
                            : row[key]}
                        </TableCell>
                      );
                    })}
                    <TableCell align="center">
                      <Button
                        className={classes.changeButton}
                        label="변경"
                        // data-id={row.id}
                        onClick={() => {
                          handleClickGroupEdit(row.id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ContextMenuLayout>
    </Flex>
  );
};

const useStyle = makeStyles({
  root: {
    margin: "10px 50px",
  },
  header: {
    alignSelf: "stretch",
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },
  button: {
    border: "5px solid white",
    borderRadius: "7px",
    width: "150px",
  },
  changeButton: {
    width: "80px",
    color: "white",
  },
  btnDel: {
    border: "1px solid rgba(0,0,0,0.23)",
    width: "100%",
  },
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  tableHeader: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "flex-end",
    margin: "10px 50px",
  },
  tableContainer: {
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    "& th, td": {
      padding: 5,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      fontFamily: fonts.notoSansKRBold,
      backgroundColor: "#f5f5f5",
      textAlign: "center",
    },
    "& tr:hover": {
      backgroundColor: "#ededed",
    },
  },
  modal: {
    textAlign: "center",
    width: "100%",
    height: "50px",
    background: " rgb(180,180,180)",
    position: "absolute",
    right: "0px",
    top: "0px",
    borderRadius: "10px 10px 0px 0px",
    lineHeight: "50px",
  },
  content: {
    marginTop: "30px",
    height: "150px",
  },
  btnWrap: {
    padding: "5px 5px 5px 5px",
    textAlign: "center",
  },
  contentText: {
    display: "flex",
    justifyContent: "space-between",
  },
  sectionContents: {
    overflow: "hidden",
    overflowY: "auto",
    height: "400px",
    width: "857px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  buttonWrap: {
    width: "100%",
    // margin: "0px 0px 10px",
    display: "inline-block",
    textAlign: "right",
  },
  groupList: {
    overflow: "hidden",
    overflowY: "auto",
    height: "70%",
    width: "98%",
    display: "flex",
    flexWrap: "wrap",
    padding: "0px",
  },
});
