import {
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
  Button,
} from "@material-ui/core";
import {
  Add,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import fonts from "../../libs/fonts";
import consts from "../../libs/consts";

import Flex from "../../components/flex/Flex";
// import Button from "../../components/button/Button";

import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { objToQueryStr } from "../../services/utils";

import CategoryRows from "./CategoryRows";
import SubcategoryList from "./SubcategoryList";
import { gridColumnLookupSelector } from "@material-ui/data-grid";
import * as APIS from "../../libs/apis";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";

export default function HotProduct() {
  const classes = useStyle();
  const dispatch = useDispatch();

  const [hotGoods, setHotGoods] = useState([]);
  const [goods, setGoods] = useState([]);

  useEffect(() => {
    dispatch(loadingStart);

    APIS.getHotGoods()
      .then(setData)
      .finally(() => dispatch(loadingEnd));
  }, []);

  // 조회 API Callback
  const setData = ({ data: { success, data, message } }) => {
    if (success) {
      setHotGoods(data.hotGoods || []);
      setGoods(data.goods || []);
    } else {
      dispatch(actionError(message));
    }
  };

  // 정렬 이벤트
  const onSort = (index, targetIndex) => {
    const goodsIdList = [hotGoods[index].id, hotGoods[targetIndex].id];

    dispatch(loadingStart);
    APIS.putHotGoodsPosition({ goodsIdList })
      .then(({ data: { success, message } }) => {
        if (success) {
          const newArray = [...hotGoods];
          const tmpGoods = newArray[index];
          newArray[index] = newArray[targetIndex];
          newArray[targetIndex] = tmpGoods;

          setHotGoods(newArray);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  // 인기상품 등록 이벤트
  const registerHotGoods = () => {
    dispatch(loadingStart);
    APIS.putHotGoodsCreate({
      goodsIdList: goods.filter(({ checked }) => checked).map(({ id }) => id),
    })
      .then(setData)
      .finally(() => dispatch(loadingEnd));
  };

  // 인기상품 해제 이벤트
  const liftHotGoods = () => {
    dispatch(loadingStart);
    APIS.putHotGoodsDelete({
      goodsIdList: hotGoods
        .filter(({ checked }) => checked)
        .map(({ id }) => id),
    })
      .then(setData)
      .finally(() => dispatch(loadingEnd));
  };

  return (
    <>
      <Flex
        row
        style={{ flex: 1, padding: "20px 50px 0px 50px", position: "relative" }}
      >
        <HotProductSection
          isHot
          list={hotGoods}
          setList={setHotGoods}
          onSort={onSort}
          onSubmit={liftHotGoods}
        />
        <Flex style={{ width: 90 }} />
        <HotProductSection
          list={goods}
          setList={setGoods}
          onSubmit={registerHotGoods}
        />
      </Flex>
    </>
  );
}

const HotProductSection = ({ isHot, list = [], setList, onSort, onSubmit }) => {
  const classes = useStyle();
  const label = isHot ? "인기 상품" : "일반 상품";
  const SubmitIcon = isHot ? ChevronRight : ChevronLeft;

  // 체크박스 전체 선책 이벤트
  const handleChangeAllCheck = (e) => {
    setList(list.map((item) => ({ ...item, checked: e.target.checked })));
  };

  // 체크박스 개별 선책 이벤트
  const handleChangeCheckBox = (index, checked) => {
    setList(
      list.map((item, i) => ({
        ...item,
        checked: index === i ? checked : item.checked,
      }))
    );
  };

  // 정렬 이벤트
  const handleClickSort = (index, targetIndex) => {
    return () => {
      onSort(index, targetIndex);
    };
  };

  return (
    <Flex style={{ flex: 1 }}>
      <Button
        variant="outlined"
        disabled={list.findIndex(({ checked }) => checked) === -1}
        onClick={onSubmit}
        className={classes.submitButton}
        style={{ top: `calc(50% ${isHot ? "-" : "+"} 25px)` }}
      >
        <SubmitIcon />
      </Button>

      <Text font={fonts.notoSansKRBold} className={classes.label}>
        {label}
      </Text>

      <Flex style={{ border: "1px solid", overflowY: "auto", height: 700 }}>
        <table className={classes.table} cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>
                <Checkbox
                  checked={
                    list.length.toString() &&
                    !(list.findIndex(({ checked }) => !checked) > -1)
                  }
                  onChange={handleChangeAllCheck}
                />
              </th>
              {isHot && <th>순서</th>}
              <th>상품코드</th>
              <th>카테고리</th>
              <th>상품명</th>
            </tr>
          </thead>
          <tbody>
            {list.map(({ id, code, categoryName, name, checked }, i) => (
              <tr key={i.toString()}>
                <td>
                  <Checkbox
                    checked={!!checked}
                    onChange={(e) => {
                      handleChangeCheckBox(i, e.target.checked);
                    }}
                  />
                </td>
                {isHot && (
                  <td>
                    <Flex row>
                      <ButtonBase
                        className={classes.arrowButton}
                        onClick={handleClickSort(i - 1, i)}
                        disabled={i === 0}
                      >
                        <ArrowDropUpOutlined />
                      </ButtonBase>
                      <ButtonBase
                        className={classes.arrowButton}
                        onClick={handleClickSort(i, i + 1)}
                        disabled={i === list.length - 1}
                      >
                        <ArrowDropDownOutlined />
                      </ButtonBase>
                    </Flex>
                  </td>
                )}
                <td>{code}</td>
                <td>{categoryName}</td>
                <td>{name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Flex>
    </Flex>
  );
};

const useStyle = makeStyles({
  img2: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 300,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  img: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    border: "1px solid rgba(0,0,0,0.23)",
    padding: "10px 20px",
    alignItems: "center",
    boxSizing: "border-box",
    width: "auto !important",
  },

  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },

  label: { textAlign: "center", fontSize: 18, margin: "10px 0" },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",
    minWidth: "50%",
    padding: "5px 10px",
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
  },
  radiogroup: {
    marginTop: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "80%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
      position: "sticky",
      top: 0,
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: "center",
    justifyContent: "space-between",
  },
  arrowButton: {
    flex: 1,
    "&:disabled": { color: colors.lightGray },
  },
  submitButton: {
    position: "absolute",
    width: 70,
    height: 40,
    left: "calc(50% - 35px)",
  },
});
