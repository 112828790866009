import {
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardMedia,
  RadioGroup,
} from "@material-ui/core";
import { Add, ExpandMore, PanoramaOutlined } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useLayoutEffect, useState } from "react";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import Flex from "../../components/flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import * as APIS from "../../libs/apis";
import GridRow from "../../components/grid-row/GridRow";
import consts from "../../libs/consts";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionOpen, actionError } from "../../redux/action/ActionActions";
import { numberWithCommas } from "../../libs/utils";
import { openImageView } from "../../redux/image-viewer/ImageViewerReducer";
import { AiOutlineConsoleSql } from "react-icons/ai";

const MAP_COLUMN_TO_PRODUCT_RECEPTION = {
  orderId: "판매번호",
  categoryName: "카테고리",
  brand: "브랜드(제조사)",
  name: "제품명(모델명)",
  outDate: "제조출시년도",
  buyPrice: "구매 가격",
  buyStore: "구매처",
  buyDate: "구입년도",
  state: "제품상태",
  currentPrice: "판매 희망가격",
  isContract: "보증서 여부",
  isParts: "구성품 여부",
  inStoreReserve: "매장 방문 예약일",
  inStoreReserveTime: "매장 방문 예약 시간",
  memo: "사용자 메모",
  cancelReason: "접수 취소 사유",
};

export default function ProductReceptionInfo(props) {
  const { list, recentStatus, isKorda, setHistory } = props;
  const receptionInfo = list;
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const classes = useStyle();
  const [cancelReason, setCancelReason] = useState("");
  // const [tagNumber, setTagNumber] = useState("");
  // const [status, setStatus] = useState("");
  // const [receptionStatus, setReceptionStatus] = useState("");
  // console.log(receptionInfo, "receptionInfo");
  const getColumnData = (key, option = {}) => {
    return {
      label: MAP_COLUMN_TO_PRODUCT_RECEPTION[key],
      value:
        key === "isContract"
          ? receptionInfo[key] === true
            ? "유"
            : "무"
          : key === "isParts"
          ? receptionInfo[key] === true
            ? "유"
            : "무"
          : receptionInfo[key],
      ...option,
    };
  };

  // const onSaveAdmin = async (adminPassword) => {
  //   // if (isKorda) {
  //   //   dispatch(actionError("매장 관리자만 접수정보를 저장할 수 있습니다."));
  //   // }
  //   if (status === "INBOUND_CANCEL" && cancelReason === "") {
  //     dispatch(actionError("취소 사유를 입력하여 주세요."));
  //   } else if (
  //     recentStatus !== "INBOUND_WAIT" &&
  //     recentStatus !== "INBOUND_COMPLETE"
  //   ) {
  //     dispatch(
  //       actionError("해당 상태에서는 접수 정보를 저장하실 수 없습니다.")
  //     );
  //   } else if (status === "") {
  //     dispatch(actionError("접수 상태를 선택하여 주세요."));
  //   }
  //   // else if (status === "INBOUND_COMPLETE" && tagNumber === "") {
  //   //   dispatch(actionError("Tag Number를 입력하여 주세요."));
  //   // }
  //   else {
  //     const data = {
  //       status: status,
  //       // tagNo: tagNumber,
  //       cancelReason: cancelReason,
  //       managerPassword: adminPassword,
  //     };
  //     dispatch(loadingStart);
  //     APIS.postSafeTradeReceptionInfo(state.itemId, data)
  //       .then(({ data: { data, message, success } }) => {
  //         if (success) {
  //           dispatch(
  //             actionOpen(
  //               "상태가 변경되었습니다.",
  //               () => {
  //                 history.replace({
  //                   pathname: history.location.pathname,
  //                   state: { ...state, itemId: receptionInfo.itemId },
  //                 });
  //               },
  //               null,
  //               null,
  //               true
  //             )
  //           );
  //           setHistory("true");
  //           setCancelReason(data.cancelReason);
  //         } else {
  //           dispatch(actionError(message));
  //         }
  //       })
  //       .catch((error) => dispatch(actionError(error)))
  //       .finally(() => dispatch(loadingEnd));
  //   }
  // };

  // useLayoutEffect(() => {
  //   setReceptionStatus(recentStatus);
  // }, [recentStatus]);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Typography style={{ fontWeight: "bold" }}>제품 접수 정보</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Flex>
          {/* <Flex row>
            <RadioGroup
              row
              name="status"
              value={receptionStatus}
              onChange={(e) => {
                setReceptionStatus(e.target.value);
              }}
            >
              <FormControlLabel
                value="INBOUND_COMPLETE"
                label="접수완료"
                disabled={
                  // !isKorda &&
                  recentStatus === "INBOUND_WAIT" ? false : true
                }
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                control={<Radio style={{ color: "black" }} color="default" />}
              />
              <FormControlLabel
                value="INBOUND_CANCEL"
                label="접수취소"
                disabled={
                  // !isKorda &&
                  recentStatus === "INBOUND_WAIT" ||
                  recentStatus === "INBOUND_COMPLETE"
                    ? false
                    : true
                }
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                control={<Radio style={{ color: "black" }} color="default" />}
              />
            </RadioGroup>

            <input
              disabled={
                // isKorda ||
                (recentStatus === "INBOUND_COMPLETE" &&
                  status === "INBOUND_CANCEL") ||
                (status === "INBOUND_CANCEL" && recentStatus === "INBOUND_WAIT")
                  ? false
                  : true
              }
              type="text"
              autoComplete="off"
              value={cancelReason}
              onChange={(e) => {
                setCancelReason(e.target.value);
              }}
              placeholder="취소 사유 입력"
              className={classes.input}
            />
            <AdminConfirmButton
              popupLabel="접수 정보 변경"
              popupMessage="변경사항을 저장하시겠습니까?"
              label="저장"
              callback={onSaveAdmin}
              rootClassName={classes.confirmRoot}
              btnClassName={classes.confirmButton}
            />
          </Flex> */}
          {/* <Flex row>
            <Typography>Tag Number </Typography>
            <input
              type="text"
              autoComplete="off"
              disabled={
                // !isKorda &&
                status === "INBOUND_COMPLETE" && recentStatus === "INBOUND_WAIT"
                  ? false
                  : true
              }
              onChange={(e) => {
                setTagNumber(e.target.value);
              }}
              placeholder="Tag Number"
              className={classes.input}
            />
          </Flex> */}
          <GridRow
            data={[
              {
                label: "판매번호",
                value: receptionInfo?.itemId,
              },
              {
                label: "카테고리",
                value: receptionInfo?.categoryInfo?.categoryName,
              },
              {
                label: "브랜드(제조사)",
                value: receptionInfo?.categoryInfo?.brand,
              },
              getColumnData("name"),
              getColumnData("outDate"),
              {
                label: "구매 가격",
                value: numberWithCommas(receptionInfo?.buyPrice) + " 원",
              },
              getColumnData("buyStore"),
              getColumnData("buyDate"),
              getColumnData("state"),
              {
                label: "판매 희망가격",
                value: numberWithCommas(receptionInfo?.currentPrice) + " 원",
              },
              getColumnData("isContract"),
              getColumnData("isParts"),
              // getColumnData("inStoreReserve"),
              // getColumnData("inStoreReserveTime"),
              getColumnData("memo", { fullWidth: true }),
              getColumnData("cancelReason", { fullWidth: true }),
            ]}
          />
          <Grid className={classes.grid}>
            <Grid item>
              <Flex>
                <div className={classes.imgWrap}>
                  {receptionInfo?.frontImage ? (
                    <CardMedia
                      component="img"
                      image={consts.s3BaseUrl + "/" + receptionInfo?.frontImage}
                      style={{ borderRadius: 10, width: 110 }}
                      onClick={(e) => {
                        dispatch(
                          openImageView({
                            src:
                              consts.s3BaseUrl +
                              "/" +
                              receptionInfo?.frontImage,
                          })
                        );
                      }}
                    />
                  ) : (
                    <PanoramaOutlined className={classes.defaultImage} />
                  )}
                </div>
                <div className={classes.imgTitle}>전면</div>
              </Flex>
              <Flex>
                <div className={classes.imgWrap}>
                  {receptionInfo?.sideImage ? (
                    <CardMedia
                      component="img"
                      image={consts.s3BaseUrl + "/" + receptionInfo?.sideImage}
                      style={{ borderRadius: 10, width: 110 }}
                      onClick={(e) => {
                        dispatch(
                          openImageView({
                            src:
                              consts.s3BaseUrl + "/" + receptionInfo?.sideImage,
                          })
                        );
                      }}
                    />
                  ) : (
                    <PanoramaOutlined className={classes.defaultImage} />
                  )}
                </div>
                <div className={classes.imgTitle}>측면</div>
              </Flex>
              <Flex>
                <div className={classes.imgWrap}>
                  {receptionInfo?.backImage ? (
                    <CardMedia
                      component="img"
                      image={consts.s3BaseUrl + "/" + receptionInfo?.backImage}
                      style={{ borderRadius: 10, width: 110 }}
                      onClick={(e) => {
                        dispatch(
                          openImageView({
                            src:
                              consts.s3BaseUrl + "/" + receptionInfo?.backImage,
                          })
                        );
                      }}
                    />
                  ) : (
                    <PanoramaOutlined className={classes.defaultImage} />
                  )}
                </div>
                <div className={classes.imgTitle}>뒷면</div>
              </Flex>
              <Flex>
                <div className={classes.imgWrap}>
                  {receptionInfo?.itemImage ? (
                    <CardMedia
                      component="img"
                      image={consts.s3BaseUrl + "/" + receptionInfo?.itemImage}
                      style={{ borderRadius: 10, width: 110 }}
                      onClick={(e) => {
                        dispatch(
                          openImageView({
                            src:
                              consts.s3BaseUrl + "/" + receptionInfo?.itemImage,
                          })
                        );
                      }}
                    />
                  ) : (
                    <PanoramaOutlined className={classes.defaultImage} />
                  )}
                </div>
                <div className={classes.imgTitle}>모델명</div>
              </Flex>
              <Flex>
                <div className={classes.imgWrap}>
                  {receptionInfo?.contractImage ? (
                    <CardMedia
                      component="img"
                      image={
                        consts.s3BaseUrl + "/" + receptionInfo?.contractImage
                      }
                      style={{ borderRadius: 10, width: 110 }}
                      onClick={(e) => {
                        dispatch(
                          openImageView({
                            src:
                              consts.s3BaseUrl +
                              "/" +
                              receptionInfo?.contractImage,
                          })
                        );
                      }}
                    />
                  ) : (
                    <PanoramaOutlined className={classes.defaultImage} />
                  )}
                </div>
                <div className={classes.imgTitle}>보증서</div>
              </Flex>
              <Flex>
                <div className={classes.imgWrap}>
                  {receptionInfo?.contractImage ? (
                    <CardMedia
                      component="img"
                      image={consts.s3BaseUrl + "/" + receptionInfo?.partsImage}
                      style={{ borderRadius: 10, width: 110 }}
                      onClick={(e) => {
                        dispatch(
                          openImageView({
                            src:
                              consts.s3BaseUrl +
                              "/" +
                              receptionInfo?.partsImage,
                          })
                        );
                      }}
                    />
                  ) : (
                    <PanoramaOutlined className={classes.defaultImage} />
                  )}
                </div>
                <div className={classes.imgTitle}>구성품</div>
              </Flex>
            </Grid>
          </Grid>
        </Flex>
      </AccordionDetails>
    </Accordion>
  );
}
const useStyle = makeStyles({
  grid: {
    justifyContent: "center",
    margin: "0px 50px",
    "& div": {
      display: "flex",
    },
  },
  imgWrap: {
    width: 100,
    height: 100,
    border: "1px solid rgb(220,220,220)",
    marginRight: 80,
  },
  imgTitle: {
    marginLeft: 30,
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
  accordion: {
    marginBottom: "20px",
  },
  input: {
    flex: 1,
    marginRight: "20px",
    paddingLeft: "16px",
    // marginLeft: "100px",
    width: "800px",
    height: 40,
    border: "1px solid rgb(224,224,224)",
  },

  bold: {
    fontWeight: "bold",
  },

  df: {
    display: "flex",
  },
  defaultImage: {
    width: 50,
    height: 50,
    alignSelf: "center",
    marginLeft: 25,
  },
});
