import { makeStyles, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import ClassificationManagement from "./ClassificationManagement";
import FeeSetting from "./FeeSetting";
import Flex from "../../components/flex/Flex";

export default function SettingDirect({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const state = location.state || {};
  const [content, setContent] = useState("classificationManagement");

  const selectComponent = {
    classificationManagement: <ClassificationManagement />,
    feeRate: <FeeSetting />,
  };

  const buttonValueSetting = (name) => {
    setContent(name);
  };

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
        page: "1",
      });
    }
  }, [state.tab]);

  return (
    <>
      <LabelTabs
        data={[
          { label: "안심직거래", value: "1" },
          // { label: "분류관리", value: "1" },
          // { label: "인기상품 관리", value: "2" },
          // { label: "수수료 설정", value: "3" },
        ]}
      />
      {(() => {
        switch (state.tab) {
          case "1":
            return (
              <Flex>
                <Flex
                  row
                  style={{
                    marginTop: 5,
                    marginLeft: 50,
                    flexWrap: "wrap",
                    alignSelf: "stretch",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={`${classes.button} ${
                      content === "classificationManagement" &&
                      classes.buttonSel
                    }`}
                    data-name="classificationManagement"
                    onClick={(e) => {
                      buttonValueSetting(e.target.dataset.name);
                    }}
                  >
                    분류관리
                  </button>
                  <button
                    className={`${classes.button} ${
                      content === "feeRate" && classes.buttonSel
                    }`}
                    data-name="feeRate"
                    onClick={(e) => {
                      buttonValueSetting(e.target.dataset.name);
                    }}
                  >
                    수수료율
                  </button>
                </Flex>
                {content && selectComponent[content]}
              </Flex>
            );
          // case "2":
          //   return <PopularProductManagement />;
          // case "3":
          //   return <FeeSetting />;
        }
      })()}
    </>
  );
}

const useStyle = makeStyles({
  root: {},
  button: {
    width: "150px",
    padding: 8,
    marginRight: 10,
  },
  buttonSel: {
    width: "150px",
    padding: 8,
    color: "#fff !important",
    backgroundColor: "black !important",
  },
});
