import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import SimpleTable from "../../components/simple-table/SimpleTable";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { actionOpen } from "../../redux/action/ActionActions";
import SettingPolicy5Popup from "./SettingPolicy5Popup";

export default function SettingPolicy5({}) {
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const handleSave = () => {
    dispatch(actionOpen("수정하시겠습니까?", () => {}, "취소", "저장"));
  };
  return (
    <Flex>
      {popup && (
        <SettingPolicy5Popup
          onClose={() => {
            setPopup(false);
          }}
        />
      )}
      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>거래일 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          {["월~금", "토", "일", "전일", "공휴일 거래"].map((x, i) => {
            if (x === "공휴일 거래") {
              return (
                <th key={i.toString()} style={{}}>
                  <FormControl>
                    <FormLabel style={{ color: "#000" }}>공휴일 거래</FormLabel>
                    <RadioGroup row>
                      <FormControlLabel
                        label={"있음"}
                        control={
                          <Radio style={{ color: "black" }} color="default" />
                        }
                      />
                      <FormControlLabel
                        label={"없음"}
                        control={
                          <Radio style={{ color: "black" }} color="default" />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </th>
              );
            }
            return (
              <th key={i.toString()}>
                <FormControlLabel
                  label={x}
                  control={<Radio style={{ color: "black" }} color="default" />}
                />
              </th>
            );
          })}
        </tr>
      </table>

      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>거래시간 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>
      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th>거래시작 시간</th>
          <th>
            <Flex row className="row-center">
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />시
              </div>
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />분
              </div>
            </Flex>
          </th>
          <th>거래마감 시간</th>
          <th>
            <Flex row className="row-center">
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />시
              </div>
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />분
              </div>
            </Flex>
          </th>
        </tr>
      </table>

      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>매장 방문 시간 설정</span>
        <Button variant="outlined" title="수정" onClick={handleSave}>
          수정
        </Button>
      </Flex>
      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <tr>
          <th>매장오픈 시간</th>
          <th>
            <Flex row className="row-center">
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />시
              </div>
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />분
              </div>
            </Flex>
          </th>
          <th>매장마감 시간</th>
          <th>
            <Flex row className="row-center">
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />시
              </div>
              <div className={classes.inputWrap}>
                <InputBase className={classes.input} />분
              </div>
            </Flex>
          </th>
        </tr>
      </table>

      <Flex row className={classes.labelWrap}>
        <span className={classes.label}>공휴일 설정</span>
        <Flex row className="row-center">
          <Button variant="outlined" title="수정" onClick={handleSave}>
            삭제
          </Button>
          <Button
            style={{ backgroundColor: "#000", marginLeft: 10, color: "#fff" }}
            variant="contained"
            title="수정"
            onClick={() => {
              setPopup(true);
            }}
          >
            등록
          </Button>
        </Flex>
      </Flex>
      <SimpleTable
        fullWidth
        columns={[
          {
            label: <Checkbox />,
            render: (x) => <Checkbox />,
          },
          {
            label: "제목",
          },
          {
            label: "시작",
          },
          {
            label: "종료",
          },
          {
            label: "종류",
          },
          {
            label: "활성여부",
          },
          {
            label: "등록일",
          },
          {
            label: "수정일",
          },
        ]}
        data={[{}, {}, {}]}
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  labelWrap: {
    margin: "0px 50px",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    "& button": { width: 100 },
  },

  table2: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  tdb: {
    backgroundColor: "#f5f5f5",
  },

  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
  inputWrap: {
    border: "1px solid #ddd",
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    width: "80%",
    marginLeft: "10%",
    backgroundColor: "#fff",
    paddingRight: 10,
  },
  input: {
    paddingRight: 10,
  },
  a: { alignItems: "center", whiteSpace: "nowrap" },
});
