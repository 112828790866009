import React from "react";

export default function RenderInput({ onlyImage, onChange, inputRef }) {
  return (
    <input
      ref={inputRef}
      type="file"
      onChange={(e) => {
        const file = e.target.files[0];

        if (file) {
          const fs = new FileReader();
          fs.onload = () => {
            file.uri = fs.result;
            onChange(file);
          };
          fs.readAsDataURL(file);
        }
        e.target.value = "";
      }}
      style={{ display: "none" }}
      accept={onlyImage ? "image/*" : ""}
    />
  );
}
