import {
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import SimpleTable from "../../components/simple-table/SimpleTable";
import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import { parse } from "query-string";
import { objToQueryStr, formatTime } from "../../services/utils";

export default function StatusUpdater1({ appraisalRequestStatus, id }) {
  const authReducer = useSelector((s) => s.auth);
  let isShop = !authReducer.isKorda;
  let clickCheck = false;
  const { isKorda } = authReducer;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyle();
  const [t1, setT1] = useState(""); // 감정시작 - 반려 사유 입력
  const [t2, setT2] = useState(""); // 감정결과 - 사유
  const [t3, setT3] = useState(""); // 반송 - 사유
  const [t4, setT4] = useState(""); // 감정 결과 숫자
  // const [approvalGram, setApprovalGram] = useState(""); // 승인 그램 보여주는 칸
  const [approvalReason, setApprovalReason] = useState(""); //  승인 사유 보여주는 칸

  const [r1, setR1] = useState(""); // 감정시작 라운드 버튼
  const [r2, setR2] = useState(""); // 감정결과 라운드 버튼
  const [r3, setR3] = useState(""); // 반송 라운드 버튼
  const [approval, setApproval] = useState("1"); //승인 라운드 버튼

  // const dStatus = (id) => {
  //   return new Promise((resolve, rejet) => {
  //     APIS.deleteAppraisalRequestStatus(id, isKorda).then(resolve).catch(rejet);
  //   });
  // };
  
  // useEffect(() => {
  //   if (appraisalRequestStatus.filter((x) => x.status === "반송반려")[0]) {
  //     setR3("2");
  //     return setT3(
  //       appraisalRequestStatus.filter((x) => x.status === "반송반려")[0]
  //         ?.returnCancelReason
  //     );
  //   } else if (
  //     appraisalRequestStatus.filter((x) => x.status === "반송완료")[0]
  //   ) {
  //     setR3("1");
  //   }

  //   if (appraisalRequestStatus.filter((x) => x.status === "감정반려")[0]) {
  //     setR1("2");
  //     return setT1(
  //       appraisalRequestStatus.filter((x) => x.status === "감정반려")[0]
  //         ?.appraiseCancelReason
  //     );
  //   } else if (
  //     appraisalRequestStatus.filter((x) => x.status === "감정시작")[0]
  //   ) {
  //     setR1("1");
  //   }

  //   if (appraisalRequestStatus.filter((x) => x.status === "승인반려")[0]) {
  //     setApproval("2");
  //     return setT1(
  //       appraisalRequestStatus.filter((x) => x.status === "승인반려")[0]
  //         ?.appraiseMemo
  //     );
  //   } else if (
  //     appraisalRequestStatus.filter((x) => x.status === "승인완료")[0]
  //   ) {
  //     setApproval("1");
  //   }

  //   if (appraisalRequestStatus.filter((x) => x.status === "감정완료")[0]) {
  //     let d = appraisalRequestStatus.filter((x) => x.status === "감정완료")[0];
  //     setT4(String(d.appraiseGram));
  //     setT2(String(d.appraiseMemo));
  //     setR2(d.appraiseType === "GOLD" ? "1" : "2");
  //   }
  // }, [appraisalRequestStatus]);
  const avoidDuplicateClicks = (func) => {
    if (!clickCheck) {
      clickCheck = true;
      func();
    }
  };

  const onSave = async () => {
    let last = appraisalRequestStatus[appraisalRequestStatus.length - 1];
    if (["접수", "감정시작", "감정반려"].includes(last.status)) {
      if (last.status === "감정시작" && r1 === "1") {
        if (!r2) {
          return dispatch(
            actionOpen("감정결과를 선택해 주세요.", null, "", "", true)
          );
        }
      }
      if (["1", "2"].includes(r2) && r1 !== "2") {
        if (!r2) {
          return dispatch(
            actionOpen("감정결과를 선택해 주세요.", null, "", "", true)
          );
        } else if (t4 === "" || isNaN(t4)) {
          return dispatch(
            actionOpen(
              "올바른 감정결과를 중량을 입력해 주세요.",
              null,
              "",
              "",
              true
            )
          );
        }

        if (r2 === "1" && +(+t4 * 1000).toFixed(0) % 5 !== 0) {
          // 금
          return dispatch(
            actionOpen("0.005g 단위로 입력해주세요.", null, "", "", true)
          );
        } else if (r2 === "2" && +(+t4 * 10).toFixed(0) % 5 !== 0) {
          // 은
          return dispatch(
            actionOpen("0.5g 단위로 입력해주세요.", null, "", "", true)
          );
        }

        let needApproved = true;
        const makeAsync = (a) => {
          return new Promise((resolve, reject) => {
            APIS.putAppraisalRequestsIsApprovedApprise(
              id,
              {
                approvedAppraise: a ? false : true,
              },
              isKorda
            )
              .then(resolve)
              .catch(reject);
          });
        };
        /*
        if (isShop) {
          try {
            const shopRangeGram = await getShopRange(r2 === "1" ? "금" : "은");
            if (parseFloat(shopRangeGram) >= parseFloat(t4)) {
              needApproved = true;
            }
          } catch (error) {
            return dispatch(
              actionError(
                "매장 허용 중량 조회중 발생했습니다. 다시 시도해 주세요."
              )
            );
          }
        } else {
          needApproved = true;
        }*/

        if (needApproved) {
          try {
            await makeAsync();
          } catch (error) {
            return dispatch(
              actionError(
                "최종 승인 처리중 오류가 발생했습니다. 다시 시도해 주세요."
              )
            );
          }
        }

        return APIS.putAppraisalRequestStatus(
          id,
          {
            appraiseGram: parseFloat(t4),
            appraiseMemo: t2,
            appraiseType: r2 === "1" ? "GOLD" : "SILVER",
            status: "감정완료",
          },
          isKorda
        )
          .then(() => {
            history.replace({
              pathname: history.location.pathname,
              state: history.location.state,
            });

            return dispatch(
              actionOpen("감정 완료 되었습니다.", null, "", "", true)
            );
          })
          .catch((err) => {
            makeAsync(true);
            dispatch(actionError(err));
          });
      }

      if (!r1) {
        return dispatch(
          actionOpen("감정시작 상태를 선택해 주세요.", null, "", "", true)
        );
      } else if (r1 === "2" && !t1) {
        return dispatch(
          actionOpen("감정반려 사유를 입력해 주세요.", null, "", "", true)
        );
      }

      // let exarr = appraisalRequestStatus.filter(
      //   (x) => x.status === (r1 === "1" ? "감정시작" : "감정반려")
      // );

      // if (exarr.length) {
      //   try {
      //     for await (let i of exarr) {
      //       await dStatus(i.id);
      //     }
      //   } catch (error) {
      //     return dispatch(actionOpen("처리할 수 없습니다."));
      //   }
      // }

      return APIS.putAppraisalRequestStatus(
        id,
        r1 === "1"
          ? {
              status: "감정시작",
              appraiseCancelReason: "",
            }
          : {
              status: "감정반려",
              appraiseCancelReason: t1,
            },
        isKorda
      )
        .then(async () => {
          // let arr = appraisalRequestStatus.filter(
          //   (x) => x.status === (r1 === "1" ? "감정반려" : "감정시작")
          // );

          // if (arr.length) {
          //   try {
          //     for await (let i of arr) {
          //       await dStatus(i.id);
          //     }
          //   } catch (error) {
          //     return dispatch(
          //       actionOpen("페이지를 새로고침 해주세요.", null, "", "", true)
          //     );
          //   }
          // }

          history.replace({
            pathname: history.location.pathname,
            state: history.location.state,
          });

          return dispatch(
            actionOpen(
              r1 === "1" ? "감정이 시작 되었습니다." : "감정반려 되었습니다.",
              null,
              "",
              "",
              true
            )
          );
        })
        .catch((err) => dispatch(actionError(err)));
    } else if (
      ["감정완료", "반송신청", "반송반려", "교환완료"].includes(last.status)
    ) {
      if (!r3) {
        return dispatch(
          actionOpen("반송 상태를 선택해 주세요.", null, "", "", true)
        );
      } else if (r3 === "2" && !t3) {
        return dispatch(
          actionOpen("반송반려 사유를 입력해 주세요.", null, "", "", true)
        );
      }

      // let exarr = appraisalRequestStatus.filter((x) => x.status === "반송반려");

      // if (exarr.length) {
      //   try {
      //     for await (let i of exarr) {
      //       await dStatus(i.id);
      //     }
      //   } catch (error) {
      //     return dispatch(actionOpen("처리할 수 없습니다."));
      //   }
      // }

      // console.log("onSave3 put /market/appraisal/Requests/${id}/status", id);

      return APIS.putAppraisalRequestStatus(
        id,
        r3 === "1"
          ? isKorda
            ? {
                status: "반송완료",
                returnCancelReason: "",
              }
            : appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status === "교환완료"
            ? {
                status:
                  appraisalRequestStatus[appraisalRequestStatus.length - 1]
                    .status === "교환완료"
                    ? "반송대기"
                    : "반송완료",
                returnCancelReason:
                  appraisalRequestStatus[appraisalRequestStatus.length - 1]
                    .status === "교환완료"
                    ? t3
                    : "",
                appraiseGram:
                  appraisalRequestStatus[appraisalRequestStatus.length - 1]
                    .status === "교환완료"
                    ? appraisalRequestStatus.find(
                        (item) => item.status === "감정완료"
                      ).appraiseGram
                    : "",
                appraiseType:
                  appraisalRequestStatus[appraisalRequestStatus.length - 1]
                    .status === "교환완료"
                    ? appraisalRequestStatus.find(
                        (item) => item.status === "감정완료"
                      ).appraiseType
                    : "",
              }
            : { status: "반송완료", returnCancelReason: "" }
          : {
              status: "반송반려",
              returnCancelReason: t3,
            },
        isKorda
      )
        .then(() => {
          history.replace({
            pathname: history.location.pathname,
            state: history.location.state,
          });

          return dispatch(
            actionOpen(
              r3 === "1"
                ? appraisalRequestStatus[appraisalRequestStatus.length - 1]
                    .status === "교환완료"
                  ? "반송 대기 처리 되었습니다."
                  : "반송 완료 처리 되었습니다."
                : "반송반려 되었습니다.",
              null,
              "",
              "",
              true
            )
          );
        })
        .catch((err) => {
          dispatch(actionError(err));
        });
    } else if (["승인대기"].includes(last.status)) {
      if (!r3) {
        return dispatch(
          actionOpen("반송 상태를 선택해 주세요.", null, "", "", true)
        );
      } else if (r3 === "2" && !t3) {
        return dispatch(
          actionOpen("반송반려 사유를 입력해 주세요.", null, "", "", true)
        );
      }
    }
  };

  const onSaveAdmin = async (adminPassword) => {
    if (!clickCheck) {
      clickCheck = true;
      let last = appraisalRequestStatus[appraisalRequestStatus.length - 1];
      if (["승인대기", "승인완료", "승인반려"].includes(last.status)) {
        if (!approval) {
          return dispatch(
            actionOpen("승인대기 상태를 선택해 주세요.", null, "", "", true)
          );
        } else if (approval === "1" && !approvalReason) {
          return dispatch(
            actionOpen("승인 사유를 입력해 주세요.", null, "", "", true)
          );
        } else if (approval === "2" && !t3) {
          return dispatch(
            actionOpen("승인 반려 사유를 입력해 주세요.", null, "", "", true)
          );
        }
        // let exarrApproval = appraisalRequestStatus.filter(
        //   (x) => x.status === "승인반려"
        // );
        // if (exarrApproval.length) {
        //   try {
        //     for await (let i of exarrApproval) {
        //       await dStatus(i.id);
        //     }
        //   } catch (error) {
        //     return dispatch(actionOpen("처리할 수 없습니다."));
        //   }
        // }

        return APIS.putAppraisalRequestStatus(
          id,
          approval === "1"
            ? {
                status: "감정완료",
                appraiseMemo: approvalReason,
                appraiseGram: last.appraiseGram,
                appraiseType: last.appraiseType,
                password: adminPassword,
              }
            : {
                status: "승인반려",
                returnCancelReason: t3,
                password: adminPassword,
              },
          isKorda
        )
          .then(() => {
            history.replace({
              pathname: history.location.pathname,
              state: history.location.state,
            });

            return dispatch(
              actionOpen(
                approval === "1"
                  ? "승인완료 처리 되었습니다."
                  : "승인반려 되었습니다.",
                null,
                "",
                "",
                true
              )
            );
          })
          .catch((err) => {
            dispatch(actionError(err));
          });
      } else if (["감정완료", "반송신청", "반송반려"].includes(last.status)) {
        if (!r3) {
          return dispatch(
            actionOpen("반송 상태를 선택해 주세요.", null, "", "", true)
          );
        } else if (r3 === "2" && !t3) {
          return dispatch(
            actionOpen("반송반려 사유를 입력해 주세요.", null, "", "", true)
          );
        }

        // let exarr = appraisalRequestStatus.filter(
        //   (x) => x.status === "반송반려"
        // );

        // if (exarr.length) {
        //   try {
        //     for await (let i of exarr) {
        //       await dStatus(i.id);
        //     }
        //   } catch (error) {
        //     return dispatch(actionOpen("처리할 수 없습니다."));
        //   }
        // }

        return APIS.putAppraisalRequestStatus(
          id,
          r3 === "1"
            ? {
                status: "반송완료",
                returnCancelReason: "",
                password: adminPassword,
              }
            : {
                status: "반송반려",
                returnCancelReason: t3,
                password: adminPassword,
              },
          isKorda
        )
          .then(() => {
            history.replace({
              pathname: history.location.pathname,
              state: history.location.state,
            });

            return dispatch(
              actionOpen(
                r3 === "1"
                  ? "반송 완료 처리 되었습니다."
                  : "반송반려 되었습니다.",
                null,
                "",
                "",
                true
              )
            );
          })
          .catch((err) => {
            dispatch(actionError(err));
          });
      } else if (["반송대기"].includes(last.status)) {
        if (!r3) {
          return dispatch(
            actionOpen("반송 상태를 선택해 주세요.", null, "", "", true)
          );
        } else if (r3 === "2" && !t3) {
          return dispatch(
            actionOpen("반송반려 사유를 입력해 주세요.", null, "", "", true)
          );
        }

        // let exarr = appraisalRequestStatus.filter((x) => x.status === "반송반려");

        // if (exarr.length) {
        //   try {
        //     for await (let i of exarr) {
        //       await dStatus(i.id);
        //     }
        //   } catch (error) {
        //     return dispatch(actionOpen("처리할 수 없습니다."));
        //   }
        // }

        return APIS.putAppraisalRequestStatus(
          id,
          r3 === "1"
            ? {
                status: "반송완료",
                returnCancelReason: "",
                password: adminPassword,
              }
            : {
                status: "반송반려",
                returnCancelReason: t3,
                password: adminPassword,
              },
          isKorda
        )
          .then(() => {
            history.replace({
              pathname: history.location.pathname,
              state: history.location.state,
            });

            return dispatch(
              actionOpen(
                r3 === "1"
                  ? "반송 완료 처리 되었습니다."
                  : "반송반려 되었습니다.",
                null,
                "",
                "",
                true
              )
            );
          })
          .catch((err) => {
            dispatch(actionError(err));
          });
      } else if (["교환완료"].includes(last.status)) {
        if (!isKorda && !r3) {
          return dispatch(
            actionOpen("반송 상태를 선택해 주세요.", null, "", "", true)
          );
        } else if (!isKorda && r3 === "1" && !t3) {
          return dispatch(
            actionOpen("반송요청 사유를 입력해 주세요.", null, "", "", true)
          );
        }

        // let exarr = appraisalRequestStatus.filter((x) => x.status === "반송반려");
        // if (exarr.length) {
        //   try {
        //     for await (let i of exarr) {
        //       await dStatus(i.id);
        //     }
        //   } catch (error) {
        //     return dispatch(actionOpen("처리할 수 없습니다."));
        //   }
        // }

        return APIS.putAppraisalRequestStatus(
          id,
          r3 === "1"
            ? {
                status:
                  appraisalRequestStatus[appraisalRequestStatus.length - 1]
                    .status === "교환완료"
                    ? "반송대기"
                    : "반송완료",
                returnCancelReason:
                  appraisalRequestStatus[appraisalRequestStatus.length - 1]
                    .status === "교환완료"
                    ? t3
                    : "",
                appraiseGram:
                  appraisalRequestStatus[appraisalRequestStatus.length - 1]
                    .status === "교환완료"
                    ? appraisalRequestStatus.find(
                        (item) => item.status === "감정완료"
                      ).appraiseGram
                    : "",
                appraiseType:
                  appraisalRequestStatus[appraisalRequestStatus.length - 1]
                    .status === "교환완료"
                    ? appraisalRequestStatus.find(
                        (item) => item.status === "감정완료"
                      ).appraiseType
                    : "",
                password: adminPassword,
              }
            : null,
          isKorda
        )
          .then(() => {
            history.replace({
              pathname: history.location.pathname,
              state: history.location.state,
            });

            return dispatch(
              actionOpen(
                r3 === "1"
                  ? "반송 요청 처리 되었습니다."
                  : "반송반려 되었습니다.",
                null,
                "",
                "",
                true
              )
            );
          })
          .catch((err) => {
            dispatch(actionError(err));
          });
      }
    }
  };

  const reformData = (data) => {
    const copyData = [...data] || [];
    const parseData = [];
    copyData?.map((item, index) => {
      parseData.push({
        createdAt: formatTime(item.createdAt, "YYYY-MM-DD HH:mm:ss") || "-",
        status: item.status || "-",
        admin: item.admin || "-",
        appraiseType: item.appraiseType || "-",
        appraiseGram: item.appraiseGram || "-",
        appraiseMemo:
          appraisalRequestStatus[index].status === "감정반려"
            ? item.appraiseCancelReason || "-"
            : appraisalRequestStatus[index].status === "감정완료"
            ? item.appraiseMemo || "-"
            : appraisalRequestStatus[index].status === "반송반려"
            ? item.returnCancelReason || "-"
            : appraisalRequestStatus[index].status === "승인대기"
            ? item.appraiseMemo || "-"
            : appraisalRequestStatus[index].status === "승인반려"
            ? item.returnCancelReason || "-"
            : appraisalRequestStatus[index].status === "반송대기"
            ? item.returnCancelReason || "-"
            : appraisalRequestStatus[index].status === "감정시작"
            ? item.returnCancelReason || "-"
            : item.appraiseMemo || "-",
        managerName: item.managerName || "-",
      });
    });
    return parseData;
  };
  const parseData = reformData(appraisalRequestStatus);
  return (
    <>
      <Flex row>
        {/* <Button label="저장" onClick={onSave} /> */}
        <Grid container className={classes.grid}>
          {appraisalRequestStatus[appraisalRequestStatus.length - 1].status ===
          "접수" ? (
            <>
              <Grid
                style={{
                  borderTop: "1px solid rgb(224,224,224)",
                }}
                item
                className={classes.gridLabel}
                xs={2}
              >
                감정시작
              </Grid>
              <Grid
                // style={{
                //   borderTop: "1px solid rgb(224,224,224)",
                // }}
                className={classes.gridValue}
                item
                xs={10}
              >
                <RadioGroup
                  row
                  value={r1}
                  onChange={(e) => {
                    setT1("");
                    setR1(e.target.value);

                    setR2("");
                    setT2("");
                    setT4("");
                  }}
                >
                  <FormControlLabel
                    value={"1"}
                    disabled={
                      appraisalRequestStatus.filter(
                        (x) => x.status === "감정완료"
                      )[0]
                    }
                    label="감정시작"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={"2"}
                    disabled={
                      appraisalRequestStatus.filter(
                        (x) => x.status === "감정완료"
                      )[0]
                    }
                    label="감정반려"
                    control={<Radio />}
                  />
                </RadioGroup>

                <input
                  value={t1}
                  disabled={r1 === "1"}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setT1(e.target.value);
                  }}
                  placeholder="반려 사유 입력"
                  className={classes.input}
                />
              </Grid>
            </>
          ) : appraisalRequestStatus[appraisalRequestStatus.length - 1]
              .status === "감정시작" ? (
            <>
              <Grid item className={classes.gridLabel} xs={2}>
                감정결과
              </Grid>
              <Grid item xs={10} className={classes.gridValue}>
                <RadioGroup
                  value={r2}
                  onChange={(e) => {
                    setT1("");
                    setR1("1");
                    /*
              if (type === "은" && e.target.value === "1") {
                return actionOpen(
                  "은 제품 감정 접수건입니다. 은으로만 감정 완료 가능합니다.",
                  null,
                  "",
                  "",
                  true
                );
              } else if (type === "금" && e.target.value === "2") {
                return actionOpen(
                  "금 제품 감정 접수건입니다. 금으로만 감정 완료 가능합니다.",
                  null,
                  "",
                  "",
                  true
                );
              }*/
                    setR2(e.target.value);
                  }}
                  row
                >
                  <FormControlLabel
                    disabled={
                      appraisalRequestStatus[appraisalRequestStatus.length - 1]
                        .status !== "감정시작"
                    }
                    value={"1"}
                    label="금(GOLD)"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    disabled={
                      appraisalRequestStatus[appraisalRequestStatus.length - 1]
                        .status !== "감정시작"
                    }
                    value={"2"}
                    label="은(SILVER)"
                    control={<Radio />}
                  />
                </RadioGroup>
                <input
                  value={t4}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isNaN(value) || !r2) {
                      return false;
                    }

                    if (r2 === "1") {
                      // 금
                      setT4(value.replace(/(^\d*[.]\d{3}).*/, "$1"));
                    } else {
                      setT4(value.replace(/(^\d*[.]\d{1}).*/, "$1"));
                    }
                  }}
                  type="number"
                  placeholder="0.0g"
                  disabled={
                    appraisalRequestStatus[appraisalRequestStatus.length - 1]
                      .status !== "감정시작"
                  }
                  className={classes.input2}
                />

                <input
                  value={t2}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setT2(e.target.value);
                  }}
                  placeholder="감정내용 설명사항 입력(선택)"
                  disabled={
                    appraisalRequestStatus[appraisalRequestStatus.length - 1]
                      .status !== "감정시작"
                  }
                  style={{ marginLeft: 10 }}
                  className={classes.input}
                />
              </Grid>
            </>
          ) : appraisalRequestStatus[appraisalRequestStatus.length - 1]
              .status === "승인대기" ? (
            isKorda ? (
              <>
                <Grid item className={classes.gridLabel} xs={2}>
                  승인대기
                </Grid>
                <Grid item xs={10} className={classes.gridValue}>
                  <RadioGroup
                    value={approval}
                    onChange={(e) => {
                      setApproval(e.target.value);
                    }}
                    row
                  >
                    <FormControlLabel
                      disabled={
                        appraisalRequestStatus[
                          appraisalRequestStatus.length - 1
                        ].status !== "승인대기"
                      }
                      value={"1"}
                      label="승인완료"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      disabled={
                        appraisalRequestStatus[
                          appraisalRequestStatus.length - 1
                        ].status !== "승인대기"
                      }
                      value={"2"}
                      label="승인반려"
                      control={<Radio />}
                    />
                  </RadioGroup>
                  {approval === "1" ? (
                    <>
                      <input
                        value={approvalReason}
                        type="text"
                        autoComplete="off"
                        onChange={(e) => {
                          setApprovalReason(e.target.value);
                        }}
                        // disabled={approval === "2"}
                        placeholder="승인 사유 입력"
                        className={classes.input}
                      />
                      <input
                        style={{
                          position: "absolute",
                          opacity: 0,
                          width: 0,
                          height: 0,
                          zIndex: -10,
                        }}
                        type="text"
                        value=""
                        autoComplete="on"
                      />
                    </>
                  ) : (
                    <>
                      <input
                        value={t3}
                        type="text"
                        autoComplete="off"
                        onChange={(e) => {
                          setT3(e.target.value);
                        }}
                        // disabled={approval === "2"}
                        placeholder="반려 사유 입력"
                        className={classes.input}
                      />
                      <input
                        style={{
                          position: "absolute",
                          opacity: 0,
                          width: 0,
                          height: 0,
                          zIndex: -10,
                        }}
                        type="text"
                        value=""
                        autoComplete="on"
                      />
                    </>
                  )}
                </Grid>
              </>
            ) : (
              <></>
            )
          ) : appraisalRequestStatus[appraisalRequestStatus.length - 1]
              .status === "반송대기" ? (
            isKorda ? (
              <Flex row>
                <Grid item className={classes.gridLabel} xs={2}>
                  반송(돌려받기)
                </Grid>
                <Grid item xs={10} className={classes.gridValue}>
                  <RadioGroup
                    // value={r3}
                    onChange={(e) => {
                      setT3("");
                      setR3(e.target.value);
                    }}
                    row
                  >
                    <FormControlLabel
                      disabled={
                        !(
                          (appraisalRequestStatus.filter(
                            (x) => x.status === "반송신청"
                          ).length > 0 ||
                            appraisalRequestStatus.filter(
                              (x) => x.status === "감정완료"
                            ).length > 0) &&
                          appraisalRequestStatus.filter(
                            (x) => x.status === "반송완료"
                          ).length === 0
                        )
                      }
                      value={"1"}
                      label="반송완료"
                      control={<Radio />}
                    />
                    {isKorda ? (
                      <FormControlLabel
                        value={"2"}
                        disabled={
                          !(
                            appraisalRequestStatus.filter(
                              (x) => x.status === "반송신청"
                            ).length === 0 ||
                            appraisalRequestStatus.filter(
                              (x) => x.status === "반송완료"
                            ).length === 0 ||
                            appraisalRequestStatus.filter(
                              (x) => x.status === "감정완료"
                            ).length > 0
                          )
                        }
                        label="반송반려"
                        control={<Radio />}
                        // style={{ visibility: "hidden" }}
                      />
                    ) : (
                      <></>
                    )}
                  </RadioGroup>
                  <input
                    className={classes.input}
                    type="text"
                    autoComplete="off"
                    disabled={
                      !(
                        appraisalRequestStatus.filter(
                          (x) => x.status === "감정완료"
                        ).length > 0 && r3 === "2"
                      )
                    }
                    // value={t3}
                    onChange={(e) => {
                      setT3(e.target.value);
                    }}
                    placeholder="반송 반려 사유 입력"
                  />
                  <input
                    style={{
                      position: "absolute",
                      opacity: 0,
                      width: 0,
                      height: 0,
                      zIndex: -10,
                    }}
                    type="text"
                    value=""
                    autoComplete="on"
                  />
                </Grid>
              </Flex>
            ) : (
              //반송대기 상태일 때 본사인지 아닌지
              <></>
            )
          ) : appraisalRequestStatus[appraisalRequestStatus.length - 1]
              .status === "감정완료" ? (
            <></>
          ) : appraisalRequestStatus[appraisalRequestStatus.length - 1]
              .status === "반송신청" ? (
            isKorda ? (
              <Flex row>
                <Grid item className={classes.gridLabel} xs={2}>
                  반송(돌려받기)
                </Grid>
                <Grid item xs={10} className={classes.gridValue}>
                  <RadioGroup
                    // value={r3}
                    onChange={(e) => {
                      setT3("");
                      setR3(e.target.value);
                    }}
                    row
                  >
                    <FormControlLabel
                      disabled={
                        !(
                          (appraisalRequestStatus.filter(
                            (x) => x.status === "반송신청"
                          ).length > 0 ||
                            appraisalRequestStatus.filter(
                              (x) => x.status === "감정완료"
                            ).length > 0) &&
                          appraisalRequestStatus.filter(
                            (x) => x.status === "반송완료"
                          ).length === 0
                        )
                      }
                      value={"1"}
                      label="반송완료"
                      control={<Radio />}
                    />
                    {isKorda ? (
                      <FormControlLabel
                        value={"2"}
                        disabled={
                          appraisalRequestStatus[
                            appraisalRequestStatus.length - 1
                          ].status === "반송신청" ||
                          appraisalRequestStatus[
                            appraisalRequestStatus.length - 1
                          ].status === "반송완료" ||
                          appraisalRequestStatus[
                            appraisalRequestStatus.length - 1
                          ].status === "감정완료"
                        }
                        label="반송반려"
                        control={<Radio />}
                        // style={{ visibility: "hidden" }}
                      />
                    ) : (
                      <></>
                    )}
                  </RadioGroup>
                  <input
                    className={classes.input}
                    type="text"
                    autoComplete="off"
                    disabled={
                      !(
                        appraisalRequestStatus.filter(
                          (x) => x.status === "감정완료"
                        ).length > 0 && r3 === "2"
                      )
                    }
                    // value={t3}
                    onChange={(e) => {
                      setT3(e.target.value);
                    }}
                    placeholder="반송 반려 사유 입력"
                  />
                </Grid>
              </Flex>
            ) : (
              <Flex row>
                <Grid item className={classes.gridLabel} xs={2}>
                  반송(돌려받기)
                </Grid>
                <Grid item xs={10} className={classes.gridValue}>
                  <RadioGroup
                    // value={r3}
                    onChange={(e) => {
                      setT3("");
                      setR3(e.target.value);
                    }}
                    row
                  >
                    <FormControlLabel
                      disabled={
                        !(
                          (appraisalRequestStatus.filter(
                            (x) => x.status === "반송신청"
                          ).length > 0 ||
                            appraisalRequestStatus.filter(
                              (x) => x.status === "감정완료"
                            ).length > 0) &&
                          appraisalRequestStatus.filter(
                            (x) => x.status === "반송완료"
                          ).length === 0
                        )
                      }
                      value={"1"}
                      label="반송완료"
                      control={<Radio />}
                    />
                    {
                      <FormControlLabel
                        value={"2"}
                        disabled={
                          appraisalRequestStatus.filter(
                            (x) => x.status === "감정완료"
                          ).length > 0
                        }
                        label="반송반려"
                        control={<Radio />}
                        // style={{ visibility: "hidden" }}
                      />
                    }
                  </RadioGroup>
                  <input
                    className={classes.input}
                    type="text"
                    autoComplete="off"
                    disabled={
                      !(
                        appraisalRequestStatus.filter(
                          (x) => x.status === "감정완료"
                        ).length > 0 && r3 === "2"
                      )
                    }
                    // value={t3}
                    onChange={(e) => {
                      setT3(e.target.value);
                    }}
                    placeholder="반송 반려 사유 입력"
                  />
                </Grid>
              </Flex>
            )
          ) : appraisalRequestStatus[appraisalRequestStatus.length - 1]
              .status === "교환완료" ? (
            isKorda ? (
              <></>
            ) : (
              <Flex row>
                <Grid item className={classes.gridLabel} xs={2}>
                  반송(돌려받기)
                </Grid>
                <Grid item xs={10} className={classes.gridValue}>
                  <RadioGroup
                    // value={r3}
                    onChange={(e) => {
                      setT3("");
                      setR3(e.target.value);
                    }}
                    row
                  >
                    <FormControlLabel
                      value={"1"}
                      label="반송요청"
                      control={<Radio />}
                    />
                    {isKorda ? (
                      <FormControlLabel
                        value={"2"}
                        disabled={
                          appraisalRequestStatus.filter(
                            (x) => x.status === "감정완료"
                          ).length > 0
                        }
                        label="반송반려"
                        control={<Radio />}
                        // style={{ visibility: "hidden" }}
                      />
                    ) : (
                      <></>
                    )}
                  </RadioGroup>
                  <input
                    className={classes.input}
                    type="text"
                    autoComplete="nope"
                    disabled={
                      !(
                        appraisalRequestStatus.filter(
                          (x) => x.status === "감정완료"
                        ).length > 0 && r3 === "1"
                      )
                    }
                    value={t3}
                    onChange={(e) => {
                      setT3(e.target.value);
                    }}
                    placeholder="반송 요청 사유 입력"
                  />
                  <input
                    style={{
                      position: "absolute",
                      opacity: 0,
                      width: 0,
                      height: 0,
                      zIndex: -10,
                    }}
                    type="text"
                    value=""
                    autoComplete="on"
                  />
                </Grid>
              </Flex>
            )
          ) : (
            <></>
          )}
        </Grid>
        <Flex row style={{ marginTop: 15 }}>
          {isKorda &&
          (appraisalRequestStatus[appraisalRequestStatus.length - 1].status ===
            "승인대기" ||
            appraisalRequestStatus[appraisalRequestStatus.length - 1].status ===
              "반송대기") ? (
            <>
              <AdminConfirmButton
                popupLabel="감정평가"
                popupMessage="저장하시겠습니까?"
                label="저장"
                callback={onSaveAdmin}
                rootClassName={classes.confirmRoot}
                btnClassName={classes.confirmButton}
              />
              <Button
                onClick={history.goBack}
                label="취소"
                className={classes.bt1}
              />
            </>
          ) : !isKorda &&
            (appraisalRequestStatus[appraisalRequestStatus.length - 1]
              .status === "승인대기" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status === "반송대기" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status === "감정반려" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status === "반송반려" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status === "반송완료" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status === "감정완료") ? (
            <></>
          ) : isKorda &&
            (appraisalRequestStatus[appraisalRequestStatus.length - 1]
              .status === "감정반려" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status === "반송완료" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status === "반송반려" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status === "교환완료" ||
              appraisalRequestStatus[appraisalRequestStatus.length - 1]
                .status === "감정완료") ? (
            <></>
          ) : !isKorda &&
            appraisalRequestStatus[appraisalRequestStatus.length - 1].status ===
              "교환완료" ? (
            <>
              <AdminConfirmButton
                popupLabel="감정평가"
                popupMessage="저장하시겠습니까?"
                label="저장"
                callback={onSaveAdmin}
                rootClassName={classes.confirmRoot}
                btnClassName={classes.confirmButton}
              />
              <Button
                onClick={history.goBack}
                label="취소"
                className={classes.bt1}
              />
            </>
          ) : (
            <>
              <Button
                label="저장"
                className={classes.bt2}
                onClick={() => {
                  avoidDuplicateClicks(onSave);
                }}
              />
              <Button
                onClick={history.goBack}
                label="취소"
                className={classes.bt1}
              />
            </>
          )}
        </Flex>
      </Flex>

      <SimpleTable
        fullWidth
        columns={[
          {
            label: "일시",
            key: "createdAt",
          },
          {
            label: "상태",
            key: "status",
          },
          {
            label: "관리자",
            key: "managerName",
          },
          {
            label: "자산",
            key: "appraiseType",
          },
          {
            label: "중량",
            key: "appraiseGram",
          },
          {
            label: "비고",
            key: "appraiseMemo",
          },
        ].filter((x) => x)}
        data={parseData}
      />
    </>
  );
}

const useStyle = makeStyles({
  gridLabel: {
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    borderRight: "1px solid rgb(224,224,224)",
    borderBottom: "1px solid rgb(224,224,224)",
    borderLeft: "1px solid rgb(224,224,224)",
    borderTop: "1px solid rgb(224,224,224)",
    padding: 14,
  },
  gridValue: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    // borderRight: "1px solid rgb(224,224,224)",
    // borderBottom: "1px solid rgb(224,224,224)",
    // borderLeft: "1px solid rgb(224,224,224)",
    "& svg": {
      color: "#000",
    },
    "& .MuiFormControlLabel-root": {
      minWidth: 150,
    },
  },
  input: {
    flex: 1,
    marginRight: "20px",
    paddingLeft: "16px",
    // marginLeft: "100px",
    width: "800px",
    height: 40,
    border: "1px solid rgb(224,224,224)",
  },
  input2: {
    paddingLeft: "16px",
    width: 100,
    height: 40,
    border: "1px solid rgb(224,224,224)",
  },
  grid: {
    alignSelf: "stretch",
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 10,
    width: 1200,
  },
  calc: {
    margin: "0px 50px",
    textAlign: "right",
    marginBottom: 100,
    borderBottom: "1px solid rgb(224,224,224)",
  },
  images: { display: "flex", alignItems: "center", width: "100%" },
  image: {
    width: "80px",
    height: "80px",
    marginRight: 16,
    "&:last-child": { marginRight: 0 },
  },
  multiInput: {
    border: "1px  solid #ddd",
    minWidth: "70%",
    padding: 10,
    marginTop: 20,
  },
  img: {
    width: 40,
    height: 40,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    alignItems: "center",
    width: "auto !important",
    "& div": {},
  },
  mainC1Content: {
    padding: "10px 20px",
  },
  mainC2Content: {
    alignItems: "center",
    padding: 50,
  },
  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },
  buttons: {
    marginRight: 50,
    "& button": {
      minWidth: 50,
    },
    // marginTop: 30,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  header: {
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    alignSelf: "stretch",
    padding: 10,
  },
  mainC1: {
    marginLeft: 50,
    flex: 4,
    borderRight: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2: {
    marginRight: 50,
    flex: 3,
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
  bt1: {
    backgroundColor: "#fff",
    border: "1px solid black",
    color: "black",
    "& span": { color: "black" },
    marginRight: 50,
    minWidth: "70px !important",
    height: 38,
    alignItems: "center",
  },
  bt2: {
    backgroundColor: "black",
    color: "white",
    marginRight: 50,
    minWidth: "70px !important",
    height: 38,
    alignItems: "center",
  },
});
