import {
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import DateRange from "../../components/date-range/DateRange";
import Flex from "../../components/flex/Flex";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import colors from "../../libs/colors";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { useDispatch, useSelector } from "react-redux";
import ContextMenuLayout from "../../components/excel-download/ContextMenuLayout";
import { numberWithCommas } from "../../libs/utils";
import { objToQueryStr, formatTime } from "../../services/utils";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import Button from "../../components/button/Button";


export default function SettlementHistory({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { replace } = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const [filterState, setFilterState] = useState("");
  const [startDate, setstartDate] = useState(
    formatTime(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      "YYYY-MM-DD"
    )
  );
  const [endDate, setEndDate] = useState(formatTime(new Date(), "YYYY-MM-DD"));
  const [salesStatus, setSalesStatus] = useState([]); // 매출 현황 목록
  const [totalCount, setTotalCount] = useState(0);
  const [originData, setOriginData] = useState([]);
  const radioButtonData = [
    { label: "전체", value: "" },
    { label: "미완료", value: "NEW" },
    { label: "발행완료", value: "DONE" },
  ];
  const reformData = (data) => {
    const copyData = [...data] || [];
    const dayList = copyData?.map((item) => {
      return { day: item["date"] };
    });
    const deduplicationDay = dayList?.filter((dayName, idx, arr) => {
      return arr.findIndex((item) => item.day === dayName.day) === idx;
    });
    let parseData = [];
    let arrayData = [];

    deduplicationDay?.forEach((day) => {
      // day 날짜 필터링
      copyData?.forEach((item) => {
        if (item["date"] === day.day) {
          arrayData.push(item);
        }
      });
      parseData.push({ day: day.day, detail: arrayData });
      arrayData = [];
    });
    parseData?.map((item) => {
      if (item.detail.length > 1) {
        item.detail.push({
          date: null,
          id: "",
          salesReportAt: null,
          salesReportType: "TOTAL",
          settledAt: "",
          status: "",
          supplyPrice:
            item.detail[0]["supplyPrice"] + item.detail[1]["supplyPrice"],
          totalBuy: item.detail[0]["totalBuy"] + item.detail[1]["totalBuy"],
          totalFee: item.detail[0]["totalFee"] + item.detail[1]["totalFee"],
          totalGoods:
            item.detail[0]["totalGoods"] + item.detail[1]["totalGoods"],
          totalSelfDirect:
            item.detail[0]["totalSelfDirect"] +
            item.detail[1]["totalSelfDirect"],
          totalSell: item.detail[0]["totalSell"] + item.detail[1]["totalSell"],
          totalWithdraw:
            item.detail[0]["totalWithdraw"] + item.detail[1]["totalWithdraw"],
          vat: item.detail[0]["vat"] + item.detail[1]["vat"],
        });
      }
    });
    return parseData;
  };
  const reformExcel = (data) => {
    let excelData = [];
    data.forEach((item) => {
      item.detail.forEach((el, index) => {
        if (index === 0) {
          excelData.push({
            ...el,
            salesReportType: el.salesReportType
              .replace("SELF", "자진발행")
              .replace("CASH", "현금영수증")
              .replace("TOTAL", "합계"),
            settledAt: formatTime(el.settledAt, "YYYY.MM.DD HH:mm:SS"),
            status:
              el.salesReportType === "SELF"
                ? el.status.replace("NEW", "미완료").replace("DONE", "발행완료")
                : null,
            totalBuy: numberWithCommas(el.totalBuy),
            totalSell: numberWithCommas(el.totalSell),
            totalGoods: numberWithCommas(el.totalGoods),
            totalSelfDirect: numberWithCommas(el.totalSelfDirect),
            totalWithdraw: numberWithCommas(el.totalWithdraw),
            supplyPrice: numberWithCommas(el.supplyPrice),
            vat: numberWithCommas(el.vat),
            totalFee: numberWithCommas(el.totalFee),
          });
        } else {
          if (item.detail[0].date === el.date) {
            excelData.push({
              ...el,
              date: "",
              salesReportType: el.salesReportType
                .replace("SELF", "자진발행")
                .replace("CASH", "현금영수증")
                .replace("TOTAL", "합계"),
              settledAt: formatTime(el.settledAt, "YYYY.MM.DD HH:mm:SS"),
              status:
                el.salesReportType === "SELF"
                  ? el.status
                      .replace("NEW", "미완료")
                      .replace("DONE", "발행완료")
                  : null,
              totalBuy: numberWithCommas(el.totalBuy),
              totalSell: numberWithCommas(el.totalSell),
              totalGoods: numberWithCommas(el.totalGoods),
              totalSelfDirect: numberWithCommas(el.totalSelfDirect),
              totalWithdraw: numberWithCommas(el.totalWithdraw),
              supplyPrice: numberWithCommas(el.supplyPrice),
              vat: numberWithCommas(el.vat),
              totalFee: numberWithCommas(el.totalFee),
            });
          } else {
            excelData.push({
              ...el,
              salesReportType: el.salesReportType
                .replace("SELF", "자진발행")
                .replace("CASH", "현금영수증")
                .replace("TOTAL", "합계"),
              settledAt: formatTime(el.settledAt, "YYYY.MM.DD HH:mm:SS"),
              status:
                el.salesReportType === "SELF"
                  ? el.status
                      .replace("NEW", "미완료")
                      .replace("DONE", "발행완료")
                  : null,
              totalBuy: numberWithCommas(el.totalBuy),
              totalSell: numberWithCommas(el.totalSell),
              totalGoods: numberWithCommas(el.totalGoods),
              totalSelfDirect: numberWithCommas(el.totalSelfDirect),
              totalWithdraw: numberWithCommas(el.totalWithdraw),
              supplyPrice: numberWithCommas(el.supplyPrice),
              vat: numberWithCommas(el.vat),
              totalFee: numberWithCommas(el.totalFee),
            });
          }
        }
      });
    });
    return excelData;
  };

  const fetchList = () => {
    const radioStatus = state.radioStatus === "" ? null : state.radioStatus;
    const param = {
      from: startDate,
      to: endDate,
      status: radioStatus,
    };
    const startDay = new Date(startDate);
    const endDay = new Date(endDate);
    const dateDiff = Math.ceil(
      (endDay.getTime() - startDay.getTime()) / (1000 * 3600 * 24)
    );
    if (dateDiff > 92) {
      dispatch(actionError("검색 가능 기간은 최대 3개월입니다."));
    } else {
      dispatch(loadingStart);
      APIS.getSalesStatusList(objToQueryStr(param))
        .then(({ data: { data, message, success } }) => {
          if (success) {
            setSalesStatus(reformData(data));
            setTotalCount(reformData(data).length);
            setOriginData(reformExcel(reformData(data)));
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };
 
  useEffect(() => {
    handleValueChange("radioStatus", "");
  }, []);

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
      });
    }
  }, [state.tab, startDate, endDate, state.radioStatus]);

  return (
    <>
      <LabelTabs
        data={[
          { label: "매출현황", value: "1" },
          // { label: "매입현황", value: "2" },
        ]}
      />
      <Flex row className={classes.toolbar}>
        <Flex className={classes.dateRange}>
          <DateRange
            startDate={setstartDate}
            endDate={setEndDate}
            period={["week", "month", "month3"]}
          />
        </Flex>
        <Flex className={classes.radioGroup}>
          <RadioGroup
            row
            value={filterState}
            onChange={(e) => {
              handleValueChange("radioStatus", e.target.value);
              setFilterState(e.target.value);
            }}
          >
            {radioButtonData?.map((radio, index) => {
              return (
                <FormControlLabel
                  key={index.toString()}
                  value={radio.value}
                  control={
                    <Radio color="default" style={{ color: colors.black }} />
                  }
                  label={radio.label}
                />
              );
            })}
          </RadioGroup>
        </Flex>
        <Flex>
          <Button
            label="검색"
            className={classes.searchButton}
            onClick={fetchList}
          />
        </Flex>
      </Flex>
      <Flex row className={classes.tableHeader}>
        <Flex>
          <Flex row className={"row-center"}>
            <Text font={fonts.notoSansKRBold}>목록 검색결과 {totalCount}</Text>
          </Flex>
        </Flex>
      </Flex>
      <SalesStatusTable
        data={salesStatus?.map((row, rowIndex) => {
          let status = "";
          let salesReportType = "";
          switch (row.detail.status) {
            case "NEW":
              status = "미완료";
              break;
            case "DONE":
              status = "발행완료";
              break;
          }
          switch (row.detail.salesReportType) {
            case "SELF":
              salesReportType = "자진발행";
              break;
            case "CASH":
              salesReportType = "현금영수증";
              break;
            case "TOTAL":
              salesReportType = "합계";
              break;
          }
          return {
            ...row,
            status,
            salesReportType,
          };
        })}
        fetchList={fetchList}
        originData={originData}
      />
    </>
  );
}

const SalesStatusTable = ({ data, fetchList, originData }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const handleClickSave = (adminPassword, id) => {
    const param = {
      idList: [id],
      status: "DONE",
    };
    param["password"] = adminPassword;

    dispatch(loadingStart);
    APIS.getSalesStatusChange(param)
      .then(({ data: { success, message } }) => {
        if (!success) {
          dispatch(actionOpen(message, null, null, null, true));
        } else {
          dispatch(
            actionOpen("저장이 완료되었습니다.", null, null, null, true)
          );
          fetchList();
        }
      })
      .finally(() => dispatch(loadingEnd));
    // }
  };
  //state.tab으로 매출현황, 매입현황 내용 구분지어 주기
  const headerRows = [
    [
      { label: "날짜", rowSpan: 2 },
      { label: "구분", rowSpan: 2 },
      { label: "매도", rowSpan: 2 },
      { label: "매수", rowSpan: 2 },
      { label: "실물인출", rowSpan: 2 },
      { label: "안심직거래", rowSpan: 2 },
      { label: "출금", rowSpan: 2 },
      { label: "총 매출 수수료", colSpan: 3 },
      { label: "처리일시", rowSpan: 2 },
      { label: "처리완료", rowSpan: 2 },
      { label: "확인", rowSpan: 2, width: 50 },
    ],
    [{ label: "공급가" }, { label: "부가세" }, { label: "합계" }],
  ];

  const columns = [
    { key: "date", label: "날짜", align: "center" },
    { key: "salesReportType", label: "구분", align: "center" },
    { key: "totalBuy", label: "매도", align: "center", type: "number" },
    { key: "totalSell", label: "매수", align: "center", type: "number" },
    { key: "totalGoods", label: "실물인출", align: "center", type: "number" },
    {
      key: "totalSelfDirect",
      label: "안심직거래",
      align: "center",
      type: "number",
    },
    { key: "totalWithdraw", label: "출금", align: "center", type: "number" },
    { key: "supplyPrice", label: "공급가", align: "center", type: "number" },
    { key: "vat", label: "부가세", align: "center", type: "number" },
    { key: "totalFee", label: "합계", align: "center", type: "number" },
    { key: "settledAt", label: "처리일시", align: "center", type: "date" },
    { key: "status", label: "처리완료", align: "center" },
  ];
  return (
    <Flex style={{ margin: "0 50px 0 50px" }}>
      <ContextMenuLayout
        name="매출현황"
        headers={columns}
        data={originData}
        isMerge={true}
      >
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              {headerRows?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {row.map(
                      ({ label, rowSpan, colSpan, width, render }, index) => {
                        return (
                          <TableCell
                            key={index}
                            rowSpan={rowSpan}
                            colSpan={colSpan}
                            width={width}
                          >
                            {render || label}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody style={{ background: "white" }}>
              {data?.map((row, index) => {
                return (
                  <TableRow
                    key={index}
                    className={
                      row.detail["salesReportType"] === "합계"
                        ? classes.total
                        : row.detail["date"]?.split("-")[2] % 2 !== 0
                        ? classes.even
                        : classes.odd
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell
                      style={{
                        rowSpan: `${row.detail.length + 1}`,
                        borderRight: "1px solid #e0e0e0",
                      }}
                      align={"center"}
                    >
                      {row.day}
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((detail, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderBottom: "none",
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {detail.salesReportType
                                  .replace("SELF", "자진발행")
                                  .replace("CASH", "현금영수증")
                                  .replace("TOTAL", "합계")}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((detail, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderBottom: "none",
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {numberWithCommas(detail.totalBuy)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((detail, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderBottom: "none",
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {numberWithCommas(detail.totalSell)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((detail, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderBottom: "none",
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {numberWithCommas(detail.totalGoods)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((detail, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderBottom: "none",
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {numberWithCommas(detail.totalSelfDirect)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((detail, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderBottom: "none",
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {numberWithCommas(detail.totalWithdraw)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((detail, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderBottom: "none",
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {numberWithCommas(detail.supplyPrice)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((detail, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderBottom: "none",
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {numberWithCommas(detail.vat)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((detail, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderBottom: "none",
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {numberWithCommas(detail.totalFee)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((detail, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderBottom: "none",
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {formatTime(
                                  detail.settledAt,
                                  "YYYY.MM.DD HH:mm:SS"
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none", height: "44px" }}>
                        {row?.detail.map((detail, index) => {
                          return detail.salesReportType === "SELF" ? (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              >
                                {detail.status
                                  .replace("NEW", "미완료")
                                  .replace("DONE", "발행완료")}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  height: "46px",
                                  borderRight: "none",
                                }}
                              />
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                    <TableCell style={{ padding: "0px" }}>
                      <Table style={{ borderRight: "none" }}>
                        {row?.detail.map((data, index) => {
                          return data.status === "NEW" &&
                            data.salesReportType === "SELF" ? (
                            <TableRow key={index}>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderLeft: "none",
                                  borderRight: "none",
                                  height: "46px",
                                }}
                              >
                                {isKorda && (
                                  <AdminConfirmButton
                                    rootClassName={classes.confirmRoot}
                                    btnClassName={classes.confirmButton}
                                    popupMessage="변경하시겠습니까?"
                                    label="확인"
                                    popupLabel="발행여부 상태 변경"
                                    selectItem={data.id}
                                    callback={handleClickSave}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell
                                align={"center"}
                                style={{
                                  borderLeft: "none",
                                  borderRight: "none",
                                  height: "46px",
                                }}
                              />
                            </TableRow>
                          );
                        })}
                      </Table>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ContextMenuLayout>
    </Flex>
  );
};

const useStyle = makeStyles({
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  label: {
    marginLeft: 50,
    "& span": { fontSize: 14, color: "#444" },
  },
  tableHeader: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "flex-end",
    margin: "10px 50px",
  },
  tableContainer: {
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    "& th, td": {
      padding: 5,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      fontFamily: fonts.notoSansKRBold,
      backgroundColor: "#f5f5f5",
      textAlign: "center",
    },
    "& tr:hover": {
      backgroundColor: "#ededed",
    },
  },
  radioGroup: {
    marginLeft: 150,
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
  },
  confirmButton: {
    minWidth: 70,
    height: 33,
    width: 40,
    marginLeft: 0,
  },
  toolbar: {
    marginTop: 50,
    alignSelf: "stretch",
    alignItems: "center",
    margin: "0px 50px",
  },
  total: {
    background: "darkgray",
  },
  button: {
    backgroundColor: "#000",
    width: 100,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  searchButton: {
    marginLeft: 10,
    height: 35,
    marginRight: 40,
  },
});
