import { makeStyles } from "@material-ui/core";
import React from "react";

export default function Flex({
  onScroll,
  children,
  onClick,
  innerRef,
  row,
  className,
  onTouchEnd,
  style,
  onKeyPress,
  onContextMenu,
}) {
  const classes = useStyle();
  return (
    <div
      onClick={onClick}
      style={style}
      ref={innerRef}
      onTouchEnd={onTouchEnd}
      onScroll={onScroll}
      className={`${classes.root} ${row ? classes.rootRow : ""} ${className}`}
      onKeyPress={onKeyPress}
      onContextMenu={onContextMenu}
    >
      {children}
    </div>
  );
}

const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  rootRow: {
    flexDirection: "row",
  },
});
