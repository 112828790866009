import {
  ButtonBase,
  Grid,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  CardMedia,
  Popover,
} from "@material-ui/core";
import Text from "../../components/text/Text";
import { Add, ExpandMore } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState, useRef, Fragment } from "react";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import Flex from "../../components/flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import * as APIS from "../../libs/apis";
import { openModal, closeModal } from "../../redux/modal/ModalReducer";
import ButtonNormal from "../../components/button/Button";
import {
  PanoramaOutlined,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@material-ui/icons";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import consts from "../../libs/consts";
import { actionOpen, actionError } from "../../redux/action/ActionActions";
import { numberWithCommas } from "../../libs/utils";
import { openImageView } from "../../redux/image-viewer/ImageViewerReducer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ConfirmButton from "../../components/button/Button";
import SimpleTable from "../../components/simple-table/SimpleTable";

export default function ProductRegisterInfo(props) {
  const { list, setList, recentStatus, isKorda } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const classes = useStyle();
  const firstRef = useRef(true);
  const moveRef = useRef(true);
  const registerInfo = list;
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [saleStartDate, setSaleStartDate] = useState("");
  const [saleEndDate, setSaleEndDate] = useState("");
  const [inventoryLocation, setInventoryLocation] = useState("");
  const [productInfo, setProductInfo] = useState("");
  const [activeImageList, setActiveImageList] = useState([]);
  const [hideImageList, setHideImageList] = useState([]);
  const editAbleStatus = [
    "INBOUND_WAIT",
    "INBOUND_COMPLETE",
    // "INBOUND_CANCEL",
    "VERIFY_WAIT",
    "RETURN",
    "VERIFY_COMPLETE",
    "SELL_CONFIRM_WAIT",
    "SELL_WAIT",
  ];
  //grid-dnd
  const [items, setItems] = useState({
    activeImageList: activeImageList,
    hideImageList: hideImageList,
  });
  const [salePriceHistory, setSalePriceHistory] = useState(null);
  const [saleDateHistory, setSaleDateHistory] = useState(null);
  const [locationHistory, setLocationHistory] = useState(null);
  const [salePriceHistoryList, setSalePriceHistoryList] = useState([]);
  const [saleDateHistoryList, setSaleDateHistoryList] = useState([]);
  const [locationHistoryList, setLocationHistoryList] = useState([]);

  //popover
  const handleClickSalePrice = (event) => {
    setSalePriceHistory(event.currentTarget);
    // 판매가 변경 이력 조회
    APIS.getSalePriceHistory(state.itemId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setSalePriceHistoryList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };
  const handleCloseSalePrice = () => {
    setSalePriceHistory(null);
  };

  const handleClickSaleDate = (event) => {
    setSaleDateHistory(event.currentTarget);
    //판매기간 변경 이력 조회
    APIS.getSaleDateHistory(state.itemId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setSaleDateHistoryList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };
  const handleCloseSaleDate = () => {
    setSaleDateHistory(null);
  };

  const handleClickLocation = (event) => {
    setLocationHistory(event.currentTarget);
    //재고위치 변경 이력 조회
    APIS.getLocationHistory(state.itemId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setLocationHistoryList(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };
  const handleCloseLocation = () => {
    setLocationHistory(null);
  };

  const openSalePriceHistory = Boolean(salePriceHistory);
  const openSaleDateHistory = Boolean(saleDateHistory);
  const openLocationHistory = Boolean(locationHistory);

  const salePriceId = openSalePriceHistory ? "salePriceHistory" : undefined;
  const saleDateId = openSaleDateHistory ? "saleDateHistory" : undefined;
  const locationId = open ? "locationHistory" : undefined;

  //필드 추가하기
  const addItem = () => {
    const arr = additionalInfo.concat({ name: "" });
    setAdditionalInfo(arr);
  };

  const deleteAdditionalInfo = (itemId, infoId) => {
    dispatch(loadingStart);
    APIS.deleteAdditionalInfo(itemId, infoId)
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setAdditionalInfo(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  // 정렬 이벤트
  const handleClickSort = (index, targetIndex) => {
    const newArray = [...additionalInfo];
    const tmpInfo = newArray[index];
    const tmpInfoSort = newArray[index].sort;
    const tmpTargetInfo = newArray[targetIndex];

    tmpInfo.sort = tmpTargetInfo.sort;
    tmpTargetInfo.sort = tmpInfoSort;

    newArray[index] = newArray[targetIndex];
    newArray[targetIndex] = tmpInfo;

    setAdditionalInfo(newArray);
  };

  //이미지 추가
  const handleClickImageAdd = () => {
    dispatch(
      openModal({
        visiable: true,
        children: (
          <AddImage setImageList={(value) => setActiveImageList(value)} />
        ),
      })
    );
  };

  //dnd onChange
  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    moveRef.current = false;
    // 다른 배열 간의 이동
    if (targetId) {
      const result = move(
        items[sourceId],
        items[targetId],
        sourceIndex,
        targetIndex
      );
      return setItems({
        ...items,
        [sourceId]: result[0].map((item, i) => {
          return {
            ...item,
            sort: i + 1,
          };
        }),
        [targetId]: result[1].map((item, i) => {
          return {
            ...item,
            sort: i + 1,
            isHide: targetId === "activeImageList" ? false : true,
          };
        }),
      });
    }
    // 같은 배열 안에서의 이동
    if (sourceId) {
      const result = swap(items[sourceId], sourceIndex, targetIndex);
      return setItems({
        ...items,
        [sourceId]: result.map((item, i) => {
          return {
            ...item,
            sort: i + 1,
            isHide: sourceId === "activeImageList" ? false : true,
          };
        }),
      });
    }
  };

  // 이미지 삭제(활성/비활성) 기능   --- dnd 기능을 사용하여 바꾼 다음에 저장할 때 상태값과 정렬값을 넣어줄 것이기 때문에 사용할 일이 없어졌다.
  // const deleteImage = (itemId, imageId) => {
  //   dispatch(loadingStart);
  //   APIS.deleteRegisterImage(itemId, imageId)
  //     .then(({ data: { data, message, success } }) => {
  //       if (success) {
  //         setItems({
  //           activeImageList: [data.filter((item) => item.isHide === false)],
  //           hideImageList: [data.filter((item) => item.isHide === true)],
  //         });
  //         setActiveImageList(data.filter((item) => item.isHide === false));
  //         setHideImageList(data.filter((item) => item.isHide === true));
  //       } else {
  //         dispatch(actionError(message));
  //       }
  //     })
  //     .catch((error) => dispatch(actionError(error)))
  //     .finally(() => dispatch(loadingEnd));
  // };

  //관리자 비밀번호 저장
  const onSaveAdmin = async (adminPassword) => {
    if (editAbleStatus.includes(recentStatus) === false) {
      dispatch(
        actionError("현재 진행 상태에서는 등록정보를 저장할 수 없습니다.")
      );
    } else if (saleStartDate === "" || saleStartDate === null) {
      dispatch(actionError("판매기간 시작일을 입력하여 주세요."));
    } else if (saleEndDate === "" || saleEndDate === null) {
      dispatch(actionError("판매기간 종료일을 입력하여 주세요."));
    } else if (inventoryLocation === "" || inventoryLocation === null) {
      dispatch(actionError("재고위치를 입력하여 주세요."));
    } else if (additionalInfo.length === 0) {
      dispatch(actionError("추가정보를 등록하여 주세요."));
    } else if (activeImageList.length === 0) {
      dispatch(actionError("상품이미지를 추가하여 주세요."));
    } else if (productInfo === "" || productInfo === null) {
      dispatch(actionError("상품설명을 입력하여 주세요."));
    } else {
      const data = {
        startDate: saleStartDate,
        endDate: saleEndDate,
        inventoryLocation: inventoryLocation,
        infoList: additionalInfo,
        imageSortList: [...items["activeImageList"], ...items["hideImageList"]],
        info: productInfo,
        managerPassword: adminPassword,
      };
      dispatch(loadingStart);
      APIS.postRegisterInfo(state.itemId, data)
        .then(({ data: { data, message, success } }) => {
          if (success) {
            dispatch(
              actionOpen(
                "제품 등록 정보가 변경되었습니다.",
                () => {
                  history.replace({
                    pathname: history.location.pathname,
                    state: { ...state, itemId: state.itemId },
                  });
                },
                null,
                null,
                true
              )
            );
            setList(data);
            setSaleStartDate(data?.tradeFee?.startDate);
            setSaleEndDate(data?.tradeFee?.endDate);
            setInventoryLocation(data?.tradeFee?.inventoryLocation);
            setAdditionalInfo(data?.infoList);
            setActiveImageList(data?.imageActiveList);
            setHideImageList(data?.imageHideList);
            setProductInfo(data?.info);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  useEffect(() => {
    Promise.all([
      setSaleStartDate(registerInfo?.tradeFee?.startDate),
      setSaleEndDate(registerInfo?.tradeFee?.endDate),
      setInventoryLocation(registerInfo?.tradeFee?.inventoryLocation),
      setAdditionalInfo(registerInfo?.infoList),
      setActiveImageList(registerInfo?.imageActiveList),
      setHideImageList(registerInfo?.imageHideList),
      setProductInfo(registerInfo?.info),
    ]).finally(() => dispatch(loadingEnd));
  }, [list]);

  useEffect(() => {
    setItems({
      activeImageList: activeImageList,
      hideImageList: hideImageList,
    });
  }, [activeImageList, hideImageList]);

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
    } else {
      setTimeout(() => {
        moveRef.current = true;
      }, 500);
    }
  }, [items]);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel3a-content"
        id="panel3a-header"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Typography style={{ fontWeight: "bold" }}>제품 등록 정보</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Flex>
          <Flex>
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell rowSpan={2}>판매가격</TableCell>
                  <TableCell>
                    <Flex row>
                      <Typography>
                        {numberWithCommas(registerInfo?.tradeFee?.price) +
                          " 원"}
                      </Typography>
                      <Button
                        aria-describedby={salePriceId}
                        variant="outlined"
                        onClick={handleClickSalePrice}
                        style={{ marginLeft: 15 }}
                      >
                        이력
                      </Button>
                      <Popover
                        id={salePriceId}
                        open={openSalePriceHistory}
                        anchorEl={salePriceHistory}
                        onClose={handleCloseSalePrice}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Flex row className={classes.popopHeader}>
                          <Text font={fonts.notoSansKRBold}>
                            {"판매가격 이력"}
                          </Text>
                        </Flex>
                        <Flex className={classes.main}>
                          <Flex row className={classes.mainInput}>
                            <SimpleTable
                              fullWidth
                              columns={[
                                {
                                  label: "일시",
                                  key: "createdAt",
                                  type: "datetime",
                                },
                                {
                                  label: "변경내용",
                                  key: "price",
                                  type: "number",
                                },
                              ].filter((x) => x)}
                              data={salePriceHistoryList}
                            />
                          </Flex>
                          <ConfirmButton
                            className={classes.cancelButton}
                            label="닫기"
                            onClick={handleCloseSalePrice}
                          />
                        </Flex>
                      </Popover>
                    </Flex>
                  </TableCell>
                  <TableCell>
                    <TableRow>판매 수수료 금액</TableRow>
                    <TableRow>구매 수수료 금액</TableRow>
                  </TableCell>
                  <TableCell>
                    <TableRow>
                      <Flex row>
                        <Typography>
                          {numberWithCommas(registerInfo?.tradeFee?.sellFee) +
                            " 원"}
                        </Typography>
                      </Flex>
                    </TableRow>
                    <TableRow>
                      <Flex row>
                        <Typography>
                          {numberWithCommas(registerInfo?.tradeFee?.buyFee) +
                            " 원"}
                        </Typography>
                      </Flex>
                    </TableRow>
                  </TableCell>
                </TableRow>
                <TableRow></TableRow>
                <TableRow>
                  <TableCell>판매 추천 매장 수수료</TableCell>
                  <TableCell>
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.sellRecommendShopFee +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.sellRecommendShopFee
                            ).toFixed()
                          ) +
                          " 원"}
                      </Typography>
                    </Flex>
                  </TableCell>
                  <TableCell>구매 추천 매장 수수료</TableCell>
                  <TableCell>
                    <Typography>
                      {registerInfo?.tradeFee?.buyRecommendShopFee +
                        "%" +
                        "   /   " +
                        numberWithCommas(
                          (
                            (registerInfo?.tradeFee?.price / 100) *
                            registerInfo?.tradeFee?.buyRecommendShopFee
                          ).toFixed()
                        ) +
                        " 원"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>판매 접수 매장 수수료</TableCell>
                  <TableCell>
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.sellShopFee +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.sellShopFee
                            ).toFixed()
                          ) +
                          " 원"}
                      </Typography>
                    </Flex>
                  </TableCell>
                  <TableCell>출고 매장 수수료</TableCell>
                  <TableCell>
                    <Typography>
                      {registerInfo?.tradeFee?.buyShopFee +
                        "%" +
                        "   /   " +
                        numberWithCommas(
                          (
                            (registerInfo?.tradeFee?.price / 100) *
                            registerInfo?.tradeFee?.buyShopFee
                          ).toFixed()
                        ) +
                        " 원"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>판매철회 위약금</TableCell>
                  <TableCell>
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.sellerPenalty +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.sellerPenalty
                            ).toFixed()
                          ) +
                          `원    (최소금액 : ${registerInfo?.tradeFee?.sellerMinPenalty}원)`}
                      </Typography>
                    </Flex>
                  </TableCell>
                  <TableCell>반품신청 위약금</TableCell>
                  <TableCell>
                    <Typography>
                      {registerInfo?.tradeFee?.buyerPenalty +
                        "%" +
                        "   /   " +
                        numberWithCommas(
                          (
                            (registerInfo?.tradeFee?.price / 100) *
                            registerInfo?.tradeFee?.buyerPenalty
                          ).toFixed()
                        ) +
                        `원    (최소금액 : ${registerInfo?.tradeFee?.buyerMinPenalty}원)`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>판매 기간</TableCell>
                  <TableCell>
                    <Flex row>
                      <input
                        disabled={
                          isKorda &&
                          editAbleStatus.includes(recentStatus) === true
                            ? false
                            : true
                        }
                        value={saleStartDate}
                        onChange={(e) => {
                          setSaleStartDate(e.target.value);
                        }}
                        type="date"
                        className={classes.datepicker}
                      />
                      <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                        ~
                      </span>
                      <input
                        disabled={
                          isKorda &&
                          editAbleStatus.includes(recentStatus) === true
                            ? false
                            : true
                        }
                        value={saleEndDate}
                        onChange={(e) => {
                          setSaleEndDate(e.target.value);
                        }}
                        type="date"
                        className={classes.datepicker}
                      />
                      <Button
                        aria-describedby={saleDateId}
                        variant="outlined"
                        onClick={handleClickSaleDate}
                        style={{ marginLeft: 15 }}
                      >
                        이력
                      </Button>
                      <Popover
                        id={saleDateId}
                        open={openSaleDateHistory}
                        anchorEl={saleDateHistory}
                        onClose={handleCloseSaleDate}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Flex row className={classes.popopHeader}>
                          <Text font={fonts.notoSansKRBold}>
                            {"판매기간 이력"}
                          </Text>
                        </Flex>
                        <Flex className={classes.main}>
                          <Flex row className={classes.mainInput}>
                            <SimpleTable
                              fullWidth
                              columns={[
                                {
                                  label: "일시",
                                  key: "createdAt",
                                  type: "datetime",
                                },
                                {
                                  label: "변경내용",
                                  key: "content",
                                },
                                {
                                  label: "관리자",
                                  key: "managerName",
                                },
                              ].filter((x) => x)}
                              data={saleDateHistoryList}
                            />
                          </Flex>
                          <ConfirmButton
                            className={classes.cancelButton}
                            label="닫기"
                            onClick={handleCloseSaleDate}
                          />
                        </Flex>
                      </Popover>
                    </Flex>
                  </TableCell>
                  <TableCell>재고위치</TableCell>
                  <TableCell>
                    <Flex row>
                      <input
                        disabled={
                          isKorda &&
                          editAbleStatus.includes(recentStatus) === true
                            ? false
                            : true
                        }
                        placeholder="재고위치 입력"
                        style={{ height: "40px", width: "250px" }}
                        value={inventoryLocation}
                        onChange={(e) => {
                          setInventoryLocation(e.target.value);
                        }}
                      />
                      <Button
                        aria-describedby={locationId}
                        variant="outlined"
                        style={{ marginLeft: 15 }}
                        onClick={handleClickLocation}
                      >
                        이력
                      </Button>
                      <Popover
                        id={locationId}
                        open={openLocationHistory}
                        anchorEl={locationHistory}
                        onClose={handleCloseLocation}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Flex row className={classes.popopHeader}>
                          <Text font={fonts.notoSansKRBold}>
                            {"재고위치 이력"}
                          </Text>
                        </Flex>
                        <Flex className={classes.main}>
                          <Flex row className={classes.mainInput}>
                            <SimpleTable
                              fullWidth
                              columns={[
                                {
                                  label: "일시",
                                  key: "createdAt",
                                  type: "datetime",
                                },
                                {
                                  label: "변경내용",
                                  key: "content",
                                },
                                {
                                  label: "관리자",
                                  key: "managerName",
                                },
                              ].filter((x) => x)}
                              data={locationHistoryList}
                            />
                          </Flex>
                          <ConfirmButton
                            className={classes.cancelButton}
                            label="닫기"
                            onClick={handleCloseLocation}
                          />
                        </Flex>
                      </Popover>
                    </Flex>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Flex>
          <Flex style={{ marginBottom: 30 }}>
            <Grid item xs={12} style={{ flexDirection: "column" }}>
              <Grid
                item
                xs={12}
                style={{
                  alignSelf: "stretch",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className={[classes.dfsc, classes.subtitle].join(" ")}
              >
                <Typography variant="subtitle1" className={classes.bold}>
                  상품이미지
                </Typography>
                <ButtonBase
                  disabled={
                    isKorda && editAbleStatus.includes(recentStatus) === true
                      ? false
                      : true
                  }
                  style={{ marginRight: 20 }}
                  onClick={handleClickImageAdd}
                >
                  <Add />
                  이미지 추가
                </ButtonBase>
              </Grid>
              <Divider className={classes.divider} />
              <GridContextProvider onChange={onChange}>
                <div className={classes.container}>
                  <Flex row>
                    <Flex>
                      <Flex>
                        <Typography
                          className={classes.bold}
                          style={{ marginLeft: 20, marginBottom: 5 }}
                        >
                          활성
                        </Typography>
                      </Flex>
                      <GridDropZone
                        disableDrag={
                          isKorda &&
                          editAbleStatus.includes(recentStatus) === true
                            ? false
                            : true
                        }
                        className={classes.dropzoneActive}
                        id="activeImageList"
                        boxesPerRow={4}
                        rowHeight={150}
                        // style={{ overflowX: "scroll", width: "100%", display: "flex !important" }}
                      >
                        {items.activeImageList?.map((item) => (
                          <GridItem
                            key={item.imageId}
                            // style={{ margin: "0px 5px" }}
                          >
                            {/* <Flex columns>
                              <Flex row style={{ height: "150px"}}>
                                <CardMedia
                                  component="img"
                                  image={
                                    consts.s3BaseUrl + "/" + item?.imageUrl
                                  }
                                  onClick={(e) => {
                                    if (moveRef.current) {
                                      dispatch(
                                        openImageView({
                                          src:
                                            consts.s3BaseUrl +
                                            "/" +
                                            item?.imageUrl,
                                        })
                                      );
                                    }
                                  }}
                                  style={{
                                    borderRadius: 10,
                                    maxHeight: 150,
                                    maxWidth: 120,
                                    webkitUserDrag: "none",
                                  }}
                                />
                              </Flex>
                              <Flex row>{item.imageName}</Flex>
                            </Flex> */}
                            <Flex className={classes.imgWrap}>
                              <CardMedia
                                component="img"
                                image={consts.s3BaseUrl + "/" + item?.imageUrl}
                                onClick={(e) => {
                                  if (moveRef.current) {
                                    dispatch(
                                      openImageView({
                                        src:
                                          consts.s3BaseUrl +
                                          "/" +
                                          item?.imageUrl,
                                      })
                                    );
                                  }
                                }}
                                style={{
                                  borderRadius: 10,
                                  width: 100,
                                  height: 150,
                                  maxHeight: 100,
                                  maxWidth: 100,
                                  webkitUserDrag: "none",
                                }}
                              />
                              <Flex row className={classes.imgTitle}>
                                {item.imageName}
                              </Flex>
                            </Flex>
                          </GridItem>
                        ))}
                      </GridDropZone>
                    </Flex>
                    <Flex>
                      <Flex>
                        <Typography
                          className={classes.bold}
                          style={{ marginLeft: 20, marginBottom: 5 }}
                        >
                          비활성
                        </Typography>
                      </Flex>
                      <Flex>
                        <GridDropZone
                          disableDrag={
                            isKorda &&
                            editAbleStatus.includes(recentStatus) === true
                              ? false
                              : true
                          }
                          className={classes.dropzoneInActive}
                          id="hideImageList"
                          boxesPerRow={2}
                          rowHeight={155}
                        >
                          {items.hideImageList?.map((item) => (
                            <GridItem key={item.imageId}>
                              <Flex className={classes.imgWrap}>
                                <CardMedia
                                  component="img"
                                  image={
                                    consts.s3BaseUrl + "/" + item?.imageUrl
                                  }
                                  onClick={(e) => {
                                    if (moveRef.current) {
                                      dispatch(
                                        openImageView({
                                          src:
                                            consts.s3BaseUrl +
                                            "/" +
                                            item?.imageUrl,
                                        })
                                      );
                                    }
                                  }}
                                  style={{
                                    borderRadius: 10,
                                    width: 100,
                                    height: 150,
                                    maxHeight: 100,
                                    maxWidth: 100,
                                    webkitUserDrag: "none",
                                  }}
                                />
                                <Flex row className={classes.imgTitle}>
                                  {item.imageName}
                                </Flex>
                              </Flex>
                            </GridItem>
                          ))}
                        </GridDropZone>
                      </Flex>
                    </Flex>
                  </Flex>
                  {/* <Divider className={classes.divider} /> */}
                </div>
              </GridContextProvider>
            </Grid>
          </Flex>
          <Flex>
            <Grid
              item
              xs={12}
              className={[classes.infoGrid, classes.df].join(" ")}
              style={{ flexDirection: "column" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  alignSelf: "stretch",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className={[classes.dfsc, classes.subtitle].join(" ")}
              >
                <Typography variant="subtitle1" className={classes.bold}>
                  추가정보
                </Typography>

                <ButtonBase
                  disabled={
                    isKorda && editAbleStatus.includes(recentStatus) === true
                      ? false
                      : true
                  }
                  style={{ marginRight: 20 }}
                  onClick={addItem}
                >
                  <Add />
                  필드 추가하기
                </ButtonBase>
              </Grid>

              <Divider className={classes.divider} />
              <Grid
                item
                xs={12}
                className={[
                  classes.df,
                  classes.infoContent,
                  classes.ckeditor,
                ].join(" ")}
                style={{ flexDirection: "column" }}
              >
                {additionalInfo?.map((item, i) => {
                  return (
                    <tr key={i.toString()} style={{ marginBottom: 10 }}>
                      <td>
                        <Flex row>
                          <ButtonBase
                            className={classes.arrowButton}
                            disabled={
                              isKorda && i === 0
                                ? true
                                : false ||
                                  (isKorda &&
                                    editAbleStatus.includes(recentStatus) ===
                                      true)
                                ? false
                                : true
                            }
                            onClick={() => handleClickSort(i, i - 1)}
                          >
                            <ArrowDropUpOutlined />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.arrowButton}
                            disabled={
                              isKorda && i === additionalInfo.length - 1
                                ? true
                                : false ||
                                  (isKorda &&
                                    editAbleStatus.includes(recentStatus) ===
                                      true)
                                ? false
                                : true
                            }
                            onClick={() => handleClickSort(i, i + 1)}
                          >
                            <ArrowDropDownOutlined />
                          </ButtonBase>
                        </Flex>
                      </td>
                      <td colSpan={5} style={{ padding: "0px 20px" }}>
                        <input
                          disabled={
                            isKorda &&
                            editAbleStatus.includes(recentStatus) === true
                              ? false
                              : true
                          }
                          f
                          className={classes.input}
                          placeholder="항목명"
                          value={item?.key}
                          onChange={(e) => {
                            const { value } = e.target;
                            const copyList = [...additionalInfo];
                            copyList[i] = {
                              ...copyList[i],
                              key: value,
                              sort: i,
                            };
                            setAdditionalInfo(copyList);
                          }}
                        />
                      </td>
                      <td colSpan={5} style={{ padding: "0px 20px" }}>
                        <input
                          disabled={
                            isKorda &&
                            editAbleStatus.includes(recentStatus) === true
                              ? false
                              : true
                          }
                          className={classes.input}
                          placeholder="내용"
                          value={item?.value}
                          onChange={(e) => {
                            const { value } = e.target;
                            const copyList = [...additionalInfo];
                            copyList[i] = {
                              ...copyList[i],
                              value: value,
                            };
                            setAdditionalInfo(copyList);
                          }}
                        />
                      </td>
                      {item.id ? (
                        <td>
                          <Button
                            disabled={
                              isKorda &&
                              editAbleStatus.includes(recentStatus) === true
                                ? false
                                : true
                            }
                            className={classes.button2}
                            variant="outlined"
                            onClick={() => {
                              deleteAdditionalInfo(state.itemId, item.id);
                            }}
                          >
                            삭제
                          </Button>
                        </td>
                      ) : (
                        <></>
                      )}
                    </tr>
                  );
                })}
              </Grid>
            </Grid>
          </Flex>
          <Flex style={{ marginTop: 50 }}>
            <Grid
              item
              xs={12}
              className={[classes.infoGrid, classes.df].join(" ")}
              style={{ flexDirection: "column" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  alignSelf: "stretch",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className={[classes.dfsc, classes.subtitle].join(" ")}
              >
                <Typography variant="subtitle1" className={classes.bold}>
                  상품설명
                </Typography>
              </Grid>

              <Divider className={classes.divider} />
              <Grid
                item
                xs={12}
                className={[
                  classes.df,
                  classes.infoContent,
                  classes.ckeditor,
                ].join(" ")}
                style={{ flexDirection: "column" }}
              >
                <textarea
                  disabled={
                    isKorda && editAbleStatus.includes(recentStatus) === true
                      ? false
                      : true
                  }
                  name="content"
                  rows="20"
                  placeholder="내용을 입력하세요(text only)"
                  value={productInfo}
                  onChange={(e) => {
                    setProductInfo(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Flex>
          <Flex className={classes.buttonDiv}>
            <AdminConfirmButton
              popupLabel="등록 정보 변경"
              popupMessage="변경사항을 저장하시겠습니까?"
              label="저장"
              callback={onSaveAdmin}
              rootClassName={classes.confirmRoot}
              btnClassName={classes.confirmButton}
            />
          </Flex>
        </Flex>
      </AccordionDetails>
    </Accordion>
  );
}

const AddImage = (props) => {
  const { setImageList } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const onChangeImage = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    const _URL = window.URL || window.webkitURL;
    const i = new Image();
    i.src = _URL.createObjectURL(file);

    i.onload = () => {
      if (i.width !== 0 && i.height !== 0) {
        if (file) {
          const f = new FileReader();
          f.onload = () => {
            file.uri = f.result;
            if (name === "image") {
              setImage(file);
            }
          };
          f.readAsDataURL(file);
          e.target.value = "";
        }
      }
    };
  };

  const uploadImage = () => {
    if (image === "") {
      dispatch(actionError("이미지를 넣어주세요."));
    } else if (imageName === "") {
      dispatch(actionError("이미지 이름을 입력하여 주세요."));
    } else {
      const json = JSON.stringify({
        imageName: imageName,
      });

      const blob = new Blob([json], {
        type: "application/json",
      });

      const formData = new FormData();
      formData.append("imageInfo", blob);
      formData.append("image", Object.keys(image).length !== 0 ? image : "");

      dispatch(loadingStart);
      APIS.postRegisterImage(state.itemId, formData)
        .then(({ data: { success, data, message } }) => {
          if (!success) {
            dispatch(
              actionOpen(
                "이미지를 업로드하지 못하였습니다",
                null,
                null,
                null,
                true
              )
            );
          } else {
            dispatch(
              actionOpen("이미지가 추가되었습니다.", null, null, null, true)
            );
            setImageName("");
            setImage("");
            dispatch(closeModal());
            const activeList = data.filter((x) => x.isHide === false);
            setImageList(activeList);
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };
  return (
    <div style={{ width: "365px", height: "330px" }}>
      <div className={classes.modal}>이미지 추가</div>
      <Flex
        row
        style={{ justifyContent: "center", marginTop: 50, marginBottom: 10 }}
      >
        <Flex>
          <input
            value={image?.name}
            placeholder="이미지 경로"
            style={{ height: "-webkit-fill-available", width: 255 }}
            disabled
          />
        </Flex>
        <Flex>
          <input
            accept="image/*"
            type="file"
            id="rasied-image-file"
            name="image"
            style={{ display: "none" }}
            onChange={onChangeImage}
            onClick={onInputClick}
          />
          <label htmlFor="rasied-image-file" className={classes.imageLabel}>
            이미지 찾기
          </label>
        </Flex>
      </Flex>
      <Flex style={{ marginBottom: 10 }}>
        <input
          placeholder="이미지 이름"
          style={{ height: 35 }}
          onChange={(e) => {
            setImageName(e.target.value);
          }}
        />
      </Flex>
      <Flex style={{ marginBottom: 10 }}>
        {image ? (
          <div
            className={classes.df}
            style={{ flexDirection: "column", paddingTop: 5 }}
          >
            <div className={classes.inputImage}>
              <CardMedia
                component="img"
                image={
                  image.path ? consts.s3BaseUrl + "/" + image.path : image.uri
                }
                style={{ borderRadius: 10, width: 110 }}
              />
            </div>
          </div>
        ) : (
          <>
            <input style={{ display: "none" }} />
            <label style={{ alignSelf: "center" }}>
              <Button className={classes.imageAddBtn} component="span" disabled>
                <PanoramaOutlined />
              </Button>
            </label>
          </>
        )}
      </Flex>
      <Flex style={{ marginBottom: 10 }}>
        <ButtonNormal
          label="이미지 업로드"
          onClick={() => {
            uploadImage();
          }}
        />
      </Flex>
    </div>
  );
};

const useStyle = makeStyles({
  grid: {
    justifyContent: "center",
    margin: "0px 50px",
    "& div": {
      display: "flex",
    },
  },
  imgWrap: {
    padding: 15,
    cursor: "pointer",
    width: "100%",
    height: "100%",
    maxWidth: 150,
    // display: "flex",
    // flexDirection: "column",
    // width: "130px", // 설정해 놓으면 이미지들 끼리 겹쳐지는 문제가 발생
    // height: "140px",
  },
  imgTitle: {
    maxWidth: 104,
    placeContent: "center",
    // padding: "0px 15px 0px 15px",
  },
  buttonDiv: {
    alignItems: "end",
  },

  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
  accordion: {
    marginBottom: "20px",
  },
  input: {
    flex: 1,
    marginRight: "20px",
    paddingLeft: "16px",
    width: "800px",
    height: 40,
    border: "1px solid rgb(224,224,224)",
  },
  saveButton: { width: 100, alignItems: "right" },
  dfsc: {
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  subtitle: {
    marginTop: "10px",
    marginLeft: "20px",
  },
  bold: {
    fontWeight: "bold",
  },
  divider: {
    width: "100%",
    marginBottom: 20,
  },
  df: {
    display: "flex",
  },
  infoContent: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
  },
  ckeditor: {
    marginTop: "30px",
    marginBottom: "30px",
    "& .ck-editor__editable_inline": {
      minHeight: "300px",
    },
  },
  textArea: {
    width: 1160,
    marginBottom: 20,
  },
  container: {
    touchAction: "none",
    width: "1200px",
    margin: "1rem auto",
    height: 350,
  },
  dropzone: {
    flex: 1,
    height: "400px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  dropzoneActive: {
    height: 340,
    width: 770,
    marginLeft: 20,
    marginBottom: 10,
    border: "1px solid",
    overflowY: "scroll",
    overflowX: "hidden",
    padding: 10,
  },
  dropzoneInActive: {
    height: 340,
    marginLeft: 20,
    width: 400,
    border: "1px solid",
    overflowY: "scroll",
    overflowX: "hidden",
    padding: 10,
  },
  modal: {
    textAlign: "center",
    fontWeight: "bold",
    width: "100%",
    height: "50px",
    background: " rgb(180,180,180)",
    position: "absolute",
    right: "0px",
    top: "0px",
    borderRadius: "10px 10px 0px 0px",
    lineHeight: "50px",
  },
  imageAddBtn: {
    width: "330px",
    height: "170px",
    marginTop: "10px",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: "5px",
    color: "rgba(0,0,0,0.5)",
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
  },
  imageLabel: {
    border: "1px solid",
    backgroundColor: "black",
    color: "white",
    fontWeight: "bold",
    width: 100,
    textAlign: "center",
    height: 38,
    padding: 8,
    cursor: "pointer",
  },
  inputImage: {
    display: "flex",
    alignItems: "center",
    width: 140,
    height: 200,
    alignSelf: "center",
  },
  input: {
    padding: " 0px 10px",
    border: "1px solid #ddd",
    alignSelf: "stretch",
    flex: 1,
    height: 40,
    width: "100%",
  },
  button1: {
    backgroundColor: "black",
    color: "#fff",
    textAlign: "center",
    width: "100%",
    height: 35,
    borderRadius: 5,
    padding: "7px",
  },
  button2: {
    textAlign: "center",
    width: "100%",
    padding: "5px",
  },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "11px",
    padding: "5px 10px",
    border: "1px solid rgba(0,0,0,0.23)",
    width: "auto",
  },
  arrowButton: {
    flex: 1,
    "&:disabled": { color: colors.lightGray },
  },
  table: {
    marginBottom: 50,
  },
  popopHeader: {
    position: "relative",
    minWidth: "400px",
    padding: "15px",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      color: "#fff",
      fontSize: 16,
    },
    backgroundColor: "rgb(180,180,180)",
  },
  main: {
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,0.23)",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    minHeight: 150,
  },
  mainInput: {
    marginTop: 5,
    alignItems: "center",
    "& span": {
      marginRight: 10,
    },
    "& input": {
      padding: "0px",
    },
  },
  cancelButton: {
    padding: 10,
    minWidth: "30%",
    // marginTop: 10,
  },
});

//예전 table 코드
{
  /* <GridRow
              data={[
                {
                  label: "판매가격",
                  type: "render",
                  rowMerge: 2, 
                  render: (
                    <Flex row >
                      <Typography>
                        {numberWithCommas(registerInfo?.tradeFee?.price) +
                          " 원"}
                      </Typography>
                    </Flex>
                  ),
                },
                {
                  label: "판매 수수료율 / 수수료 금액",
                  type: "render",
                  render: (
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.sellFee +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.sellFee
                            ).toFixed()
                          ) +
                          " 원"}
                      </Typography>
                    </Flex>
                  ),
                },
                // {},
                {
                  label: "구매 수수료율 / 수수료 금액",
                  type: "render",
                  render: (
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.buyFee +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.buyFee
                            ).toFixed()
                          ) +
                          " 원"}
                      </Typography>
                    </Flex>
                  ),
                },
                {
                  label: "판매 추천 매장 수수료",
                  type: "render",
                  render: (
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.sellRecommendShopFee +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.sellRecommendShopFee
                            ).toFixed()
                          ) +
                          " 원"}
                      </Typography>
                    </Flex>
                  ),
                },
                {
                  label: "구매 추천 매장 수수료",
                  type: "render",
                  render: (
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.buyRecommendShopFee +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.buyRecommendShopFee
                            ).toFixed()
                          ) +
                          " 원"}
                      </Typography>
                    </Flex>
                  ),
                },
                {
                  label: "판매접수 매장 수수료",
                  type: "render",
                  render: (
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.sellShopFee +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.sellShopFee
                            ).toFixed()
                          ) +
                          " 원"}
                      </Typography>
                    </Flex>
                  ),
                },
                {
                  label: "출고 매장 수수료",
                  type: "render",
                  render: (
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.buyShopFee +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.buyShopFee
                            ).toFixed()
                          ) +
                          " 원"}
                      </Typography>
                    </Flex>
                  ),
                },
                {
                  label: "판매철회 위약금",
                  type: "render",
                  render: (
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.sellerPenalty +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.sellerPenalty
                            ).toFixed()
                          ) +
                          `원    (최소금액 : ${registerInfo?.tradeFee?.sellerMinPenalty}원)`}
                      </Typography>
                    </Flex>
                  ),
                },
                {
                  label: "반품신청 위약금",
                  type: "render",
                  render: (
                    <Flex row>
                      <Typography>
                        {registerInfo?.tradeFee?.buyerPenalty +
                          "%" +
                          "   /   " +
                          numberWithCommas(
                            (
                              (registerInfo?.tradeFee?.price / 100) *
                              registerInfo?.tradeFee?.buyerPenalty
                            ).toFixed()
                          ) +
                          `원    (최소금액 : ${registerInfo?.tradeFee?.buyerMinPenalty}원)`}
                      </Typography>
                    </Flex>
                  ),
                },
                {
                  label: "판매 기간",
                  type: "render",
                  render: (
                    <Flex row>
                      <input
                        disabled={
                          isKorda &&
                          editAbleStatus.includes(recentStatus) === true
                            ? false
                            : true
                        }
                        value={saleStartDate}
                        onChange={(e) => {
                          setSaleStartDate(e.target.value);
                        }}
                        type="date"
                        className={classes.datepicker}
                      />
                      <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                        ~
                      </span>
                      <input
                        disabled={
                          isKorda &&
                          editAbleStatus.includes(recentStatus) === true
                            ? false
                            : true
                        }
                        value={saleEndDate}
                        onChange={(e) => {
                          setSaleEndDate(e.target.value);
                        }}
                        type="date"
                        className={classes.datepicker}
                      />
                    </Flex>
                  ),
                },
                {
                  label: "재고위치",
                  type: "render",
                  render: (
                    <Flex row>
                      <input
                        disabled={
                          isKorda &&
                          editAbleStatus.includes(recentStatus) === true
                            ? false
                            : true
                        }
                        placeholder="재고위치 입력"
                        style={{ height: "40px", width: "250px" }}
                        value={inventoryLocation}
                        onChange={(e) => {
                          setInventoryLocation(e.target.value);
                        }}
                      />
                    </Flex>
                  ),
                },
              ]}
            /> */
}
