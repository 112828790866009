import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import { RestoreFromTrash, Search } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import ButtonRadios from "../../components/button-radios/ButtonRadios";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import GridRow from "../../components/grid-row/GridRow";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import SimpleTable from "../../components/simple-table/SimpleTable";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import HistoryPopup from "./HistoryPopup";
import * as APIS from "../../libs/apis";
import DaumPostcode from "react-daum-postcode";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../redux/modal/ModalReducer";
import FindAddress from "../../components/find-address/FindAddress";
import { actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import InputFile from "../../components/input/InputFile";

const MAP_COLUMN_TO_LABEL = {
  shopIsFranchisee: "가맹점/직영점/본점",
  shopName: "매장명",
  shopCode: "가맹점코드",
  managerAccount: "아이디",
  managerPassword: "비밀번호",
  managerName: "이름",
  managerPhone: "연락처",
  shopBusinessNumber: "사업자등록번호",
  shopPhone: "전화번호",
  shopFax: "팩스번호",
  shopPostCode: "우편번호",
  shopAddress: "주소",
  shopAddressDetail: "상세주소",
  shopDistrict: "찾아오는길",
  shopLatitude: "위도",
  shopLongitude: "경도",
};

export default function MemberShopDetail({}) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();
  const state = history.location.state || {};
  const { id, name: originName = "" } = state;

  const [historyPopup, setHistryPopup] = useState(false);

  const [banks, setBanks] = useState([]);
  const [shop, setShop] = useState({});
  const [images, setImages] = useState([]);

  useEffect(() => {
    APIS.getBankList().then(
      ({
        data: {
          success,
          data: { content },
        },
      }) => {
        success && setBanks(content);
      }
    );

    onLoad();
  }, []);

  const onLoad = () => {
    setImages([]);

    dispatch(loadingStart);
    APIS.getShopDetail(id)
      .then(({ data: { success, data, message } }) => {
        success &&
          replaceHistory({ ...state, name: data.shopName, tab: "detail" });
        success && setShop({ ...data, managerPassword: "" });
      })
      .finally(() => dispatch(loadingEnd));
  };

  useEffect(() => {
    if (state.tab === "history2") {
      history.replace(history.location.pathname, {
        ...state,
        history1tab: "1",
      });
    } else if (state.tab === "history1") {
      history.replace(history.location.pathname, {
        ...state,
        history2tab: "1",
      });
    }
  }, [state.tab]);

  const replaceHistory = (state = {}) => {
    history.replace(history.location.pathname, state);
  };

  const handleChangeValue = (key, value) => {
    setShop((shop) => ({ ...shop, [key]: value }));
  };

  const handleClickFindAddress = () => {
    dispatch(
      openModal({
        children: (
          <FindAddress
            callback={({ postCode, address }) => {
              dispatch(closeModal());
              setShop((shop) => ({
                ...shop,
                shopPostCode: postCode,
                shopAddress: address,
                shopAddressDetail: "",
              }));
            }}
          />
        ),
      })
    );
  };

  const handleChangeFile = (file) => {
    setImages([...images, file]);
  };

  const handleClickDelete = () => {
    APIS.deleteShop(shop.id).then(({ data: { success, data, message } }) => {
      success && history.goBack();
    });
  };

  const handleClickSave = (password) => {
    const param = { ...shop };

    const invalidKeys = ["id", "createdAt", "managerAccount", "shopImages"];
    for (let key in param) {
      if (invalidKeys.includes(key) || !param[key]) {
        delete param[key];
      }
    }

    const json = JSON.stringify({ ...shop, password });
    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("shop", blob);

    for (let i in images) {
      formData.append(`image`, images[i]);
    }

    dispatch(loadingStart);

    APIS.saveShop(shop.id, formData)
      .then(({ data: { success, data, message } }) => {
        success &&
          dispatch(
            actionOpen("매장 수정이 완료되었습니다.", onLoad, null, null, true)
          );
        !success &&
          dispatch(
            actionOpen(
              message,
              () => {
                onLoad();
              },
              null,
              null,
              true
            )
          );

        // success && history.goBack();
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const getInputUI = (key, option = {}) => {
    return {
      label: MAP_COLUMN_TO_LABEL[key],
      value: shop[key],
      type: "input",
      onChange: (e) => handleChangeValue(key, e),
      ...option,
    };
  };

  const {
    createdAt = "",
    shopIsFranchisee,
    managerAccount,
    shopBankCode,
    shopBankAccountNumber,
    shopPostCode,
    shopAddress,
    shopAddressDetail,
    shopImages = [],
    shopType = "",
  } = shop;

  return (
    <>
      {historyPopup && (
        <HistoryPopup
          onClose={() => {
            setHistryPopup(false);
          }}
        />
      )}
      <h1 style={{ marginLeft: 50 }}>{originName}</h1>

      <LabelTabs
        data={[
          { label: "매장 정보", value: "detail" },
          { label: "거래내역", value: "history1" },
          { label: "보유회원현황", value: "history2" },
          // { label: "입출금내역", value: "history3" },
        ]}
      />
      {(() => {
        switch (state.tab) {
          case "detail":
            return (
              <>
                <AdminConfirmButton
                  popupLabel="매장 정보 변경"
                  popupMessage="변경하시겠습니까?"
                  label="저장"
                  callback={handleClickSave}
                  more={[
                    {
                      white: true,
                      popupLabel: "매장 삭제",
                      popupMessage: "삭제하시겠습니까?",
                      label: "삭제",
                      callback: handleClickDelete,
                    },
                  ]}
                />
                <GridRow
                  label="기본정보"
                  data={[
                    { label: "등록일", value: createdAt },
                    {
                      label: "매장 구분",
                      type: "radio",
                      data: [
                        { label: "가맹점", value: "FRANCHISEE" },
                        { label: "직영점", value: "BRANCH" },
                        { label: "본사", value: "HEAD_OFFICE" },
                      ],
                      value: shopType,
                      onChange: (e) => {
                        handleChangeValue("shopType", e);
                      },
                    },
                    getInputUI("shopName"),
                    getInputUI("shopCode", { validation: "number" }),
                    { label: "아이디", value: managerAccount },
                    getInputUI("managerPassword", { type: "input-password" }),
                    getInputUI("managerName"),
                    getInputUI("managerPhone", { validation: "tel" }),
                    getInputUI("shopBusinessNumber", {
                      validation: "businessNumber",
                    }),
                    {
                      label: "정산계좌",
                      value: "",
                      type: "render",
                      render: (
                        <Flex row className={classes.addressContainer}>
                          <Flex row>
                            <select
                              style={{
                                minWidth: 100,
                                fontSize: 16,
                              }}
                              value={shopBankCode}
                              onChange={(e) => {
                                setShop((shop) => ({
                                  ...shop,
                                  shopBankCode: e.target.value,
                                }));
                              }}
                            >
                              {banks.map(({ code, name }, i) => {
                                return (
                                  <option key={i.toString()} value={code}>
                                    {name}
                                  </option>
                                );
                              })}
                            </select>
                          </Flex>
                          <InputBase
                            className={classes.addressDetailWrap}
                            value={shopBankAccountNumber}
                            onChange={({ target: { value } }) => {
                              setShop((shop) => ({
                                ...shop,
                                shopBankAccountNumber: value,
                              }));
                            }}
                          />
                        </Flex>
                      ),
                    },
                  ]}
                />

                <GridRow
                  label="매장정보"
                  data={[
                    getInputUI("shopPhone", { validation: "tel" }),
                    getInputUI("shopFax", { validation: "tel" }),
                    {
                      label: "지역",
                      value: "",
                      type: "render",
                      fullWidth: true,
                      render: (
                        <Flex row className={classes.addressContainer}>
                          <Button
                            label="주소찾기"
                            onClick={handleClickFindAddress}
                          >
                            주소찾기
                          </Button>

                          <span
                            className={classes.addressWrap}
                            onClick={handleClickFindAddress}
                          >
                            {shopPostCode && `(${shopPostCode}) `}
                            {shopAddress}
                          </span>
                          <InputBase
                            className={classes.addressDetailWrap}
                            value={shopAddressDetail}
                            onChange={({ target: { value } }) => {
                              setShop((shop) => ({
                                ...shop,
                                shopAddressDetail: value,
                              }));
                            }}
                          />
                        </Flex>
                      ),
                    },
                    getInputUI("shopDistrict", {
                      fullWidth: true,
                      inputFullWidth: true,
                    }),
                    getInputUI("shopLatitude", { validation: "number" }),
                    getInputUI("shopLongitude", { validation: "number" }),
                  ]}
                />

                {/* <Flex row className="row-center padding">
                  <InputFile
                    accept="image/*"
                    label="첨부서류"
                    callback={handleChangeFile}
                  />

                  <span style={{ marginLeft: 10 }}>
                    ex. 사업자 등록증, 본인신분증 사본, 가맹계약서, 통장사본 등
                    (첨부파일 용량 100mb이하)
                  </span>
                </Flex> */}

                {/* <Flex
                  className="padding"
                  style={{ marginTop: 30, alignSelf: "flex-start" }}
                >
                  {shopImages.map(({ fileName, path }, i) => {
                    let imgTmp = new Image();
                    imgTmp.src = path;

                    return (
                      <ButtonBase
                        key={i.toString()}
                        style={{ alignSelf: "start" }}
                        onClick={() => {
                          let imgPopup = window.open(
                            "",
                            fileName,
                            `width=${imgTmp.width}, height=${imgTmp.height}, status=no, menubar=no, toolbar=no, scrollbars=no, resizable=no`
                          );

                          imgPopup.document.write(`
                            <html>
                              <title>${fileName}</title>
                              <body topmargin=0 leftmargin=0 marginheight=0 marginwidth=0>
                                <a href='javascript:self.close()'>
                                 <img src="${path}" border=0>
                                </a>
                              </body>
                            </html>
                          `);
                        }}
                      >
                        {fileName}
                        <Search />
                      </ButtonBase>
                    );
                  })}
                  {images.map(({ name }, i) => {
                    return (
                      <Flex row className="row-center" key={i.toString()}>
                        {name}
                        <RestoreFromTrash
                          className={classes.icon}
                          onClick={() => {
                            const copy = [...images];
                            copy.splice(i, 1);
                            setImages(copy);
                          }}
                        />
                      </Flex>
                    );
                  })}
                </Flex> */}
              </>
            );
          case "history2":
            return (
              <>
                <ButtonRadios
                  className={classes.history1buttonRadios}
                  field="history1tab"
                  data={[
                    {
                      label: "추천가입회원",
                      value: "1",
                    },
                    {
                      label: "위탁 고객",
                      value: "2",
                    },
                  ]}
                />

                {state.history1tab === "1" ? (
                  <SimpleTable
                    data={[{}]}
                    fullWidth
                    columns={[
                      { label: "추천가입회원수" },
                      { label: "총 거래 대금" },
                      // { label: "매장 수익 수수료율" },
                      { label: "거래 수수료 수익" },
                    ]}
                  />
                ) : (
                  <table
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                    className={classes.td}
                    style={{ border: "1px solid rgba(0,0,0,0.23)" }}
                  >
                    <thead>
                      <tr style={{ backgroundColor: "#f5f5f5" }}>
                        <th></th>
                        <th>위탁 고객수</th>
                        <th>총 위탁 대금</th>
                        <th>총 위탁 금(Gold)</th>
                        <th>총 위탁 은(Silver)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ backgroundColor: "#f5f5f5" }}>현재</td>
                        <td>10</td>
                        <td>100,000원</td>
                        <td>291g</td>
                        <td>312g</td>
                      </tr>
                      <tr>
                        <td style={{ backgroundColor: "#f5f5f5" }}>누적</td>
                        <td>10</td>
                        <td>100,000원</td>
                        <td>291g</td>
                        <td>312g</td>
                      </tr>
                    </tbody>
                  </table>
                )}

                {state.history1tab === "2" && (
                  <Flex row className={classes.history1Toolbar}>
                    <Flex row>
                      <select className={classes.history1Select}>
                        <option defaultValue label="정산완료 여부" />
                        <option label="미완료" />
                        <option label="완료" />
                      </select>
                      <Flex row style={{ marginLeft: 50 }}>
                        <InputBase
                          className={classes.input}
                          placeholder="이름,생년월일,휴대폰"
                        />
                        <ButtonBase className={classes.button}>검색</ButtonBase>
                      </Flex>
                    </Flex>
                    <Button label="엑셀다운로드"></Button>
                  </Flex>
                )}
                <DataTable
                  data={[{}, {}, {}]}
                  hideLabel
                  onClick={(e) => {
                    console.log("12");
                  }}
                  //{state.history1tab === "2"}
                  onExcelDownload={state.history1tab === "1" && (() => {})}
                  columns={
                    state.history1tab === "1"
                      ? [
                          { label: "가입일" },
                          { label: "이름" },
                          { label: "성별" },
                          { label: "생년월일" },
                          { label: "휴대폰" },
                          { label: "회원별 총 거래 대금" },
                          { label: "금(GOLD)" },
                          { label: "은(SILVER)" },
                          { label: "보유원화" },
                        ]
                      : [
                          { label: "등록일" },
                          { label: "이름" },
                          { label: "성별" },
                          { label: "생년월일" },
                          { label: "휴대폰" },
                          { label: "위탁내용" },
                          { label: "위탁대금" },
                          { label: "정산완료 여부" },
                          {
                            label: "위탁내역",
                            render: (x) => {
                              return (
                                <ButtonBase
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setHistryPopup(true);
                                  }}
                                  className={classes.history2ButtonText}
                                >
                                  상세보기
                                </ButtonBase>
                              );
                            },
                          },
                        ]
                  }
                />
              </>
            );
          // case "history3":
          //   return (
          //     <DataTable
          //       hideLabel
          //       dashboard={[
          //         {
          //           label: "총 거래건수",
          //         },
          //         {
          //           label: "보유 잔고",
          //         },
          //         {
          //           label: "입출금 계좌",
          //         },
          //         {
          //           label: "가상 계좌",
          //         },
          //       ]}
          //       data={[{}, {}]}
          //       columns={[
          //         {
          //           label: "구분",
          //         },
          //         {
          //           label: "거래 시간",
          //         },
          //         {
          //           label: "요청금액",
          //         },
          //         {
          //           label: "수수료",
          //         },
          //         {
          //           label: "입출금액",
          //         },
          //         {
          //           label: "상태",
          //         },
          //       ]}
          //     />
          //   );
          case "history1":
            return (
              <>
                <ButtonRadios
                  className={classes.history1buttonRadios}
                  field="history2tab"
                  data={[
                    {
                      label: "체결 현황",
                      value: "1",
                    },
                    {
                      label: "실물인출 현황",
                      value: "2",
                    },
                  ]}
                />
                {state.history2tab === "1" ? (
                  <SimpleTable
                    fullWidth
                    data={[{}]}
                    columns={[
                      { label: "보유금액" },
                      { label: "금(GOLD)" },
                      { label: "은(SILVER)" },
                      { label: "매수/매도" },
                      { label: "실물인출" },
                      { label: "안심직거래" },
                    ]}
                  />
                ) : (
                  <table
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                    className={classes.td2}
                    style={{ border: "1px solid rgba(0,0,0,0.23)" }}
                  >
                    <thead>
                      <tr style={{ backgroundColor: "#f5f5f5" }}>
                        <th rowSpan={2}>총 인출 건수</th>
                        <th colSpan={3}>금(GOLD)</th>
                        <th colSpan={3}>은(SILVER)</th>
                      </tr>
                      <tr style={{ backgroundColor: "#f5f5f5" }}>
                        <th colSpan={1}>건수</th>
                        <th colSpan={1}>중량</th>
                        <th colSpan={1}>인출 수수료 총액</th>
                        <th colSpan={1}>건수</th>
                        <th colSpan={1}>중량</th>
                        <th colSpan={1}>인출 수수료 총액</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>12</td>
                        <td>8</td>
                        <td>298,420g</td>
                        <td>298,420원</td>
                        <td>8</td>
                        <td>298,420g</td>
                        <td>298,420원</td>
                      </tr>
                    </tbody>
                  </table>
                )}

                <Flex row className={classes.history1Toolbar}>
                  <Flex row>
                    <select className={classes.history1Select}>
                      <option defaultValue label="출고/미출고 전체" />
                      <option label="출고" />
                      <option label="미출고" />
                    </select>

                    <select
                      style={{ marginLeft: 50 }}
                      className={classes.history1Select}
                    >
                      <option defaultValue label="금/은 전체" />
                      <option label="금" />
                      <option label="은" />
                    </select>
                  </Flex>
                  <Button label="엑셀다운로드"></Button>
                </Flex>
                <DataTable
                  data={[{}, {}, {}]}
                  hideLabel
                  columns={
                    state.history2tab === "1"
                      ? [
                          { label: "거래 시간" },
                          { label: "주문형태" },
                          { label: "거래자산" },
                          { label: "구분" },
                          { label: "체결가격" },
                          { label: "수수료" },
                          { label: "체결량" },
                          { label: "수익률" },
                        ]
                      : [
                          { label: "거래 시간" },
                          { label: "주문번호" },
                          { label: "거래자산" },
                          { label: "인출 총 중량" },
                          { label: "인출 수수료" },
                          { label: "제품 종류" },
                          { label: "제품 중량" },
                          { label: "수량" },
                          { label: "본사 출고/미출고" },
                        ]
                  }
                />
              </>
            );
        }
      })()}
    </>
  );
}

const useStyle = makeStyles({
  addressWrap: {
    minWidth: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    padding: "0 10px",
    minHeight: 20,
    cursor: "pointer",
  },
  addressDetailWrap: {
    flex: 1,
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    padding: 5,
    minHeight: 20,
  },
  icon: { cursor: "pointer" },
  addressContainer: { flex: 1, alignSelf: "stretch" },
  history1Toolbar: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 50px",
    marginTop: 50,
  },
  history1Select: {
    border: "1px solid rgba(0,0,0,0.23)",
  },
  input: {
    width: 200,
    padding: "0px 10px",
    border: "1px solid rgba(0,0,0,0.23)",
  },
  button: {
    backgroundColor: "#000",
    width: 100,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  history1buttonRadios: {
    margin: "0px 50px",
    marginTop: 20,
  },
  td: {
    borderRight: "none !important",
    borderBottom: "none !important",
    margin: "20px 50px 30px 50px",
    "& th": {
      padding: "6px",
    },
    "& td": {
      textAlign: "center",
      padding: "10px",
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  td2: {
    borderRight: "none !important",
    borderBottom: "none !important",
    margin: "20px 50px 30px 50px",
    "& th": {
      borderRight: "1px solid rgba(0,0,0,0.23) !important",
      borderBottom: "1px solid rgba(0,0,0,0.23) !important",
      padding: "6px",
    },
    "& td": {
      textAlign: "center",
      padding: "10px",
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  history2ButtonText: {
    color: "black",
    borderBottom: "1px solid black",
  },
});
