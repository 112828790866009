import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import Flex from "../../components/flex/Flex";
import Filters from "../../components/filters/Filters";
import Button from "../../components/button/Button";
import * as APIS from "../../libs/apis";
import { objToQueryStr } from "../../services/utils";
import DataTable from "../../components/table/Table";
import routes from "../../libs/routes";
import { makeStyles } from "@material-ui/core";
import fonts from "../../libs/fonts";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionOpen } from "../../redux/action/ActionActions";
import { getDefaultPageSize } from "../../libs/consts";

export default function EventList({}) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const [totalCount, setTotalCount] = useState(0);
  const [eventList, setEventList] = useState([]);
  // const { state = {} } = location;
  // const {} = state;
  const state = location.state || {};
  useEffect(() => {
    if (!isNaN(state.page)) {
      fetchList();
    }
  }, [state.page, state.size]);

  const fetchList = () => {
    const paramQuery = objToQueryStr({
      subject: state.eventStatus === "SUBJECT" ? state.eventSearchValue : "",
      content: state.eventStatus === "CONTENT" ? state.eventSearchValue : "",
      status: "",
      category: "EVENT",
      createdBy: state.eventStatus === "WRITER" ? state.eventSearchValue : "",
      page: state.page,
      size: state.size,
    });
    dispatch(loadingStart);
    APIS.getNoticeList(paramQuery)
      .then(({ data: { success, data, totalElements } }) => {
        if (success) {
          setEventList(data.list.content);
          setTotalCount(data.list.totalElements);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const validationData = () => {
    if (state.selected.length === 0) {
      return "목록을 선택해주세요.";
    }
    return "";
  };

  const handleChangeStatus = (value) => {
    if (state.selected.length) {
      dispatch(loadingStart);
      const copySelected = [...state.selected];
      let data = [];
      copySelected.map((item) => {
        let temp = { id: item, noticeStatus: value };
        data.push(temp);
      });
      APIS.postNoticeStatus(data)
        .then(({ data: { success, data, message } }) => {
          if (!success) {
            dispatch(
              actionOpen("상태를 바꾸지 못하였습니다", null, null, null, true)
            );
          } else {
            dispatch(
              actionOpen(
                "해당 아이템의 상태를 바꾸었습니다",
                () => {
                  history.go(0);
                },
                null,
                null,
                true
              )
            );
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    } else {
      const errorMessage = validationData();
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }
  };
  return (
    <>
      <Flex className={classes.root}>
        <Flex row className={classes.header}>
          <Flex row className="row-center"></Flex>
          <Flex row>
            <Button
              label="활성화"
              className={classes.button}
              onClick={() => handleChangeStatus("ACTIVE")}
            />
            <Button
              label="블라인드"
              className={classes.button}
              onClick={() => handleChangeStatus("BLIND")}
            />
            <Button
              onClick={() => history.push(routes.serviceNoticeDetail)}
              label="등록"
              className={classes.button}
            />
          </Flex>
        </Flex>
      </Flex>
      <Filters
        defaultState={{ ...getDefaultPageSize() }}
        onSearch={fetchList}
        type="eventSearch"
        data={[
          {
            data: [
              { label: "제목", value: "SUBJECT" },
              { label: "내용", value: "CONTENT" },
              { label: "작성자", value: "WRITER" },
            ],
            type: "menu",
            label: "검색 조건",
            // wrap: true,
            key: "eventStatus",
          },
          {
            label: "검색명",
            wrap: true,
            type: "input",
            key: "eventSearchValue",
          },
        ]}
      />
      <DataTable
        onClick={(item) => {
          history.push(routes.serviceNoticeDetail, {
            id: item.id,
          });
        }}
        data={eventList}
        totalCount={totalCount}
        useCheckbox
        checkboxSelection
        options={{ selection: true }}
        columns={[
          { label: "상태", key: "status" },
          { label: "제목", key: "subject" },
          { label: "시작일", key: "startDate", type: "date" },
          { label: "종료일", key: "endDate", type: "date" },
          { label: "작성자", key: "createdBy" },
          { label: "등록일", key: "createdAt", type: "date" },
          { label: "조회수", key: "count" },
        ]}
      />
    </>
  );
}
const useStyle = makeStyles({
  root: {
    margin: "10px 50px",
  },
  header: {
    alignSelf: "stretch",
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },
  button: {
    border: "5px solid white",
    borderRadius: "7px",
    width: "120px",
  },
});
