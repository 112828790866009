import { makeStyles, Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import DataTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import Input from "../../components/input/Input";
import consts, { getDefaultPageSize, STORAGE_KEY } from "../../libs/consts";
import * as APIS from "../../libs/apis";
import { objToQueryStr, numFormat, formatPhone } from "../../services/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError } from "../../redux/action/ActionActions";
import Flex from "../../components/flex/Flex";
import { formatTime } from "../../libs/utils";
import LabelTabs from "../../components/label-tabs/LabelTabs";

//첫 랜더링 방지
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};
// const useDidMountEffect = (func, deps) => {
//   const didMount = useRef(false);
//   const reMount = useRef(false);
//   const reReMount = useRef(false);
//   const lastMount = useRef(false);
//   useEffect(() => {
//     if (didMount.current) {
//       if (reMount.current) {
//         if (reReMount.current) {
//           if (lastMount.current) {
//             func();
//           } else {
//             lastMount.current = true;
//           }
//         } else {
//           reReMount.current = true;
//         }
//       } else {
//         reMount.current = true;
//       }
//     } else {
//       didMount.current = true;
//     }
//   }, deps);
// };

export default function DirectStatus({}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();
  const location = useLocation();
  const state = location.state || {};
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  const [summary, setSummary] = useState([]);
  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState([]);
  const [progressStatusList, setProgressStatusList] = useState([]);
  const [registerTotal, setRegisterTotal] = useState(); //접수단계 리스트 개수
  const [registerList, setRegisterList] = useState(); //접수단계 리스트
  const [saleTotal, setSaleTotal] = useState(); //판매단계 리스트 개수
  const [saleList, setSaleList] = useState(); //판매단계 리스트
  const [releaseTotal, setReleaseTotal] = useState(); //출고단계 리스트 개수
  const [releaseList, setReleaseList] = useState(); //출고단계 리스트
  const [searchReception, setSearchReception] = useState();
  const [filterStatus, setFilterStatus] = useState("orderId");
  const checkedList = location.state?.shopRangeList || [];
  const checkedListPerson = location.state?.personRangeList || [];

  const checkBoxDataPersonType = [
    { label: "판매자", value: "SELLER" },
    { label: "구매자", value: "BUYER" },
  ];
  const checkBoxDataStoreType = [
    { label: "접수매장", value: "receptionDesk" },
    { label: "출고매장", value: "store" },
  ];

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const fetchList = (type) => {
    let statusData = "";
    if (type) {
      statusData = progressStatusList?.find((item) => item.status === type);
    } else if (state.status) {
      statusData = progressStatusList?.find(
        (item) => item.status === state.status
      );
    } else {
      statusData = state.tab;
    }
    dispatch(loadingStart);
    const progressParam = type
      ? {
          page: state.page,
          size: state.size,
          status: type,
          statusLevel: statusData?.statusLevel,
        }
      : {
          ...state,
          page: state.page,
          size: state.size,
          statusLevel: statusData?.statusLevel,
        };

    delete progressParam["tab"];
    const progressParamQuery = objToQueryStr(progressParam);
    const registerArr = [];
    const saleArr = [];
    const releaseArr = [];
    APIS.getSafeTradeProductList(progressParamQuery)
      .then(
        ({
          data: {
            data: { registerPage, releasePage, sellPage, statusLevel },
          },
        }) => {
          statusLevel === "REGISTER"
            ? (handleValueChange("tab", statusLevel),
              setRegisterList(registerPage?.content),
              setRegisterTotal(registerPage?.totalElements),
              setSaleList(sellPage?.content),
              setSaleTotal(sellPage?.totalElements),
              setReleaseList(releasePage.content),
              setReleaseTotal(releasePage.totalElements))
            : statusLevel === "SALE"
            ? (handleValueChange("tab", statusLevel),
              setSaleList(sellPage?.content),
              setSaleTotal(sellPage?.totalElements),
              setRegisterList(registerPage?.content),
              setRegisterTotal(registerPage?.totalElements),
              setReleaseList(releasePage.content),
              setReleaseTotal(releasePage.totalElements))
            : statusLevel === "RELEASE"
            ? (handleValueChange("tab", statusLevel),
              setReleaseList(releasePage.content),
              setReleaseTotal(releasePage.totalElements),
              setSaleList(sellPage?.content),
              setSaleTotal(sellPage?.totalElements),
              setRegisterList(registerPage?.content),
              setRegisterTotal(registerPage?.totalElements))
            : (handleValueChange("tab", statusLevel),
              registerPage?.content.find((item) => {
                progressStatusList.forEach((progressStatus) => {
                  if (progressStatus.status === item.status) {
                    if (progressStatus.statusLevel === "REGISTER") {
                      registerArr.push(item);
                    }
                  }
                });
              }),
              sellPage?.content.find((item) => {
                progressStatusList.forEach((progressStatus) => {
                  if (progressStatus.status === item.status) {
                    if (progressStatus.statusLevel === "SALE") {
                      saleArr.push(item);
                    }
                  }
                });
              }),
              releasePage?.content.find((item) => {
                progressStatusList.forEach((progressStatus) => {
                  if (progressStatus.status === item.status) {
                    if (progressStatus.statusLevel === "RELEASE") {
                      releaseArr.push(item);
                    }
                  }
                });
              }),
              setRegisterList(registerArr),
              setRegisterTotal(registerArr.length || "0"),
              setSaleList(saleArr),
              setSaleTotal(saleArr.length || "0"),
              setReleaseList(releaseArr),
              setReleaseTotal(releaseArr.length || "0"));
          setSearchReception();
        }
      )
      .catch((err) => dispatch(actionError(err)))
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  useEffect(() => {
    // 접수현황 summary 조회
    const getSummary = APIS.getSafeTradeSummary()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setSummary(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));

    // Filters 카테고리 조회
    const getCategoryList = APIS.getSafeTradeFitersList()
      .then(
        ({
          data: {
            success,
            message,
            data: { categoryList, statusList },
          },
        }) => {
          if (success) {
            setCategory([
              ...categoryList.map(({ categoryId, name }) => ({
                label: name,
                value: categoryId,
              })),
            ]);
            setProgressStatusList(statusList);
            const searchTypeList = statusList.map((item) => {
              return { statusLevel: item.statusLevel };
            });
            const deduplicationType = searchTypeList.filter(
              (dayName, idx, arr) => {
                return (
                  arr.findIndex(
                    (item) => item.statusLevel === dayName.statusLevel
                  ) === idx
                );
              }
            );
            let parseData = {};
            let arrayData = [];

            deduplicationType.forEach((type) => {
              statusList.forEach((item) => {
                if (item.statusLevel === type.statusLevel) {
                  arrayData.push(item);
                  parseData[type.statusLevel] = [...arrayData];
                }
              });
              arrayData = [];
            });
            setStatus(parseData);
          } else {
            dispatch(actionError(message));
          }
        }
      )
      .catch((err) => dispatch(actionError(err)));

    //접수단계, 판매단계, 출고단계 fetchList호출
    const registerParam = {
      page: state.page,
      size: state.size,
      statusLevel: "REGISTER",
    };
    const registerParamQuery = objToQueryStr(registerParam);
    const registerList = APIS.getSafeTradeProductList(registerParamQuery)
      .then(
        ({
          data: {
            success,
            message,
            data: { registerPage, sellPage, releasePage },
          },
        }) => {
          if (success) {
            setRegisterTotal(registerPage?.totalElements);
            setRegisterList(registerPage?.content);
            setSaleTotal(sellPage?.totalElements);
            setSaleList(sellPage?.content);
            setReleaseTotal(releasePage?.totalElements);
            setReleaseList(releasePage?.content);
          } else {
            dispatch(actionError(message));
          }
        }
      )
      .catch((err) => dispatch(actionError(err)));

    // const saleParam = {
    //   page: state.page,
    //   size: state.size,
    //   statusLevel: "SALE",
    // };
    // const saleParamQuery = objToQueryStr(saleParam);
    // const saleList = APIS.getSafeTradeProductList(saleParamQuery)
    //   .then(
    //     ({
    //       data: {
    //         success,
    //         message,
    //         data: { page },
    //       },
    //     }) => {
    //       if (success) {
    //         setSaleTotal(page?.totalElements);
    //         setSaleList(page?.content);
    //       } else {
    //         dispatch(actionError(message));
    //       }
    //     }
    //   )
    //   .catch((err) => dispatch(actionError(err)));

    // const releaseParam = {
    //   page: state.page,
    //   size: state.size,
    //   statusLevel: "RELEASE",
    // };
    // const releaseParamQuery = objToQueryStr(releaseParam);
    // const releaseList = APIS.getSafeTradeProductList(releaseParamQuery)
    //   .then(
    //     ({
    //       data: {
    //         success,
    //         message,
    //         data: { page },
    //       },
    //     }) => {
    //       if (success) {
    //         setReleaseTotal(page?.totalElements);
    //         setReleaseList(page?.content);
    //       } else {
    //         dispatch(actionError(message));
    //       }
    //     }
    //   )
    //   .catch((err) => dispatch(actionError(err)));

    Promise.all([
      getSummary,
      getCategoryList,
      registerList, //판매신청 리스트
      // saleList, //판매 리스트
      // releaseList, //출고 리스트
    ]).finally(() => dispatch(loadingEnd));
  }, []);

  useDidMountEffect(() => {
    if (
      registerList?.length > 0 ||
      saleList?.length > 0 ||
      releaseList?.length > 0
    ) {
      if (!searchReception) {
        fetchList();
      }
    }
  }, [state.page, state.size]);

  useDidMountEffect(() => {
    handleValueChange("status", status[`${state.statusLevel}`]?.[0].status);
  }, [state.statusLevel]);

  return (
    <>
      {isKorda ? (
        <>
          <span className={classes.label}>판매신청 접수현황</span>
          <table cellPadding={0} cellSpacing={0} className={classes.table}>
            <thead>
              <tr>
                <th></th>
                <th>접수대기</th>
                <th>접수완료</th>
                <th>감정 대기 중</th>
                <th>감정 완료</th>
                <th>판매대기</th>
                <th>판매 중</th>
                <th>결제 대기 중</th>
                <th>결제 완료</th>
                <th>출고중</th>
                <th>인도 대기</th>
                {/* <th>구매 완료</th> */}
                <th>반송 중</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ backgroundColor: "#f5f5f5" }}>건수</td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("INBOUND_WAIT");
                      fetchList("INBOUND_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "INBOUND_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("INBOUND_COMPLETE");
                      fetchList("INBOUND_COMPLETE");
                    }}
                  >
                    {summary?.find((item) => item.status === "INBOUND_COMPLETE")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("VERIFY_WAIT");
                      fetchList("VERIFY_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "VERIFY_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("VERIFY_COMPLETE");
                      fetchList("VERIFY_COMPLETE");
                    }}
                  >
                    {summary?.find((item) => item.status === "VERIFY_COMPLETE")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("SELL_WAIT");
                      fetchList("SELL_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "SELL_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("SELL");
                      fetchList("SELL");
                    }}
                  >
                    {summary?.find((item) => item.status === "SELL")?.count ||
                      0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("PAYMENT_WAIT");
                      fetchList("PAYMENT_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "PAYMENT_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("PAYMENT_DONE");
                      fetchList("PAYMENT_DONE");
                    }}
                  >
                    {summary?.find((item) => item.status === "PAYMENT_DONE")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("DELIVERY");
                      fetchList("DELIVERY");
                    }}
                  >
                    {summary?.find((item) => item.status === "DELIVERY")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("TRANSFER_WAIT");
                      fetchList("TRANSFER_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "TRANSFER_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                {/* <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("COMPLETE");
                      fetchList("COMPLETE");
                    }}
                  >
                    {summary?.find((item) => item.status === "COMPLETE")
                      ?.count || 0}
                  </a>
                </td> */}
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("RETURN");
                      fetchList("RETURN");
                    }}
                  >
                    {summary?.find((item) => item.status === "RETURN")?.count ||
                      0}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <>
          <span className={classes.label}>판매신청 접수현황</span>
          <table cellPadding={0} cellSpacing={0} className={classes.table}>
            <thead>
              <tr>
                <th></th>
                <th>접수대기</th>
                <th>접수완료</th>
                <th>판매 중</th>
                <th>출고 중</th>
                <th>인도 대기</th>
                <th>반송 중</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ backgroundColor: "#f5f5f5" }}>건수</td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("INBOUND_WAIT");
                      fetchList("INBOUND_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "INBOUND_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("INBOUND_COMPLETE");
                      fetchList("INBOUND_COMPLETE");
                    }}
                  >
                    {summary?.find((item) => item.status === "INBOUND_COMPLETE")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("SELL");
                      fetchList("SELL");
                    }}
                  >
                    {summary?.find((item) => item.status === "SELL")?.count ||
                      0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("DELIVERY");
                      fetchList("DELIVERY");
                    }}
                  >
                    {summary?.find((item) => item.status === "DELIVERY")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("TRANSFER_WAIT");
                      fetchList("TRANSFER_WAIT");
                    }}
                  >
                    {summary?.find((item) => item.status === "TRANSFER_WAIT")
                      ?.count || 0}
                  </a>
                </td>
                <td>
                  <a
                    href="#"
                    onClick={() => {
                      setSearchReception("TRANSFER_RETURN");
                      fetchList("TRANSFER_RETURN");
                    }}
                  >
                    {summary?.find((item) => item.status === "TRANSFER_RETURN")
                      ?.count || 0}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      <span className={classes.label}>접수 관리</span>

      <Filters
        defaultState={{
          ...getDefaultPageSize(),
          total: 0,
          // startDate: formatTime(),
          startDate: formatTime(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            "YYYY-MM-DD"
          ),
          endDate: formatTime(),
          dateType: "CREATED",
          tab: "REGISTER",
          numberType: "ORDER_ID",
          userType: "ALL",
          shopType: "ALL",
          numberKeyword: "",
          userKeyword: "",
          shopKeyword: "",
          statusLevel: "",
          status: "",
        }}
        onSearch={fetchList}
        data={[
          {
            label: "조회기간",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.dateType}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("dateType", e.target.value);
                  }}
                >
                  <option value="CREATED">{"요청일"}</option>
                  <option value="UPDATED">{"수정일"}</option>
                </select>
                <input
                  value={state?.startDate || ""}
                  onChange={(e) => {
                    handleValueChange("startDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
                <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                  ~
                </span>
                <input
                  value={state?.endDate || ""}
                  onChange={(e) => {
                    handleValueChange("endDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
              </Flex>
            ),
          },
          {
            // key: "orderId",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.numberType}
                  className={classes.selectBoxHeader}
                  onChange={(e) => {
                    handleValueChange("numberType", e.target.value);
                  }}
                >
                  <option value="ORDER_ID">{"접수번호"}</option>
                  <option value="ITEM_ID">{"판매번호"}</option>
                  {/* <option value="TAG_NO">{"Tag No."}</option> */}
                </select>
                <input
                  value={state?.numberKeyword}
                  onChange={(e) => {
                    handleValueChange("numberKeyword", e.target.value);
                  }}
                  placeholder={"접수번호, 판매번호"}
                  style={{ width: 400 }}
                />
              </Flex>
            ),
          },
          {
            type: "render",
            renderWidth: "30%",
            render: (
              <Flex row>
                <select
                  value={state?.userType}
                  className={classes.selectBoxHeader}
                  onChange={(e) => {
                    handleValueChange("userType", e.target.value);
                  }}
                >
                  <option value="ALL">{"전체"}</option>
                  <option value="SELLER">{"판매자"}</option>
                  <option value="BUYER">{"구매자"}</option>
                </select>
                <input
                  value={state?.userKeyword}
                  onChange={(e) => {
                    handleValueChange("userKeyword", e.target.value);
                  }}
                  placeholder={"고객명, 연락처"}
                  style={{ width: 220 }}
                />
              </Flex>
              // <Flex row>
              //   {checkBoxDataPersonType.map((checkbox, index) => {
              //     const { value: checkValue, label } = checkbox;
              //     return (
              //       <FormControlLabel
              //         key={index.toString()}
              //         style={{ marginRight: 30 }}
              //         control={
              //           <Checkbox
              //             checked={checkedListPerson.includes(checkValue)}
              //             onChange={(e) => {
              //               if (e.target.checked) {
              //                 handleValueChange("personRangeList", [
              //                   ...checkedListPerson,
              //                   checkValue,
              //                 ]);
              //               } else {
              //                 handleValueChange(
              //                   "personRangeList",
              //                   checkedListPerson.filter(
              //                     (e) => e !== checkValue
              //                   )
              //                 );
              //               }
              //             }}
              //           />
              //         }
              //         label={label}
              //       ></FormControlLabel>
              //     );
              //   })}
              //   <input
              //     // value={state?.orderId || ""}
              //     onChange={(e) => {
              //       handleValueChange("personSearch", e.target.value);
              //     }}
              //     placeholder={"고객명, 연락처"}
              //     style={{ width: 200 }}
              //   />
              // </Flex>
            ),
          },
          // {
          //   label: "판매자",
          //   placeholder: "고객명, 연락처",
          //   type: "input",
          //   wrap: true,
          //   key: "seller",
          // },
          {
            label: "카테고리",
            type: "menu",
            data: category,
            key: "categoryId",
            renderWidth: "30%",
          },
          {
            label: "상품검색",
            placeholder: "상품명",
            type: "input",
            renderWidth: "30%",
            key: "name",
          },
          isKorda
            ? {
                // label: "접수매장",       //접수매장 sellShop, 출고매장 buyShop
                // placeholder: "매장명",
                // type: "input",
                // wrap: true,
                // key: "sellShop",
                type: "render",
                renderWidth: "15%",
                render: (
                  <Flex row>
                    <select
                      value={state?.shopType}
                      className={classes.selectBoxHeader}
                      onChange={(e) => {
                        handleValueChange("shopType", e.target.value);
                      }}
                    >
                      <option value="ALL">{"전체"}</option>
                      <option value="RECOMMEND_SHOP">{"추천매장"}</option>
                      <option value="SELL_SHOP">{"접수매장"}</option>
                      <option value="BUY_SHOP">{"출고매장"}</option>
                    </select>
                    <input
                      value={state?.shopKeyword}
                      onChange={(e) => {
                        handleValueChange("shopKeyword", e.target.value);
                      }}
                      placeholder={"매장명"}
                      style={{ width: 220 }}
                    />
                  </Flex>
                ),
              }
            : { renderWidth: "15%" },
          isKorda
            ? {
                label: "재고위치",
                placeholder: "재고위치",
                type: "input",
                renderWidth: "15%",
                key: "inventoryLocation",
              }
            : { renderWidth: "45%" },
          {
            label: "진행상태",
            renderWidth: "15%",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.statusLevel}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("statusLevel", e.target.value);
                  }}
                >
                  <option value="">{"전체"}</option>
                  {Object.keys(status)?.map((type, index) => {
                    return (
                      <option value={type} key={index}>
                        {type === "REGISTER"
                          ? "판매신청"
                          : type === "SALE"
                          ? "판매"
                          : type === "RELEASE"
                          ? "출고"
                          : ""}
                      </option>
                    );
                  })}
                </select>
                <select
                  value={state?.status}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("status", e.target.value);
                  }}
                >
                  {status[`${state.statusLevel}`]?.map((item) => {
                    return (
                      item.statusLevel === state.statusLevel && (
                        <option value={item.status}>{item.description}</option>
                      )
                    );
                  })}
                </select>
              </Flex>
            ),
          },
        ]}
      />
      <LabelTabs
        data={[
          {
            label: "판매신청",
            value: "REGISTER",
            totalCount: `${registerTotal || 0}`,
            // disabled: `${state.status !== "" && state.tab !== "REGISTER"}`,
          },
          {
            label: "판매",
            value: "SALE",
            totalCount: `${saleTotal || 0}`,
            // disabled: `${state.status !== "" && state.tab !== "SALE"}`,
          },
          {
            label: "출고",
            value: "RELEASE",
            totalCount: `${releaseTotal || 0}`,
            // disabled: `${state.status !== "" && state.tab !== "RELEASE"}`,
          },
        ]}
      />
      <DataTable
        data={
          state.tab === "REGISTER"
            ? registerList
            : state.tab === "SALE"
            ? saleList
            : state.tab === "RELEASE"
            ? releaseList
            : []
        }
        totalCount={
          state.tab === "REGISTER"
            ? registerTotal
            : state.tab === "SALE"
            ? saleTotal
            : state.tab === "RELEASE"
            ? releaseTotal
            : state.total || "0"
        }
        onClick={(id) => {
          const itemId = id.itemId;
          history.push(routes.directStatusDetail, { itemId });
        }}
        columns={
          state.tab !== "RELEASE"
            ? [
                {
                  label: "접수일",
                  key: "createdAt",
                  type: "datetime",
                },
                {
                  label: "접수번호",
                  key: "orderId",
                },
                {
                  label: "추천매장",
                  key: "sellerRecommendShop",
                },
                {
                  label: "고객명",
                  key: "seller",
                },
                {
                  label: "접수매장",
                  key: "sellShop",
                },
                {
                  label: "판매번호",
                  key: "itemId",
                },
                {
                  label: "카테고리",
                  key: "category",
                },
                {
                  label: "상품명",
                  key: "name",
                },
                // {
                //   label: "Tag No.",
                //   key: "tagNo",
                // },
                {
                  label: "재고위치",
                  key: "inventoryLocation",
                },
                {
                  label: "판매희망 가격",
                  key: "currentPrice",
                  type: "number",
                },
                {
                  label: "감정가격",
                  key: "authPrice",
                  type: "number",
                },
                {
                  label: "진행상태",
                  key: "status",
                },
                {
                  label: "최종처리일시",
                  key: "updatedAt",
                  type: "datetime",
                },
              ]
            : [
                {
                  label: "접수일",
                  key: "createdAt",
                  type: "datetime",
                },
                {
                  label: "접수번호",
                  key: "orderId",
                },
                {
                  label: "추천매장", //
                  key: "buyerRecommendShop",
                },
                {
                  label: "고객명",
                  key: "buyer",
                },
                {
                  label: "출고매장", //
                  key: "buyShop",
                },
                {
                  label: "구매번호", //
                  key: "outBoundOrderBookId",
                },
                {
                  label: "카테고리",
                  key: "category",
                },
                {
                  label: "상품명",
                  key: "name",
                },
                // {
                //   label: "Tag No.",
                //   key: "tagNo",
                // },
                {
                  label: "재고위치",
                  key: "inventoryLocation",
                },
                {
                  label: "예약일시",
                  key: "inStoreReserve",
                },
                {
                  label: "판매금액",
                  key: "currentPrice",
                  type: "number",
                },
                {
                  label: "진행상태",
                  key: "status",
                },
                {
                  label: "최종처리일시",
                  key: "updatedAt",
                  type: "datetime",
                },
              ]
        }
      />
    </>
  );
}

const useStyle = makeStyles({
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },

  table: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },

  selectBox: {
    minWidth: "117px !important",
    fontSize: 16,
    marginRight: 10,
  },
  selectBoxHeader: {
    background: "whitesmoke",
    marginRight: 60,
    fontWeight: "bold",
    fontSize: "initial",
  },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    maxWidth: "176px",
  },
});
