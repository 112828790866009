import { ROLE } from "../../libs/routes";

// Actions
const OPEN = "modal/OPEN";
const CLOSE = "auth/CLOSE";

const INIT_STATE = {
  visible: false,
  children: null,
  onClose: () => {},
  maskClosable: false,
  closable: true,
  value : ""
};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case OPEN:
      return { ...INIT_STATE, visible: true, ...action.payload };
    case CLOSE:
      return { ...INIT_STATE };

    default:
      return state;
  }
}

// Action 생성자
export function openModal(payload) {
  return { type: OPEN, payload };
}

export function closeModal() {
  return { type: CLOSE };
}