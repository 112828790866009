import { InputBase } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonRadios from "../../components/button-radios/ButtonRadios";
import Button from "../../components/button/Button";
import Filters from "../../components/filters/Filters";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import PageLabel from "../../components/page-label/PageLabel";
import SimpleTable from "../../components/simple-table/SimpleTable";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";

export default function ServiceNotification({}) {
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};

  useEffect(() => {
    if (state.tab === undefined || state.tab === null) {
      history.replace(location.pathname, {
        ...state,
        tab: "1",
        page: "1",
      });
    }
  }, [state.tab]);
  return (
    <>
      <LabelTabs
        data={[
          { label: "자동발송", value: "1" },
          { label: "수동발송", value: "2" },
        ]}
      />

      <div style={{ marginLeft: 50, marginTop: 30 }}>
        <ButtonRadios
          data={[
            { label: "푸시알림", value: "" },
            { label: "푸시알림 내역", value: "푸시알림 내역" },
            { label: "E-mail", value: "E-mail" },
            { label: "E-mail 발송내역", value: "E-mail 발송내역" },
            { label: "SMS", value: "SMS" },
            { label: "SMS 발송내역", value: "SMS 발송내역" },
          ]}
          field="location"
        />
      </div>
      <Button
        label="저장"
        style={{ alignSelf: "flex-end", marginRight: 50, width: 200 }}
      />
      <SimpleTable
        fullWidth
        columns={[
          { label: "종류" },
          {
            label: "내용",
            render: () => (
              <InputBase
                style={{
                  border: "1px solid #ddd",
                  flex: 1,
                  width: "100%",
                  alignSelf: "stretch",
                }}
              />
            ),
          },
        ]}
        data={[{}, {}]}
      />
    </>
  );
}
