import {
  ButtonBase,
  Dialog,
  FormControlLabel,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React from "react";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import fonts from "../../libs/fonts";

export default function SettingPolicy5Popup({ onClose, isAll }) {
  const classes = useStyle();
  return (
    <Dialog open fullWidth maxWidth="md">
      <Flex className={classes.container}>
        <h2>공휴일 설정</h2>
        <Flex row className={classes.root}>
          <RadioGroup row>
            <FormControlLabel
              label="활성"
              control={<Radio color="default" style={{ color: "black" }} />}
            />
            <FormControlLabel
              label="비활성"
              control={<Radio color="default" style={{ color: "black" }} />}
            />
          </RadioGroup>
          <Flex className={classes.buttons} row>
            <ButtonBase onClick={onClose} className={classes.button1}>
              취소
            </ButtonBase>
            <ButtonBase onClick={onClose} className={classes.button2}>
              저장
            </ButtonBase>
          </Flex>
        </Flex>
        <RadioGroup row style={{ marginTop: 30 }}>
          <FormControlLabel
            label="매년 반복되는 기념일"
            control={<Radio color="default" style={{ color: "black" }} />}
          />
        </RadioGroup>
        <table cellPadding={0} cellSpacing={0} className={classes.table1}>
          <tr>
            <th>
              <Flex row className="row-center">
                시작
                <select style={{ marginLeft: "10%" }}>
                  <option label="00시" />
                </select>
                <select style={{ marginLeft: "10%" }}>
                  <option label="00시" />
                </select>
              </Flex>
            </th>
            <th>
              <Flex row className="row-center">
                종료
                <select style={{ marginLeft: "10%" }}>
                  <option label="00시" />
                </select>
                <select style={{ marginLeft: "10%" }}>
                  <option label="00시" />
                </select>
              </Flex>
            </th>
          </tr>
        </table>

        <Flex row className={classes.root} style={{ marginTop: 30 }}>
          <RadioGroup row>
            <FormControlLabel
              label="지정 공휴일"
              control={<Radio color="default" style={{ color: "black" }} />}
            />
          </RadioGroup>
          <Flex row className="row-center">
            <input type="date" />
            <span style={{ margin: "0px 10px" }}>~</span>
            <input type="date" />
          </Flex>
        </Flex>

        <h4 style={{ marginTop: 30 }}>제목</h4>
        <InputBase className={classes.input} />

        <h4 style={{ marginTop: 30 }}>관리자 비밀번호</h4>
        <InputBase className={classes.input} />
      </Flex>
    </Dialog>
  );
}

const useStyle = makeStyles({
  root: {
    alignItems: "center",

    justifyContent: "space-between",
  },
  container: {
    padding: 20,
  },
  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  labelWrap: {
    margin: "0px 50px",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    "& button": { width: 100 },
  },
  labelWrap2: {
    margin: "0px 50px",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "start",
    "& button": { width: 100, marginLeft: 20 },
  },

  tdb: {
    backgroundColor: "#f5f5f5",
  },

  input: {
    marginRight: 10,
    paddingRight: 10,
  },

  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    "& th": {
      paddingLeft: 30,
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
  input: {
    border: "1px solid #ddd",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    width: "80%",
    backgroundColor: "#fff",
    padding: "6px 10px",
  },
  a: { alignItems: "center", whiteSpace: "nowrap" },
  buttons: { alignSelf: "center" },
  button1: {
    border: "1px solid #ddd",
    height: 40,
    width: 150,
    color: "#000",
  },
  button2: {
    height: 40,
    width: 150,
    color: "#fff",
    backgroundColor: "#000",
    marginLeft: 20,
  },
  white: {
    marginLeft: 10,
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    "& span": { color: "#000" },
  },
});
