import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import Flex from "../flex/Flex";

export default function ButtonRadios({
  style,
  className,
  field,
  data,
  buttonStyle,
}) {
  const classes = useStyle();
  const { replace, location } = useHistory();
  const state = location.state || {};
  return (
    <Flex row className={classes.root + " " + className} style={style}>
      {data.map((x, i) => {
        return (
          <ButtonBase
            onClick={() => {
              replace(location.pathname, {
                ...state,
                [field]: x.value,
              });
            }}
            className={`${classes.button} ${
              (state[field] || "") === x.value && classes.buttonSel
            }`}
            value={x.value}
            key={i.toString()}
            style={buttonStyle}
          >
            {x.label}
          </ButtonBase>
        );
      })}
    </Flex>
  );
}

const useStyle = makeStyles({
  button: {
    width: "150px",
    padding: 14,
    marginRight: 10,
    backgroundColor: "#f5f5f5",
  },
  buttonSel: {
    color: "#fff !important",
    backgroundColor: "black !important",
  },
  root: {
    flexWrap: "wrap",
    alignSelf: "stretch",
    alignItems: "center",
  },
});
