import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/input/Input";
import ButtonRadios from "../../components/button-radios/ButtonRadios";
import Button from "../../components/button/Button";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import PageLabel from "../../components/page-label/PageLabel";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import * as APIS from "../../libs/apis";
import { objToQueryStr, numFormat, formatPhone } from "../../services/utils";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import consts, { getDefaultPageSize, STORAGE_KEY } from "../../libs/consts";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { CSVLink } from "react-csv";
import { formatTime } from "../../libs/utils";
import DataTable from "../../components/table/DataTable";
import moment from "moment";
import { AssignmentInd } from "@material-ui/icons";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";

//첫 랜더링 방지
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  const reMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      if (reMount.current) {
        func();
      } else {
        reMount.current = true;
      }
    } else {
      didMount.current = true;
    }
  }, deps);
};

const PHYSICAL_ORDER_STATUS = [
  { label: "인출완료", value: "TAKEOUT" },
  { label: "인출확정", value: "CONFIRM" },
  { label: "결제취소", value: "CANCEL" },
];

const SHIPMENT_STATUS = [
  { label: "제작중", value: "MAKE" },
  { label: "출고중", value: "SHIPMENT" },
  { label: "출고완료", value: "DELIVERY" },
];

export default function PhysicalOrder({}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();
  const { location, push, replace } = useHistory();
  const state = location.state || {};
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  // const [totalCount, setTotalCount] = useState(0);
  // const [orderList, setOrderList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [goldWeight, setGoldWeight] = useState(0);
  const [silverWeight, setSilverWeight] = useState(0);
  const [withdrawalChange, setWithdrawalChange] = useState("TAKEOUT");
  const [inventoryChange, setInventoryChange] = useState("MAKE");
  const [summary, setSummary] = useState({});
  let clickCheck = false;
  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const handleChangeCheckbox = (e) => {
    let newselected = [...state?.selected];
    if (e.target.checked) {
      newselected.push(+e.target.value);
    } else {
      const deleteIndex = state?.selected.indexOf(+e.target.value);
      newselected.splice(deleteIndex, 1);
    }
    handleValueChange("selected", newselected);
  };

  const onChange = (key, value) => {
    history.replace({
      pathname: history.location.pathname,
      state: {
        ...state,
        [key]: value,
        page: 0,
      },
    });
  };
  const onSaveAdmin = async (adminPassword, selectState) => {
    if (state?.selected?.length === 0 || state?.selected === undefined) {
      dispatch(
        actionOpen("상태변경할 항목을 선택하여 주세요.", null, null, null, true)
      );
    } else {
      if (!clickCheck) {
        clickCheck = true;
        dispatch(loadingStart);
        const data = {
          ids: state?.selected || [],
          status: selectState,
          managerPassword: adminPassword,
        };
        APIS.putGoodsWithdrawalStatus(data)
          .then(({ data: { data, message, success } }) => {
            if (success) {
              dispatch(
                actionOpen(
                  "진행상태가 변경되었습니다.",
                  () => {
                    history.replace({
                      pathname: location.pathname,
                      state: null,
                    });
                  },
                  null,
                  null,
                  true
                )
              );
            } else {
              dispatch(actionError(message));
            }
          })
          .finally(() => dispatch(loadingEnd));
      }
    }
    return () => {};
  };

  useEffect(() => {
    APIS.getGoodsCategoryList().then(({ data }) => {
      var cates = data;
      var categorys = [];
      for (let i in cates) {
        categorys.push({ label: cates[i].kind, value: cates[i].id });
      }
      setCategories(categorys);
      history.replace({
        pathname: location.pathname,
        state: null,
      });
    });

    APIS.getOrderSummary().then((res) => {
      try {
        const { data } = res;
        if (data) {
          let parseData = {};
          Object.keys(data).forEach((item) => {
            data[item].forEach((el) => {
              let type = el.goldOrSilver;
              let key = el.status;
              parseData[item] = {
                ...parseData[item],
              };
              parseData[item][type] = {
                ...parseData[item][type],
                [key]: {
                  count: el.count,
                },
              };
            });
          });
          setSummary(parseData);
        }
      } catch (err) {
        console.log(err, "통합주문현황");
      }
    });
  }, []);

  useEffect(() => {
    if (state.total === 0) {
      setGoldWeight(0);
      setSilverWeight(0);
    }
  }, [state.total]);

  useDidMountEffect(() => {
    if (state?.list) {
      fetchList();
    }
  }, [state.page, state.size, state.sortType]);

  const fetchList = () => {
    dispatch(loadingStart);
    const param = {
      ...state,
      page: state.page,
      size: state.size,
      sortType: state.sortType,
      shopRangeList: state.shopRangeList,
      // shopRangeList:
      //   state?.shopRangeList?.length === 0 || !state?.shopRangeList
      //     ? [""]
      //     : state.shopRangeList,
    };
    const paramQuery = objToQueryStr(param);

    APIS.getOrderList(paramQuery)
      .then(({ data: { data, totalElements } }) => {
        history.replace({
          pathname: history.location.pathname,
          state: {
            ...state,
            total: totalElements,
            list: data,
          },
        });
        searchTotalWeight();
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const searchTotalWeight = () => {
    dispatch(loadingStart);
    APIS.getGoodsWithdrawalTotalWeight(
      objToQueryStr({
        ...state,
      })
    )
      .then((res) => {
        let goldWeight = 0,
          silverWeight = 0;
        res?.data?.data.forEach((item) => {
          if (item.assetType === "GOLD") {
            goldWeight = item.total || 0;
          }
          if (item.assetType === "SILVER") {
            silverWeight = item.total || 0;
          }
        });
        setGoldWeight(goldWeight);
        setSilverWeight(silverWeight);
      })
      .catch((err) => {
        setGoldWeight(0);
        setSilverWeight(0);
        dispatch(actionError(err));
      })
      .finally(() => dispatch(loadingEnd));
  };
  const exchargeExcelDown = () => {
    const paramQuery = objToQueryStr({
      ...state,
      page: state.page,
      size: state.size,
      sortType: state.sortType,
    });

    var element = document.createElement("a");
    element.setAttribute(
      "href",
      consts.apiBaseUrl +
        "/api/v1/admin/market/goodswithdrawal/trans/downloadExcel" +
        paramQuery
    );
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    return (element = null);
  };
  const checkedList = location.state?.shopRangeList || [];
  const statusCheckedList = location.state?.status || [];
  const shipmentStatusCheckedList = location.state?.shipmentStatus || [];

  const checkBoxData = [
    { label: "추천", value: "RECOMMEND_SHOP" },
    { label: "출고", value: "OUT_SHOP" },
  ];
  const checkWithdrawalData = [
    { label: "결제", value: "APPROVAL" },
    { label: "결제취소", value: "CANCEL" },
    { label: "인출완료", value: "TAKEOUT" },
    { label: "인출확정", value: "CONFIRM" },
  ];
  const checkShipmentData = [
    { label: "미출고", value: "NOT_SHIPPED" },
    { label: "제작중", value: "MAKE" },
    { label: "출고중", value: "SHIPMENT" },
    { label: "출고완료", value: "DELIVERY" },
  ];
  return (
    <>
      <span className={classes.label}>실물인출 현황(매장 수령건)</span>

      <table cellPadding={0} cellSpacing={0} className={classes.table1}>
        <thead>
          <tr>
            <th></th>
            <th>결제</th>
            <th>인출완료</th>
            <th>인출확정</th>
            <th>결제취소</th>
            <th>미출고</th>
            <th>제작중</th>
            <th>출고중</th>
            <th>출고완료</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ backgroundColor: "#f5f5f5" }}>금</td>
            <td>{summary?.["status"]?.["GOLD"]?.["APPROVAL"]?.count || "-"}</td>
            <td>{summary?.["status"]?.["GOLD"]?.["TAKEOUT"]?.count || "-"}</td>
            <td>{summary?.["status"]?.["GOLD"]?.["CONFIRM"]?.count || "-"}</td>
            <td>{summary?.["status"]?.["GOLD"]?.["CANCEL"]?.count || "-"}</td>
            <td>
              {summary?.["shipmentStatus"]?.["GOLD"]?.["NOT_SHIPPED"]?.count ||
                "-"}
            </td>
            <td>
              {summary?.["shipmentStatus"]?.["GOLD"]?.["MAKE"]?.count || "-"}
            </td>
            <td>
              {summary?.["shipmentStatus"]?.["GOLD"]?.["SHIPMENT"]?.count ||
                "-"}
            </td>
            <td>
              {summary?.["shipmentStatus"]?.["GOLD"]?.["DELIVERY"]?.count ||
                "-"}
            </td>
          </tr>
          <tr>
            <td style={{ backgroundColor: "#f5f5f5" }}>은</td>
            <td>
              {summary?.["status"]?.["SILVER"]?.["APPROVAL"]?.count || "-"}
            </td>
            <td>
              {summary?.["status"]?.["SILVER"]?.["TAKEOUT"]?.count || "-"}
            </td>
            <td>
              {summary?.["status"]?.["SILVER"]?.["CONFIRM"]?.count || "-"}
            </td>
            <td>{summary?.["status"]?.["SILVER"]?.["CANCEL"]?.count || "-"}</td>
            <td>
              {summary?.["shipmentStatus"]?.["SILVER"]?.["NOT_SHIPPED"]
                ?.count || "-"}
            </td>
            <td>
              {summary?.["shipmentStatus"]?.["SILVER"]?.["MAKE"]?.count || "-"}
            </td>
            <td>
              {summary?.["shipmentStatus"]?.["SILVER"]?.["SHIPMENT"]?.count ||
                "-"}
            </td>
            <td>
              {summary?.["shipmentStatus"]?.["SILVER"]?.["DELIVERY"]?.count ||
                "-"}
            </td>
          </tr>
        </tbody>
      </table>
      <span className={classes.label}>주문내역 조회</span>
      <Filters
        defaultState={{
          ...getDefaultPageSize(),
          shopRangeList: [],
          status: ["APPROVAL", "TAKEOUT", "CONFIRM"],
          total: 0,
          shipmentStatus: [],
          shopSearch: "",
          sortType: "DESC",
          beginDate: formatTime(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            "YYYY-MM-DD"
          ),
          endDate: formatTime(),
          dateType: "CREATED",
          selected: [],
        }}
        onSearch={fetchList}
        data={[
          // {
          //   label: "조회기간",
          //   // wrap: true,
          //   type: "date-range",
          //   key: "beginDate,endDate",
          // },
          {
            label: "조회기간",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.dateType}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("dateType", e.target.value);
                  }}
                >
                  <option value="CREATED">{"요청일"}</option>
                  <option value="CONFIRM">{"확정일"}</option>
                  <option value="SHIPMENT">{"출고일"}</option>
                </select>
                <input
                  value={state?.beginDate || ""}
                  onChange={(e) => {
                    handleValueChange("beginDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
                <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                  ~
                </span>
                <input
                  value={state?.endDate || ""}
                  onChange={(e) => {
                    handleValueChange("endDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
              </Flex>
            ),
          },
          {
            label: "주문번호",
            placeholder: "주문번호",
            type: "input",
            key: "no",
          },
          {
            label: "주문자",
            placeholder: "고객명, 연락처",
            type: "input",
            key: "userSearch",
          },
          {
            label: "상품검색",
            placeholder: "상품명",
            type: "input",
            key: "productName",
          },
          // {
          //   label: "수령방법",
          //   data: [{ label: "전체" }, { label: "매장방문" }],
          //   type: "menu",
          //   wrap: true,
          //   key: "delivery",
          // },

          {
            label: "매장",
            type: "render",
            render: (
              <Flex row>
                {checkBoxData.map((checkbox, index) => {
                  const { value: checkValue, label } = checkbox;
                  return (
                    <FormControlLabel
                      key={index.toString()}
                      style={{ marginRight: 30 }}
                      control={
                        <Checkbox
                          checked={checkedList.includes(checkValue)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleValueChange("shopRangeList", [
                                ...checkedList,
                                checkValue,
                              ]);
                            } else {
                              handleValueChange(
                                "shopRangeList",
                                checkedList.filter((e) => e !== checkValue)
                              );
                            }
                          }}
                        />
                      }
                      label={label}
                    ></FormControlLabel>
                  );
                })}
                <Input
                  value={location.state?.shopSearch || ""}
                  onChange={(value) => {
                    handleValueChange("shopSearch", value);
                  }}
                  placeholder="매장명, 연락처"
                  className={classes.input}
                  disabled={isKorda === false ? true : false}
                />
              </Flex>
            ),
          },
          {
            label: "인출상태",
            type: "render",
            render: (
              <Flex row>
                {checkWithdrawalData.map((checkbox, index) => {
                  const { value: checkValue, label } = checkbox;
                  return (
                    <FormControlLabel
                      key={index.toString()}
                      style={{ marginRight: 30 }}
                      control={
                        <Checkbox
                          checked={statusCheckedList.includes(checkValue)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleValueChange("status", [
                                ...statusCheckedList,
                                checkValue,
                              ]);
                            } else {
                              handleValueChange(
                                "status",
                                statusCheckedList.filter(
                                  (e) => e !== checkValue
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={label}
                    ></FormControlLabel>
                  );
                })}
              </Flex>
            ),
          },
          {
            label: "카테고리",
            data: categories,
            type: "menu",
            key: "categoryId",
          },
          {
            label: "출고상태",
            type: "render",
            render: (
              <Flex row>
                {checkShipmentData.map((checkbox, index) => {
                  const { value: checkValue, label } = checkbox;
                  return (
                    <FormControlLabel
                      key={index.toString()}
                      style={{ marginRight: 30 }}
                      control={
                        <Checkbox
                          checked={shipmentStatusCheckedList.includes(
                            checkValue
                          )}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleValueChange("shipmentStatus", [
                                ...shipmentStatusCheckedList,
                                checkValue,
                              ]);
                            } else {
                              handleValueChange(
                                "shipmentStatus",
                                shipmentStatusCheckedList.filter(
                                  (e) => e !== checkValue
                                )
                              );
                            }
                          }}
                        />
                      }
                      label={label}
                    ></FormControlLabel>
                  );
                })}
              </Flex>
            ),
          },
        ]}
      />
      {/* <DataTable
        rows={[
          [
            { label: "주문일시", key: "createdAt" },
            { label: "주문자", key: "userName" },
            { label: "카테고리", rowSpan: 2, key: "categoryName" },
            { label: "상품명", rowSpan: 2, key: "name" },
            { label: "단위중량", key: "gram" },
            { label: "수량", rowSpan: 2, key: "qty" },
            { label: "결제중량", key: "totalGram" },
            { label: "수령방법", key: "delivery" },
            { label: "선택옵션", rowSpan: 2, key: "optionValue" },
            { label: "진행상태", rowSpan: 2, key: "statusTxt" },
          ],
          [
            { label: "주문번호", key: "no" },
            { label: "연락처", key: "phone" },
            { label: "단위수수료", key: "fees" },
            { label: "결제수수료", key: "totalFees" },
            { label: "수령매장", key: "shopName" },
          ],
        ]}
        list={orderList}
        onRowClick={({ transId }) => {
          history.push(routes.physicalOrderDetail, { id: transId });
        }}
      /> */}
      <Flex row className={classes.tableHeader}>
        <Flex row>
          <Text
            font={fonts.notoSansKRBold}
            className={classes.weightTotalLabel}
          >
            금 중량 합계 : {numFormat(goldWeight) || 0} g
          </Text>
          &nbsp;&nbsp;
          <Text
            font={fonts.notoSansKRBold}
            className={classes.weightTotalLabel}
          >
            은 중량 합계 : {numFormat(silverWeight) || 0} g
          </Text>
        </Flex>
        {isKorda ? (
          <>
            <Flex row className={classes.statusChange}>
              인출상태 변경
              <select
                onChange={(e) => {
                  setWithdrawalChange(e.target.value);
                }}
              >
                {PHYSICAL_ORDER_STATUS.map(({ label, value }) => {
                  return <option key={value} label={label} value={value} />;
                })}
              </select>
              <AdminConfirmButton
                popupLabel="인출상태 변경"
                popupMessage="변경사항을 저장하시겠습니까?"
                label="저장"
                callback={onSaveAdmin}
                selectItem={withdrawalChange}
                rootClassName={classes.confirmRoot}
                btnClassName={classes.confirmButton}
              />
            </Flex>
            <Flex row className={classes.statusChangeShipment}>
              출고상태 변경
              <select
                onChange={(e) => {
                  setInventoryChange(e.target.value);
                }}
              >
                {SHIPMENT_STATUS.map(({ label, value }) => {
                  return <option key={value} label={label} value={value} />;
                })}
              </select>
              <AdminConfirmButton
                popupLabel="출고상태 변경"
                popupMessage="변경사항을 저장하시겠습니까?"
                label="저장"
                callback={onSaveAdmin}
                selectItem={inventoryChange}
                rootClassName={classes.confirmRoot}
                btnClassName={classes.confirmButton}
              />
            </Flex>
          </>
        ) : (
          ""
        )}
        <Flex row>
          <RadioGroup
            key={state.sortType}
            value={state.sortType}
            onChange={(e) => {
              onChange("sortType", e.target.value);
            }}
            row
            style={{ alignSelf: "flex-end", marginTop: 50 }}
          >
            <FormControlLabel
              label="최근 순서로"
              value="DESC"
              control={<Radio color="default" style={{ color: "black" }} />}
            />
            <FormControlLabel
              label="오래된 순서로"
              value="ASC"
              control={<Radio color="default" style={{ color: "black" }} />}
            />
          </RadioGroup>
        </Flex>
      </Flex>
      <Flex className={classes.root}>
        <Flex row className={classes.header}>
          <Flex row className="row-center">
            <Text font={fonts.notoSansKRMedium}>
              목록 검색결과{" "}
              <Text
                font={fonts.notoSansKRMedium}
                style={{ color: colors.red, fontSize: "20px" }}
              >
                {state.total}
              </Text>
            </Text>
          </Flex>

          {/* <Flex row>
            <Button
              label="엑셀 다운로드"
              className={classes.button}
              classNameLabel={classes.buttonLabel}
              onClick={() => exchargeExcelDown()}
            />
          </Flex> */}
        </Flex>
      </Flex>
      <ExcelDownload data={state?.list}>
        <table cellPadding={0} cellSpacing={0} className={classes.table2}>
          <thead>
            <tr>
              <th rowSpan={2}>No</th>
              <th>주문일시</th>
              <th>주문자</th>
              <th rowSpan={2}>추천 매장</th>
              <th rowSpan={2}>카테고리</th>
              <th rowSpan={2}>상품명</th>
              <th>단위중량</th>
              <th rowSpan={2}>수량</th>
              <th>결제중량</th>
              <th>수령방법</th>
              <th rowSpan={2}>선택옵션</th>
              <th rowSpan={2}>인출상태</th>
              <th rowSpan={2}>인출확정일</th>
              <th rowSpan={2}>출고상태</th>
              <th rowSpan={2}>출고일</th>
              <th rowSpan={2}>
                <Checkbox
                  checked={
                    state?.list
                      ?.filter(({ status }) => status !== "CANCEL")
                      .filter(
                        ({ shipmentStatus }) => shipmentStatus !== "DELIVERY"
                      ).length === state?.selected?.length &&
                    state?.selected?.length > 0
                  }
                  onChange={(e) => {
                    if (!e.target.checked) {
                      handleValueChange("selected", []);
                    } else {
                      handleValueChange(
                        "selected",
                        state?.list
                          .filter(({ status }) => status !== "CANCEL")
                          .filter(
                            ({ shipmentStatus }) =>
                              shipmentStatus !== "DELIVERY"
                          )
                          .map(({ transId }) => transId)
                      );
                    }
                  }}
                />
              </th>
            </tr>

            <tr>
              <th>주문번호</th>
              <th>연락처</th>
              <th>단위수수료</th>
              <th>결제수수료</th>
              <th>수령매장</th>
            </tr>
          </thead>
          <tbody>
            {state?.list?.map((x, i) => {
              var statusTxt = "";
              var shipmentTxt = "";
              if (x.status === "APPROVAL") {
                statusTxt = "결제";
              } else if (x.status === "CANCEL") {
                statusTxt = "취소";
              } else if (x.status === "TAKEOUT") {
                statusTxt = "인출완료";
              } else if (x.status === "CONFIRM") {
                statusTxt = "인출확정";
              } else {
                statusTxt = "-";
              }

              if (x.shipmentStatus === "NOT_SHIPPED") {
                shipmentTxt = "미출고";
              } else if (x.shipmentStatus === "MAKE") {
                shipmentTxt = "제작중";
              } else if (x.shipmentStatus === "SHIPMENT") {
                shipmentTxt = "출고중";
              } else if (x.shipmentStatus === "DELIVERY") {
                shipmentTxt = "출고완료";
              } else {
                shipmentTxt = "-";
              }

              return (
                <React.Fragment key={Math.random()}>
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(routes.physicalOrderDetail, {
                        id: x.transId,
                      });
                    }}
                  >
                    <td rowSpan={2}>
                      {state.total -
                        parseInt(state.page) * parseInt(state.size) -
                        i}
                    </td>
                    <td>{formatTime(x.createdAt, "YYYY-MM-DD HH:mm:SS")}</td>
                    <td>{x.userName}</td>
                    <td rowSpan={2}>{x.recommendShopName}</td>
                    <td rowSpan={2}>{x.categoryName}</td>
                    <td rowSpan={2}>{x.name}</td>
                    <td>{x.gram}g</td>
                    <td rowSpan={2}>{x.qty}</td>
                    <td>{x.totalGram}g</td>
                    <td>{x.delivery}</td>
                    <td rowSpan={2}>{x.optionValue || "-"}</td>
                    <td rowSpan={2}>{statusTxt}</td>
                    <td rowSpan={2}>
                      {formatTime(x.confirmDate, "YYYY-MM-DD HH:mm:SS") || "-"}
                    </td>
                    <td rowSpan={2}>{shipmentTxt}</td>
                    <td rowSpan={2}>
                      {formatTime(x.shipmentDate, "YYYY-MM-DD HH:mm:SS") || "-"}
                    </td>
                    {x.status === "CANCEL" ||
                    x.shipmentStatus === "DELIVERY" ? (
                      <td rowSpan={2}></td>
                    ) : (
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        rowSpan={2}
                      >
                        <Checkbox
                          checked={state?.selected?.includes(x.transId) || ""}
                          value={x.transId}
                          onChange={handleChangeCheckbox}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ margin: 0, padding: 0 }}
                        />
                      </td>
                    )}
                  </tr>

                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(routes.physicalOrderDetail, {
                        id: x.transId,
                      });
                    }}
                  >
                    <td>{x.no}</td>
                    <td>{formatPhone(x.phone)}</td>
                    <td>{numFormat(x.fee)}</td>
                    <td>{numFormat(x.totalFee)}</td>
                    <td>{x.outShopName}</td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              {!isNaN(state.page) && (
                <TablePagination
                  onChangePage={(e, p) => {
                    replace(location.pathname, {
                      ...state,
                      page: p,
                    });
                  }}
                  className={classes.pagination}
                  count={state.total || 0}
                  onChangeRowsPerPage={(e) => {
                    replace(location.pathname, {
                      ...state,
                      page: 0,
                      size: e.target.value,
                    });
                    localStorage.setItem(STORAGE_KEY.tableSize, e.target.value);
                  }}
                  page={parseInt(state.page)}
                  rowsPerPage={parseInt(state.size)}
                  style={{ margin: "0px 50px", borderBottom: "none" }}
                />
              )}
            </tr>
          </tfoot>
        </table>
      </ExcelDownload>
    </>
  );
}

const ExcelDownload = (props) => {
  const { data = [] } = props;
  // Excel Control --------------------------------------------------
  const [contextMenu, setContextMenu] = useState(null);
  const excelRef = useRef(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleClickDownloadExcel = () => {
    handleCloseContextMenu();
    excelRef.current.link.click();
  };

  const headers = [
    { label: "주문일시", key: "createdAt" },
    { label: "주문번호", key: "no" },
    { label: "주문자", key: "userName" },
    { label: "연락처", key: "phone" },
    { label: "카테고리", key: "categoryName" },
    { label: "상품명", key: "name" },
    { label: "단위중량", key: "gram" },
    { label: "단위수수료", key: "fee" },
    { label: "수량", key: "qty" },
    { label: "결제중량", key: "totalGram" },
    { label: "결제수수료", key: "totalFee" },
    { label: "수령방법", key: "delivery" },
    { label: "수령매장", key: "outShopName" },
    { label: "선택옵션", key: "optionValue" },
    { label: "인출상태", key: "statusTxt" },
    { label: "인출확정일", key: "confirmDate" },
    { label: "출고상태", key: "shipmentTxt" },
    { label: "출고일", key: "shipmentDate" },
  ];

  const mapDataToCsv = () => {
    return data.map((row) => {
      const csvRow = { ...row };

      if (csvRow.status === "APPROVAL") {
        csvRow.statusTxt = "결제";
      } else if (csvRow.status === "CANCEL") {
        csvRow.statusTxt = "취소";
      } else if (csvRow.status === "MAKE") {
        csvRow.statusTxt = "제작중";
      } else if (csvRow.status === "SHIPMENT") {
        csvRow.statusTxt = "출고중";
      } else if (csvRow.status === "DELIVERY") {
        csvRow.statusTxt = "출고완료";
      } else if (csvRow.status === "TAKEOUT") {
        csvRow.statusTxt = "인출완료";
      } else if (csvRow.status === "CONFIRM") {
        csvRow.statusTxt = "인출확정";
      } else if (csvRow.status === "REJECT") {
        csvRow.statusTxt = "반려";
      } else {
        csvRow.statusTxt = "구매요청";
      }

      for (let key in csvRow) {
        if (key === "gram" || key === "totalGram") {
          csvRow[key] = `${csvRow[key]}g`;
        }

        if (csvRow[key] && !isNaN(csvRow[key])) {
          csvRow[key] = `=""${csvRow[key]}""`;
        }
      }
      return csvRow;
    });
  };
  // -------------------------------------------------- Excel Control

  return (
    <div style={{ display: "flex", flex: 1 }} onContextMenu={handleContextMenu}>
      {props.children}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        onContextMenu={handleContextMenu}
      >
        <MenuItem onClick={handleClickDownloadExcel}>Download Excel</MenuItem>
      </Menu>
      <CSVLink
        filename={`실물인출_${formatTime(new Date(), "YYYYMMDDHHmmSS")}`}
        headers={headers}
        data={mapDataToCsv()}
        ref={excelRef}
        style={{ width: 0, height: 0 }}
      />
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    margin: "0px 50px 10px 50px",
  },
  header: {
    alignSelf: "stretch",
    marginBottom: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },

  table2: {
    width: "100%",
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },

  table1: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  thHi: {
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    backgroundColor: "black !important",
  },
  input: { backgroundColor: colors.white },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    maxWidth: "176px",
  },
  selectBox: {
    minWidth: "117px !important",
    fontSize: 16,
    marginRight: 10,
  },
  tableHeader: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    margin: "0px 50px 10px 50px",
    alignItems: "flex-end",
  },
  weightTotalLabel: {
    marginBottom: "10px",
    marginRight: "15px",
    "& span": { fontSize: 14, color: "#444" },
  },
  statusCount: {
    flexDirection: "row",
    fontWeight: "bold",
    height: 29,
  },
  linkStatus: {
    "& span": {
      color: "blue",
    },
    cursor: "pointer",
  },
  linkStatusSelected: {
    "& span": {
      color: "brown",
    },
  },
  statusChange: {
    fontWeight: "bold",
    marginLeft: 30,
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  statusChangeShipment: {
    fontWeight: "bold",
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
});
