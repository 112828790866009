import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import Text from "../../components/text/Text";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/DataTable";
import Flex from "../../components/flex/Flex";
import consts, { getDefaultPageSize } from "../../libs/consts";
import routes from "../../libs/routes";
import { actionOpen, actionError } from "../../redux/action/ActionActions";
import {
  formatPhone,
  numFormat,
  getPurityLabel,
  objToQueryStr,
} from "../../services/utils";
import moment from "moment-timezone";
import { formatTime, getNoProcessLabel, parseToBr } from "../../libs/utils";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import fonts from "../../libs/fonts";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";

const OUTCOME_STATUS = [
  { label: "입고", value: "입고" },
  { label: "미입고", value: "미입고" },
];

export default function ApprasialStatus({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyle();
  const location = useLocation();
  const state = location.state || {};
  // const [appraisalGoldProducts, setAppraisalGoldProducts] = useState([]);
  // const [appraisalSilverProducts, setAppraisalSilverProducts] = useState([]);
  const [weightRates, setWeightRates] = useState([]);
  const [silverGramPrice, setSilverGramPrice] = useState(0);
  const [goldGramPrice, setGoldGramPrice] = useState(0);
  const [goldWeight, setGoldWeight] = useState(0);
  const [silverWeight, setSilverWeight] = useState(0);
  const [waitingStatus, setWaitingStatus] = useState("");
  const [waitingApprovalCount, setWaitingApprovalCount] = useState(0);
  const [waitingReturnCount, setWaitingReturnCount] = useState(0);
  const [inventoryChange, setInventoryChange] = useState("입고");
  const authReducer = useSelector((s) => s.auth);
  let isShop = !authReducer.isKorda;
  let clickCheck = false;

  let { isKorda, shopId } = authReducer;
  if (isKorda) {
    shopId = null;
  }

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  useEffect(() => {
    // 시세 조회
    APIS.getcurrentmarketprice("GOLD")
      .then(({ data: { data } }) => setGoldGramPrice(data))
      .catch((err) => dispatch(actionError(err)));

    APIS.getcurrentmarketprice("SILVER")
      .then(({ data: { data } }) => setSilverGramPrice(data))
      .catch((err) => dispatch(actionError(err)));

    // 감정 차감율 조회
    APIS.getAppraisalWeightRates()
      .then(({ data: { data } }) => {
        setWeightRates(data);
      })
      .catch((err) => dispatch(actionError(err)));

    // 제품 목록 조회
    // APIS.getAppraisalProducts(objToQueryStr({ type: "GOLD" }))
    //   .then((res) => {
    //     setAppraisalGoldProducts([
    //       ...res.data.data.map((x) => ({ label: x.name })),
    //     ]);
    //   })
    //   .catch((err) => dispatch(actionError(err)));

    // APIS.getAppraisalProducts(objToQueryStr({ type: "SILVER" }))
    //   .then((res) => {
    //     setAppraisalSilverProducts([
    //       ...res.data.data.map((x) => ({ label: x.name })),
    //     ]);
    //   })
    //   .catch((err) => dispatch(actionError(err)));
  }, [state.list]);

  const fetchList = (mode) => {
    if (mode === "excel") {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        consts.apiBaseUrl +
          "/api/v1/" +
          (isShop ? "shop" : "admin") +
          "/market/appraisal/Requests/downloadExcel" +
          objToQueryStr({
            ...state,
            page: undefined,
            size: undefined,
            shopId: !isShop ? null : authReducer.shopId,
          })
      );
      element.target = "_blank";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return (element = null);
    }

    dispatch(loadingStart);
    APIS.getAppraisalRequests(
      objToQueryStr({
        ...state,
        shopId,
        startDate: state?.startDate,
        endDate: state?.endDate,
        dateType: state?.dateType,
      }),
      isKorda
    )
      .then((res) => {
        history.replace({
          pathname: history.location.pathname,
          state: {
            ...state,
            total: res.data.totalElements,
            list: res.data.data,
          },
        });
        searchTotalWeight();
        searchWaitingCount();
        setWaitingStatus("");
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const searchTotalWeight = (type, waitStatusType) => {
    dispatch(loadingStart);
    type === "waitSearch"
      ? APIS.getAppraisalTotalWeight(
          objToQueryStr({
            status: waitStatusType,
            ...getDefaultPageSize(),
            sortType: "DESC",
            selected: [],
          }),
          isKorda
        )
          .then((res) => {
            let goldWeight = 0,
              silverWeight = 0;
            res?.data?.data.forEach((item) => {
              if (item.assetType === "GOLD") {
                goldWeight = item.gram || 0;
              }
              if (item.assetType === "SILVER") {
                silverWeight = item.gram || 0;
              }
            });
            setGoldWeight(goldWeight);
            setSilverWeight(silverWeight);
          })
          .catch((err) => {
            setGoldWeight(0);
            setSilverWeight(0);
            dispatch(actionError(err));
          })
          .finally(() => dispatch(loadingEnd))
      : APIS.getAppraisalTotalWeight(
          objToQueryStr({
            ...state,
            shopId,
          }),
          isKorda
        )
          .then((res) => {
            let goldWeight = 0,
              silverWeight = 0;
            res?.data?.data.forEach((item) => {
              if (item.assetType === "GOLD") {
                goldWeight = item.gram || 0;
              }
              if (item.assetType === "SILVER") {
                silverWeight = item.gram || 0;
              }
            });
            setGoldWeight(goldWeight);
            setSilverWeight(silverWeight);
          })
          .catch((err) => {
            setGoldWeight(0);
            setSilverWeight(0);
            dispatch(actionError(err));
          })
          .finally(() => dispatch(loadingEnd));
  };

  const searchWaitingCount = () => {
    dispatch(loadingStart);
    APIS.getAppraisalWaiting()
      .then((res) => {
        let waitApproval = 0,
          waitReturn = 0;
        res?.data?.data?.forEach((item) => {
          if (item.status === "승인대기") {
            waitApproval = item.count || 0;
          }
          if (item.status === "반송대기") {
            waitReturn = item.count || 0;
          }
        });
        setWaitingApprovalCount(waitApproval);
        setWaitingReturnCount(waitReturn);
      })
      .catch((err) => {
        setWaitingApprovalCount(0);
        setWaitingReturnCount(0);
        dispatch(actionError(err));
      })
      .finally(() => dispatch(loadingEnd));
  };

  const onChange = (key, value) => {
    history.replace({
      pathname: history.location.pathname,
      state: {
        ...state,
        [key]: value,
        page: 0,
      },
    });
  };

  const onSaveAdmin = async (adminPassword) => {
    if (state?.selected?.length === 0 || state?.selected === undefined) {
      dispatch(
        actionOpen("재고변경할 항목을 선택하여 주세요.", null, null, null, true)
      );
    } else {
      if (!clickCheck) {
        clickCheck = true;
        dispatch(loadingStart);
        const data = {
          ids: state?.selected || [],
          stockStatus: inventoryChange,
          managerPassword: adminPassword,
        };
        APIS.putAppraisalChangeStockStatus(data)
          .then(({ data: { data, message, success } }) => {
            if (success) {
              dispatch(
                actionOpen(
                  "재고 상태가 변경되었습니다.",
                  () => {
                    history.replace({
                      pathname: history.location.pathname,
                      state: null,
                    });
                  },
                  null,
                  null,
                  true
                )
              );
            } else {
              dispatch(actionError(message));
            }
          })
          .catch((error) => dispatch(actionError(error)))
          .finally(() => dispatch(loadingEnd));
      }
    }
  };

  const waitSearch = (statusType) => {
    dispatch(loadingStart);
    APIS.getAppraisalRequests(
      objToQueryStr({
        status: statusType,
        ...getDefaultPageSize(),
        sortType: "DESC",
        selected: [],
      }),
      isKorda
    )
      .then((res) => {
        history.replace({
          pathname: history.location.pathname,
          state: {
            ...state,
            total: res.data.totalElements,
            list: res.data.data,
          },
        });
        searchTotalWeight("waitSearch", statusType);
        searchWaitingCount();
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const parseData = useMemo(() => {
    return state.list?.map((row) => {
      if (
        row.status === "교환완료" ||
        row.status === "감정완료" ||
        row.status === "반송완료" ||
        row.status === "반송신청" ||
        row.status === "반송대기"
      ) {
        const { appraisalRequestStatus } = row;
        const appraiseData =
          appraisalRequestStatus.find(({ status }) => status === "감정완료") ||
          {};
        const { appraiseType, appraiseGram } = appraiseData;
        row.type = appraiseType;
        row.appraiseGram = appraiseGram;
      }

      // 요청일
      let requestDate = "-";
      if (
        row.appraisalRequestStatus &&
        row.appraisalRequestStatus.filter((x) => x.status === "접수")[0]
      ) {
        requestDate = formatTime(
          row.appraisalRequestStatus.filter((x) => x.status === "접수")[0]
            .createdAt,
          "YYYY.MM.DD HH:mm"
        );
      }

      // 종류
      let kind = "-";
      if (row.appraisalSimples?.length) {
        kind = "";
        row.appraisalSimples.forEach((x) => {
          kind += kind
            ? "\n" + x.appraisalProduct.name
            : x.appraisalProduct.name;
        });
      }

      // 감정 정보
      let info = "-";
      if (
        row.status === "교환완료" ||
        row.status === "감정완료" ||
        row.status === "반송완료" ||
        row.status === "반송신청" ||
        row.status === "반송대기"
      ) {
        // info = numFormat(row.appraiseGram) + "g";
        info = numFormat(row.appraiseGram);
      } else {
        const infoData = [];
        row.appraisalSimples.forEach((z) => {
          let a =
            z.weightGram *
            (weightRates.filter(
              (y) => y.type === z.appraisalProduct.type && y.purity === z.purity
            )[0]?.weightRatePct /
              100) *
            (z.appraisalProduct.weightRatePct / 100) *
            z.quantity;
          infoData.push({ gram: a, ...z });
        });

        if (infoData.length) {
          info = "";
          infoData.forEach((z) => {
            if (info) {
              info += "\n";
            }

            info +=
              getPurityLabel(z.purity) +
              " · " +
              z.quantity +
              "개 · 보증서(" +
              (z.guaranteeImageUrl ? "유" : "무") +
              ") " +
              parseFloat(z.gram).toFixed(4) +
              "g = " +
              numFormat(
                z.gram *
                  (z.appraisalProduct.type === "GOLD"
                    ? goldGramPrice
                    : silverGramPrice)
              ) +
              "원";
          });
        }
      }

      // 교환 건 수
      let count = row.appraisalSimples?.length || "-";

      // 고객
      let customer = "-";
      if (row.user) {
        customer = row.user.name + "\n" + formatPhone(row.user.phone);
      }

      // 접수 방법
      let method = row.method;
      if (["현장감정", "내방"].includes(method)) {
        method += "\n" + `(${row.shop.name})`;
      }

      // 예약일시
      let reservationDateTime = "-";
      if (row.method === "택배") {
        reservationDateTime = row.collectDate + "\n" + row.collectTime;
      } else if (row.method === "내방") {
        reservationDateTime = row.visitDate + "\n" + row.visitTime;
      }

      // 최종처리일시
      let lastProcessingDate = "-";
      if (row.appraisalRequestStatus) {
        lastProcessingDate = moment(
          row.appraisalRequestStatus[row.appraisalRequestStatus.length - 1]
            .createdAt
        ).format("YYYY.MM.DD HH:mm");
      }

      // 미처리
      let adminStatus = getNoProcessLabel({
        status: row.appraisalRequestStatus,
        method: row.method,
        approvedAppraise: row.approvedAppraise,
      });

      return {
        ...row,
        requestDate,
        kind,
        info,
        count,
        customer,
        method,
        reservationDateTime,
        lastProcessingDate,
        adminStatus,
      };
    });
  }, [state.list]);

  useEffect(() => {
    if (state.total === 0) {
      setGoldWeight(0);
      setSilverWeight(0);
      // setWaitingApprovalCount(0);
      // setWaitingReturnCount(0);
    }
  }, [state.total]);

  useEffect(() => {
    if (state.list) {
      fetchList();
    }
    searchWaitingCount();
  }, [state.page, state.sortType, state.size]);

  return (
    <>
      <PageLabel>감정/등록 내역</PageLabel>
      <Filters
        defaultState={{
          ...getDefaultPageSize(),
          sortType: "DESC",
          dateType: "CREATED",
          total: 0,
          startDate: formatTime(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            "YYYY-MM-DD"
          ),
          endDate: formatTime(),
          selected: [],
        }}
        dateType="week"
        onSearch={fetchList}
        data={[
          // { label: "조회기간", type: "date-range", key: "startDate,endDate" },
          {
            label: "조회기간",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.dateType}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("dateType", e.target.value);
                  }}
                >
                  <option value="CREATED">{"요청일"}</option>
                  <option value="UPDATED">{"완료일"}</option>
                  <option value="STOCKED">{"입고일"}</option>
                </select>
                <input
                  value={state?.startDate || ""}
                  onChange={(e) => {
                    handleValueChange("startDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
                <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                  ~
                </span>
                <input
                  value={state?.endDate || ""}
                  onChange={(e) => {
                    handleValueChange("endDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
              </Flex>
            ),
          },
          {
            label: "회원검색",
            type: "input",
            wrap: true,
            placeholder: "고객명,연락처",
            key: "user",
          },
          isShop
            ? false
            : {
                label: "매장검색",
                type: "input",
                placeholder: "매장명,연락처",
                wrap: true,
                key: "shop",
              },
          {
            label: "제품",
            type: "menu",
            key: "type",
            wrap: true,
            data: [
              { label: "금", value: "GOLD" },
              { label: "은", value: "SILVER" },
            ],
            // data2:
            //   state?.type === "GOLD"
            //     ? appraisalGoldProducts
            //     : state?.type === "SILVER"
            //     ? appraisalSilverProducts
            //     : [],
          },
          {
            label: "상태",
            type: "menu",
            key: "status",
            wrap: true,
            data: [
              { label: "접수", value: "접수" },
              { label: "접수승인", value: "접수승인" },
              { label: "접수반려", value: "접수반려" },
              { label: "감정시작(감정중)", value: "감정시작" },
              { label: "감정반려", value: "감정반려" },
              { label: "승인대기", value: "승인대기" },
              { label: "감정완료", value: "감정완료" },
              { label: "교환완료", value: "교환완료" },
              { label: "반송신청", value: "반송신청" },
              { label: "반송대기", value: "반송대기" },
              { label: "반송완료", value: "반송완료" },
            ],
          },
          {
            wrap: true,
            label: "접수",
            type: "menu",
            key: "method",
            data: [
              { label: "택배", value: "택배" },
              { label: "내방", value: "내방" },
              { label: "현장감정", value: "현장감정" },
              //   { label: "위탁거래", value: "위탁거래" },
            ],
          },
          {
            label: "재고",
            type: "radio",
            key: "stockStatus",
            wrap: true,
            data: [
              { label: "입고", value: "입고" },
              { label: "미입고", value: "미입고" },
            ],
          },
          /*
          {
            label: "재고 상태",
            type: "menu",
            key: "stockStatus",
            wrap: true,
            data: [
              { label: "입고", value: "입고" },
              { label: "미입고", value: "미입고" },
            ],
          },*/
        ].filter((x) => x)}
      />
      <Flex row className={classes.tableHeader}>
        <Flex row>
          <Text font={fonts.notoSansKRBold} className={classes.label}>
            금 중량 합계 : {numFormat(goldWeight) || 0} g
          </Text>
          &nbsp;&nbsp;
          <Text font={fonts.notoSansKRBold} className={classes.label}>
            은 중량 합계 : {numFormat(silverWeight) || 0} g
          </Text>
        </Flex>
        {isKorda ? (
          <>
            <Flex className={classes.statusCount} row>
              <span
                className={`${
                  waitingStatus === "승인대기"
                    ? classes.linkStatusSelected
                    : classes.linkStatus
                }`}
              >
                승인대기 :
                <span
                  onClick={() => {
                    setWaitingStatus("승인대기");
                    waitSearch("승인대기");
                  }}
                >
                  {waitingApprovalCount}건
                </span>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span
                className={`${
                  waitingStatus === "반송대기"
                    ? classes.linkStatusSelected
                    : classes.linkStatus
                }`}
              >
                반송대기 :
                <span
                  name="반송대기"
                  onClick={() => {
                    setWaitingStatus("반송대기");
                    waitSearch("반송대기");
                  }}
                >
                  {waitingReturnCount}건
                </span>
              </span>
            </Flex>
            <Flex row className={classes.item}>
              재고변경
              <select
                onChange={(e) => {
                  setInventoryChange(e.target.value);
                }}
              >
                {OUTCOME_STATUS.map(({ label, value }) => {
                  return <option key={value} label={label} value={value} />;
                })}
              </select>
              <AdminConfirmButton
                popupLabel="재고 상태 변경"
                popupMessage="변경사항을 저장하시겠습니까?"
                label="저장"
                callback={onSaveAdmin}
                rootClassName={classes.confirmRoot}
                btnClassName={classes.confirmButton}
              />
            </Flex>
          </>
        ) : (
          ""
        )}
        <Flex row>
          <RadioGroup
            key={state.sortType}
            value={state.sortType}
            onChange={(e) => {
              onChange("sortType", e.target.value);
            }}
            row
            style={{ alignSelf: "flex-end", marginTop: 50 }}
          >
            <FormControlLabel
              label="최근 순서로"
              value="DESC"
              control={<Radio color="default" style={{ color: "black" }} />}
            />
            <FormControlLabel
              label="오래된 순서로"
              value="ASC"
              control={<Radio color="default" style={{ color: "black" }} />}
            />
          </RadioGroup>
        </Flex>
      </Flex>
      <DataTable
        name="감정현황"
        rows={[
          [
            { type: "no" },
            { label: "접수번호", key: "number" },
            { label: "요청일", key: "requestDate", type: "date" },
            { label: "분류", key: "type" },
            { label: "종류", key: "kind" },
            { label: "감정 정보", key: "info" },
            { label: "교환 건 수", key: "count" },
            { label: "고객", key: "customer" },
            { label: "접수 방법", key: "method" },
            { label: "진행상태", key: "status" },
            { label: "예약일시", key: "reservationDateTime" },
            { label: "최종 처리일시", key: "lastProcessingDate" },
            { label: "입고일", key: "stockedAt" },
            { type: "check" },
            // {
            //   label: "testRender",
            //   type: "render",
            //   render: (x) => {
            //     return <div>{x.customer + x.customer}</div>;
            //   },
            // },
          ],
        ]}
        list={parseData}
        totalCount={state.total}
        onClick={(item) => {
          history.replace({
            state: null,
          });
          history.push(routes.appraisalStatusDetail, {
            id: item.id,
          });
        }}
      />
    </>
  );
}

const useStyle = makeStyles({
  tableHeader: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    margin: "10px 50px",
    alignItems: "flex-end",
  },
  label: {
    marginBottom: "10px",
    marginRight: "15px",
    "& span": { fontSize: 14, color: "#444" },
  },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    maxWidth: "176px",
  },
  selectBox: {
    minWidth: "117px !important",
    fontSize: 16,
    marginRight: 10,
  },
  statusCount: {
    flexDirection: "row",
    fontWeight: "bold",
    height: 29,
  },
  linkStatus: {
    "& span": {
      color: "blue",
    },
    cursor: "pointer",
  },
  linkStatusSelected: {
    "& span": {
      color: "brown",
    },
  },
  item: {
    fontWeight: "bold",
    alignSelf: "flex-end",
    marginRight: 50,
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
});
