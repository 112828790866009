import {
  makeStyles,
  Radio,
  RadioGroup,
  Tooltip,
  FormControlLabel,
} from "@material-ui/core";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import * as APIS from "../../libs/apis";
import { objToQueryStr, numFormat } from "../../services/utils";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { getDefaultPageSize } from "../../libs/consts";
import DataTable from "../../components/table/Table";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import routes from "../../libs/routes";
import { closeModal, openModal } from "../../redux/modal/ModalReducer";
import Input from "../../components/input/Input";
import LabelTabs from "../../components/label-tabs/LabelTabs";

export default function SettlementSalesFee({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyle();
  const { location, push, replace } = useHistory();
  const state = location.state || {};
  const [totalCount, setTotalCount] = useState(0);
  const [exchargeList, setexchargeList] = useState([]);
  const [totalPrice, settotalPrice] = useState([]);
  const [selectid, setselectid] = useState([]);
  const [statusChselect, setstatusChselect] = useState("CANCEL");
  //const [searchTypeList, setSearchTypeList] = useState([]);
  // const [startDate, setstartDate] = useState(formatTime());
  // const [endDate, setendDate] = useState(formatTime());

  const handleChangeValue = (key, value = "") => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value },
    });
  };

  // useEffect(() => {
  //   if (state.tab === undefined || state.tab === null) {
  //     history.replace(location.pathname, {
  //       ...state,
  //       tab: "1",
  //       ...getDefaultPageSize(),
  //     });
  //   }
  // }, []);
  // useEffect(() => {
  //   handleChangeValue("status", []);
  //   // if (!isNaN(state.page)) {
  //   //   fetchList();
  //   // }
  // }, [state.tab]);
  useLayoutEffect(() => {
    history.replace({
      pathname: location.pathname,
      state: {
        ...getDefaultPageSize(),
        tab: state.tab === "2" ? "2" : "1",
        orderBy: "DESC",
        replace: !state.replace,
      },
    });
  }, [state.tab]);

  useEffect(() => {
    if (!isNaN(state.page)) {
      fetchList();
    }
  }, [state.page, state.orderBy, state.replace]);

  const fetchList = async () => {
    const param = { ...state };
    if (state.tab === "1") {
      param.depositOrWithdraw = state.tab === "1" ? "WITHDRAW" : "DEPOSIT";
    } else {
      // 입금내역
      param.depositOrWithdraw = state.tab === "1" ? "WITHDRAW" : "DEPOSIT";
      param.status = state.statusDeposit;
      param.beginCompleteDate = state.beginDepositDate;
      param.endCompleteDate = state.endDepositDate;
    }
    // API.getCall(objToQueryStr(param))

    // var paramQuery = "";
    // if (state.tab === "2") {
    // if (state.searchType === "today") {
    //   paramQuery = objToQueryStr({
    //     ...state,
    //     page: state.page,
    //     size: state.size,
    //     depositOrWithdraw: state.tab === "1" ? "WITHDRAW" : "DEPOSIT",
    //     beginCompleteDate: formatTime(),
    //     endCompleteDate: formatTime(),
    //   });
    // } else {
    // paramQuery = objToQueryStr({
    //   ...state,
    //   page: state.page,
    //   size: state.size,
    //   depositOrWithdraw: state.tab === "1" ? "WITHDRAW" : "DEPOSIT",
    //   beginCompleteDate: state.beginCompleteDate,
    //   endCompleteDate: state.endCompleteDate,
    // });
    // }
    // } else {
    //   paramQuery = objToQueryStr({
    //       ...state,
    //       page: state.page,
    //       size: state.size,
    //       depositOrWithdraw: state.tab === "1" ? "WITHDRAW" : "DEPOSIT",
    //     });
    // }
    delete param.replace;
    dispatch(loadingStart);
    const listApi = APIS.getOrderExcharge(objToQueryStr(param))
      .then(({ data: { data, totalElements } }) => {
        setexchargeList(data);
        setTotalCount(totalElements);
      })
      .catch((err) => dispatch(actionError(err)));

    const totalApi = APIS.getOrderExchargeTotal(objToQueryStr(param))
      .then(({ data: { data, totalElements, success } }) => {
        if (success === true) {
          settotalPrice(data.total);
        } else {
          settotalPrice(0);
        }
      })
      .catch((err) => dispatch(actionError(err)));

    Promise.all([listApi, totalApi]).finally(() => {
      setselectid([]);
      dispatch(loadingEnd);
    });

    // setselectid([]);
    // dispatch(loadingEnd);
  };
  const categoryselectfunc = (id, e) => {
    if (e.target.checked === true) {
      setselectid((selectid) => [...selectid, { id }]);
    } else {
      setselectid(selectid.filter((selectid) => selectid.id !== id));
    }
  };

  const statusChfunc = () => {
    var params = { ids: selectid, status: statusChselect };

    APIS.putexChargeStatus(params).then(
      ({ data: { data, success, message } }) => {
        if (success) {
          fetchList();
        } else {
          dispatch(actionOpen(message, null, null, null, true));
        }
      }
    );
  };

  const onClickConfirmWithdraw = (props, password, msg) => {
    const { type, data } = props;
    if (!password) {
      alert("관리자 패스워드를 입력해주세요.");
      return false;
    }
    let payload = {};
    // 직접이체
    if (type === "direct") {
      payload = {
        id: data.id,
        password: password,
        status: "COMPLETE",
        type: "WITHDRAW",
      };
    }

    // 승인시 자동이체
    if (type === "approval") {
      payload = {
        id: data.id,
        password: password,
        status: "COMPLETE",
        type: "FIRM_BANKING",
      };
    }

    // 출금 취소
    if (type === "cancel") {
      payload = {
        id: data.id,
        password: password,
        status: "CANCEL",
        msg: msg,
      };
    }

    APIS.putexChargeStatus(payload)
      .then(({ data: { success, message } }) => {
        dispatch(closeModal());
        if (!success) {
          dispatch(actionError(message));
        } else {
          dispatch(actionOpen("처리되었습니다.", null, null, null, true));
          fetchList();
        }
      })
      .catch((err) => {
        dispatch(closeModal());
        dispatch(actionError(err));
      });
  };

  const AdminConfirmModal = (props) => {
    const { type, data } = props;
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");
    return (
      <div style={{ width: "400px", height: "200px" }}>
        <div className={classes.modal}>
          출금{" "}
          {type === "cancel"
            ? "취소"
            : type === "approval"
            ? "승인"
            : type === "direct"
            ? "직접이체"
            : "-"}
        </div>
        <div className={classes.content}>
          <Flex
            style={{
              textAlign: "center",
              lineHeight: type === "cancel" ? "30px" : "170px",
            }}
          >
            {type === "cancel" && (
              <span>
                <textarea
                  cols={40}
                  rows={5}
                  maxLength="100"
                  value={msg}
                  placeholder="취소 사유를 입력해주세요"
                  onChange={(e) => setMsg(e.target.value)}
                  style={{ resize: "none" }}
                />
              </span>
            )}
            <span>
              <Text>관리자 비밀번호</Text>&nbsp;
              <Input
                password
                maxLength="30"
                value={password}
                onChange={(e) => setPassword(e)}
              />
            </span>
          </Flex>
        </div>
        <div className={classes.btnWrap}>
          <Button
            label="확인"
            onClick={() => onClickConfirmWithdraw(props, password, msg)}
          />
          &nbsp;&nbsp;
          <Button
            label="취소"
            onClick={() => {
              setPassword("");
              setMsg("");
              dispatch(closeModal());
            }}
          />
        </div>
      </div>
    );
  };

  const onClickApproval = (props) => {
    // props data
    dispatch(closeModal());
    const payload = {
      visiable: true,
      children: <AdminConfirmModal data={props} type="approval" />,
    };
    dispatch(openModal(payload));
  };

  const onClickDirectApproval = (props) => {
    // props data
    dispatch(closeModal());
    const payload = {
      visiable: true,
      children: <AdminConfirmModal data={props} type="direct" />,
    };
    dispatch(openModal(payload));
  };

  const onClickCancelApproval = (props) => {
    const payload = {
      visiable: true,
      children: <AdminConfirmModal data={props} type="cancel" />,
    };
    dispatch(openModal(payload));
  };

  const ConfrimModal = (props) => {
    const {
      accountHolder,
      accountNumber,
      accountBankName,
      phone,
      requestAmount,
    } = props;
    return (
      <div style={{ width: "300px", height: "180px" }}>
        <div className={classes.modal}>승인요청</div>
        <div className={classes.content}>
          {accountHolder}
          <br />
          {phone}
          <br />
          <br />

          <div className={classes.contentText}>
            <span>{accountBankName}</span>
            <span>{accountNumber}</span>
          </div>
          <br />
          <div className={classes.contentText}>
            <span>요청금액</span>
            <span>{numFormat(requestAmount)} 원</span>
          </div>
        </div>
        <div className={classes.btnWrap}>
          <Button label="출금승인" onClick={() => onClickApproval(props)} />
          &nbsp;&nbsp;
          <Button
            label="직접이체"
            onClick={() => onClickDirectApproval(props)}
          />
          &nbsp;&nbsp;
          <Button
            label="출금취소"
            onClick={() => onClickCancelApproval(props)}
          />
        </div>
      </div>
    );
  };

  const withdrawConfirm = (props) => {
    const payload = {
      visiable: true,
      children: <ConfrimModal {...props} />,
    };
    dispatch(openModal(payload));
  };
  const exchargeExcelDown = () => {
    var paramQuery = "";
    if (!state.status) {
      paramQuery = objToQueryStr({
        ...state,
        page: state.page,
        size: state.size,
        status: "ALL",
      });
    } else {
      paramQuery = objToQueryStr({
        ...state,
        page: state.page,
        size: state.size,
      });
    }
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      `${process.env.REACT_APP_ADMIN_API_URL}/api/v1/manager/adjustment/withdrawal/downloadExcel${paramQuery}`
    );
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    return (element = null);
  };
  // const searchType = [
  //   { label: "사용자 요청", value: "USER_REQUEST" },
  //   { label: "승인 대기", value: "MANAGER_WAIT" },
  //   { label: "펌뱅킹 실패", value: "FIRM_BANK_FAILED" },
  //   { label: "출금 실패", value: "FAILED" },
  //   { label: "출금완료", value: "COMPLETE" },
  //   { label: "출금취소", value: "CANCEL" },
  // ];

  // const onChangeSearchType = (e, type) => {
  //   const { value, checked } = e.target;
  //   if (type === "ALL") {
  //     if (!checked) {
  //       setSearchTypeList([]);
  //     } else {
  //       let type = [];
  //       searchType.forEach((data) => {
  //         type.push(data.value);
  //       });
  //       setSearchTypeList(type);
  //     }
  //   } else {
  //     if (!checked) {
  //       let copyList = [...searchTypeList];
  //       const removeIndex = copyList.indexOf(value);
  //       if (removeIndex > -1) {
  //         copyList = copyList.splice(removeIndex, 1);
  //       }
  //       console.log(copyList, "copyListslit");
  //       setSearchTypeList(...copyList);
  //     } else {
  //       let arr = (searchTypeList instanceof Array) ? searchTypeList : [...searchTypeList];
  //       arr.push(type)
  //       console.log(arr, "arrrrrrrrr arararararar");
  //       setSearchTypeList(arr);
  //     }
  //   }
  // };

  return (
    <>
      <LabelTabs
        data={[
          { label: "출금관리", value: "1" },
          { label: "입금내역", value: "2" },
        ]}
      />
      <Filters
        defaultState={{
          ...getDefaultPageSize(),
          tab: state.tab === "2" ? "2" : "1",
          orderBy: "DESC",
          // searchType: "today",
          // status: null,
        }}
        onSearch={fetchList}
        searchType="all"
        data={
          state.tab !== "1"
            ? [
                {
                  label: "입금일",
                  type: "date-range",
                  key: "beginDepositDate,endDepositDate",
                },
                // {
                //   label: "입금일",
                //   type: "render",
                //   key: "beginCompleteDate,endCompleteDate",
                //   render: (
                //     <DateRange startDate={setstartDate} endDate={setendDate} />
                //   ),
                // },
                {},
                {
                  label: "회원검색",
                  type: "input",
                  wrap: true,
                  placeholder: "고객명",
                  key: "search",
                },
                {
                  label: "금액",
                  type: "render",
                  render: <AmountFilter />,
                },
                {
                  label: "처리상태",
                  type: "checkbox",
                  key: "statusDeposit",
                  wrap: true,
                  data: [
                    { label: "성공", value: "COMPLETE" },
                    { label: "실패", value: "FAILED" },
                  ],
                },
              ]
            : [
                {
                  label: "출금요청일",
                  type: "date-range",
                  key: "beginRequestDate,endRequestDate",
                },
                {
                  label: "출금완료일",
                  type: "date-range",
                  key: "beginCompleteDate,endCompleteDate",
                },
                {
                  label: "회원검색",
                  type: "input",
                  wrap: true,
                  placeholder: "고객명,연락처",
                  key: "search",
                },
                {
                  label: "처리상태",
                  type: "checkbox",
                  key: "status",
                  wrap: true,
                  data: [
                    //{ label: "전체", value: "ALL" },
                    { label: "사용자 요청", value: "USER_REQUEST" },
                    { label: "승인 대기", value: "MANAGER_WAIT" },
                    { label: "펌뱅킹 실패", value: "FIRM_BANK_FAILED" },
                    { label: "출금 실패", value: "FAILED" },
                    { label: "출금완료", value: "COMPLETE" },
                    { label: "출금취소", value: "CANCEL" },
                  ],
                  // render: (
                  //   <FormGroup row>
                  //     <FormControlLabel
                  //       label="전체"
                  //       control={
                  //         <Checkbox
                  //           checked={
                  //             searchTypeList.length === searchType.length
                  //               ? true
                  //               : false
                  //           }
                  //           onChange={(e) => onChangeSearchType(e, "ALL")}
                  //         />
                  //       }
                  //     >
                  //       {searchTypeList.length}
                  //     </FormControlLabel>
                  //     {searchType.map((item) => {
                  //       const label = item.label;
                  //       return (
                  //         <FormControlLabel
                  //           label={label}
                  //           style={{ marginLeft: "5px" }}
                  //           control={
                  //             <Checkbox
                  //               checked={searchTypeList.includes(item.value)}
                  //               onChange={(e) => onChangeSearchType(e, item.value)}
                  //             />
                  //           }
                  //         >{searchTypeList.includes(item.value)}</FormControlLabel>
                  //       );
                  //     })}
                  //   </FormGroup>
                  // ),
                },
              ]
          // {
          //       /* {x.data?.map((checkbox, index) => {
          //       const { value: checkValue, label } = checkbox;
          //       return (
          //         <FormControlLabel
          //           key={index.toString()}
          //           style={{ marginLeft: 5 }}
          //           control={
          //             <Checkbox
          //               checked={checkValue === "ALL" && ch ?  :checkedList.includes(checkValue)}
          //               onChange={(e) => {
          //                 if (e.target.checked) {
          //                   handleValueChange(x.key, [
          //                     ...checkedList,
          //                     checkValue,
          //                   ]);
          //                 } else {
          //                   handleValueChange(
          //                     x.key,
          //                     checkedList.filter(
          //                       (e) => e !== checkValue
          //                     )
          //                   );
          //                 }
          //               }}
          //             />
          //           }
          //           label={label}
          //         />
          //       );
          //     })} */
          // },
        }
      />
      <Flex row style={{ alignSelf: "flex-end", marginRight: "34px" }}>
        <RadioGroup
          key={state.orderBy}
          value={state.orderBy}
          onChange={(e) => {
            handleChangeValue("orderBy", e.target.value);
          }}
          row
        >
          <FormControlLabel
            label="최근 순서로"
            value="DESC"
            control={<Radio color="default" style={{ color: "black" }} />}
          />
          <FormControlLabel
            label="오래된 순서로"
            value="ASC"
            control={<Radio color="default" style={{ color: "black" }} />}
          />
        </RadioGroup>
      </Flex>
      {/* <Flex row className={classes.item}>
        상태변경
        <select
          onChange={(e) => {
            setstatusChselect(e.target.value);
          }}
        >
          <option label="취소" value="CANCEL" />
          <option label="출금완료" value="COMPLETE" />
        </select>
        <Button label="저장" onClick={() => statusChfunc()} />
      </Flex> */}
      <Flex row className={classes.tableHeader}>
        <Flex row className={"row-center"}>
          <Text font={fonts.notoSansKRBold}>목록 검색결과 {totalCount}</Text>
          <Text font={fonts.notoSansKRBold} className={classes.label}>
            합계 : {numFormat(totalPrice)} 원
          </Text>
        </Flex>
        <Button label="엑셀다운로드" onClick={() => exchargeExcelDown()} />
      </Flex>
      {state.tab === "1" ? (
        <DataTable
          hideLabel
          data={exchargeList}
          totalCount={totalCount}
          clicktype="checkbox"
          columns={[
            { label: "출금요청일", key: "requestDate", type: "datetime" },
            { label: "입금은행", key: "accountBankName" },
            { label: "출금계좌번호", key: "accountNumber" },
            { label: "입금액", key: "requestAmount", type: "number" },
            {
              label: "예상예금주",
              key: "accountHolder",
              render: (row) => {
                return (
                  <a
                    onClick={() => {
                      history.push(`${routes.memberDetail}/${row.userId}`);
                    }}
                    className={classes.underline}
                  >
                    {row.accountHolder}
                  </a>
                );
              },
            },
            { label: "입금통장표시", key: "depositAccount" },
            { label: "출금통장표시", key: "withdrawAccount" },
            { label: "메모", key: "memo" },
            { label: "CMS코드", key: "cmsCd" },
            { label: "받는분 휴대폰번호", key: "phone" },
            {
              label: "처리상태",
              key: "status",
              type: "exchargestatus",
              render: (row) => {
                const parseStatus =
                  row.status === "COMPLETE"
                    ? "출금완료"
                    : row.status === "WITHDRAWAL_REQUEST"
                    ? "출금 요청"
                    : row.status === "CANCEL"
                    ? "출금취소"
                    : row.status === "FAILED"
                    ? "출금실패"
                    : row.status === "MANAGER_WAIT"
                    ? "승인대기"
                    : row.status === "USER_REQUEST"
                    ? "사용자 요청"
                    : row.status === "FIRM_BANK_FAILED"
                    ? "펌뱅킹 실패"
                    : "-";
                return row.status === "FIRM_BANK_FAILED" ? (
                  <Tooltip
                    title={row.failedReason}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <span
                      className={classes.underline}
                      onClick={() => {
                        withdrawConfirm(row);
                      }}
                    >
                      {parseStatus}
                    </span>
                  </Tooltip>
                ) : row.status === "CANCEL" ? (
                  <Tooltip
                    title={row?.failedReason || "-"}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <span>{parseStatus}</span>
                  </Tooltip>
                ) : row.status === "MANAGER_WAIT" ? (
                  <span
                    className={classes.underline}
                    onClick={() => {
                      withdrawConfirm(row);
                    }}
                  >
                    {parseStatus}
                  </span>
                ) : (
                  <Text>{parseStatus}</Text>
                );
              },
            },
            { label: "처리일시", key: "completeDate" },
          ]}
        />
      ) : (
        <DataTable
          hideLabel
          data={exchargeList}
          totalCount={totalCount}
          clicktype="checkbox"
          columns={[
            { label: "입금일", key: "requestDate", type: "datetime" },
            // { label: "입금은행", key: "accountBankName" },
            { label: "이름", key: "userName" },
            { label: "가상계좌번호", key: "accountNumber" },
            { label: "입금금액", key: "requestAmount", type: "number" },
            { label: "추천매장", key: "shopName" },
            { label: "입금자", key: "accountHolder" },
            // {
            //   label: "예상예금주",
            //   key: "accountHolder",
            //   render: (row) => {
            //     return (
            //       <a
            //         onClick={() => {
            //           history.push(`${routes.memberDetail}/${row.userId}`);
            //         }}
            //         className={classes.underline}
            //       >
            //         {row.accountHolder}
            //       </a>
            //     );
            //   },
            // },
            // { label: "입금통장표시", key: "depositAccount" },
            // { label: "출금통장표시", key: "withdrawAccount" },
            // { label: "메모", key: "memo" },
            // { label: "CMS코드", key: "cmsCd" },
            // { label: "받는분 휴대폰번호", key: "phone" },
            {
              label: "처리상태",
              key: "status",
              type: "exchargestatus",
              render: (row) => {
                const parseStatus = row.status === "COMPLETE" ? "성공" : "-";
                return <Text>{parseStatus}</Text>;
              },
            },
            { label: "처리일시", key: "completeDate", type: "datetime" },
          ]}
        />
      )}
    </>
  );
}

const AmountFilter = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};
  const { amountType, amountFrom = "", amountTo = "" } = state;

  const handleChangeValue = (key, value = "") => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value },
    });
  };

  return (
    <Flex row>
      <Input
        white
        validation="number"
        value={amountFrom}
        onChange={(value) => {
          handleChangeValue("amountFrom", value);
        }}
      />
      <span
        style={{
          alignSelf: "center",
          margin: "0px 5px",
          width: 30,
        }}
      >
        이상
      </span>
      <Input
        white
        validation="number"
        value={amountTo}
        onChange={(value) => {
          handleChangeValue("amountTo", value);
        }}
      />
      <span
        style={{
          alignSelf: "center",
          margin: "0px 5px",
          width: 30,
        }}
      >
        이하
      </span>
    </Flex>
  );
};

const useStyle = makeStyles({
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    marginRight: 50,
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  underline: {
    color: "blue",
  },
  label: {
    marginLeft: 50,
    "& span": { fontSize: 14, color: "#444" },
  },
  supplementLabel: {
    marginLeft: 10,
    color: "gray",
  },
  tableHeader: {
    alignSelf: "stretch",
    marginTop: 10,
    justifyContent: "space-between",
    margin: "10px 50px",
  },
  table3: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  modal: {
    textAlign: "center",
    width: "100%",
    height: "50px",
    background: " rgb(180,180,180)",
    position: "absolute",
    right: "0px",
    top: "0px",
    borderRadius: "10px 10px 0px 0px",
    lineHeight: "50px",
  },
  content: {
    marginTop: "30px",
    height: "150px",
  },
  btnWrap: {
    padding: "5px 5px 5px 5px",
    textAlign: "center",
  },
  contentText: {
    display: "flex",
    justifyContent: "space-between",
  },
  tooltip: {
    fontSize: "18px",
  },
});
