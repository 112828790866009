/* eslint-disable multiline-ternary */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Editor from "../../components/editor/Editor";
import { useHistory, useLocation } from "react-router";
import consts from "../../libs/consts";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import {
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  FormControlLabel,
  Radio,
  CardMedia,
  Icon,
  InputBase,
  ButtonBase,
} from "@material-ui/core";
import MainLayout from "../../layouts/main/MainLayout";
import { Add } from "@material-ui/icons";
import Flex from "../../components/flex/Flex";
import MakerRows from "./MakerRows";
import { formatTime } from "../../libs/utils";

export default function PhysicalProductAdd() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const state = location.state || {};

  const [distributor, setDistributor] = useState("선택하기");
  const [productName, setProductName] = useState("");
  const [productDescrition, setProductDescrition] = useState("");
  const [category1, setCategory1] = useState("");
  const [category2, setCategory2] = useState("선택하기");
  const [supplyPrice, setSupplyPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [radioSelectValue, setRadioSelectValue] = useState("not_apply");
  const [salesStatus, setSalesStatus] = useState("on");
  const dispatch = useDispatch();
  const [orderCount, setOrderCount] = useState("");
  const [detail, setDetail] = useState("");
  const [testImageArr, setTestImageArr] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ds, setDs] = useState([]);
  const [htmlPath, setHtmlPath] = useState("");
  const [productId, setProductId] = useState();
  const [hotRank, setHotRank] = useState();
  const [makerdata, setmakerdatadata] = useState([]);

  const er = useRef();

  const [goldOrSilver, setgoldOrSilver] = useState("GOLD");
  const [gram, setgram] = useState(0);
  const [options, setoptions] = useState(true);
  const [optionsValue, setoptionsValue] = useState("");
  const [outsourcing, setoutsourcing] = useState("");
  const [pay, setpay] = useState(0);
  const [days, setdays] = useState(0);
  const [stock, setstock] = useState(0);
  const [visible, setvisible] = useState("VISIBLE");
  const [memocomment, setmemocomment] = useState("");
  const [productcomment, setproductcomment] = useState("");
  const [imagedel, setimagedel] = useState([]);

  const [recommendShopFee, setRecommendShopFee] = useState(0);
  const [outShopFee, setOutShopFee] = useState(0);

  let now = new Date();
  var today = new Date().toISOString().substring(0, 19).replace(/-/g, "");
  today = today.replace(/:/g, "");

  today = formatTime(new Date(), "YYYYMMDDTHHmmss");

  var productcode = "";
  if (goldOrSilver === "GOLD") {
    productcode = "GP" + today;
  }

  const [productBarcode, setProductBarCode] = useState(productcode);

  useEffect(() => {
    if (goldOrSilver === "GOLD") {
      productcode = "GP" + today;
    } else {
      productcode = "SP" + today;
    }
    setProductBarCode(productcode);
  }, [goldOrSilver]);

  useEffect(() => {
    if (state.action === "detail") {
      APIS.getProductDetail(state.id)
        .then((res) => {
          var rows = res.data.data;
          setProductId(rows.id);
          setProductBarCode(rows.code);
          setProductName(rows.name);
          setCategory1(rows.category);
          setgoldOrSilver(rows.goldOrSilver);
          setgram(rows.gram);
          setSellingPrice(rows.fees);
          setoptions(rows.options);
          setoutsourcing(rows.outsourcing);
          setpay(rows.pay);
          setdays(rows.days);
          setstock(rows.stock);
          setvisible(rows.visible);
          setHotRank(rows.hotRank);
          for (let i in rows.goodsInfoDtoList) {
            setmakerdatadata((makerdata) => [
              ...makerdata,
              {
                kind: rows.goodsInfoDtoList[i].text,
                id: rows.goodsInfoDtoList[i].id,
                label: rows.goodsInfoDtoList[i].label,
              },
            ]);
            //setCategories({...categories, );
          }

          setmemocomment(rows.memo);
          setTestImageArr(rows.imageIds);
          setoptionsValue(rows.optionValue);

          setproductcomment(rows.comment);

          // 매장수수료 추천,출고
          setRecommendShopFee(rows.recommendShopFee);
          setOutShopFee(rows.outShopFee);
        })
        .catch((err) => {
          dispatch(actionError(err));
        });
    }

    APIS.getGoodsCategoryList().then((res) => {
      setCategories(res.data);
    });
  }, []);
  const handleProductBarcode = (e) => {
    if (e.target.value.length > 100) {
      return;
    }
    setProductBarCode(e.target.value);
  };

  const handleDistributor = (e) => {
    setDistributor(e.target.value);
  };

  const handleProductName = (e) => {
    setProductName(e.target.value);
  };

  const handleProductDescription = (e) => {
    setProductDescrition(e.target.value);
  };

  const handleCategory1 = (e) => {
    setCategory1(e.target.value);
  };

  const handleCategory2 = (e) => {
    setCategory2(e.target.value);
  };

  const handleSupplyPrice = (e) => {
    setSupplyPrice(e.target.value);
  };

  const handleSellingPrice = (e) => {
    setSellingPrice(e.target.value);
  };

  const handleRadioBtn = (e) => {
    setRadioSelectValue(e.target.value);
    setOrderCount("");
  };

  const handleSalesStatus = (e) => {
    setSalesStatus(e.target.value);
  };

  const onClickCancelBtn = () => {
    history.goBack();
  };

  const onClickSaveBtn = async () => {
    var goodsInfoDtoList = [];
    for (var key in makerdata) {
      if (productId > 0) {
        goodsInfoDtoList.push({
          label: makerdata[key].label,
          text: makerdata[key].kind,
          id: makerdata[key].id,
        });
      } else {
        goodsInfoDtoList.push({
          label: makerdata[key].label,
          text: makerdata[key].kind,
        });
      }
    }

    if (productName === "") {
      dispatch(actionError("상품명을 입력해주세요."));
      return;
    }

    if (category1 === "") {
      dispatch(actionError("카테고리를 선택해주세요."));
      return;
    }

    if (days === 0) {
      dispatch(actionError("제작기간을 입력해주세요."));
      return;
    }

    ////////////////////////////////////////////////
    // dto 데이터를 json object에 담아줍니다.

    const goods = {
      id: productId,
      category: category1,
      comment: productcomment,
      days,
      fees: sellingPrice,
      goldOrSilver,
      gram,
      goodsInfoDtoList,
      memo: memocomment,
      name: productName,
      options,
      optionValue: optionsValue,
      outsourcing,
      pay,
      stock,
      type: 1,
      visible,
      deleteInfoIds: [],
      deleteFileIds: imagedel,
      recommendShopFee,
      outShopFee,
      hotRank: hotRank,
    };

    // json 데이터를 formData로 전송하려면 blob로 변환하는 과정이 필요합니다.
    const jsonString = JSON.stringify(goods);
    const blob = new Blob([jsonString], {
      type: "application/json",
    });

    const formData2 = new FormData();

    // dto data 추가
    formData2.append("goods", blob);

    // 파일은 이런식으로 추가하시면 됩니다.
    for (let i in testImageArr) {
      formData2.append("files", testImageArr[i]);
    }

    ////////////////////////////////////////////////
    if (productId > 0) {
      APIS.putModifyProduct(formData2, productId)
        .then(({ data: { success, data, message } }) => {
          if (!success) {
            dispatch(actionOpen(message, null, null, null, true));
          } else {
            dispatch(
              actionOpen(
                "상품 수정이 완료되었습니다.",
                () => {
                  history.goBack();
                },
                null,
                null,
                true
              )
            );
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    } else {
      APIS.postCreateProduct(formData2)
        .then(({ data: { success, data, message } }) => {
          if (!success) {
            dispatch(actionOpen(message, null, null, null, true));
          } else {
            dispatch(
              actionOpen(
                "상품 추가가 완료되었습니다.",
                () => {
                  history.goBack();
                },
                null,
                null,
                true
              )
            );
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };

  const onChangeImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const f = new FileReader();
      f.onload = () => {
        file.uri = f.result;
        setTestImageArr([...testImageArr, file]);
      };
      f.readAsDataURL(file);
      e.target.value = "";
    }
  };

  const handleOrderCount = (e) => {
    setOrderCount(e.target.value);
  };

  const makerAdd = () => {
    setmakerdatadata((makerdata) => [...makerdata, {}]);
  };
  const makerModify = (v, i) => {
    makerdata[i].kind = v;
  };
  const makerModifylabel = (v, i) => {
    makerdata[i].label = v;
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Typography variant="h6" className={classes.bold}>
          {productId ? "상품수정" : "상품등록"}
        </Typography>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}
        >
          <Grid item xs={12} className={classes.df}>
            <Grid
              item
              xs={5}
              className={[classes.dfsc, classes.subtitle].join(" ")}
            >
              <Typography
                style={{ marginBottom: "10px" }}
                variant="subtitle1"
                className={classes.bold}
              >
                기본정보
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column" }}
          >
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  상품코드
                </Typography>
              </Grid>
              <Grid
                item
                xs={9}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={productBarcode}
                  style={{ marginLeft: "20px" }}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  상품명
                </Typography>
              </Grid>
              <Grid
                item
                xs={9}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={productName}
                  onChange={handleProductName}
                  style={{ marginLeft: "20px" }}
                />
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  카테고리
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  select
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={category1}
                  onChange={handleCategory1}
                  style={{ marginLeft: "20px" }}
                >
                  <option selected disabled className={classes.selectForm}>
                    선택하기
                  </option>
                  {categories.map((item, idx) => {
                    return (
                      <option
                        key={idx}
                        value={item.id}
                        className={classes.selectForm}
                      >
                        {item.kind}
                      </option>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  중량
                </Typography>
              </Grid>
              <Grid
                item
                // xs={0}
                className={[classes.dfsc, classes.infoCell].join(" ")}
              >
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={goldOrSilver === "GOLD"}
                      onChange={(e) => setgoldOrSilver(e.target.value)}
                      value="GOLD"
                    />
                  }
                  label="금"
                  // labelPlacement="gold"
                />
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={goldOrSilver === "SILVER"}
                      onChange={(e) => setgoldOrSilver(e.target.value)}
                      value="SILVER"
                    />
                  }
                  label="은"
                  // labelPlacement="silver"
                />
              </Grid>

              <Grid
                item
                xs={6}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={gram}
                  onChange={(e) => setgram(e.target.value)}
                  style={{ marginLeft: "20px", width: "120px" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}
                >
                  g
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfcc, classes.infocell].join(" ")}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#B7ACAC",
                  }}
                >
                  인출수수료
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  variant="outlined"
                  value={sellingPrice}
                  onChange={handleSellingPrice}
                  style={{ marginLeft: "20px" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}
                >
                  원
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  선택옵션
                </Typography>
              </Grid>
              <Grid
                item
                // xs={0}
                className={[classes.dfsc, classes.infoCell].join(" ")}
              >
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={options === true}
                      onChange={(e) => setoptions(true)}
                      value={true}
                    />
                  }
                  label="있음"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={options === false}
                      onChange={(e) => setoptions(false)}
                      value={false}
                    />
                  }
                  label="없음"
                  labelPlacement="end"
                />
              </Grid>
              <Grid
                item
                xs={6}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={optionsValue}
                  onChange={(e) => setoptionsValue(e.target.value)}
                  style={{ marginLeft: "20px" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}
        >
          <Grid item xs={12} className={classes.df}>
            <Grid
              item
              xs={5}
              className={[classes.dfsc, classes.subtitle].join(" ")}
            >
              <Typography
                style={{ marginBottom: "10px" }}
                variant="subtitle1"
                className={classes.bold}
              >
                관리자 등록정보
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column" }}
          >
            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  외주제조사
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  fullWidth
                  type="text"
                  size="small"
                  variant="outlined"
                  value={outsourcing}
                  onChange={(e) => setoutsourcing(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>

              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  기본공임
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  variant="outlined"
                  value={pay}
                  onChange={(e) => setpay(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}
                >
                  원
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  제작기간
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={days}
                  onChange={(e) => setdays(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{ marginLeft: "10px", color: "#000" }}
                >
                  일
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  재고수량
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={stock}
                  onChange={(e) => setstock(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>

              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  매장수수료(추천)
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={recommendShopFee}
                  onChange={(e) => setRecommendShopFee(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>

              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  매장수수료(출고)
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  value={outShopFee}
                  onChange={(e) => setOutShopFee(e.target.value)}
                  style={{ marginLeft: "20px", width: "70%" }}
                />
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  노출상태
                </Typography>
              </Grid>
              <Grid
                item
                // xs={0}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={visible === "VISIBLE"}
                      onChange={(e) => setvisible(e.target.value)}
                      value="VISIBLE"
                    />
                  }
                  label="노출"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      className={classes.radioBtn}
                      checked={visible === "HIDDEN"}
                      onChange={(e) => setvisible(e.target.value)}
                      value="HIDDEN"
                    />
                  }
                  label="노출안함"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}
        >
          <Grid
            item
            xs={12}
            style={{
              alignSelf: "stretch",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            className={[classes.dfsc, classes.subtitle].join(" ")}
          >
            <Typography variant="subtitle1" className={classes.bold}>
              추가정보
            </Typography>

            <ButtonBase style={{ marginRight: 20 }} onClick={() => makerAdd()}>
              <Add />
              필드 추가하기
            </ButtonBase>
          </Grid>

          <Divider className={classes.divider} />
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent, classes.ckeditor].join(
              " "
            )}
            style={{ flexDirection: "column" }}
          >
            {makerdata.map((x, i) => {
              return (
                // <>
                <MakerRows
                  key={Math.random()}
                  rows={x}
                  i={i}
                  makerModify={makerModify}
                  makerModifylabel={makerModifylabel}
                />
                // </>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}
        >
          <Grid
            item
            xs={12}
            className={[classes.dfsc, classes.subtitle].join(" ")}
          >
            <Typography variant="subtitle1" className={classes.bold}>
              메모
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent, classes.ckeditor].join(
              " "
            )}
            style={{ flexDirection: "column", height: "500px" }}
          >
            <InputBase
              multiline
              style={{
                flex: 1,
                border: "1px solid #f5f5f5",
              }}
              inputProps={{
                style: {
                  minHeight: "300px",
                },
              }}
              value={memocomment}
              onChange={(e) => {
                setmemocomment(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}
        >
          <Grid
            item
            xs={12}
            className={[classes.dfsc, classes.subtitle].join(" ")}
          >
            <Typography variant="subtitle1" className={classes.bold}>
              상품 이미지
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent].join(" ")}
            style={{ flexDirection: "column" }}
          >
            <Grid item xs={12} className={classes.df}>
              <Grid
                item
                xs={1}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                <Typography variant="subtitle2" style={{ color: "#B7ACAC" }}>
                  상품사진
                </Typography>
              </Grid>
              <Grid
                item
                xs={11}
                className={[classes.dfsc, classes.infocell].join(" ")}
              >
                {(() => {
                  return testImageArr.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className={classes.df}
                        style={{ flexDirection: "column" }}
                      >
                        <div
                          className={classes.dfcc}
                          style={{ width: "150px", marginLeft: "16px" }}
                        >
                          <CardMedia
                            component="img"
                            image={
                              item.path
                                ? consts.s3BaseUrl + "/" + item.path
                                : item.uri
                            }
                            width="100"
                          />
                        </div>
                        <Grid item xs={12} className={classes.dfcs}>
                          <Button
                            onClick={() => {
                              setTestImageArr([
                                ...testImageArr.filter((x, i) => i !== idx),
                              ]);
                              setimagedel([...imagedel, item.id]);
                            }}
                            size="small"
                          >
                            <Typography variant="subtitle2">삭제</Typography>
                          </Button>
                        </Grid>
                      </div>
                    );
                  });
                })()}
                <>
                  <input
                    accept="image/*"
                    type="file"
                    id="rasied-button-file"
                    multiple
                    style={{ display: "none" }}
                    onChange={onChangeImage}
                  />
                  <label htmlFor="rasied-button-file">
                    <Button className={classes.imageAddBtn} component="span">
                      <Icon fontSize="large">add</Icon>
                    </Button>
                  </label>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={[classes.infoGrid, classes.df].join(" ")}
          style={{ flexDirection: "column" }}
        >
          <Grid
            item
            xs={12}
            className={[classes.dfsc, classes.subtitle].join(" ")}
          >
            <Typography variant="subtitle1" className={classes.bold}>
              상품 설명
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            item
            xs={12}
            className={[classes.df, classes.infoContent, classes.ckeditor].join(
              " "
            )}
            style={{ flexDirection: "column", height: "500px" }}
          >
            {/*
            <Editor
              ref={er}
              htmlPath={htmlPath}
              html={detail}
              onHtmlChange={setDetail}
            />
            */}
            <InputBase
              multiline
              style={{
                flex: 1,
                border: "1px solid #f5f5f5",
              }}
              inputProps={{
                style: {
                  minHeight: "300px",
                },
              }}
              value={productcomment}
              onChange={(e) => {
                setproductcomment(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.dfcc}
          style={{ marginBottom: "30px" }}
        >
          <Button
            variant="outlined"
            onClick={onClickCancelBtn}
            style={{ backgroundColor: "#fff", color: "#000" }}
          >
            <Typography variant="subtitle1">취소</Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={onClickSaveBtn}
            style={{
              marginLeft: "10px",
              backgroundColor: "#4664FD",
              color: "#fff",
            }}
          >
            <Typography variant="subtitle1">저장</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles({
  container: {
    width: "calc(100%)",
    height: "100%",
    overflow: "auto",
    paddingTop: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "50px",
    backgroundColor: "rgb(250,250,250)",
  },
  titleGrid: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
    marginBottom: "20px",
  },
  subtitle: {
    marginTop: "10px",
    marginLeft: "20px",
  },
  divider: {
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  df: {
    display: "flex",
  },
  dfcc: {
    display: "flex",
    alignItems: "center",
  },
  dfcs: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  dfsc: {
    display: "flex",
    textAlign: "left",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  infoGrid: {
    border: "1px solid #B7ACAC",
    backgroundColor: "#fff",
    marginBottom: "20px",
  },
  infoContent: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
  },
  infocell: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
  radioBtn: {
    marginLeft: "20px",
  },
  imageAddBtn: {
    width: "100px",
    height: "100px",
    border: "2px dotted #efefef",
  },
  ckeditor: {
    marginTop: "30px",
    marginBottom: "30px",
    "& .ck-editor__editable_inline": {
      minHeight: "300px",
    },
  },
  productAddBtn: {
    width: "120px",
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  selectForm: {
    cursor: "pointer",
    paddingLeft: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0, 0.1)",
    },
  },
  withDrawalBtn: {
    backgroundColor: "#e9e9e9",
    margin: "10px",
    "&:hover": {
      backgroundColor: "#e9e9e9",
    },
  },
  row: {
    borderBottom: "1px solid rgba(0,0,0,0.23)",
    alignSelf: "stretch",
    justifyContent: "space-between",
    marginRight: "50%",
    padding: "10px 0px",
    alignItems: "center",
  },
});
