import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "../../../libs/utils";
import { actionError } from "../../../redux/action/ActionActions";
import * as APIS from "../../../libs/apis";
import { objToQueryStr } from "../../../services/utils";
import Flex from "../../../components/flex/Flex";

const StatusRadioGroup = ({
  status,
  shipmentStatus,
  setShipmentStatus,
  statussave,
  shipmentStatusSave,
  setStatus,
  histories = [],
}) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const { isKorda } = useSelector((s) => s.auth);
  const [confirmDay, setConfirmDay] = useState("");

  useEffect(() => {
    APIS.getTradeConfig(
      objToQueryStr({ config: "CAN_GOODS_CONFIRM_DAY" })
    ).then(({ data: { data, success, message } }) => {
      if (success) {
        setConfirmDay(data.value);
      }
    });
  }, []);

  return (
    <RadioGroup row style={{ alignSelf: "flex-end", marginRight: 50 }}>
      <Flex>
        <Flex row>
          <Flex className={classes.statusName}>인출 상태</Flex>
          <FormControlLabel
            control={
              <Radio
                style={{ color: "black" }}
                color="default"
                disabled={
                  statussave === "APPROVAL" || statussave === "CANCEL"
                    ? false
                    : true
                }
                checked={status === "CANCEL"}
                onChange={(e) => {
                  if (isKorda && statussave === "APPROVAL") {
                    setStatus(e.target.value);
                  }
                }}
                value="CANCEL"
              />
            }
            label="결제취소"
          />
          <FormControlLabel
            control={
              <Radio
                style={{ color: "black" }}
                color="default"
                disabled={statussave === "APPROVAL" ? false : true}
                checked={status === "APPROVAL"}
                onChange={(e) => {
                  if (isKorda && statussave === "APPROVAL") {
                    setStatus(e.target.value);
                  }
                }}
                value="APPROVAL"
              />
            }
            label="결제"
          />
          <FormControlLabel
            control={
              <Radio
                style={{ color: "black" }}
                color="default"
                disabled={
                  statussave === "CANCEL" || statussave === "CONFIRM"
                    ? true
                    : false
                }
                checked={status === "TAKEOUT"}
                onChange={(e) => {
                  if (statussave === "APPROVAL") {
                    setStatus(e.target.value);
                  }
                }}
                value="TAKEOUT"
              />
            }
            label="인출완료"
          />
          <FormControlLabel
            control={
              <Radio
                style={{ color: "black" }}
                color="default"
                disabled={statussave === "CANCEL" ? true : false}
                checked={status === "CONFIRM"}
                onChange={(e) => {
                  if (isKorda && statussave === "TAKEOUT") {
                    const takeoutCreatedAt = histories.find(
                      ({ status }) => status === "TAKEOUT"
                    )["createdAt"];

                    const canConfirmDate = new Date(takeoutCreatedAt);
                    canConfirmDate.setDate(
                      canConfirmDate.getDate() + +confirmDay
                    );

                    if (new Date() < canConfirmDate) {
                      dispatch(
                        actionError(
                          `[인출완료]로부터 ${confirmDay}일(${formatTime(
                            canConfirmDate,
                            "YYYY-MM-DD HH:mm:SS"
                          )}) 이후 [인출확정] 하실 수 있습니다.`
                        )
                      );
                    } else {
                      setStatus(e.target.value);
                    }
                  }
                }}
                value="CONFIRM"
              />
            }
            label="인출확정"
          />
        </Flex>
        <Flex row>
          <Flex className={classes.statusName}>출고 상태</Flex>
          <FormControlLabel
            control={
              <Radio
                style={{ color: "black" }}
                color="default"
                disabled={statussave === "CANCEL" ? true : false}
                checked={shipmentStatus === "MAKE"}
                onChange={(e) => {
                  if (
                    isKorda &&
                    shipmentStatusSave === "NOT_SHIPPED" &&
                    (statussave === "APPROVAL" ||
                      statussave === "TAKEOUT" ||
                      statussave === "CONFIRM")
                  ) {
                    setShipmentStatus(e.target.value);
                  }
                }}
                value="MAKE"
              />
            }
            label="제작중"
          />
          <FormControlLabel
            control={
              <Radio
                style={{ color: "black" }}
                color="default"
                disabled={statussave === "CANCEL" ? true : false}
                checked={shipmentStatus === "SHIPMENT"}
                onChange={(e) => {
                  if (
                    isKorda &&
                    shipmentStatusSave === "MAKE" &&
                    (statussave === "APPROVAL" ||
                      statussave === "TAKEOUT" ||
                      statussave === "CONFIRM")
                  ) {
                    setShipmentStatus(e.target.value);
                  }
                }}
                value="SHIPMENT"
              />
            }
            label="출고중"
          />
          <FormControlLabel
            control={
              <Radio
                style={{ color: "black" }}
                color="default"
                disabled={statussave === "CANCEL" ? true : false}
                checked={shipmentStatus === "DELIVERY"}
                onChange={(e) => {
                  if (
                    shipmentStatusSave === "SHIPMENT" &&
                    (statussave === "APPROVAL" ||
                      statussave === "TAKEOUT" ||
                      statussave === "CONFIRM")
                  ) {
                    setShipmentStatus(e.target.value);
                  }
                }}
                value="DELIVERY"
              />
            }
            label="출고완료"
          />
        </Flex>
      </Flex>

      {/* <FormControlLabel
        control={
          <Radio
            style={{ color: "black" }}
            color="default"
            checked={status === "CANCEL"}
            onChange={(e) => setStatus(e.target.value)}
            value="CANCEL"
          />
        }
        label="반려(취소)"
      /> */}
    </RadioGroup>
  );
};

export default StatusRadioGroup;

const useStyle = makeStyles({
  statusName: {
    alignSelf: "center",
    fontFamily: "NotoSansKR-Bold",
    marginRight: 20,
  },
});
