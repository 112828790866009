import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonRadios from "../../components/button-radios/ButtonRadios";
import Filters from "../../components/filters/Filters";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import routes from "../../libs/routes";
import * as APIS from "../../libs/apis";
import { openModal } from "../../redux/modal/ModalReducer";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { getDefaultPageSize, STATUS_CODE } from "../../libs/consts";
import { objToQueryStr } from "../../services/utils";
import { actionError } from "../../redux/action/ActionActions";

export default function MemberShop({}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { state = {} } = location;
  const { page = 0, size = 10 } = state;
  const [cities, setCities] = useState([]);
  const [shops, setShops] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchList = () => {
    const paramQuery = objToQueryStr({
      page: state.page,
      size: state.size,
      city: state?.city,
      shopName: state?.shopName,
    });
    dispatch(loadingStart);
    if (Object.keys(state).length > 0) {
      APIS.getShops(paramQuery)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            setShops(
              data.content.map((item) => {
                const { status } = item;

                return {
                  ...item,
                  bank: `${item.bankName} ${item.bankAccountName}`,
                  status: STATUS_CODE[status] || status,
                };
              })
            );
            setTotalCount(data.totalElements);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((e) => {
          console.log("catch", e);
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getCity()
      .then(({ data: { success, data } }) => {
        const cities = [];
        for (let key in data) {
          cities.push({ label: data[key], value: key });
        }
        setCities(cities);
      })
      .finally(() => dispatch(loadingEnd));
  }, []);
  // useEffect(() => {
  //   const defaultPageSize = getDefaultPageSize();
  //   history.replace(location.pathname, {
  //     ...state,
  //     ...defaultPageSize,
  //   });
  // }, [city]);

  useEffect(() => {
    fetchList();
  }, [page, size]);

  return (
    <>
      <PageLabel
        add={{
          title: "매장 추가",
          onClick: () => {
            history.push(routes.memberShopAdd);
          },
        }}
      >
        매장 관리
      </PageLabel>
      {/* <div style={{ marginLeft: 50, marginTop: 30 }}>
        <ButtonRadios
          data={[{ label: "전체", value: "" }, ...cities]}
          field="city"
          buttonStyle={{ marginTop: 10 }}
        />
      </div> */}
      <Filters
        onSearch={fetchList}
        defaultState={{ ...getDefaultPageSize() }}
        data={[
          {
            label: "지역",
            type: "menu",
            data: cities,
            key: "city",
          },
          {
            label: "매장명",
            type: "input",
            placeholder: "매장명",
            key: "shopName",
          },
        ]}
      />
      <DataTable
        data={shops}
        totalCount={totalCount}
        columns={[
          { label: "지역", key: "city" },
          { label: "매장명", key: "name" },
          { label: "매장코드", key: "code" },
          { label: "사업주", key: "managerName" },
          { label: "연락처", key: "managerPhone" },
          { label: "사업자등록번호", key: "businessNumber" },
          { label: "정산계좌", key: "bank" },
          { label: "전화번호", key: "phone" },
          { label: "주소", key: "address" },
          { label: "추천회원수", key: "recommendedCount", type: "number" },
          { label: "상태", key: "status" },
        ]}
        onExcelDownload={() => {}}
        onClick={({ id }) => {
          history.push(routes.memberShopDetail, { id });
        }}
      />
    </>
  );
}
