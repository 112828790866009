import { makeStyles, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import LabelTabs from "../../components/label-tabs/LabelTabs";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import Flex from "../../components/flex/Flex";
import Button from "../../components/button/Button";
import CategoryRows from "../physical-product/CategoryRows";
import SubcategoryList from "../physical-product/SubcategoryList";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import SubClassificationManagementList from "./SubClassificationManagementList";
import ClassificationManagementRows from "./ClassificationManagementRows";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionOpen, actionError } from "../../redux/action/ActionActions";

export default function ClassificationManagement({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [categorydata, setcategorydata] = useState([]);
  const [categoryid, setcategoryid] = useState([]);
  const [subcategoryid, setsubcategoryid] = useState(0);
  const [subCategoryName, setSubCategoryName] = useState("");
  // const [newcategorydata, setnewcategorydata] = useState([]);
  // const [newcategoryreser, setnewcategoryreser] = useState("");
  // const [newcategoryid, setnewcategoryid] = useState([]);

  const categoryAdd = () => {
    setcategorydata((categorydata) => [...categorydata, {}]);
  };

  const categoryselectfunc = (categoryId, e) => {
    if (e.target.checked === true) {
      setcategoryid((categoryid) => [...categoryid, { categoryId }]);
    } else {
      setcategoryid(
        categoryid.filter((categoryid) => categoryid.categoryId !== categoryId)
      );
    }
  };
  const setsubcategoryidFunc = (id, name) => {
    setsubcategoryid(0);
    setTimeout(function () {
      setsubcategoryid(id);
      setSubCategoryName(name);
    }, 100);
  };

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getDirectCategoryList()
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setcategorydata(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  }, []);
  return (
    <>
      <Flex row style={{ height: 650 }}>
        <Flex style={{ flex: 1, margin: "0px 50px", height: 650 }}>
          <Flex style={{ flex: 1, height: 650 }}>
            <Flex row className={classes.header}>
              <span className={classes.label}>카테고리 관리</span>
              <Grid item xs={6} className={classes.gridHeaderRow}>
                <Button
                  onClick={() => categoryAdd()}
                  label="추가"
                  className={classes.bt1}
                  style={{ width: "70px", marginRight: "10px" }}
                />
              </Grid>
            </Flex>
            <Flex className={classes.list}>
              <table className={classes.table} cellPadding="0" cellSpacing="0">
                <thead>
                  <tr>
                    <th>순서</th>
                    <th>No</th>
                    <th>카테고리</th>
                    <th>사용여부</th>
                    <th>수정</th>
                  </tr>
                </thead>
                <tbody>
                  {categorydata?.map((x, i) => {
                    return (
                      <ClassificationManagementRows
                        key={Math.random()}
                        rows={x}
                        i={i}
                        // setcategoryreser={setcategoryreser}
                        categoryselectfunc={categoryselectfunc}
                        before={categorydata[i - 1]}
                        after={categorydata[i + 1]}
                        setsubcategoryidFunc={setsubcategoryidFunc}
                        list={categorydata}
                        setList={setcategorydata}
                      />
                    );
                  })}
                </tbody>
              </table>
            </Flex>
          </Flex>
        </Flex>
        <Flex style={{ flex: 1, margin: "0px 50px" }}>
          {subcategoryid > 0 && (
            <SubClassificationManagementList
              subcategoryid={subcategoryid}
              subCategoryName={subCategoryName}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
}

const useStyle = makeStyles({
  label: {
    fontFamily: fonts.notoSansKRBold,
  },
  gridHeaderRow: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bt1: {
    "& span": { color: "#000" },
    border: "1px solid black",
    backgroundColor: "#fff",
    marginLeft: 10,
    height: 40,
  },
  list: {
    minHeight: "30%",
    border: "1px solid black",
    overflowY: "scroll",
    height: "90%",
  },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  header: {
    // marginBottom: 20,
    marginTop: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
});
