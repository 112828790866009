import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Filters from "../../components/filters/Filters";
import Flex from "../../components/flex/Flex";
import PageLabel from "../../components/page-label/PageLabel";
import DataTable from "../../components/table/DataTable";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import * as APIS from "../../libs/apis";
import { objToQueryStr } from "../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import { actionError } from "../../redux/action/ActionActions";
import moment from "moment";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { formatTime, numberWithCommas } from "../../libs/utils";
import ContextMenuLayout from "../../components/excel-download/ContextMenuLayout";

const SHOP_FEE_STATUS = [
  { label: "미완료", value: "NEW" },
  { label: "정산완료", value: "DONE" },
  { label: "취소", value: "CANCEL" },
];

export default function SettlementShopFee({}) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const location = useLocation();
  const state = location.state || {};
  const [shopFees, setShopFees] = useState([]);
  const [modifyStatus, setModifyStatus] = useState("DONE");
  const [checkedList, setCheckedList] = useState([]);
  const [summary, setSummary] = useState({});

  const auth = useSelector((s) => s.auth);
  const { isKorda, shopId } = auth;

  useEffect(() => {
    // fetchList();
  }, []);

  const getDefaultState = () => {
    return {
      fromDate:
        new Date().getDate() <= 15
          ? moment().format("YYYY-MM-01")
          : moment().format("YYYY-MM-16"),
      toDate:
        new Date().getDate() <= 15
          ? moment().format("YYYY-MM-15")
          : moment().endOf("month").format("YYYY-MM-DD"),
      sort: "registrationDate",
    };
  };

  const fetchList = () => {
    const { fromDate, toDate } = state;
    if (!fromDate || !toDate) {
      dispatch(actionError("기간을 설정해주세요."));
      return;
    }

    const param = { ...state };

    param["fromDate"] =
      new Date(fromDate).getDate() <= 15
        ? moment(fromDate).format("YYYY-MM-01")
        : moment(fromDate).format("YYYY-MM-16");

    param["toDate"] =
      new Date(toDate).getDate() <= 15
        ? moment(toDate).format("YYYY-MM-15")
        : moment(toDate).endOf("month").format("YYYY-MM-DD");

    if (!isKorda) {
      param["shopId"] = shopId;
    }

    // if(param["isFeeAsc"] === "option") {
    //   delete param['isFeeAsc'];
    // }

    dispatch(loadingStart);
    APIS.getShopFeeList(objToQueryStr(param))
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setShopFees(data);
          setCheckedList([]);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));

    APIS.getShopFeeSummary(objToQueryStr(param))
      .then(({ data: { data, message, success } }) => {
        if (success) {
          setSummary(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((error) => dispatch(actionError(error)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleClickSave = () => {
    if (!checkedList.length) {
      dispatch(actionError("목록을 선택해주세요."));
      return;
    }

    dispatch(loadingStart);
    APIS.postModifyShopFee({
      settleStatus: modifyStatus,
      settleIdList: checkedList,
    })
      .then(({ data: { data, message, success } }) => {
        if (success) {
          fetchList();
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const { fromDate, toDate, sumTradeKrw, sumTotal, sumSupply, sumVat } =
    summary;

  return (
    <>
      <PageLabel>가맹점 수수료</PageLabel>
      <Filters
        defaultState={getDefaultState()}
        data={[
          { label: "기간", type: "date-range", key: "fromDate,toDate" },
          isKorda && {
            label: "매장검색",
            type: "input",
            placeholder: "매장명, 연락처",
            key: "shopKeyword",
          },
          {
            label: "정산여부",
            type: "menu",
            key: "settleStatus",
            data: SHOP_FEE_STATUS,
          },

          {
            label: "정렬순서",
            type: "menu",
            key: "sort",
            data: [
              { label: "수수료 금액 큰 순서", value: "feeDesc" },
              { label: "수수료 금액 작은 순서", value: "feeAsc" },
              { label: "가맹점 등록일", value: "registrationDate" },
            ],
            notEmpty: true,
          },
        ]}
        onSearch={fetchList}
      />

      <Flex row className={classes.tableHeader}>
        <Flex>
          <Flex row className={"row-center"}>
            {fromDate && toDate && (
              <Text font={fonts.notoSansKRBold}>
                조회기간 : {formatTime(fromDate, "YYYY.MM.DD")} ~{" "}
                {formatTime(toDate, "YYYY.MM.DD")}
              </Text>
            )}
          </Flex>
          <Flex row className={"row-center"}>
            <Text font={fonts.notoSansKRBold}>
              목록 검색결과 {shopFees.length}
            </Text>
            <Text font={fonts.notoSansKRBold} className={classes.label}>
              총 거래금액 : {numberWithCommas(sumTradeKrw)} 원
            </Text>
            <Text font={fonts.notoSansKRBold} className={classes.label}>
              총 수수료 : {numberWithCommas(sumTotal)} 원
              <Text>
                {" "}
                (공급가 : {numberWithCommas(sumSupply)} 원 / 부가세 :{" "}
                {numberWithCommas(sumVat)} 원)
              </Text>
            </Text>
          </Flex>
        </Flex>
        <Flex>
          <Flex row className={classes.item}>
            상태변경
            <select
              onChange={(e) => {
                setModifyStatus(e.target.value);
              }}
              value={modifyStatus}
            >
              {SHOP_FEE_STATUS.filter(({ value }) => value !== "NEW").map(
                ({ label, value }) => {
                  return <option label={label} value={value} />;
                }
              )}
            </select>
            <Button label="저장" onClick={handleClickSave} />
          </Flex>
        </Flex>
      </Flex>

      <ShopFeeTable
        data={shopFees.map((row, rowIndex) => {
          const no = row.id;
          const recommendTotal = row.recommendFee + row.recommendVat;
          const outTotal = row.outFee + row.outVat;
          const totalFee =
            row.recommendFee + row.outFee + row.vat + row.appraisalFee;
          const supplyFee = row.recommendFee + row.outFee + row.appraisalFee;

          let status = "";
          let updatedAt = row.updatedAt;
          switch (row.status) {
            case "NEW":
              status = "미완료";
              updatedAt = "";
              break;
            case "DONE":
              status = "정산완료";
              break;
            case "CANCEL":
              status = "취소";
              break;
          }
          return {
            ...row,
            no,
            recommendTotal,
            outTotal,
            status,
            totalFee,
            supplyFee,
            updatedAt,
          };
        })}
        checkedList={checkedList}
        setCheckedList={setCheckedList}
      />
    </>
  );
}

const ShopFeeTable = ({ data, checkedList, setCheckedList }) => {
  const classes = useStyle();
  const handleChangeAllCheck = (e) => {
    if (e.target.checked) {
      setCheckedList(
        data
          .filter(({ shopType }) => shopType === "FRANCHISEE")
          .filter(({ toDate }) => new Date(toDate) < new Date())
          .map(({ id }) => id)
      );
    } else {
      setCheckedList([]);
    }
  };

  const handleChangeCheck = (id) => {
    const checked = checkedList.includes(id);

    if (checked) {
      setCheckedList(checkedList.filter((prev) => prev !== id));
    } else {
      setCheckedList([...checkedList, id]);
    }
  };

  const headerRows = [
    [
      { label: "NO", rowSpan: 2, width: 50 },
      { label: "from", rowSpan: 2 },
      { label: "to", rowSpan: 2 },
      { label: "정산매장", rowSpan: 2 },
      { label: "가맹점 코드", rowSpan: 2 },
      { label: "사업자 번호", rowSpan: 2 },
      { label: "사업주명", rowSpan: 2 },
      { label: "은행명", rowSpan: 2 },
      { label: "정산계좌", rowSpan: 2 },
      { label: "거래금액", rowSpan: 2 },
      { label: "추천수수료", colSpan: 1 },
      { label: "출고수수료", colSpan: 1 },
      { label: "감정수수료", colSpan: 1 },
      { label: "총 수수료", colSpan: 3 },
      { label: "정산여부", rowSpan: 2 },
      { label: "처리일시", rowSpan: 2 },
      {
        // label: "선택",
        rowSpan: 2,
        width: 50,
        render: (
          <Checkbox
            checked={
              data.filter(({ shopType }) => shopType === "FRANCHISEE").length &&
              data
                .filter(({ shopType }) => shopType === "FRANCHISEE")
                .filter(({ toDate }) => new Date(toDate) < new Date())
                .length === checkedList.length &&
              checkedList.length > 0
            }
            onChange={handleChangeAllCheck}
          />
        ),
      },
    ],
    [
      { label: "공급" },
      { label: "공급" },
      { label: "공급" },
      { label: "공급" },
      { label: "부가세" },
      { label: "합계" },
    ],
  ];

  const columns = [
    { key: "no", label: "NO", align: "center" },
    { key: "fromDate", label: "from", align: "center" },
    { key: "toDate", label: "to", align: "center" },
    { key: "shopName", label: "정산매장", align: "center" },
    { key: "shopCode", label: "가맹점 코드", align: "center" },
    { key: "shopBusinessNo", label: "사업자 번호", align: "center" },
    { key: "shopOwner", label: "사업주명" },
    { key: "bankName", label: "은행명" },
    { key: "accountNumber", label: "정산계좌" },
    { key: "tradeKrw", label: "거래금액", align: "right", type: "number" },
    {
      key: "recommendFee",
      label: "추천수수료 공급",
      align: "right",
      type: "number",
    },
    {
      key: "outFee",
      label: "출고수수료 공급",
      align: "right",
      type: "number",
    },
    {
      key: "appraisalFee",
      label: "감정수수료 공급",
      align: "right",
      type: "number",
    },
    {
      key: "supplyFee",
      label: "총 수수료 공급",
      align: "right",
      type: "number",
    },
    {
      key: "vat",
      label: "총 수수료 부가세",
      align: "right",
      type: "number",
    },
    {
      key: "totalFee",
      label: "총 수수료 합계",
      align: "right",
      type: "number",
    },
    { key: "status", label: "정산여부", align: "center" },
    { key: "updatedAt", label: "처리일시", align: "center" },
  ];
  return (
    <Flex style={{ margin: "0 50px 0 50px" }}>
      <ContextMenuLayout name="가맹점수수료" headers={columns} data={data}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              {headerRows.map((row) => {
                return (
                  <TableRow>
                    {row.map(({ label, rowSpan, colSpan, width, render }) => {
                      return (
                        <TableCell
                          rowSpan={rowSpan}
                          colSpan={colSpan}
                          width={width}
                        >
                          {render || label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody>
              {data.map((row) => {
                return (
                  <TableRow
                    onClick={() => {
                      row["shopType"] === "FRANCHISEE";
                      // && handleChangeCheck(row.id);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {columns.map(({ key, align, type }) => {
                      const defaultAlign = type === "number" ? "right" : "left";
                      return (
                        <TableCell align={align || defaultAlign}>
                          {type === "number"
                            ? numberWithCommas(row[key])
                            : row[key]}
                        </TableCell>
                      );
                    })}
                    {new Date(row["toDate"]) < new Date() ? (
                      <TableCell align="center">
                        {row["shopType"] === "FRANCHISEE" && (
                          <Checkbox
                            checked={checkedList.includes(row.id)}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleChangeCheck(row.id);
                            }}
                            style={{ margin: 0, padding: 0 }}
                          />
                        )}
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ContextMenuLayout>
    </Flex>
  );
};

const useStyle = makeStyles({
  item: {
    marginTop: 20,
    alignSelf: "flex-end",
    // marginRight: 50,
    alignItems: "center",
    "& select": {
      margin: " 0px 10px",
    },
  },
  label: {
    marginLeft: 50,
    "& span": { fontSize: 14, color: "#444" },
  },
  tableHeader: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "flex-end",
    margin: "10px 50px",
  },
  table3: {
    borderLeft: "1px solid rgba(0,0,0,0.23)",
    borderTop: "1px solid rgba(0,0,0,0.23)",
    margin: "0px 50px 10px 50px",
    "& th": {
      backgroundColor: "#f5f5f5",
      padding: 5,
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
    "& td": {
      borderRight: "1px solid rgba(0,0,0,0.23)",
      textAlign: "center",
      padding: 8,
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  tableContainer: {
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    "& th, td": {
      padding: 5,
      border: "1px solid rgba(224, 224, 224, 1)",
    },
    "& th": {
      fontFamily: fonts.notoSansKRBold,
      backgroundColor: "#f5f5f5",
      textAlign: "center",
    },
    "& tr:hover": {
      backgroundColor: "#ededed",
    },
  },
});
