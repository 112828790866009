import * as React from "react";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import Flex from "../flex/Flex";

import {
  Checkbox,
  ButtonBase,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Menu,
  MenuItem,
  TableFooter,
  Paper,
} from "@material-ui/core";
import Text from "../text/Text";
import fonts from "../../libs/fonts";
import colors from "../../libs/colors";
import Button from "../button/Button";
import { useHistory, useLocation } from "react-router";
import { formatTime, numFormat } from "../../libs/utils";
import consts, { STORAGE_KEY } from "../../libs/consts";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useRef } from "react";
import { formatPhone } from "../../services/utils";
export default function DataTable({
  columns = [],
  data = [],
  onAdd,
  labelEndComponent,
  optionComponent,
  hideLabel,
  labelComponent,
  onClick,
  totalCount,
  dashboard,
  onExcelDownload,
  onPageChanged,
  onSelectAllClick,
  clicktype,
  numSelected,
  rowCount,
  pageable = true,
  route,
  useCheckbox = false,
  onChangeCheckbox,
  title = "",
  name = "",
  isFixed,
  contextMenuVisible = true,
  rootStyle,
  tableBodyStyle,
  containerStyle,
}) {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation({});
  const state = location.state || {};
  const [selected, setSelected] = React.useState([]);

  const [contextMenu, setContextMenu] = useState(null);
  const excelRef = useRef(null);

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };
  if (isNaN(state.page)) {
    return null;
  }

  const handleChangeCheckbox = ({ target: { checked, value } }) => {
    let newselected = [...selected];
    if (checked) {
      newselected.push(+value);
    } else {
      const deleteIndex = selected.indexOf(+value);
      newselected.splice(deleteIndex, 1);
    }
    handleValueChange("selected", newselected);
    setSelected(newselected);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleClickDownloadExcel = () => {
    handleCloseContextMenu();
    excelRef.current.link.click();
  };

  const mapDataToCsv = () => {
    const headers = columns.filter(({ key }) => key);
    const csvData = data.map((row) => {
      const csvRow = {};
      headers.forEach(({ key, label }) => {
        const data = row[key] || "-";
        if (isNaN(data)) {
          csvRow[label] = data;
        } else {
          csvRow[label] = `=""${data}""`;
        }
      });
      return csvRow;
    });

    return csvData;
  };

  return (
    <Flex className={rootStyle ? rootStyle : classes.root}>
      {(!hideLabel || Boolean(onExcelDownload)) && (
        <Flex row className={classes.header}>
          <Flex row className="row-center">
            {labelComponent && labelComponent}
            {!hideLabel && (
              <Text font={fonts.notoSansKRMedium}>
                {title ? title : "목록 검색결과"}
                <Text
                  font={fonts.notoSansKRMedium}
                  style={{ color: colors.red, fontSize: "20px" }}
                >
                  {totalCount ? totalCount || 0 : ""}
                </Text>
              </Text>
            )}
            {labelEndComponent}
          </Flex>

          {/* <Flex row>
            {optionComponent}
            {Boolean(onExcelDownload) && (
              <Button
                onClick={onExcelDownload}
                label="엑셀 다운로드"
                className={classes.button}
                classNameLabel={classes.buttonLabel}
              />
            )}
          </Flex> */}
        </Flex>
      )}
      {dashboard && (
        <Table className={classes.tableDashboard} aria-label="simple table">
          <TableHead className={classes.th}>
            <TableRow>
              {dashboard.map((x, i) => {
                return (
                  <TableCell
                    colSpan={3}
                    component="th"
                    key={i.toString()}
                    align="center"
                  >
                    {x.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {dashboard.map((x, index) => {
                return (
                  <TableCell
                    key={index.toString()}
                    align="center"
                    component="td"
                    colSpan={3}
                  >
                    {x.value || "Default Value"}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      )}
      <Paper>
        <TableContainer
          className={containerStyle ? containerStyle : classes.container}
          onContextMenu={contextMenuVisible && handleContextMenu}
        >
          <Table
            stickyHeader
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead className={classes.th}>
              <TableRow>
                {useCheckbox && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        state?.selected?.length === data?.length &&
                        state?.selected?.length > 0
                      }
                      onChange={(e) => {
                        if (!e.target.checked) {
                          handleValueChange("selected", []);
                        } else {
                          let selectedArray = [];
                          data?.forEach((item) => {
                            const { id } = item;
                            selectedArray.push(id);
                          });
                          handleValueChange("selected", selectedArray);
                        }
                      }}
                    />
                  </TableCell>
                )}
                {!isFixed && (
                  <TableCell
                    colSpan={1}
                    style={{ width: 50 }}
                    component="th"
                    align="left"
                  >
                    NO
                  </TableCell>
                )}
                {columns.map((x, i) => {
                  return (
                    <TableCell
                      colSpan={3}
                      component="th"
                      key={i.toString()}
                      align="center"
                      style={{ fontWeight: "bolder" }}
                    >
                      {x.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className={tableBodyStyle}>
              {data.map((row, i) => (
                <TableRow
                  className={
                    row.status === "BLIND" || row.status === "문의 종료"
                      ? classes.blind
                      : classes.active
                  }
                  key={i.toString()}
                  onClick={() => {
                    if (clicktype !== "checkbox") {
                      onClick && onClick(row);
                    }
                  }}
                >
                  {useCheckbox && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={state?.selected?.includes(row.id) || ""}
                        onChange={handleChangeCheckbox}
                        onClick={onChangeCheckbox}
                        value={row.id}
                      />
                    </TableCell>
                  )}
                  {!isFixed && totalCount ? (
                    <TableCell
                      style={{ width: 50 }}
                      align="left"
                      component="td"
                      colSpan={1}
                    >
                      {totalCount -
                        parseInt(state.page) * parseInt(state.size) -
                        i}
                    </TableCell>
                  ) : null}
                  {columns.map((column, index) => {
                    return (
                      <TableCell
                        key={index.toString()}
                        align="center"
                        component="td"
                        colSpan={3}
                      >
                        {column.render
                          ? column.render(row)
                          : (() => {
                              const textSlice = (text) => {
                                const { length } = column;
                                if (length && text.length > length) {
                                  return text.substr(0, length) + "...";
                                } else {
                                  return text;
                                }
                              };

                              switch (column.type) {
                                case "date":
                                  const dateString = formatTime(
                                    row[column.key],
                                    "YYYY-MM-DD"
                                  );
                                  return dateString === "Invalid date" ||
                                    !dateString
                                    ? "-"
                                    : dateString;
                                case "datetime":
                                  return formatTime(
                                    row[column.key],
                                    "YYYY-MM-DD HH:mm"
                                  );
                                case "number":
                                  return numFormat(row[column.key]);
                                case "s3img":
                                  return (
                                    <>
                                      {row[column.key] ? (
                                        <img
                                          src={
                                            consts.s3BaseUrl +
                                            "/" +
                                            row[column.key]
                                          }
                                          alt="상품이미지"
                                          width="60"
                                        />
                                      ) : (
                                        "-"
                                      )}
                                    </>
                                  );
                                case "gender":
                                  if (row[column.key]) {
                                    if (row[column.key] === 1) {
                                      return "남";
                                    } else {
                                      return "여";
                                    }
                                  } else {
                                    return row[column.key] || "-";
                                  }
                                case "visible":
                                  if (row[column.key] === "HIDDEN") {
                                    return "비노출";
                                  } else {
                                    return "노출";
                                  }
                                case "tradetype":
                                  if (row[column.key] === "BUY") {
                                    return (
                                      <span style={{ color: "red" }}>매수</span>
                                    );
                                  } else {
                                    return (
                                      <span style={{ color: "blue" }}>
                                        매도
                                      </span>
                                    );
                                  }
                                case "exchargestatus":
                                  if (row[column.key] === "COMPLETE") {
                                    return "출금완료";
                                  } else if (
                                    row[column.key] === "USER_REQUEST"
                                  ) {
                                    return "미처리";
                                  } else if (row[column.key] === "CANCEL") {
                                    return "취소";
                                  } else {
                                    return "";
                                  }

                                case "checkbox":
                                  if (clicktype === "checkbox") {
                                    const label = {
                                      inputProps: {
                                        "aria-label": "Checkbox demo",
                                      },
                                    };
                                    return (
                                      <Checkbox
                                        {...label}
                                        onChange={(e) =>
                                          onClick(row[column.key], e)
                                        }
                                      />
                                    );
                                  }
                                case "phone":
                                  return formatPhone(row[column.key]);
                                case "render":
                                  return column.render && column.render(row);

                                default:
                                  return textSlice(row[column.key]) || "-";
                              }
                            })()}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!isNaN(state.page) && pageable && (
          <TablePagination
            onChangePage={(e, p) => {
              history.replace(location.pathname, {
                ...state,
                page: p,
              });
            }}
            className={classes.pagination}
            count={totalCount || 0}
            onChangeRowsPerPage={(e) => {
              history.replace(location.pathname, {
                ...state,
                page: 0,
                size: e.target.value,
              });
              localStorage.setItem(STORAGE_KEY.tableSize, e.target.value);
            }}
            // page={parseInt(state.page)}
            page={state.page > 0 && totalCount < state.page ? 0 : state.page}
            rowsPerPage={parseInt(state.size)}
          />
        )}
      </Paper>
      {onAdd && (
        <Button
          onClick={onAdd}
          classNameLabel={classes.addLabel}
          className={classes.add}
          label="데이터 등록"
        />
      )}
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        onContextMenu={handleContextMenu}
      >
        <MenuItem onClick={handleClickDownloadExcel}>Download Excel</MenuItem>
      </Menu>
      <CSVLink
        filename={`${name}_${formatTime(new Date(), "YYYYMMDDHHmmSS")}`}
        data={mapDataToCsv()}
        ref={excelRef}
        style={{ width: 0, height: 0 }}
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    border: "1px solid #ddd",
    margin: "10px 50px",
    padding: "20px 50px",
  },
  header: {
    alignSelf: "stretch",
    marginTop: "30px",
    marginBottom: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button: {
    marginLeft: "16px",
    padding: "10px 50px",
    backgroundColor: "#000",
  },
  buttonLabel: {
    color: "white",
  },
  add: {
    minWidth: "40%",
    alignSelf: "center",
    marginTop: "50px",
    backgroundColor: "rgba(0,0,0,0)",
    border: "1px solid black",
  },
  addLabel: { color: "black" },
  container: {
    border: "1px solid #eee",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    cursor: "pointer",
  },
  th: {
    backgroundColor: "#f5f5f5",
  },
  tableDashboard: {
    marginBottom: 50,
  },
  active: {
    cursor: "pointer",
  },
  blind: {
    backgroundColor: "#b7adad",
    cursor: "pointer",
  },
  pagination: {
    float: "right",
  },
});
