import {
  ButtonBase,
  makeStyles,
  Popover,
  FormControl,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { signOut } from "../../redux/auth/AuthReducer";
import {
  HiUser,
  HiOutlineTrendingUp,
  HiOutlineMenu,
  HiShoppingCart,
} from "react-icons/hi";
import {
  IoPersonSharp,
  IoSettingsOutline,
  IoSearchSharp,
} from "react-icons/io5";
import { IoIosLogOut, IoIosCalculator } from "react-icons/io";
import { AiOutlineGold, AiOutlineHeart } from "react-icons/ai";
import { BsPersonLinesFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";

const KORDA_MENU = [
  {
    path: routes.manager,
    label: "관리자",
    to: routes.managerAuthority,
    icon: <IoPersonSharp style={{ fontSize: "20px", marginRight: "5px" }} />,
  },
  {
    path: routes.member,
    label: "회원 관리",
    to: routes.memberNormal,
    icon: (
      <BsPersonLinesFill style={{ fontSize: "20px", marginRight: "5px" }} />
    ),
  },
  {
    path: routes.trading,
    label: "매수/매도",
    to: routes.tradingAsset,
    icon: (
      <HiOutlineTrendingUp style={{ fontSize: "20px", marginRight: "5px" }} />
    ),
  },
  {
    path: routes.appraisal,
    label: "감정평가",
    to: routes.appraisalStatus,
    icon: <FiEdit style={{ fontSize: "20px", marginRight: "5px" }} />,
  },
  {
    path: routes.physical,
    label: "실물인출",
    to: routes.physicalOrder,
    icon: <AiOutlineGold style={{ fontSize: "20px", marginRight: "5px" }} />,
  },
  { path: routes.direct, label: "안심직거래", to: routes.directStatus },
  {
    path: routes.settlement,
    label: "정산관리",
    to: routes.settlementHistory,
    icon: <IoIosCalculator style={{ fontSize: "20px", marginRight: "5px" }} />,
  },
  {
    path: routes.service,
    label: "고객센터",
    to: routes.serviceInquiry,
    icon: <AiOutlineHeart style={{ fontSize: "20px", marginRight: "5px" }} />,
  },
  {
    path: routes.setting,
    label: "설정",
    to: routes.settingDirect,
    icon: (
      <IoSettingsOutline style={{ fontSize: "20px", marginRight: "5px" }} />
    ),
  },
];

const SHOP_MENU = [
  {
    path: routes.trading,
    label: "매수/매도",
    to: routes.tradingAsset,
    icon: (
      <HiOutlineTrendingUp style={{ fontSize: "20px", marginRight: "5px" }} />
    ),
  },
  {
    path: routes.appraisal,
    label: "감정평가",
    to: routes.appraisalStatus,
    icon: <FiEdit style={{ fontSize: "20px", marginRight: "5px" }} />,
  },
  {
    path: routes.physical,
    label: "실물인출",
    to: routes.physicalOrder,
    icon: <AiOutlineGold style={{ fontSize: "20px", marginRight: "5px" }} />,
  },
  { path: routes.direct, label: "안심직거래", to: routes.directStatus },
  {
    path: routes.member,
    label: "회원 관리",
    to: routes.memberNormal,
    icon: (
      <BsPersonLinesFill style={{ fontSize: "20px", marginRight: "5px" }} />
    ),
  },
  {
    path: routes.shop,
    label: "매장 관리",
    to: routes.shopInfo,
    icon: <HiShoppingCart style={{ fontSize: "20px", marginRight: "5px" }} />,
  },
  {
    path: routes.settlement,
    label: "정산관리",
    to: routes.settlementShopFee,
    icon: <IoIosCalculator style={{ fontSize: "20px", marginRight: "5px" }} />,
  },
];

const KORDA_SIDE_MENU = [
  { path: routes.managerAuthority, label: "권한관리" },
  { path: routes.managerManagement, label: "관리자관리" },
  { path: routes.myInfo, label: "내 정보" },
  { path: routes.memberNormal, label: "일반회원" },
  { path: routes.memberShop, label: "매장회원" },
  // { path: routes.memberInactive, label: "사용정지회원" },
  // { path: routes.memberDelete, label: "휴면회원" },
  { path: routes.tradingAsset, label: "자산" },
  { path: routes.appraisalStatus, label: "감정현황" },
  { path: routes.appraisalProduct, label: "제품설정" },
  { path: routes.appraisalProcess, label: "처리현황" },
  //  { path: routes.appraisalStock, label: "수탁재고관리" },
  { path: routes.physicalOrder, label: "통합주문현황" },
  { path: routes.physicalProduct, label: "제품관리" },
  { path: routes.directStatus, label: "접수/등록 현황" },
  { path: routes.directProduct, label: "상품관리" },
  { path: routes.directOrder, label: "주문관리" },
  { path: routes.settlementHistory, label: "정산현황" },
  { path: routes.settlementVirtualAc, label: "가상계좌관리" },
  { path: routes.settlementOutmoney, label: "출금관리" },
  { path: routes.settlementSalesFee, label: "매출수수료" },
  { path: routes.settlementShopFee, label: "가맹점수수료" },
  { path: routes.serviceNotice, label: "공지사항" },
  // { path: routes.serviceReview, label: "거래후기 관리" },
  { path: routes.serviceFaq, label: "FAQ 관리" },
  { path: routes.serviceFaqCategory, label: "FAQ 분류 관리" },
  // { path: routes.serviceUseGuide, label: "이용가이드 관리" },
  { path: routes.serviceInquiry, label: "고객문의관리" },
  { path: routes.serviceDirect, label: "상품 문의" },
  // { path: routes.serviceNotifi, label: "알림서비스" },
  // { path: routes.settingOrderPolicy, label: "거래정책" },
  // { path: routes.settingFee, label: "수수료정책" },
  { path: routes.settingPolicy, label: "이용약관" },
  // { path: routes.settingAdmin, label: "관리자 정보" },
  // { path: routes.settingLog, label: "접속기록" },
  // { path: routes.settingBasicInfo, label: "기본정보" },
];

const SHOP_SIDE_MENU = [
  { path: routes.tradingAsset, label: "자산" },
  // { path: routes.appraisalProcess, label: "처리현황" },
  { path: routes.appraisalStatus, label: "감정현황" },
  // { path: routes.appraisalProduct, label: "제품설정" },
  //  { path: routes.appraisalStock, label: "수탁재고관리" },
  { path: routes.physicalOrder, label: "통합주문현황" },
  // { path: routes.physicalProduct, label: "제품관리" },
  // {
  //   parent: routes.direct,
  //   routes: [
  //     { path: routes.directStatus, label: "접수/등록 현황" },
  //     { path: routes.directProduct, label: "상품관리" },
  //     { path: routes.directOrder, label: "주문관리" },
  //   ],
  // },
  { path: routes.memberNormal, label: "일반회원" },
  { path: routes.shopInfo, label: "기본정보" },
  { path: routes.settlementShopFee, label: "가맹점수수료" },
];

export default function Topbar({}) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (item) => (event) => {
    history.push({ pathname: item.to + "/", state: null });
  };

  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;

  let menu = isKorda ? KORDA_MENU : SHOP_MENU;
  let sideMenu = isKorda ? KORDA_SIDE_MENU : SHOP_SIDE_MENU;

  const handleClickSearch = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Flex row className={classes.root}>
      <Flex
        onClick={() => {
          history.replace(routes.main);
        }}
        row
        className={classes.labelWrap}
      >
        <img src={images.logo_new} className={classes.logo} />
        <Text font={fonts.notoSansKRBold} className={classes.label}>
          관리시스템
        </Text>
      </Flex>
      <Flex className={classes.menus} row>
        {menu.map((x, i) => {
          const point = location.pathname.startsWith(x.path);
          return (
            <Flex
              key={`Flex-${i}`}
              className={point ? classes.buttonWrapPoint : classes.buttonWrap}
            >
              <ButtonBase
                //className={`${point && classes.buttonRootPoint}`}
                key={`${i}`}
                onClick={handleClick(x)}
              >
                {x?.icon || <HiOutlineMenu />}
                <Text
                  font={point ? fonts.notoSansKRMedium : fonts.notoSansKRMedium}
                  className={`${classes.buttonLabel}`}
                  //  point && classes.buttonLabelPoint
                  //}`}
                >
                  {x.label}
                </Text>
              </ButtonBase>
            </Flex>
          );
        })}
      </Flex>
      <Flex row>
        <ButtonBase className={classes.logout} onClick={handleClickSearch}>
          <IoSearchSharp />
          빠른검색
        </ButtonBase>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          style={{ width: "400px", height: "150px" }}
        >
          <FormControl
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100px",
              alignItems: "center",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            <Autocomplete
              id="combo-box-demo"
              options={sideMenu}
              getOptionLabel={(option) => option.label}
              sx={{ width: 300 }}
              onChange={(_, newValue) => {
                if (newValue) {
                  history.push(newValue.path);
                  setAnchorEl(null);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="이동하실 메뉴를 선택해주세요." />
              )}
            />
          </FormControl>
        </Popover>
        <ButtonBase
          onClick={() => history.replace(routes.myInfo)}
          className={classes.logout}
        >
          <HiUser />내 정보
        </ButtonBase>
        <ButtonBase
          onClick={() => dispatch(signOut())}
          className={classes.logout}
        >
          <IoIosLogOut />
          로그아웃
        </ButtonBase>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: colors.white,
    borderBottom: "1px solid #dddddd",
    alignSelf: "stretch",
    padding: "0px 28px",
  },
  logo: { width: 210, height: 50, objectFit: "contain" },
  label: {
    color: "black",
    marginLeft: "12px",
    fontSize: "18px",
  },
  labelWrap: {
    cursor: "pointer",
    minWidth: consts.drawerMinWidth,
    alignItems: "center",
  },
  buttonLabel: {
    fontSize: "18px",
  },
  buttonLabelPoint: {
    fontSize: "18px",
    color: "black",
    //borderBottom: "3px solid " + colors.primary,
    alignSelf: "center",
  },
  menus: { alignItems: "center", flex: 1, paddingLeft: "50px" },
  logout: {
    borderRadius: "3px",
    border: "1px solid white",
    color: "#999",
    fontSize: "20px",
    padding: "22px 10px",
    fontFamily: fonts.notoSansKRMedium,
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      color: "black",
    },
  },
  buttonWrap: {
    width: "105px",
    height: "50px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#999",
    fontSize: "15px",
    marginLeft: "5px",
    marginRight: "5px",
    "&:hover": {
      color: "black",
    },
  },
  buttonWrapPoint: {
    width: "120px",
    height: "50px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontSize: "20px",
    background: `linear-gradient(311deg, #e89157 0%, #ff761a 74%)`,
    boxShadow: "0 0 10px 1px rgb(188 184 180 / 70%)",
  },
});
