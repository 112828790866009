import { makeStyles, Checkbox, ButtonBase, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Filters from "../../components/filters/Filters";
import DataTable from "../../components/table/Table";
import MainLayout from "../../layouts/main/MainLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import Flex from "../../components/flex/Flex";
import { useDispatch } from "react-redux";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  ChevronRight,
  ChevronLeft,
} from "@material-ui/icons";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import { objToQueryStr, numFormat, formatPhone } from "../../services/utils";
import { getDefaultPageSize } from "../../libs/consts";
import { STORAGE_KEY } from "../../libs/consts";

export default function DirectProduct({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyle();
  const location = useLocation();
  const { state = {} } = location;
  const [hotGoods, setHotGoods] = useState([]);
  const [goods, setGoods] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const SubmitIcon = ChevronLeft;

  // 조회 API Callback
  const setData = ({ data: { success, data, message } }) => {
    if (success) {
      setHotGoods(data.hotList || []);
      const newListData = data.list.content.map((item) => {
        let newObj = {};
        newObj["id"] = item.itemId;
        newObj["sort"] = item.sort;
        newObj["tagNo"] = item.tagNo;
        newObj["categoryName"] = item.categoryName;
        newObj["productName"] = item.productName;
        newObj["brand"] = item.brand;
        return newObj;
      });
      setGoods(newListData || []);
      setTotalCount(data.list.totalElements || 0);
    } else {
      dispatch(actionError(message));
    }
  };
  // 정렬 이벤트
  const onSort = (index, targetIndex) => {
    const goodsIdList = [
      { id: hotGoods[index].itemId, sort: hotGoods[index].sort },
      { id: hotGoods[targetIndex].itemId, sort: hotGoods[targetIndex].sort },
    ];
    dispatch(loadingStart);
    APIS.putDirectHotGoodsPosition(goodsIdList)
      .then(({ data: { success, message } }) => {
        if (success) {
          const newArray = [...hotGoods];
          const tmpGoods = newArray[index];
          newArray[index] = newArray[targetIndex];
          newArray[targetIndex] = tmpGoods;
          setHotGoods(newArray);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  // 인기상품 해제 이벤트
  const liftHotGoods = () => {
    dispatch(loadingStart);
    const data = {
      itemIdList: hotGoods
        .filter(({ checked }) => checked)
        .map(({ itemId }) => itemId),
      categoryId: category,
    };
    APIS.deleteDirectHotGoods(data)
      .then(setData)
      .finally(() => {
        dispatch(loadingEnd);
        history.replace(location.pathname, {
          page: 0,
          size: localStorage.getItem(STORAGE_KEY.tableSize) || 10,
          selected: [],
        });
      });
  };

  // 인기상품 등록 이벤트
  const registerHotGoods = () => {
    dispatch(loadingStart);
    APIS.postDirectHotGoodsCreate({
      itemIdList: state.selected,
      categoryId: category,
    })
      .then(setData)
      .finally(() => {
        dispatch(loadingEnd);
        history.replace(location.pathname, {
          page: 0,
          size: localStorage.getItem(STORAGE_KEY.tableSize) || 10,
          selected: [],
        });
      });
  };

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getDirectCategoryList()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const newData = [{ categoryId: "", name: "전체", isDelete: false }];
          newData.push(...data);
          setCategoryList(newData);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  }, []);

  useEffect(() => {
    history.replace(location.pathname, {
      ...state,
      ...getDefaultPageSize(),
    });
  }, []);

  useEffect(() => {
    const data = {
      page: state.page,
      size: state.size,
      categoryId: category,
    };
    const dataParamQuery = objToQueryStr(data);
    dispatch(loadingStart);
    APIS.getDirectHotGoods(dataParamQuery)
      .then(setData)
      .finally(() => dispatch(loadingEnd));
  }, [state.page, state.size]);

  useEffect(() => {
    const data = {
      page: 0,
      size: localStorage.getItem(STORAGE_KEY.tableSize) || 10,
      categoryId: category,
    };
    const dataParamQuery = objToQueryStr(data);
    dispatch(loadingStart);
    APIS.getDirectHotGoods(dataParamQuery)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setHotGoods(data.hotList || []);
          const newListData = data.list.content.map((item) => {
            let newObj = {};
            newObj["id"] = item.itemId;
            newObj["sort"] = item.sort;
            newObj["tagNo"] = item.tagNo;
            newObj["categoryName"] = item.categoryName;
            newObj["productName"] = item.productName;
            newObj["brand"] = item.brand;
            return newObj;
          });
          setGoods(newListData || []);
          setTotalCount(data.list.totalElements || 0);
          history.replace(location.pathname, {
            page: 0,
            size: localStorage.getItem(STORAGE_KEY.tableSize) || 10,
            selected: [],
          });
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  }, [category]);

  return (
    <>
      {/* <span className={classes.label}>인기상품 관리</span> */}
      <Flex
        row
        style={{ flex: 1, padding: "20px 50px 0px 50px", position: "relative" }}
      >
        <HotProductSection
          isHot
          list={hotGoods}
          setList={setHotGoods}
          onSort={onSort}
          onSubmit={liftHotGoods}
        />
        <Flex style={{ width: 90 }} />
        <Flex style={{ flex: 1 }}>
          <Button
            variant="outlined"
            disabled={state?.selected?.length === 0}
            onClick={registerHotGoods}
            className={classes.submitButton}
            style={{ top: `calc(50% + 25px)` }}
          >
            <SubmitIcon />
          </Button>
          <Flex row style={{ justifyContent: "flex-end" }}>
            <Text
              font={fonts.notoSansKRBold}
              className={classes.labelTitle}
              style={{ margin: "auto" }}
            >
              일반상품
            </Text>
            <Flex>
              <select
                value={category}
                className={classes.selectBox}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                {categoryList?.map((item) => {
                  return <option value={item.categoryId}>{item.name}</option>;
                })}
              </select>
            </Flex>
          </Flex>
          <DataTable
            onChangeCheckbox={(e) => {
              e.stopPropagation();
            }}
            useCheckbox
            hideLabel
            isFixed
            data={goods}
            totalCount={totalCount}
            columns={[
              { label: "브랜드", key: "brand" },
              { label: "상품명", key: "productName" },
            ]}
            rootStyle={classes.dataTable}
            containerStyle={classes.container}
            tableBodyStyle={classes.tableBodyStyle}
          />
        </Flex>
        {/* <HotProductSection
          list={goods}
          setList={setGoods}
          onSubmit={registerHotGoods}
          categoryList={categoryList}
          category={category}
          setCategory={setCategory}
          totalCount={totalCount}
        /> */}
      </Flex>
    </>
  );
}
const HotProductSection = ({
  isHot,
  list = [],
  setList,
  onSort,
  onSubmit,
  categoryList,
  category,
  setCategory,
  totalCount,
}) => {
  const classes = useStyle();
  const label = isHot ? "인기 상품" : "일반 상품";
  const SubmitIcon = isHot ? ChevronRight : ChevronLeft;

  // 체크박스 전체 선책 이벤트
  const handleChangeAllCheck = (e) => {
    setList(list.map((item) => ({ ...item, checked: e.target.checked })));
  };
  // 체크박스 개별 선책 이벤트
  const handleChangeCheckBox = (index, checked) => {
    setList(
      list.map((item, i) => ({
        ...item,
        checked: index === i ? checked : item.checked,
      }))
    );
  };

  // 정렬 이벤트
  const handleClickSort = (index, targetIndex) => {
    return () => {
      onSort(index, targetIndex);
    };
  };

  return (
    <Flex style={{ flex: 1 }}>
      <Button
        variant="outlined"
        disabled={list?.findIndex(({ checked }) => checked) === -1}
        onClick={onSubmit}
        className={classes.submitButton}
        style={{ top: `calc(50% ${isHot ? "-" : "+"} 25px)` }}
      >
        <SubmitIcon />
      </Button>
      {!isHot ? (
        <Flex row style={{ justifyContent: "flex-end" }}>
          <Text
            font={fonts.notoSansKRBold}
            className={classes.labelTitle}
            style={{ margin: "auto" }}
          >
            {label}
          </Text>
          <Flex>
            <select
              value={category}
              className={classes.selectBox}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              {categoryList?.map((item) => {
                return <option value={item.categoryId}>{item.name}</option>;
              })}
            </select>
          </Flex>
        </Flex>
      ) : (
        <Text font={fonts.notoSansKRBold} className={classes.labelTitle}>
          {label}
        </Text>
      )}
      <Flex>
        <Flex
          style={{ border: "1px solid #ddd", overflowY: "scroll", height: 700 }}
        >
          <table className={classes.table} cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={
                      list.length.toString() &&
                      !(list.findIndex(({ checked }) => !checked) > -1)
                    }
                    onChange={handleChangeAllCheck}
                  />
                </th>
                {isHot && <th>순서</th>}
                {isHot && <th>카테고리</th>}
                <th>브랜드</th>
                <th>상품명</th>
              </tr>
            </thead>
            <tbody>
              {list.map(
                (
                  { id, tagNo, categoryName, brand, productName, checked },
                  i
                ) => (
                  <tr key={i.toString()}>
                    <td>
                      <Checkbox
                        checked={!!checked}
                        onChange={(e) => {
                          handleChangeCheckBox(i, e.target.checked);
                        }}
                      />
                    </td>
                    {isHot && (
                      <td>
                        <Flex row>
                          <ButtonBase
                            className={classes.arrowButton}
                            onClick={handleClickSort(i - 1, i)}
                            disabled={i === 0}
                          >
                            <ArrowDropUpOutlined />
                          </ButtonBase>
                          <ButtonBase
                            className={classes.arrowButton}
                            onClick={handleClickSort(i, i + 1)}
                            disabled={i === list.length - 1}
                          >
                            <ArrowDropDownOutlined />
                          </ButtonBase>
                        </Flex>
                      </td>
                    )}
                    {isHot && <td>{categoryName}</td>}
                    <td>{brand}</td>
                    <td>{productName}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </Flex>
      </Flex>
    </Flex>
  );
};
const useStyle = makeStyles({
  label: {
    margin: "0px 50px",
    marginTop: 30,
    fontFamily: fonts.notoSansKRBold,
    marginBottom: 10,
  },
  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },
  labelTitle: { textAlign: "center", fontSize: 18, margin: "10px 0" },
  table: {
    "& th": {
      backgroundColor: "#f5f5f5",
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
      position: "sticky",
      top: 0,
    },
    "& td": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
      borderRight: "1px solid rgba(0,0,0,0.13)",
      padding: "6px",
      alignItems: "center",
      textAlign: "center",
    },
  },
  arrowButton: {
    flex: 1,
    "&:disabled": { color: colors.lightGray },
  },
  submitButton: {
    position: "absolute",
    width: 70,
    height: 40,
    left: "calc(50% - 35px)",
  },
  dataTable: {
    border: "1px solid #ddd",
    margin: 0,
    padding: 0,
    height: 700,
  },
  tableBodyStyle: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  container: {
    minHeight: 650,
    maxHeight: 650,
  },
});
