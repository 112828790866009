import axios from "axios";
import { actionError } from "../redux/action/ActionActions";
import { signOut } from "../redux/auth/AuthReducer";
import { STORAGE_KEY } from "../libs/consts";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.baseURL = process.env.REACT_APP_ADMIN_API_URL;

// 응답 인터셉터 추가
axios.interceptors.response.use(
  function (response) {
    // 응답 데이터를 가공
    // ...
    return response;
  },
  function (error) {
    // 오류 응답을 처리
    // ...
    const { store } = require("../redux/store");
    const status = error?.response?.status;

    if (status === 403 || typeof error.response === "undefined") {
      localStorage.removeItem("passwordVerification");
      localStorage.removeItem(STORAGE_KEY.accessToken);
      store.dispatch(
        actionError("인증 토큰이 만료되었습니다.\n다시 로그인해주세요.")
      );
      store.dispatch(signOut());
    } else if (status === 401) {
      // 401 제외 에러처리
      store.dispatch(actionError(error));
    } else if (status !== 422) {
      // 422 제외 에러처리
      store.dispatch(actionError("알 수 없는 오류가 발생했습니다."));
    }

    return Promise.reject(error);
  }
);

export const setAuthorization = (authToken) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
};

//////////////////////////////////////////////////////////////////////////////////////////////////// USER API

export const getTradeConfig = (query) => {
  return axios.get(`/api/v1/manager/shared/trade${query}`);
};

export const getTerms = () => {
  return axios.get(`/api/v1/manager/shared/terms`);
};

export const getTermDetail = (type) => {
  return axios.get(`/api/v1/manager/shared/terms/${type}`);
};

export const getcurrentmarketprice = (types) => {
  return axios.get(
    `/api/v1/manager/market/buysell/currentmarketprice?type=${types}`
  );
};

export const getOrderDetail = (transId) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/${transId}/getDetail`
  );
};

export const postProductOrderHistory = (transId) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/transOrder/${transId}`
  );
};

export const postProductOrderMod = (querys, param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/trans/transOrder${querys}`,
    param
  );
};

export const postProductOrderModPut = (querys, param) => {
  return axios.put(
    `/api/v1/manager/market/goodswithdrawal/trans/transOrder${querys}`,
    param
  );
};

export const getAppraisalStatSummary = () => {
  return axios.get(`/api/v1/manager/market/appraisal/Stat/summary`);
};

export const getAppraisalStatStatus = (query) => {
  return axios.get(`/api/v1/manager/market/appraisal/Stat/status${query}`);
};

export const getGoodsCategoryList = () => {
  return axios.get(`/api/v1/manager/market/goodswithdrawal/goodsCategory/list`);
};

export const postGoodsCategoryModify = (param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goodsCategory/modify`,
    param
  );
};

export const postGoodsCategoryCreate = (param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goodsCategory/create`,
    param
  );
};

export const getGoodsTypeList = (categoryId) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/goodsType/${categoryId}/list`
  );
};

export const postGoodsTypeDelete = (param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goodsType/delete`,
    param
  );
};

export const postGoodsTypeModify = (id, param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goodsType/${id}/modify`,
    param
  );
};

export const postGoodsTypeCreate = (id, param) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goodsType/${id}/create`,
    param
  );
};
////////////////////////////////////////////////////////////////////////////////////////////////////

export const postSignIn = (param) => {
  return axios.post("/api/v1/manager/auth", param, {
    headers: { Authorization: "" },
  });
};

export const searchAccount = (param) => {
  return axios.post(
    `/api/v1/manager/search?page=${param.page}&size=${param.size}`,
    param
  );
};

export const searchUserDetail = (id) => {
  return axios.get(`/api/v1/manager/search/detail/${id}`);
};

export const getUserAsset = (id) => {
  return axios.get(`/api/v1/manager/search/detail/${id}/asset`);
};

export const getUserTradeFee = (id) => {
  return axios.get(`/api/v1/manager/user/${id}/fee`);
};

export const putUserTradeFee = (id, param) => {
  return axios.put(`/api/v1/manager/user/${id}/fee`, param);
};

export const putUserStatus = (id, param) => {
  return axios.put(`/api/v1/manager/user/${id}/status`, param);
};

export const modifyUserDetail = (id, param) => {
  return axios.post(`/api/v1/manager/user/${id}/modify`, param);
};

export const modifyCanReSignupDate = (id, param) => {
  return axios.post(`/api/v1/manager/user/${id}/reset/resignupdate`, param);
};

export const getCity = () => {
  return axios.get("/api/v1/manager/shop/city");
};

export const getShops = (param) => {
  return axios.get(`/api/v1/manager/shop${param}`);
};

export const getShopDetail = (id) => {
  return axios.get(`/api/v1/manager/shop/${id}`);
};

export const deleteShop = (id) => {
  return axios.delete(`/api/v1/manager/shop/${id}`);
};

export const saveShop = (id, data) => {
  return axios.post(`/api/v1/manager/shop/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getBankList = () => {
  return axios.get(`/api/v1/manager/asset/bank?size=200`);
};

export const postCreateShop = (data) => {
  return axios.post(`/api/v1/manager/shop`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getRecommendShops = () => {
  return axios.get(`/api/v1/manager/shop/recommend`);
};

export const postCreateProduct = (data) => {
  return axios.post(
    `/api/v1/manager/market/goodswithdrawal/goods/create`,
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
};

export const searchVirtualList = (query = "") => {
  return axios.get(`/api/v1/manager/asset/virtual${query}`);
};

export const unAssignVirtualAccount = (data) => {
  return axios.delete(`/api/v1/manager/asset/virtual`, { data });
};

export const postVirtualAccount = (data) => {
  return axios.post(`/api/v1/manager/asset/virtual/081`, data);
};

export const putModifyProduct = (data, id) => {
  return axios.put(`/api/v1/manager/market/goodswithdrawal/goods/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const postCreateNotice = (data) => {
  return axios.post(`/api/v1/manager/notice`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getNoticeList = (querys) => {
  return axios.get(`/api/v1/manager/notice${querys}`);
};

export const getNoticeDetail = (noticeId) => {
  return axios.get(`/api/v1/manager/notice/${noticeId}`);
};

export const postNoticeStatus = (data) => {
  return axios.post(`/api/v1/manager/notice/status`, data);
};

export const postEditNotice = (data, noticeId) => {
  return axios.post(`/api/v1/manager/notice/${noticeId}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteImage = (noticeId, imageId) => {
  return axios.delete(`/api/v1/manager/notice/image/${noticeId}/${imageId}`);
};

export const deleteFaqImage = (faqId, imageId) => {
  return axios.delete(`/api/v1/manager/faq/${faqId}/${imageId}`);
};

export const postRegisterFaqCategory = (data) => {
  return axios.post(`/api/v1/manager/faq/category`, data);
};

export const getFaqCategoryList = () => {
  return axios.get(`/api/v1/manager/faq/category`);
};

export const putChageStatusFaqCategory = (param) => {
  return axios.put(`/api/v1/manager/faq/category${param}`);
};

export const postChangePositionFaqCategory = (data) => {
  return axios.post(`/api/v1/manager/faq/category/position`, data);
};

export const getFaqList = (querys) => {
  return axios.get(`/api/v1/manager/faq${querys}`);
};

export const postFaqRegister = (data) => {
  return axios.post(`/api/v1/manager/faq`, data);
};

export const postFaqStatus = (data) => {
  return axios.post(`/api/v1/manager/faq/status`, data);
};

export const getFaqDetail = (id) => {
  return axios.get(`/api/v1/manager/faq/${id}`);
};

export const postEditFaq = (data) => {
  return axios.post(`/api/v1/manager/faq/modify`, data);
};

export const getQnaList = (querys) => {
  return axios.get(`/api/v1/manager/qna${querys}`);
};

export const getQnaSummary = () => {
  return axios.get(`/api/v1/manager/qna/summary`);
};

export const getQnaDetail = (querys) => {
  return axios.get(`/api/v1/manager/qna/detail${querys}`);
};

export const postQnaRegister = (data) => {
  return axios.post(`/api/v1/manager/qna`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const putCloseQna = (param) => {
  return axios.put(`/api/v1/manager/qna/close${param}`);
};

export const productList = (querys) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/goods/search${querys}`
  );
};

export const getOrderList = (querys) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/search${querys}`
  );
};

export const getGoodsWithdrawalTotalWeight = (querys) => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/summary${querys}`
  );
};

export const putGoodsWithdrawalStatus = (data) => {
  return axios.put(`/api/v1/manager/market/goodswithdrawal/trans/status`, data);
};

export const getProductDetail = (goodsId) => {
  return axios.get(`/api/v1/manager/market/goodswithdrawal/goods/${goodsId}`);
};

export const getOrderExcharge = (querys) => {
  return axios.get(`/api/v1/manager/adjustment/withdrawal/search${querys}`);
};

export const getOrderExchargeTotal = (querys) => {
  return axios.get(
    `/api/v1/manager/adjustment/withdrawal/search/total${querys}`
  );
};

export const putexChargeStatus = (param) => {
  return axios.put(`/api/v1/manager/adjustment/withdrawal/status`, param);
};

export const getOrderSummary = () => {
  return axios.get(
    `/api/v1/manager/market/goodswithdrawal/trans/search/summary`
  );
};

// 정산관리 > 정산현황 > 매출현황 리스트 조회
export const getSalesStatusList = (param) => {
  return axios.get(`/api/v1/manager/adjustment/salestax/search${param}`);
};

// 정산관리 > 정산현황 > 매출현황 상태변경
export const getSalesStatusChange = (data) => {
  return axios.post(`/api/v1/manager/adjustment/salestax/modify`, data);
};

// 정산관리 > 매춠수수료 > 매출 수수료 조회
export const getoutcomefeeSearch = (querys) => {
  return axios.get(`/api/v1/manager/adjustment/salesfee/search${querys}`);
};

// 정산관리 > 매춠수수료 > 매출 수수료 합계
export const getoutcomefeeSummary = (querys) => {
  return axios.get(
    `/api/v1/manager/adjustment/salesfee/search/summary${querys}`
  );
};

// 정산관리 > 매출수수료 > 상태변경
export const patchOutcomeFeeStatus = (data) => {
  return axios.patch(`/api/v1/manager/adjustment/salesfee/status`, data);
};

// 정산관리 > 가맹점수수료 > 조회
export const getShopFeeList = (query) => {
  return axios.get(`/api/v1/manager/adjustment/shopfee/list${query}`);
};

// 정산관리 > 가맹점수수료 > 합계
export const getShopFeeSummary = (query) => {
  return axios.get(`/api/v1/manager/adjustment/shopfee/summary${query}`);
};

// 정산관리 > 가맹점수수료 > 상태변경
export const postModifyShopFee = (param) => {
  return axios.post(`/api/v1/manager/adjustment/shopfee/modify`, param);
};

// 감정평가 > 제품설정 > 제품 조회
export const getAppraisalProducts = (query = "") => {
  return axios.get(`/api/v1/manager/market/appraisal/Products${query}`);
};

// 감정평가 > 제품설정 > 제품 추가
export const postAppraisalProducts = (param) => {
  return axios.post(`/api/v1/manager/market/appraisal/Products`, param);
};

// 감정평가 > 제품설정 > 제품 수정
export const putAppraisalProducts = (id, param) => {
  return axios.put(`/api/v1/manager/market/appraisal/Products/${id}`, param);
};

// 감정평가 > 제품설정 > 제품 가이드 수정
export const putAppraisalProductsGuide = (id, param) => {
  return axios.put(
    `/api/v1/manager/market/appraisal/Products/${id}/guide`,
    param,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

// 감정평가 > 제품설정 > 제품 이미지 수정
export const putAppraisalProductsImage = (id, param) => {
  return axios.put(
    `/api/v1/manager/market/appraisal/Products/${id}/image`,
    param,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

// 감정평가 > 제품설정 > 제품 삭제
export const deleteAppraisalProducts = (data) => {
  return axios.delete(`/api/v1/manager/market/appraisal/Products`, {
    data,
  });
};

// 감정평가 > 처리현황 > 미처리 조회
export const getAppraisalRequestTodolist = (query) => {
  return axios.get(
    `/api/v1/manager/market/appraisal/Requests/todolist${query}`
  );
};

// 감정평가 순도별 중량 차감율 조회
export const getAppraisalWeightRates = (query = "") => {
  return axios.get(`/api/v1/manager/market/appraisal/WeightRates${query}`);
};

// 감정평가 > 감정현황 > 목록 조회
export const getAppraisalRequests = (query, isKorda = false) => {
  return axios.get(
    `/api/v1/${isKorda ? "manager" : "shop"}/market/appraisal/Requests${query}`
  );
};

// 감정평가 > 감정현황 > 중량합계 조회
export const getAppraisalTotalWeight = (query, isKorda = false) => {
  return axios.get(
    `/api/v1/${
      isKorda ? "manager" : "shop"
    }/market/appraisal/Requests/summary${query}`
  );
};

// 감정평가 > 감정현황 > 승인대기, 반송대기 조회
export const getAppraisalWaiting = () => {
  return axios.get(`/api/v1/manager/market/appraisal/Requests/status/summary`);
};

// 감정평가 > 감정현황 > 상세 조회
export const getAppraisalRequestDetail = (id) => {
  return axios.get(`/api/v1/manager/market/appraisal/Requests/${id}`);
};

// 감정평가 > 감정현황 > 중량 합계 조회
export const getAppraisalRequestTotalWeight = (param) => {
  return axios.get(`/api/v1/manager/market/appraisal/Requests/summary${param}`);
};

// 매수 매도 체결/미체결 조회
export const getTradeList = (status, querys) => {
  return axios.get(`/api/v1/manager/market/buysell/${status}/list${querys}`);
};

// 매수 매도 체결/미체결 합계
export const getTradeSummaryList = (status, querys) => {
  return axios.get(
    `/api/v1/manager/market/buysell/${status}/summary/list${querys}`
  );
};

// 감정평가 > 감정현황 > 상세 > 상태 변경
export const putAppraisalRequestStatus = (id, param, isKorda = false) => {
  return axios.put(
    `/api/v1/${
      isKorda ? "manager" : "shop"
    }/market/appraisal/Requests/${id}/status`,
    param
  );
};

// 감정평가 > 감정현황 > 재고상태 변경
export const putAppraisalChangeStockStatus = (data) => {
  return axios.put(`/api/v1/manager/market/appraisal/Requests/stock`, data);
};

// 감정평가 > 감정현황 > 상세 > 상태 변경
export const deleteAppraisalRequestStatus = (id, isKorda = false) => {
  return axios.delete(
    `/api/v1/${
      isKorda ? "manager" : "shop"
    }/market/appraisal/Requests/${id}/status`
  );
};

// 감정평가 > 감정현황 > 상세 > 상태 변경
export const putAppraisalRequestsIsApprovedApprise = (
  id,
  param,
  isKorda = false
) => {
  return axios.put(
    `/api/v1/manager/market/appraisal/Requests/${id}/isApprovedApprise`,
    param
  );
};

// 실물인출 > 제품관리 > 인기상품 관리 조회
export const getHotGoods = () => {
  return axios.get(`/api/v1/manager/market/goodswithdrawal/goods/hot`);
};

// 실물인출 > 제품관리 > 인기상품 등록
export const putHotGoodsCreate = (param) => {
  return axios.put(
    `/api/v1/manager/market/goodswithdrawal/goods/hot/create`,
    param
  );
};

// 실물인출 > 제품관리 > 인기상품 해제
export const putHotGoodsDelete = (param) => {
  return axios.put(
    `/api/v1/manager/market/goodswithdrawal/goods/hot/delete`,
    param
  );
};

// 실물인출 > 제품관리 > 인기상품 순서 변경
export const putHotGoodsPosition = (param) => {
  return axios.put(
    `/api/v1/manager/market/goodswithdrawal/goods/hot/position`,
    param
  );
};

// 안심직거래 > 접수현황 summary 조회
export const getSafeTradeSummary = () => {
  return axios.get(`/api/v1/manager/market/safe-trade/search/summary`);
};

// 안심직거래 > 상품 목록 조회
export const getSafeTradeProductList = (querys) => {
  return axios.get(`/api/v1/manager/market/safe-trade/search${querys}`);
};

// 안심직거래 > Filters 카테고리/ 상태 리스트 조회
export const getSafeTradeFitersList = () => {
  return axios.get(`/api/v1/manager/market/safe-trade/search/list`);
};

// 안심직거래 > 상세 > 상품 상태 리스트 조회
export const getSafeTradeProductStatusList = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/status`);
};

// 안심직거래 > 상세 > 제품 접수 정보 조회
export const getSafeTradeReceptionInfo = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/order`);
};

// 안심직거래 > 상세 > 상품 이력 목록 조회
export const getSafeTradeProductHistoryList = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/history`);
};

// 안심직거래 > 상세 > 제품 감정 정보 조회
export const getSafeTradeAppraisalInfo = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}`);
};

// 안심직거래 > 상세 > 제품 등록 정보 조회
export const getSafeTradeRegisterInfo = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/item`);
};

// 안심직거래 > 상세 > 제품 카테고리 상세 조회
export const getSafeTradeCategoryDetail = (itemId, categoryId) => {
  return axios.get(
    `/api/v1/manager/market/safe-trade/item/${itemId}/category/${categoryId}`
  );
};

// 안심직거래 > 상세 > 제품 접수 정보 변경
export const postSafeTradeReceptionInfo = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/item/${param}/order`,
    data
  );
};

// 안심직거래 > 상세 > 제품 감정 정보 변경
export const postSafeTradeAppraisalInfo = (param, data) => {
  return axios.post(`/api/v1/manager/market/safe-trade/item/${param}`, data);
};

// 안심직거래 > 상세 > 제품 등록 정보 추가정보 삭제
export const deleteAdditionalInfo = (itemId, infoId) => {
  return axios.delete(
    `/api/v1/manager/market/safe-trade/item/${itemId}/info/${infoId}`
  );
};

// 안심직거래 > 상세 > 판매가 변경 이력 조회
export const getSalePriceHistory = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/price`);
};

// 안심직거래 > 상세 > 판매기간 변경 이력 조회
export const getSaleDateHistory = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/period`);
};

// 안심직거래 > 상세 > 재고위치 변경 이력 조회
export const getLocationHistory = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/inventory`);
};

// 안심직거래 > 상세 > 관리자 메모 이력 조회
export const getAdminMemoHistory = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/${param}/memo`);
};

// 안심직거래 > 상세 > 관리자 메모 등록
export const postRegisterAdminMemo = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/item/${param}/memo`,
    data
  );
};

// 안심직거래 > 상세 > 제품 등록 상품 이미지 등록
export const postRegisterImage = (itemId, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/item/${itemId}/images`,
    data
  );
};

// 안심직거래 > 상세 > 제품 등록 상품 이미지 삭제
export const deleteRegisterImage = (itemId, imageId) => {
  return axios.delete(
    `/api/v1/manager/market/safe-trade/item/${itemId}/images/${imageId}`
  );
};

// 안심직거래 > 상세 > 제품 등록 정보 변경
export const postRegisterInfo = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/item/${param}/item`,
    data
  );
};

// 안심직거래 > 상세 > 상품 상태 업데이트 변경
export const putChangeStatus = (param, data) => {
  return axios.put(
    `/api/v1/manager/market/safe-trade/item/${param}/status`,
    data
  );
};

// 안심직거래 > 인기상품 관리 > 인기상품 관리 조회
export const getDirectHotGoods = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/category/hot${param}`);
};

// 안심직거래 > 인기상품 관리 > 카테고리 목록 조회
export const getDirectCategoryList = () => {
  return axios.get(`/api/v1/manager/market/safe-trade/category`);
};

// 안심직거래 > 인기상품 관리 > 인기상품 등록
export const postDirectHotGoodsCreate = (param) => {
  return axios.post(`/api/v1/manager/market/safe-trade/category/hot`, param);
};

// 안심직거래 > 인기상품 관리 > 인기상품 해제
export const deleteDirectHotGoods = (data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/category/hot/delete`,
    data
  );
};

// 안심직거래 > 인기상품 관리 > 인기상품 순서 변경
export const putDirectHotGoodsPosition = (param) => {
  return axios.put(`/api/v1/manager/market/safe-trade/category/hot`, param);
};

// 설정 > 수수료정책 > 분류관리 > 브랜드 관리
export const getDirectBrandList = (categoryId) => {
  return axios.get(
    `/api/v1/manager/market/safe-trade/category/detail/${categoryId}`
  );
};

// 설정 > 수수료정책 > 분류관리 > 카테고리 등록
export const postDirectCategoryCreate = (data) => {
  return axios.post(`/api/v1/manager/market/safe-trade/category`, data);
};

// 설정 > 수수료정책 > 분류관리 > 카테고리 수정
export const putDirectCategoryModify = (data, param) => {
  return axios.put(`/api/v1/manager/market/safe-trade/category/${param}`, data);
};

// 설정 > 수수료정책 > 분류관리 > 카테고리 정렬 변경
export const putDirectCategorySort = (data) => {
  return axios.put(`/api/v1/manager/market/safe-trade/category`, data);
};

// 설정 > 수수료정책 > 분류관리 > 브랜드 정렬 변경
export const putDirectBrandSort = (param, data) => {
  return axios.put(
    `/api/v1/manager/market/safe-trade/category/detail/${param}`,
    data
  );
};

// 설정 > 수수료정책 > 분류관리 > 카테고리 등록
export const postDirectBrandCreate = (param, data) => {
  return axios.post(
    `/api/v1/manager/market/safe-trade/category/detail/${param}`,
    data
  );
};

// 설정 > 수수료정책 > 분류관리 > 카테고리 수정
export const putDirectBrandModify = (param, data, secondParam) => {
  return axios.put(
    `/api/v1/manager/market/safe-trade/category/detail/${param}/${secondParam}`,
    data
  );
};

// 설정 > 수수료정책 > 수수료율 > 수수료 설정 조회
export const getDirectFeeSetting = () => {
  return axios.get(`/api/v1/manager/market/safe-trade/category/fee`);
};

// 설정 > 수수료정책 > 수수료율 > 수수료 설정 상세 조회
export const getDirectFeeSettingDetail = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/category/fee/${param}`);
};

// 설정 > 수수료정책 > 수수료율 > 수수료 설정 수정
export const putDirectFeeSettingEdit = (param, data) => {
  return axios.put(
    `/api/v1/manager/market/safe-trade/category/fee/${param}`,
    data
  );
};

// 고객센터 > 상품문의 > 상품 문의 조회
export const getDirectProductInquiry = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/qna${param}`);
};

// 고객센터 > 상품문의 > 상품 문의 상세 조회
export const getDirectProductInquiryDetail = (param) => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/qna/${param}`);
};

// 고객센터 > 상품문의 > 상품 문의 답변
export const postDirectProductInquiryAnswer = (param, data) => {
  return axios.post(`/api/v1/manager/market/safe-trade/item/qna/${param}`, data);
};

// 고객센터 > 상품문의 > 상품 문의 현황 조회
export const getDirectProductInquirySummary = () => {
  return axios.get(`/api/v1/manager/market/safe-trade/item/qna/summary`);
};

// // 안심직거래 > Filters 진행상태 조회
// export const getSafeTradeStatus = () => {
//   return axios.get(`/api/v1/manager/market/safe-trade/search/summary`);
// };

// 관리자 > 권한관리 > 권한 리스트 조회
export const getAuthorityList = () => {
  return axios.get(`/api/v1/manager/auth/authority`);
};

// 관리자 > 권한관리 > 권한 그룹 리스트 조회
export const getAuthorityGroupList = () => {
  return axios.get(`/api/v1/manager/auth/group`);
};

// 관리자 > 권한관리 > 권한 그룹 생성
export const postAuthorityGroup = (data) => {
  return axios.post(`/api/v1/manager/auth/group`, data);
};

// 관리자 > 권한관리 > 권한 그룹 상세 조회
export const getAuthorityGroupDetail = (id) => {
  return axios.get(`/api/v1/manager/auth/group/${id}`);
};

// 관리자 > 권한관리 > 권한 그룹 수정
export const postAuthorityGroupModify = (data) => {
  return axios.post(`/api/v1/manager/auth/group/modify`, data);
};

// 관리자 > 관리자관리 > 관리자 리스트 조회
export const getAdminList = (param) => {
  return axios.get(`/api/v1/manager/admin${param}`);
};

// 관리자 > 관리자관리 > SHOP 리스트 조회
export const getShopList = () => {
  return axios.get(`/api/v1/manager/shop/list`);
};

// 관리자 > 관리자관리 > 관리자 상태변경
export const patchAdminChangeStatus = (data) => {
  return axios.patch(`/api/v1/manager/admin`, data);
};

// 관리자 > 관리자관리 > 관리자 생성
export const postCreateAdmin = (data) => {
  return axios.post(`/api/v1/manager/admin`, data);
};

// 관리자 > 관리자관리 > 관리자 상세 조회
export const getAdminDetail = (id) => {
  return axios.get(`/api/v1/manager/admin/${id}`);
};

// 관리자 > 관리자관리 > 관리자 수정
export const putEditAdmin = (data) => {
  return axios.put(`/api/v1/manager/admin`, data);
};

// 관리자 > 관리자관리 > 관리자 아이디 중복확인
export const postCheckRedundancy = (data) => {
  return axios.post(`/api/v1/manager/admin/check`, data);
};

// 관리자 > 관리자관리 > 관리자 비밀번호 변경
export const putModifyPassword = (data) => {
  return axios.put(`/api/v1/manager/admin/password`, data);
};

// 관리자 > 내 정보
export const getAdminInfo = () => {
  return axios.get(`/api/v1/manager/info`);
};

// 관리자 > 내 정보 > 새 비밀번호 변경
export const putModifyMyPassword = (data) => {
  return axios.put(`/api/v1/manager/admin/my/password`, data);
};

export const putModifyConfirmPassword = (data) => {
  return axios.put(`/api/v1/manager/admin/confirm/password`, data);
};

// 관리자 > 내 정보 > 현재 비밀번호 확인
export const postRecentPasswordCheck = (data) => {
  return axios.post(`/api/v1/manager/verify/password`, data);
};

// 관리자 > 내 정보 > 내 정보 수정
export const putEditMyInfo = (data) => {
  return axios.put(`/api/v1/manager/info`, data);
};
