import { IconButton, makeStyles } from "@material-ui/core";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import React, { useState } from "react";
import Flex from "../../components/flex/Flex";
import Drawer from "./Drawer";
import Topbar from "./Topbar";
import { useHistory } from "react-router";
import routes from "../../libs/routes";
import { useSelector } from "react-redux";

const DRAWER_DISABLED = [routes.directStatus, routes.directStatusDetail];
export default function MainLayout({ children }) {
  const classes = useStyle();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const auth = useSelector((s) => s.auth);
  const { isKorda } = auth;
  let drawerDisabled = false;
  DRAWER_DISABLED.forEach((path) => {
    if (history.location.pathname.startsWith(path)) {
      if (path === "/direct/status" && isKorda) {
        drawerDisabled = false;
      } else {
        drawerDisabled = true;
      }
    }
  });
  return (
    <Flex className={classes.root}>
      <Topbar />
      {!drawerDisabled ? (
        <>
          <ExpandedButton expanded={expanded} setExpanded={setExpanded} />
          <Flex className={classes.main} row>
            <Drawer expanded={expanded} />
            <Flex className={classes.children}>{children}</Flex>
          </Flex>
        </>
      ) : (
        <Flex className={classes.main} row>
          <Flex className={classes.children}>{children}</Flex>
        </Flex>
      )}
    </Flex>
  );
}

const ExpandedButton = ({ expanded, setExpanded }) => {
  const classes = useStyle();
  return (
    <IconButton
      className={`${classes.drawerButton} ${
        expanded && classes.drawerButtonExpanded
      }`}
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      {expanded ? <ChevronRight /> : <ChevronLeft />}
    </IconButton>
  );
};

const useStyle = makeStyles({
  root: {
    flex: 1,
    alignSelf: "center",
    width: "100%",
  },
  main: { flex: 1 },
  children: { flex: 1, overflow: "auto", paddingBottom: 50 },
  drawerButton: {
    width: 40,
    height: 40,
    marginLeft: 230,
    marginTop: "140px",
    borderRadius: 15,
    background: "#fff",
    boxShadow: "1px 1px 1px 1px lightGray",
    position: "absolute",
    transition: "all 0.3s;",
    zIndex: 100,
    "&:hover": {
      background: "#efefef",
    },
  },
  drawerButtonExpanded: {
    transform: "rotate(360deg)",
    transition: "all 0.3s;",
    marginLeft: 48,
  },
  icon: {
    width: "100%",
    height: "100%",
    transition: "transform 0.3s ease-in-out",
  },
  expandedIcon: {
    transform: "rotate(90deg)",
  },
});
