import {
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Add, ExpandMore } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import Flex from "../../components/flex/Flex";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import * as APIS from "../../libs/apis";
import GridRow from "../../components/grid-row/GridRow";
import Text from "../../components/text/Text";
import { numberWithCommas, unComma } from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionOpen, actionError } from "../../redux/action/ActionActions";
import fonts from "../../libs/fonts";

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  const reMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      if (reMount.current) {
        func();
      } else {
        reMount.current = true;
      }
    } else {
      didMount.current = true;
    }
  }, deps);
};

export default function ProductAppraisalInfo(props) {
  const { list, setList, recentStatus, isKorda } = props;
  const appraisalInfo = list;
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const classes = useStyle();
  const [category, setCategory] = useState("");
  const [brandName, setBrandName] = useState();
  const [selectedBrandName, setSelectedBrandName] = useState("");
  const [brandNameList, setBrandNameList] = useState([]);
  const [productName, setProductName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [productPrice, setProductPrice] = useState(""); //시중 신품 가격
  const [estimatedSalePrice, setEstimatedSalePrice] = useState(""); //예상판매가격
  const [manufacturingReleaseYear, setManufacturingReleaseYear] = useState(""); //제조 출시년도
  const [productStatus, setProductStatus] = useState("");
  const [isContract, setIsContract] = useState("");
  const editAbleStatus = [
    "INBOUND_WAIT",
    "INBOUND_COMPLETE",
    // "INBOUND_CANCEL",
    "VERIFY_WAIT",
    "RETURN",
    // "VERIFY_COMPLETE",
    "SELL_CONFIRM_WAIT",
    "SELL_WAIT",
  ];

  //브랜드 네임 리스트 받기
  const getBrandNameList = () => {
    // console.log(category, "카테고리");
    if (category !== "" || category !== undefined) {
      dispatch(loadingStart);
      APIS.getSafeTradeCategoryDetail(appraisalInfo.itemId, category)
        .then(({ data: { data, message, success } }) => {
          if (success) {
            setBrandNameList(data);
            //카테고리가 바뀌지 않았는데 값이 셋팅이 되어서 문제이다.
            // setBrandName(data[0].categoryDetailId);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => dispatch(loadingEnd));
    }
  };
  // console.log(recentStatus, "recentStatus");

  //관리자 비밀번호 저장
  const onSaveAdmin = async (adminPassword) => {
    if (editAbleStatus.includes(recentStatus) === false) {
      dispatch(
        actionError("현재 진행 상태에서는 감정정보를 저장할 수 없습니다.")
      );
    } else if (category === "") {
      dispatch(actionError("카테고리를 입력하여 주세요."));
    } else if (productStatus === "") {
      dispatch(actionError("제품상태를 선택하여 주세요."));
    } else if (estimatedSalePrice === "") {
      dispatch(actionError("예상판매가격을 입력하여 주세요."));
    } else if (manufacturingReleaseYear === "") {
      dispatch(actionError("제조 출시년도를 입력하여 주세요."));
    } else if (productPrice === "") {
      dispatch(actionError("시중신품가격을 입력하여 주세요."));
    } else if (isContract === "") {
      dispatch(actionError("보증서 유/무를 선택하여 주세요."));
    } else {
      const data = {
        categoryDetailId: brandName
          ? brandName
          : brandNameList[0]?.categoryDetailId, //브랜드(제조사)
        name: productName, //제품명(모델명)
        serial: serialNumber, //시리얼번호
        state: productStatus, //제품상태
        authPrice: unComma(estimatedSalePrice), //예상 판매가격
        outDate: manufacturingReleaseYear, //제조출시년도
        productPrice: unComma(productPrice), // 시중신품가격
        isContract: isContract, //보증서
        managerPassword: adminPassword, //관리자 비번
      };
      dispatch(loadingStart);
      APIS.postSafeTradeAppraisalInfo(state.itemId, data)
        .then(({ data: { data, message, success } }) => {
          if (success) {
            dispatch(
              actionOpen(
                "감정정보가 변경되었습니다.",
                () => {
                  history.replace({
                    pathname: history.location.pathname,
                    state: { ...state, itemId: appraisalInfo.itemId },
                  });
                },
                null,
                null,
                true
              )
            );
            setList(data);
            setIsContract(data?.isContract); //보증서
            setCategory(data?.categoryInfo?.categoryId); //카테고리
            setSelectedBrandName(data?.categoryInfo); //브랜드
            setBrandName(data?.categoryInfo?.categoryDetailId);
            setProductName(data?.name); //제품명
            setSerialNumber(data?.serial); //시리얼 번호
            setProductPrice(data?.productPrice); //시중신품가격
            setManufacturingReleaseYear(data.outDate); //제조 출시년도
            setProductStatus(data.state); //제품 상태
            setEstimatedSalePrice(data.authPrice); //예상판매가격
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  useEffect(() => {
    setIsContract(list?.isContract); //보증서
    setCategory(list?.categoryInfo?.categoryId); //카테고리
    setSelectedBrandName(list?.categoryInfo); //브랜드
    setBrandName(list?.categoryInfo?.categoryDetailId);
    setProductName(list?.name); //제품명
    setSerialNumber(list?.serial); //시리얼 번호
    setProductPrice(list?.productPrice); //시중신품가격
    setManufacturingReleaseYear(list.outDate); //제조 출시년도
    setProductStatus(list.state); //제품 상태
    setEstimatedSalePrice(list.authPrice); //예상판매가격
  }, [list]);

  useDidMountEffect(() => {
    getBrandNameList();
  }, [category]);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Typography style={{ fontWeight: "bold" }}>제품 감정 정보</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Flex>
          <GridRow
            data={[
              {
                label: "카테고리",
                type: "render",
                render: (
                  <Flex row>
                    <select
                      disabled={
                        isKorda &&
                        editAbleStatus.includes(recentStatus) === true
                          ? false
                          : true
                      }
                      style={{ width: 250, fontSize: 16 }}
                      value={category}
                      onChange={(e) => {
                        setCategory(e.target.value);
                        setBrandName("");
                      }}
                    >
                      {appraisalInfo?.categoryDtoList?.map((category, i) => {
                        return (
                          <option
                            key={i.toString()}
                            value={category.categoryId}
                          >
                            {category.name}
                          </option>
                        );
                      })}
                    </select>
                  </Flex>
                ),
              },
              {
                label: "브랜드(제조사)",
                type: "render",
                render: (
                  <Flex row>
                    <select
                      disabled={
                        isKorda &&
                        editAbleStatus.includes(recentStatus) === true
                          ? false
                          : true
                      }
                      style={{ width: 250, fontSize: 16 }}
                      value={brandName}
                      onChange={(e) => {
                        setBrandName(e.target.value);
                      }}
                    >
                      {brandNameList?.map((brand, i) => {
                        return (
                          <option
                            key={i.toString()}
                            value={brand.categoryDetailId}
                          >
                            {brand.brand}
                          </option>
                        );
                      })}
                    </select>
                  </Flex>
                ),
              },
              {
                label: "제품명(모델명)",
                type: "render",
                render: (
                  <Flex row>
                    <input
                      disabled={
                        isKorda &&
                        editAbleStatus.includes(recentStatus) === true
                          ? false
                          : true
                      }
                      style={{ height: 40, width: 250 }}
                      value={productName}
                      onChange={(e) => {
                        setProductName(e.target.value);
                      }}
                    />
                  </Flex>
                ),
              },
              {
                label: "시리얼 번호",
                type: "render",
                render: (
                  <Flex row>
                    <input
                      disabled={
                        isKorda &&
                        editAbleStatus.includes(recentStatus) === true
                          ? false
                          : true
                      }
                      style={{ height: 40, width: 250 }}
                      value={serialNumber}
                      onChange={(e) => {
                        setSerialNumber(e.target.value);
                      }}
                    />
                  </Flex>
                ),
              },
              {
                label: "시중신품가격",
                type: "render",
                render: (
                  <Flex row>
                    <input
                      disabled={
                        isKorda &&
                        editAbleStatus.includes(recentStatus) === true
                          ? false
                          : true
                      }
                      style={{ height: 40, width: 250, textAlignLast: "right" }}
                      value={numberWithCommas(productPrice)}
                      onChange={(e) => {
                        setProductPrice(e.target.value);
                      }}
                      placeholder="원"
                    />
                  </Flex>
                ),
              },
              {
                label: "제조출시년도",
                type: "render",
                render: (
                  <Flex row>
                    <input
                      disabled={
                        isKorda &&
                        editAbleStatus.includes(recentStatus) === true
                          ? false
                          : true
                      }
                      type="date"
                      className={classes.datepicker}
                      style={{ height: 40, width: 250 }}
                      value={manufacturingReleaseYear}
                      onChange={(e) => {
                        setManufacturingReleaseYear(e.target.value);
                      }}
                    />
                  </Flex>
                ),
              },
              {
                label: "보증서",
                type: "render",
                render: (
                  <Flex row>
                    <select
                      disabled={
                        isKorda &&
                        editAbleStatus.includes(recentStatus) === true
                          ? false
                          : true
                      }
                      style={{ width: 250, fontSize: 16 }}
                      value={isContract}
                      onChange={(e) => {
                        setIsContract(e.target.value);
                      }}
                    >
                      <option value={true}>{"유"}</option>
                      <option value={false}>{"무"}</option>
                    </select>
                  </Flex>
                ),
              },
              {
                label: "제품상태",
                type: "render",
                render: (
                  <Flex row>
                    <select
                      disabled={
                        isKorda &&
                        editAbleStatus.includes(recentStatus) === true
                          ? false
                          : true
                      }
                      style={{ width: 250, fontSize: 16 }}
                      value={productStatus}
                      onChange={(e) => {
                        setProductStatus(e.target.value);
                      }}
                    >
                      <option value={"새상품"}>{"새상품"}</option>
                      <option value={"매우좋음"}>{"매우좋음"}</option>
                      <option value={"좋음"}>{"좋음"}</option>
                      <option value={"보통"}>{"보통"}</option>
                    </select>
                  </Flex>
                ),
              },
              {
                label: "예상 판매가격",
                type: "render",
                render: (
                  <Flex row>
                    <input
                      disabled={
                        isKorda &&
                        editAbleStatus.includes(recentStatus) === true
                          ? false
                          : true
                      }
                      style={{ height: 40, width: 250, textAlignLast: "right" }}
                      value={numberWithCommas(estimatedSalePrice)}
                      onChange={(e) => {
                        setEstimatedSalePrice(e.target.value);
                      }}
                      placeholder="원"
                    />
                  </Flex>
                ),
              },
              {
                label: "감정비용",
                type: "render",
                render: (
                  <Flex row>
                    <Text style={{ height: 40, width: 250 }}>
                      {numberWithCommas(appraisalInfo.authPricePenalty)} 원
                    </Text>
                  </Flex>
                ),
              },
            ]}
          />
          <Flex className={classes.buttonDiv}>
            <AdminConfirmButton
              popupLabel="감정 정보 변경"
              popupMessage="변경사항을 저장하시겠습니까?"
              label="저장"
              callback={onSaveAdmin}
              rootClassName={classes.confirmRoot}
              btnClassName={classes.confirmButton}
            />
          </Flex>
        </Flex>
      </AccordionDetails>
    </Accordion>
  );
}
const useStyle = makeStyles({
  grid: {
    justifyContent: "center",
    margin: "0px 50px",
    "& div": {
      display: "flex",
    },
  },

  buttonDiv: {
    alignItems: "end",
  },

  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 20,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
  accordion: {
    marginBottom: "20px",
  },
  input: {
    flex: 1,
    marginRight: "20px",
    paddingLeft: "16px",
    width: "800px",
    height: 40,
    border: "1px solid rgb(224,224,224)",
  },
  saveButton: { width: 100, alignItems: "right" },

  bold: {
    fontWeight: "bold",
  },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "11px",
    padding: "5px 10px",
    border: "1px solid rgba(0,0,0,0.23)",
    width: "auto",
  },
});
