import { Dialog, Grid, makeStyles, TablePagination } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import SimpleTable from "../../components/simple-table/SimpleTable";
import fonts from "../../libs/fonts";

export default function HistoryPopup({ onClose }) {
  const classes = useStyle();
  return (
    <Dialog fullWidth open maxWidth="md">
      <Flex row className={classes.header}>
        위탁 내역
        <Close
          onClick={onClose}
          style={{ cursor: "pointer", position: "absolute", right: 20 }}
        />
      </Flex>

      <Grid container style={{ padding: "20px 50px" }}>
        <Grid item xs={6} className={classes.grid1}>
          홍길동
        </Grid>
        <Grid className={classes.grid2} item xs={6}>
          등록일 : 2020.03.23
        </Grid>
        <Grid item xs={2} className={classes.grid3}>
          휴대폰
        </Grid>
        <Grid item xs={4} className={classes.grid4}>
          010-2188-3985
        </Grid>
        <Grid item xs={2} className={classes.grid3}>
          출금계좌
        </Grid>
        <Grid item xs={4} className={classes.grid4}>
          국민은행 12391938193 홍길동
        </Grid>
        <Grid item xs={2} className={classes.grid3}>
          생년월일/성별
        </Grid>
        <Grid item xs={4} className={classes.grid4}>
          540101, 남
        </Grid>
        <Grid item xs={2} className={classes.grid3}>
          주소
        </Grid>
        <Grid item xs={4} className={classes.grid4}>
          서울시 강남구 테헤란로 12, 1212-12
        </Grid>
        <Grid item xs={2} className={classes.grid3}>
          메모
        </Grid>
        <Grid className={classes.grid4} item xs={4}>
          10돈 순금열쇠 2개 4/25일까지 수령하길 원함
        </Grid>
      </Grid>

      <SimpleTable
        data={[{}]}
        fullWidth
        columns={[
          { label: "총 위탁 건수" },
          { label: "매수 대금 합계" },
          { label: "매도 대금 합계" },
          { label: "금 위탁 중량 합계" },
          { label: "은 위탁 중량 합계" },
          { label: "실물인출" },
          { label: "미완료" },
        ]}
      />

      <SimpleTable
        data={[{}, {}, {}, {}]}
        columns={[
          { label: "위탁일" },
          { label: "주문금액" },
          { label: "구분" },
          { label: "메모" },
          { label: "정산완료 여부" },
        ]}
        fullWidth
      />
      <TablePagination
        style={{ margin: "0px 50px", border: "none" }}
        page={1}
        count={100}
      />
      <Button label="닫기" onClick={onClose} className={classes.closeButton} />
    </Dialog>
  );
}

const useStyle = makeStyles({
  closeButton: {
    width: 100,
    marginBottom: 50,
    alignSelf: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "gray",
    justifyContent: "center",
    position: "relative",
    height: 50,
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
  },
  grid1: {
    fontFamily: fonts.notoSansKRBold,
    fontSize: 18,
    color: "black",
  },
  grid2: {
    textAlign: "right",
  },
  grid3: {
    marginTop: 10,
    color: "gray",
  },
  grid4: {
    marginTop: 10,
    color: "black",
  },
});
