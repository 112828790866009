import {
  FormControlLabel,
  Grid,
  InputBase,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import SimpleTable from "../../components/simple-table/SimpleTable";
import MainLayout from "../../layouts/main/MainLayout";
import consts from "../../libs/consts";
import routes from "../../libs/routes";
import { actionError, actionOpen } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import moment from "moment-timezone";
import { formatPhone } from "../../services/utils";
export default function Step1({ status, setStatus, remark, setRemark }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state || {};
  const inputRef = useRef();
  const classes = useStyle();
  const [data, setData] = useState();

  useEffect(() => {
    if (status === "승인") {
      setRemark("");
    } else {
    }
  }, [status]);

  return (
    <Flex className={classes.mainC2}>
      <Flex row className={classes.header}>
        접수여부
      </Flex>
      <Flex className={classes.mainC2Content}>
        <RadioGroup
          value={status}
          onChange={(e) => {
            if (e.target.value === "반려") {
              inputRef.current.focus();
            }
            setStatus(e.target.value);
          }}
          row
          style={{ marginTop: 10 }}
        >
          <FormControlLabel
            value="승인"
            label="승인"
            control={<Radio color="default" style={{ color: "black" }} />}
          />
          <FormControlLabel
            value="반려"
            label="반려"
            control={<Radio color="default" style={{ color: "black" }} />}
          />
        </RadioGroup>
        <InputBase
          multiline
          value={remark}
          ref={inputRef}
          disabled={status === "승인"}
          onChange={(e) => setRemark(e.target.value)}
          rows={5}
          className={classes.multiInput}
          placeholder="반려사유 입력"
        />
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  bt1: {
    backgroundColor: "#fff",
    border: "1px solid black",
    color: "black",
    "& span": { color: "black" },
    marginRight: 10,
  },
  multiInput: {
    border: "1px  solid #ddd",
    minWidth: "70%",
    padding: 10,
    marginTop: 20,
  },
  img: {
    width: 40,
    height: 40,
    marginRight: 10,
    border: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2ContentGrid: {
    alignItems: "center",
    width: "auto !important",
    "& div": {},
  },
  mainC1Content: {
    padding: "10px 20px",
  },
  mainC2Content: {
    alignItems: "center",
    padding: 50,
  },
  main: {
    marginRight: "20%",
    alignSelf: "stretch",
    margin: "0px 50px",
    border: "1px solid rgba(0,0,0,0.23)",
    marginTop: 30,
  },
  buttons: {
    marginRight: "20%",
    marginTop: 30,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  header: {
    backgroundColor: "#f5f5f5",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    alignSelf: "stretch",
    padding: 10,
  },
  mainC1: {
    marginLeft: 50,
    flex: 4,
    borderRight: "1px solid rgba(0,0,0,0.23)",
  },
  mainC2: {
    marginRight: 50,
    flex: 3,
  },
  label: {
    margin: "0px 50px",
    marginTop: 30,
  },
  container: {
    margin: "30px 50px 100px 50px",
    boxSizing: "border-box",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "auto !important",
    "& div": {
      height: "auto",
      textAlign: "center",
      padding: 10,
    },
  },
  input: {
    border: "1px solid rgba(0,0,0,0.23)",

    padding: "5px 10px",
  },
});
